import DateFnsUtils from "@date-io/date-fns";
import {
  Backdrop,
  Box, Card,
  CardContent, Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Portal,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Alert, Button,
  ChevronDownIcon,
  CloseIcon, LoadingScreen, MoreHorizontalIcon, Notification, NotificationWithDialog, Page,
  SearchIcon,
  Tabs,
} from "@primeroedge/ui-components";
import clsx from "clsx";
import {
  AddAll, fetchMailUserList, RemoveAll,
  RemoveUser,
  resetSelectedUsers,
  selectedBroadcastMessage,
  SelectedUser, setGlobalMessage, setUserMail, updateUsers,
} from "control/actions";
import { postMessage } from "control/transport/message-api";
import _ from "lodash";
import { MailEntity } from "model/entity/mail.entity";
import { getIsGlobalMessage, getSelectedUsers } from "model/entity/message.entity";
import React, { FC, Fragment, useEffect, useState } from "react";
import {
  Maximize as MaximizeIcon,
  Minimize as MinimizeIcon,
  X as XIcon,
} from "react-feather";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import QuillEditor from "../QuillEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: `calc(100% - ${theme.spacing(6)}px)`,
    maxHeight: `calc(100% - ${theme.spacing(6)}px)`,
    width: 600,
    position: "fixed",
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
    outline: "none",
    zIndex: 1300,
    display: "flex",
    flexDirection: "column",
    minHeight: 500,
    overflow: "auto",
  },
  fullScreen: {
    height: "100%",
    width: "100%",
    zIndex: 1300,
  },
  editor: {
    flexGrow: 1,
    "& .ql-editor": {
      minHeight: 300,
    },
  },
  userList: {
    position: "absolute",
    background: "white",
    zIndex: 2,
    border: "1px solid",
    maxHeight: "300px",
    overflowX: "auto",
  },
  userListItem: {
    padding: "5px 10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f4f6f8",
    },
  },
  action: {
    marginRight: theme.spacing(1),
  },
  dateFilter: {
    width: "220px",
    marginRight: "10px",
    marginTop: "10px",
  },
  search: {
    width: "100%",
    padding: "10px",
  },
  chip: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

interface IComposeBroadcastProps {
  onClose: () => void;
  onPost: () => void;
  editable: any;
  message: any;
  view?: any;
}

const ComposeBroadcast: FC<IComposeBroadcastProps> = ({
  onClose,
  onPost,
  editable,
  message,
  view,
}) => {
  const classes = useStyles();
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [messageBody, setMessageBody] = useState<string>("");
  const [recipient, setRecipient] = useState(false);
  const [missingInfo, setMissingInfo] = useState<string[]>([]);
  const [broadcastStartDateTime, setBroadcastStartDateTime] = useState<any>(
    new Date(new Date().getTime() + 600000),
  );
  const [broadcastEndDateTime, setBroadcastEndDateTime] = useState<any>(null);
  const [createdOn, setCreatedOn] = useState<any>(new Date());
  const [closeBroadcastCompose, setCloseBroadcastCompose] = useState<any>(
    false,
  );
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const [warning, setWarning] = useState("");
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const usersSelect = useSelector(getSelectedUsers);
  const isGlobalMessage = useSelector(getIsGlobalMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RemoveAll());
    if (editable || view) {
      setMessageBody(message.content);
      setBroadcastStartDateTime(message.broadcastStartDateTime);
      setBroadcastEndDateTime(message.broadcastEndDateTime);
      setCreatedOn(message.createdOn);
      if (message.recipient) {
        message.recipient.forEach((ele: any) => {
          const user: any = {};
          user.eMail = ele.recipientEmail;
          user.firstName = ele.recipientFirstName;
          user.lastName = ele.recipientLastName;
          user.primeroUserId = ele.perseususer;
          user.userGuid = ele.recipientId;
          dispatch(SelectedUser(user));
        });
      }
    }

    return () => {
      dispatch(RemoveAll());
    };
  }, [message]);

  const handleChange = (value: string): void => {
    setMessageBody(value);
  };

  const handleStartDateChange = (value: any): void => {
    setBroadcastStartDateTime(value);
  };

  const handleEndDateChange = (value: any): void => {
    setBroadcastEndDateTime(value);
  };

  const handleExitFullScreen = (): void => {
    setFullScreen(false);
  };

  const handleEnterFullScreen = (): void => {
    setFullScreen(true);
  };

  const handleClose = () => {
    if (view || editable) {
      confirmClose();
    } else if (usersSelect.length || recipient || messageBody.length) {
      setCloseBroadcastCompose(true);
    } else {
      confirmClose();
    }
    setMissingInfo(() => []);
  };

  const confirmClose = () => {
    onClose();
    dispatch(selectedBroadcastMessage({}));
    dispatch(setGlobalMessage(false));
  };

  const cancelClose = () => {
    setCloseBroadcastCompose(false);
  };

  const handleDelete = (user: any) => {
    dispatch(RemoveUser(user));
  };

  useEffect(() => {
    if (broadcastStartDateTime < new Date()) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    } if (broadcastStartDateTime >= broadcastEndDateTime) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
    
  }, [broadcastStartDateTime, broadcastEndDateTime])

  const handleSend = () => {
    if (messageBody.length > 5000) {
      setWarning(`This message contains ${messageBody.length} characters. Messages cannot exceed 5000 characters (including HTML tags).`);
      return;
    }
    setWarning("");
    const sendMessage: any = {};
    let dateError: boolean = false;
    sendMessage.content = messageBody;
    sendMessage.broadcastStartDateTime = broadcastStartDateTime;
    sendMessage.broadcastEndDateTime = broadcastEndDateTime;
    sendMessage.subject = "Broadcast";
    if(isGlobalMessage) {
      sendMessage.isGlobalMessage = isGlobalMessage;
    } else {
      sendMessage.isGlobalMessage = false;
      sendMessage.recipient = [];
    }
    const errors: string[] = [];
    if (broadcastStartDateTime < new Date()) {
      dateError = true;
      setStartDateError(true);
    } else {
      dateError = false;
      setStartDateError(false);
    }
    if (broadcastStartDateTime >= broadcastEndDateTime) {
      dateError = true;
      setEndDateError(true);
    } else {
      dateError = false;
      setEndDateError(false);
    }
    if (!isGlobalMessage && usersSelect.length === 0 && !errors.includes("To")) {
      errors.push("To");
    }
    if (messageBody.length === 0 && !errors.includes("Message Body")) {
      errors.push("Message Body");
    }
    setMissingInfo(() => errors);
    if (!dateError && !startDateError && !endDateError) {
      if (usersSelect.length > 0 && messageBody.length > 0) {
        usersSelect.forEach((user) => {
          sendMessage.recipient.push({
            recipientId: user.userGuid,
            recipientFirstName: user.firstName,
            recipientLastName: user.lastName,
            recipientEmail: user.eMail,
            recipientPrimeroUserId: user.primeroUserId,
          });
        });
      }
      postMessage(sendMessage).then(() => {
        dispatch(setGlobalMessage(false));
        onPost();
      });
      setMissingInfo(() => []);
    }
  };

  return (
    <Page>
      <Portal>
        <Backdrop open={true} className="backdrop-bg" />
        <Paper
          className={clsx(classes.root, { [classes.fullScreen]: fullScreen })}
          elevation={12}
        >
          <Box
            bgcolor="background.dark"
            display="flex"
            alignItems="center"
            py={1}
            px={2}
          >
            {warning !== "" && (
              <Notification
                color="error"
                duration={5000}
                message={warning}
                onClose={() => setWarning("")}
                variant="filled"
              />
            )}
            <Typography variant="h5" color="textPrimary">
              {view ? "VIEW" : editable ? "EDIT" : "NEW"} BROADCAST
            </Typography>
            <Box flexGrow={1} />
            {fullScreen ? (
              <IconButton id="minimizeButton" type="button" title="minimize-button" onClick={handleExitFullScreen}>
                <SvgIcon fontSize="small">
                  <MinimizeIcon />
                </SvgIcon>
              </IconButton>
            ) : (
              <IconButton id="maximizeButton" type="button" title="maximize-button" onClick={handleEnterFullScreen}>
                <SvgIcon fontSize="small">
                  <MaximizeIcon />
                </SvgIcon>
              </IconButton>
            )}
            <IconButton id="closeButton" type="button" title="close-button" onClick={handleClose}>
              <SvgIcon fontSize="small">
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          {missingInfo.length > 0 && <Alert color={"error"} message={`Please enter the following information before broadcasting: ${missingInfo.join(", ")}.`} />}
          <Box p={2} display="flex" style={{ padding: "16px 16px 0px 16px" }}>
            <Box p={2} display="flex">
              <Typography>
                To:{view ? ":" : ""}
                {(view && !isGlobalMessage) &&
                  usersSelect &&
                  usersSelect.map((user: any, i: number) => (
                    <Fragment key={i}>{`${user.eMail}, `}</Fragment>
                  ))}{" "}
                  {isGlobalMessage && 'Global Message'}
              </Typography>
              {!view && (
                <ChevronDownIcon onClick={() => setRecipient(!recipient)} />
              )}
            </Box>
            <div className={classes.chip}>
              {usersSelect &&
                !view &&
                usersSelect.map((user: any, i: number) =>
                  i <= 7 ? (
                    <Chip
                      key={user.userGuid}
                      label={user.firstName}
                      onDelete={() => handleDelete(user)}
                      deleteIcon={<CloseIcon />}
                    />
                  ) : (
                    ""
                  ),
                )}
              {usersSelect.length > 7 && <MoreHorizontalIcon />}
            </div>
            {recipient && (
              <Recipient
                cancel={(data: any, flag: boolean) => {
                  if (data) {
                    dispatch(updateUsers(data));
                  }
                  setRecipient(!flag);
                  dispatch(setUserMail([]));
                }}
              />
            )}
          </Box>
          <Moment
            format="MMM DD, yyyy hh:mm:ss a"
            style={{ paddingLeft: "30px", paddingBottom: "10px" }}
          >
            {createdOn}
          </Moment>
          <Divider />
          <QuillEditor
            className={classes.editor}
            onChange={handleChange}
            placeholder="Leave a message"
            value={messageBody}
            readOnly={view}
          />
          {!view && (
            <>
              <Divider />
              <Box alignItems="center" py={1} px={2}>
                <Typography variant="h5" color="textPrimary">
                  Schedule
                </Typography>
                <div style={{ display: "flex" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      format={"MM/dd/yyyy hh:mm a"}
                      label="Post On"
                      variant="inline"
                      inputVariant="outlined"
                      value={broadcastStartDateTime}
                      onChange={handleStartDateChange}
                      className={classes.dateFilter}
                      disablePast={true}
                      disabled={view}
                      inputProps={{
                        "aria-label": "Post On",
                      }}
                      error={startDateError}
                      helperText={startDateError ? "Broadcast StartDateTime should be future Date" : ""}
                    />
                    <DateTimePicker
                      clearable={true}
                      format={"MM/dd/yyyy hh:mm a"}
                      label="Expire On"
                      variant="inline"
                      inputVariant="outlined"
                      value={broadcastEndDateTime}
                      onChange={handleEndDateChange}
                      className={classes.dateFilter}
                      disablePast={true}
                      disabled={view}
                      inputProps={{
                        "aria-label": "Expire On",
                      }}
                      error={endDateError}
                      helperText={endDateError ? "Broadcast EndDateTime should be future Date" : ""}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Box>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                py={1}
                px={2}
              >
                <Button
                  color="primary"
                  id="send-button"
                  onClick={handleSend}
                  className={classes.action}
                  disabled={view}
                >
                  Post
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Portal>
      <NotificationWithDialog
        open={Boolean(closeBroadcastCompose)}
        title="Close New Broadcast Message"
        message="Closing this window will discard this message. Proceed?"
        color="primary"
        primaryAction={{
          title: "Yes",
          callback: () => confirmClose(),
        }}
        secondaryAction={{
          title: "No",
          callback: () => cancelClose(),
        }}
      />
    </Page>
  );
};

const GroupsTab: FC<any> = ({ prevUsers, setPrevUsers }) => {
  const classes = useStyles();
  const [checked] = React.useState([0]);

  useEffect(() => {
    setPrevUsers(prevUsers || []);
  }, []);

  return (
    <Box>
      <TextField
        className={classes.search}
        id="input-with-icon-textfield"
        label="Search"
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <div>
        <Paper style={{ maxHeight: 150, overflow: "scroll" }}>
          <List
            component="nav"
            aria-label="secondary mailbox folders"
            dense={true}
          >
            <ListItem button={true}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(1) !== -1}
                  tabIndex={-1}
                  disableRipple={true}
                  inputProps={{ "aria-label": "sas-1" }}
                />
              </ListItemIcon>
              <ListItemText primary="Select All" />
            </ListItem>
            <ListItem button={true} />
          </List>
        </Paper>
      </div>
    </Box>
  );
};

const UsersTab: FC<any> = ({ prevUsers, setPrevUsers }) => {
  const classes = useStyles();
  const [selectedAll, setSelectedAll] = useState(false);
  const selectedUsers = useSelector(getSelectedUsers);
  const [showList, setShowList] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [noUsersMessage, setNoUsersMessage] = useState("");
  const list = useSelector(MailEntity.getUsersList);
  const isLoading = useSelector(MailEntity.isLoading);
  const isError = useSelector(MailEntity.isError);
  const isGlobalMessage = useSelector(getIsGlobalMessage);

  useEffect(() => {
    setPrevUsers(prevUsers || []);
  }, []);

  const selectUser = (value: any, e: any) => {
    if (e.target.checked) {
      dispatch(SelectedUser(value));
    } else {
      dispatch(RemoveUser(value));
    }
  };

  useEffect(() => {
    if (list && list.length > 0) {
      setShowList(() => true);
      setNoUsersMessage("");
      if (filteredUsers.length === 0) {
        setSelectedAll(true);
      }
    }
  }, [list]);

  const filteredUsers = _.differenceWith(list, prevUsers, function (
    o1: any,
    o2: any,
  ) {
    return o1.userGuid === o2.userGuid;
  });

  const search = (event: any) => {
    if (event.target.value.length >= 3) {
      setSelectedAll(false);
      setShowList(false);
      dispatch(fetchMailUserList(event.target.value));
      setNoUsersMessage("We did not find a user with that email");
    } else {
      setShowList(false);
      setNoUsersMessage("");
    }
  };

  const checkUser = (user: any) => {
    return (
      selectedUsers.filter((ele: any) => ele.userGuid === user.userGuid)
        .length > 0
    );
  };

  const selectAll = (e: any) => {
    setSelectedAll(e.target.checked);
    if (e.target.checked) {
      dispatch(AddAll([...prevUsers, ...filteredUsers]));
    } else {
      dispatch(RemoveAll());
    }
  };

  return (
    <Box>
      <TextField
        className={classes.search}
        disabled={isGlobalMessage}
        id="input-with-icon-textfield"
        label="Search"
        variant="outlined"
        size="small"
        onChange={search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <div>
        <Paper style={{ maxHeight: 130, minHeight: 130, overflow: "scroll" }}>
          <List
            component="nav"
            aria-label="secondary mailbox folders"
            dense={true}
          >
            {isLoading && (
              <ListItem>
                <LoadingScreen />
              </ListItem>
            )}

            {!isError && list && !list.email && !isLoading && noUsersMessage && (
              <ListItem>
                <Card variant="outlined">
                  <CardContent>
                    <Typography color="error">{noUsersMessage}</Typography>
                  </CardContent>
                </Card>
              </ListItem>
            )}
            {list && list.length > 0 && showList && (
              <ListItem button={true}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={
                      selectedAll || selectedUsers.length === list.length
                    }
                    tabIndex={-1}
                    disableRipple={true}
                    inputProps={{ "aria-label": "sas-1" }}
                    onClick={(e) => selectAll(e)}
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </ListItem>
            )}
            {list &&
              list.length > 0 &&
              showList &&
              list.map((user: any, i: number) => (
                <ListItem
                  button={true}
                  key={i}
                  onClick={(e) => selectUser(user, e)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checkUser(user)}
                      tabIndex={i}
                      disableRipple={true}
                      inputProps={{ "aria-label": `sas-${i}` }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${user.lastName}, ${user.firstName} (${user.eMail})`}
                  />
                </ListItem>
              ))}
          </List>
        </Paper>
      </div>
    </Box>
  );
};

const Recipient: FC<any> = ({ cancel }) => {
  const usersSelect = useSelector(getSelectedUsers);
  const [prevUsers, setPrevUsers] = useState([]);
  const isGlobalMessage = useSelector(getIsGlobalMessage);
const dispatch = useDispatch();
  return (
    <Box
      style={{
        zIndex: 3000,
        width: "450px",
        height: "350px",
        position: "absolute",
        borderRadius: "5px",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
        backgroundColor: "#FFFFFF",
        marginTop: "40px",
      }}
    >
      <Typography variant="h6" color="textPrimary" style={{ padding: "10px" }}>
        RECIPIENT
      </Typography>
      <Box>
        <Tabs
          gutterBottom={true}
          onChange={function noRefCheck() { }}
          tabs={[
            {
              component: (
                <GroupsTab
                  prevUsers={usersSelect}
                  setPrevUsers={setPrevUsers}
                />
              ),
              label: "MAILING GROUPS",
              value: "GroupsTab",
              hidden: true,
            },
            {
              component: (
                <UsersTab prevUsers={usersSelect} setPrevUsers={setPrevUsers} />
              ),
              label: "USERS",
              value: "UsersTab",
            },
          ]}
          textColor="primary"
          value="UsersTab"
          variant="contained"
        />
      </Box>
      <Divider />
      <Box display="flex" alignItems='center'>
      <FormControlLabel
          control={(
            <Checkbox
              id={"global-message"}
              checked={isGlobalMessage}
              color="primary"
              onChange={(event: any) => {
                dispatch(resetSelectedUsers());
                dispatch(setGlobalMessage(event.target.checked))
              }}
            />
          )}
          label={<Typography style={{fontSize: '14px'}}>Global Message</Typography>}
          style={{display: 'flex', justifyContent: 'flex-start', marginLeft: '16px'}}
      />
      <div style={{marginLeft: '59px'}}>
        <Button
          color="default"
          dense={true}
          id="defaultButton"
          label="Cancel"
          name="Button"
          onClick={function noRefCheck() {

            cancel(prevUsers, true);
          }}
          type="button"
          variant="default"
          style={{ margin: "10px" }}
        />

        <Button
          color="primary"
          dense={true}
          id="primaryButton"
          label="Ok"
          name="Button"
          onClick={function noRefCheck() {
            cancel(usersSelect, true);
          }}
          type="button"
          variant="default"
          style={{ margin: "10px" }}
        />
      </div>
      </Box>
    </Box>
  );
};
export default ComposeBroadcast;
