import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { AuthProvider, MainProvider } from "@primeroedge/ui-components";
import React from "react";
import { Route } from "react-router";
import { BrowserRouter, Switch } from "react-router-dom";
import {
  API_USER_MANAGEMENT,
  APIM_BASE_URL,
  APIM_ENVIRONMENT,
  APIM_SUBSCRIPTION_KEY,
  REACT_APP_ENABLE_APP_INSIGHTS,
} from "../control/transport/clients/api-constants";
import { reactPlugin } from "./appInsights";
import LoginPage from "./pages/login-page";
import routes from "./routes";
import RouteWithSubRoutes from "./RouteWithSubRoutes";
import "./styles.scss";

export function AppInsightsEnabled() {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MainProvider>
        <AuthProvider
          loginUrl={`${API_USER_MANAGEMENT}`}
          environment={`${APIM_ENVIRONMENT}`}
          apimUrl={`${APIM_BASE_URL}`}
          subscriptionKey={`${APIM_SUBSCRIPTION_KEY}`}
        >
          <BrowserRouter>
            <Switch>
              <Route path="/login" component={LoginPage} />
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </BrowserRouter>
        </AuthProvider>
      </MainProvider>
    </AppInsightsContext.Provider>
  );
}

export function AppInsightsDisabled() {
  return (
    <MainProvider>
      <AuthProvider
        apimUrl={`${APIM_BASE_URL}`}
        loginUrl={`${API_USER_MANAGEMENT}`}
        environment={`${APIM_ENVIRONMENT}`}
        subscriptionKey={`${APIM_SUBSCRIPTION_KEY}`}
      >
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={LoginPage} />
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </BrowserRouter>
      </AuthProvider>
    </MainProvider>
  );
}

const App: React.FC = () => {
  return REACT_APP_ENABLE_APP_INSIGHTS === "true" ? (
    AppInsightsEnabled()
  ) : (
    AppInsightsDisabled()
  );
};

export default App;
