import React from "react";
import ActivitiesPage from "./activites";

const Activities = () => {
    return (
        <>
            <ActivitiesPage />
        </>
    );
};

export default Activities;
