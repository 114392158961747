import { IAppState } from "../reducers";

export interface IGradeGroupState {
    gradeGroups: IGradeGroups[];
    availableGrades: IGrades[];
    gradeGroupStatus: string;
    addGradeGroupSuccess: string;
    isLoading: boolean;
}

export interface IGradeGroup {
    id: string;
    name: string;
    grades: IGrades[];
    gradeGroupAddSuccess?: any;
    isLoading: boolean;
}

export interface IGrades {
    districtRegionId: number;
    id: number;
    name: string;
    rdaGroupId: number;
}

export interface IGradeGroups {
    districtRegionId: number;
    grades: IGrades[];
    id: number;
    name: string;
}

export interface IAddEditGradeGroupPayload {
    grades: IGrades[] | [];
    id: number;
    name: string;
}

export enum GradeGroupStatus {
    Initial = "Initial",
    addSuccessful = "Add Success",
    editSuccessful = "Edit Success",
    addFail = "Add Failed",
    editFail = "Edit Failed",
}

export class GradeGroupEntity {

    public static gradeGroupAddSuccess ({ gradeGroup }: IAppState) {
        return gradeGroup.addGradeGroupSuccess;
    }

    public static getGradeGroups ({ gradeGroup }: IAppState) {
        return gradeGroup.gradeGroups;
    }

    public static getAvailableGrades ({ gradeGroup }: IAppState) {
        return gradeGroup.availableGrades;
    }

    public static getIsLoading ({ gradeGroup }: IAppState) {
        return gradeGroup.isLoading;
    }

    public static getGradeGroupAddEditStatus ({ gradeGroup }: IAppState) {
        return gradeGroup.gradeGroupStatus;
    }
}
