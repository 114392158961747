import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import { AuthLogout, setActiveMessageTabValue, setWorkspaceDetails, setWorkspaceStatus } from "../../control/actions";
import { ActiveMessageTabValue, IWorkspaceDetails, ResponseStatus } from "../entity";

const initialState: IWorkspaceDetails = {
    status: ResponseStatus.Initial,
    activeMessageTabValue: ActiveMessageTabValue.initialValue,
};

export const workSpaceDetailsReducer = createReducer(initialState, {
    [setWorkspaceDetails.toString()]: (state, action) =>
        produce(state, (draft: IWorkspaceDetails) => {
            draft.broadcastMessages = action.payload.broadcastMessages;
            draft.favorites = action.payload.favorites;
            draft.isBroadcastUser = action.payload.isBroadcastUser;
            draft.messages = action.payload.messages;
            draft.modules = action.payload.modules?.length > 1
                // Remove "" modules
                ? action.payload.modules.filter((module: string) => !!module)
                : action.payload.modules;
            draft.reports = action.payload.reports;
            draft.tasks = action.payload.tasks;
            draft.totalUnreadBroadcastMessages = action.payload.totalUnreadBroadcastMessages;
            draft.totalUnreadMessages = action.payload.totalUnreadMessages;
        }),
    [setWorkspaceStatus.toString()]: (state, action) =>
        produce(state, (draft: IWorkspaceDetails) => {
            draft.status = action.payload;
        }),
    [setActiveMessageTabValue.toString()]: (state, action) =>
        produce(state, (draft: IWorkspaceDetails) => {
            draft.activeMessageTabValue = action.payload;
        }),
    [AuthLogout.toString()]: () => initialState,
});
