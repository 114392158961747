import { Badge, withStyles } from "@material-ui/core";

const TabBadge = withStyles(() => ({
    badge: {
        top: 12,
        right: -18,
    },
}))(Badge);

export default TabBadge;
