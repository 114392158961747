import { AlertCircleIcon } from '@primeroedge/ui-components';
import { deleteCustomReportFavoriteInit, getFavoriteCustomReportsInit, uiShowMainWindowInit } from 'control/actions/customReports.actions';
import {MouseEvent, useState} from 'react';
import { useDispatch } from 'react-redux';

export interface IAlertContent {
    icon: any,
    message: string,
    color: "default" | "secondary" | "primary" | "warning" | "tertiary" | "success" | "error" | "info" | undefined,
}
export const useFavoriteReportMenu = (props: any) => {
    const dispatch = useDispatch();
    const [favoritesMenuAnchorElement, setFavoritesMenuAnchorElement] = useState<HTMLElement | null>(null);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const handleFavoriteMenuClick = (ev: MouseEvent<HTMLButtonElement>) => {
        setFavoritesMenuAnchorElement(ev.currentTarget);
    };
    const handleFavoriteMenuClose = () => {
        setFavoritesMenuAnchorElement(null);
    };

    const deleteCustomReportFavoriteCallback = function (isSuccess: boolean) {
        return () => {
            if (isSuccess) {
                props.setAlertContent({
                    icon: AlertCircleIcon,
                    message: `Custom Report Unstarred Successfully.`,
                    color: "success",
                });
                dispatch(getFavoriteCustomReportsInit());
            } else {
                props.setAlertContent({
                    icon: AlertCircleIcon,
                    message: `Failed to unstar Custom Report`,
                    color: "error",
                });
            }
        };
    };
    const onConfirmOnStar = (customReportFavoriteId: number) => {
        handleFavoriteMenuClose();
        setShowConfirmation(false);
        dispatch(deleteCustomReportFavoriteInit({
            customReportFavoriteId,
            successCallback: deleteCustomReportFavoriteCallback(true),
            errorCallback: deleteCustomReportFavoriteCallback(false)
        }));
    }
    const closeConfirmation = () => {
        handleFavoriteMenuClose();
        setShowConfirmation(false);
    }
    const unStarReport = () => {
        setShowConfirmation(true);
    }
    const sendEmail = () => {
        const data = props.favorite || props.distributedReport;
        handleFavoriteMenuClose();
        dispatch(uiShowMainWindowInit(data));
    }
    return {
        handleFavoriteMenuClick,
        handleFavoriteMenuClose,
        favoritesMenuAnchorElement,
        unStarReport,
        onConfirmOnStar,
        showConfirmation,
        closeConfirmation,
        sendEmail
    }

}