import { APIInstance } from "@primeroedge/ui-components";
import { API_USER_MANAGEMENT, APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY } from "./api-constants";

const userManagementAPIClient = new APIInstance({
    baseURL: API_USER_MANAGEMENT,
    environment: APIM_ENVIRONMENT,
    subscriptionKey: APIM_SUBSCRIPTION_KEY,
}).axios;

export default userManagementAPIClient;
