import { createAction } from "@reduxjs/toolkit";
import { IWorkspaceDetails, ResponseStatus } from "../../model/entity";

export const fetchWorkspaceDetails = createAction("FETCH_WORKSPACE_DETAILS");

export const setWorkspaceDetails = createAction(
    "SET_WORKSPACE_DETAILS",
    (data: IWorkspaceDetails) => ({
        payload: data,
    }),
);

export const setWorkspaceStatus = createAction(
    "SET_WORKSPACE_STATUS",
    (status: ResponseStatus) => ({
        payload: status,
    }),
);

export const setActiveMessageTabValue = createAction(
    "SET_ACTIVE_MESSAGE_TAB_VALUE",
    (activeMessageTabValue: any) => ({
        payload: activeMessageTabValue,
    }),
);

export const deleteMessage = createAction(
    "DELETE_MESSAGE",
    (id: string | string[]) => ({
        payload: id,
    }),
);

export const deleteWorkspaceBroadcast = createAction(
    "DELETE_WORKSPACE_BROADCAST",
    (id: string) => ({
        payload: id,
    }),
);
