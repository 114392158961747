import React from 'react';
import { Menu, MenuItem } from "@material-ui/core";
import { IconButton, MoreVerticalIcon, NotificationWithDialog } from "@primeroedge/ui-components";
import { useFavoriteReportMenu } from "./hooks/useFavoriteReportMenu";

const FavoriteReportMenu = (props: any) => {

    const {
        handleFavoriteMenuClick,
        handleFavoriteMenuClose,
        favoritesMenuAnchorElement,
        unStarReport,
        onConfirmOnStar,
        showConfirmation,
        closeConfirmation,
        sendEmail
    } = useFavoriteReportMenu(props);
    return (
        <>
            <NotificationWithDialog
                message="Are you sure you want to unstar this report?"
                open={showConfirmation}
                primaryAction={{
                    callback: () => onConfirmOnStar(props.favorite.customReportFavoriteId),
                    title: 'Confirm'
                }}
                secondaryAction={{
                    callback: closeConfirmation,
                    title: 'Cancel'
                }}
                title="Confirmation"
            />
            <IconButton
                aria-controls="messagesMenu"
                aria-haspopup="true"
                onClick={handleFavoriteMenuClick}
                size="small"
            >
                <MoreVerticalIcon />
            </IconButton>
            <Menu
                id="messagesMenu"
                anchorEl={favoritesMenuAnchorElement}
                keepMounted={false}
                open={Boolean(favoritesMenuAnchorElement)}
                onClose={handleFavoriteMenuClose}
            >
                <MenuItem onClick={() => sendEmail()}>
                    Email
                </MenuItem>
                <MenuItem onClick={() => unStarReport()}>
                    Unstar
                </MenuItem>
            </Menu>
        </>

    )
}

export default FavoriteReportMenu;