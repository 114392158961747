import { createReducer } from "@reduxjs/toolkit";
import { userAuthLogoutDone } from "control/actions/auth.actions";
import produce from "immer";
import * as actions from "../../control/actions/sitetype.actions";

export enum SiteTypeStatus {
    Initial = "Initial",
    Loading = "InProgress",
    SuccessWithResponseData = "SuccessWithResponseData",
    FailedWithInvalidUser = "InvalidUser",
    FailedWithInvalidPassword = "InvalidPassword",
    FailedWithNetworkError = "NetworkError",
    FailedWithUnknownError = "UnknownError",
}

export interface ISiteTypeState {
    siteTypeByRegion: any;
    siteTypeTypes: any;
    allUSDASiteTypes: any;
    siteTypeGradesAddForm: any;
    siteTypeStatus: SiteTypeStatus;
    sitetypeAddSuccess?: any;
}

const initialState: ISiteTypeState = {
    siteTypeTypes: "",
    siteTypeByRegion: "",
    allUSDASiteTypes: [],
    siteTypeGradesAddForm: "",
    siteTypeStatus: SiteTypeStatus.Initial,
};

export const sitetypeReducer = createReducer(initialState, {

    [actions.fetchSiteTypesByRegionInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.siteTypeStatus = SiteTypeStatus.Loading;
        }),

    [actions.fetchSiteTypesByRegionSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.siteTypeByRegion = action.payload;
            draft.siteTypeStatus = SiteTypeStatus.SuccessWithResponseData;
        }),

    [actions.fetchSiteTypesByRegionFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.siteTypeStatus = SiteTypeStatus.FailedWithUnknownError;
        }),

    [actions.fetchAllSiteTypeTypesSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.siteTypeTypes = action.payload;
            draft.siteTypeStatus = SiteTypeStatus.SuccessWithResponseData;
        }),

    [actions.fetchAllSiteTypeTypeseFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.siteTypeStatus = SiteTypeStatus.FailedWithUnknownError;
        }),

    [actions.fetchAllUSDASiteTypesSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.allUSDASiteTypes = action.payload;
            draft.siteTypeStatus = SiteTypeStatus.SuccessWithResponseData;
        }),

    [actions.fetchAllUSDASiteTypesFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.siteTypeStatus = SiteTypeStatus.FailedWithUnknownError;
        }),

    [actions.fetchSiteTypeGradesAddFormSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.siteTypeGradesAddForm = action.payload;
            draft.siteTypeStatus = SiteTypeStatus.FailedWithUnknownError;
        }),

    [actions.fetchSiteTypeGradesAddFormFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.siteTypeStatus = SiteTypeStatus.FailedWithUnknownError;
        }),

    [actions.sitetypeAddSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.sitetypeAddSuccess = action.payload;
        }),

    [userAuthLogoutDone.toString()]: () => initialState,

});
