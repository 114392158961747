import workspaceAPIClient from "./clients/workspace-api.client";

function fetchMessages (
    pageSize: number,
    pageNumber: number,
    setMessages: any,
    setSize: any,
    setLoading: any
) {
    return workspaceAPIClient({
        method: "GET",
        url: `/api/list-messages?pageSize=${pageSize}&pageNumber=${pageNumber}&sortColumn=id`,
    }).then((responsePayLoad: any) => {
        setMessages(responsePayLoad.data.messages);
        setSize(responsePayLoad.data.totalMessageCount);
        setLoading(false);
    }).catch(() => setLoading(false));
}

function searchUser (value: any) {
    return workspaceAPIClient({
        method: "POST",
        url: `/api/search-broadcastuser`,
        data: { searchText: value },
    });
}

function postMessage (message: any) {
    return workspaceAPIClient({
        method: "POST",
        url: `/api/new-broadcast`,
        data: message,
    });
}

function deleteMessage (data: any) {
    return workspaceAPIClient({
        method: "DELETE",
        url: `/api/delete-message`,
        data,
    });
}
function getGlobalBroadcatBanner (setGlobalBroadcastMessages: any) {
    return workspaceAPIClient({
        method: "GET",
        url: `api/global-broadcast-messages`,
    }).then((responsePayLoad: any) => {
        setGlobalBroadcastMessages(responsePayLoad.data.messages);
    });
}
function getBroadcastBanner (setMessages: any) {
    return workspaceAPIClient({
        method: "GET",
        url: `/api/user-broadcast-messages`,
    }).then((responsePayLoad: any) => {
        setMessages(responsePayLoad.data.messages);
    });
}



function updateBroadCast (data: any) {
    return workspaceAPIClient({
        method: "PATCH",
        url: `/api/update-broadcast`,
        data,
    });
}

export {
    deleteMessage,
    getBroadcastBanner,
    searchUser,
    postMessage,
    updateBroadCast,
    getGlobalBroadcatBanner
};

export default fetchMessages;
