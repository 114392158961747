import { Themes } from "@primeroedge/ui-components";
import _ from "lodash";
import logger from "util/logger";
import administrationAPIClient from "./clients/administration-api.client";
import dataExchangeAPIClient from "./clients/data-exchange-api.client";
import userManagementAPIClient from "./clients/user-management-api.client";

class UsersTransportLayer {

  public static async addUsers (user: any): Promise<any> {
    const dynamicPayload = user.payload;
    const payload: any = {
      id: "0",
      userId: "0",
      firstName: dynamicPayload.firstName,
      lastName: dynamicPayload.lastName,
      mi: dynamicPayload.middleName,
      email: dynamicPayload.email,
      roles: dynamicPayload.roles,
      groups: dynamicPayload.groupsObj,
      enabled: true,
      districtName: dynamicPayload.regionName,
      languagepreference: "English",
      theme: Themes.Perseus,
      attributes: dynamicPayload.attributes,
      workPhone: dynamicPayload.workPhone,
      cellPhone: dynamicPayload.cellPhone,
      image: dynamicPayload.image,
      userStatus: "Disabled",
      regionId: dynamicPayload.regionId,
      isBroadcastUser: dynamicPayload.isBroadcastUser,
    };
    const res = await userManagementAPIClient({
      method: "POST",
      url: "/api/Users/CreateUserAsync",
      data: payload,
    });

    return res.data;
  }

  public static async getRegionsAndSites (payload: string): Promise<any> {
    try {
      const response = await userManagementAPIClient({
        method: "GET",
        url: `/api/Groups/GetAllSitesAsync?groupGuid=${payload}`,
      });

      return response.data.PayLoad;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

  public static async getRoles (): Promise<any> {
    try {
      const response = await userManagementAPIClient({
        method: "GET",
        url: `/api/Roles/GetRolesAsync`,
      });

      return response.data.PayLoad;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

  public static async getOrganization (): Promise<any> {
    try {
      const response = await administrationAPIClient({
        method: "GET",
        url: `/api/OrganizationLevels/organizationlevels`,
      });

      return response.data.PayLoad;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

  public static async editUsers (user: any): Promise<any> {
    const dynamicPayload = user.payload;
    const res = await userManagementAPIClient({
      method: "PUT",
      url: `/api/Users/UpdateUserAsync`,
      data: dynamicPayload,
    });

    return res.data;
  }

  public static async getUser (email: any): Promise<any> {
    const res = await administrationAPIClient({
      method: "GET",
      url: `/api/User/GetUserByEmailAsync${email}`,
    });

    return res.data;
  }

  public static async updateUserStatus (
    userId: number,
    userStatusId: number,
  ): Promise<boolean> {
    try {
      await administrationAPIClient({
        method: "PATCH",
        url: `api/User/${userId}/Status/${userStatusId}`,
      });

      return Promise.resolve(true);
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

  public static async saveImageFile (image: any) {
    try {
      const formData = new FormData();
      formData.append("file", image);
      const response = await dataExchangeAPIClient({
        method: "POST",
        url: `${process.env.REACT_APP_DATA_EXCHANGE}/api/FileStorage/Upload`,
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
          FeatureName: "user",
          foldername: "profilepicture",
        },
      });
      const { PayLoad } = response.data;

      return PayLoad;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

  public static async getSiteFilter (payload: string): Promise<any> {
    try {
      const response = await userManagementAPIClient({
        method: "GET",
        url: `/api/Groups/GetUsersByGroup?groupGuid=${payload}`,
      });

      return response.data.PayLoad;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

  public static async getRoleFilter (payload: string): Promise<any> {
    try {
      const response = await userManagementAPIClient({
        method: "GET",
        url: `/api/Users/GetUsersByRoleName?roleName=${payload}`,
      });

      return response.data.PayLoad;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

  public static async getValidateEmail (payload: any): Promise<any> {
    try {
      const response = await userManagementAPIClient({
        method: "GET",
        url: `/api/Users/SearchUsersByRegionAsync?searchUsersRequest=${payload.email}`,
      });

      return response.data;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

  public static async getUserDetailsKeyclock (payload: any): Promise<any> {
    try {
      const response = await userManagementAPIClient({
        method: "GET",
        url: `/api/Users/GetUserInfoByEmail?email=${payload}`,
      });

      return response.data;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

  public static async usersResetPassword (user: any): Promise<any> {
    const dynamicPayload = user.payload;
    const res = await userManagementAPIClient({
      method: "POST",
      url: `/api/Users/ResetForgotPasswordAsync`,
      data: dynamicPayload,
    });

    return res.data;
  }

  public static async getSiteFiltered (payload: any): Promise<any> {
    const firstPayload: any[] = [];
    let id: string = "";
    try {
      for (let i = 0; i < payload.length; i++) {
        id = payload.map((a: any) => a.id)[i].toString();

        const response = await userManagementAPIClient({
          method: "GET",
          url: `/api/Groups/GetUsersByGroup?groupGuid=${id}`,
        });
        firstPayload.push(response.data.PayLoad);
      }

      return _.flatten(firstPayload);
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

  public static async getRoleFiltered (payload: any): Promise<any> {
    const rolePayload: any[] = [];
    let name: string = "";
    try {
      for (let i = 0; i < payload.length; i++) {
        name = payload.map((a: any) => a.roleName)[i].toString();
        const response = await userManagementAPIClient({
          method: "GET",
          url: `/api/Users/GetUsersByRoleName?roleName=${name}`,
        });
        rolePayload.push(response.data.PayLoad);
      }

      return _.flatten(rolePayload);
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

  public static async getPasswordPolicies (): Promise<any> {
    const res = await administrationAPIClient({
      method: "GET",
      url: `/api/Security/PasswordPolicesByRegion`,
    });

    return res.data.PayLoad;
  }

  public static async removePicture (payload: any): Promise<any> {
    const removePictureId = payload.payload;
    try {
      const response = await dataExchangeAPIClient({
        method: "DELETE",
        url: `/api/FileStorage/Delete?featureName=user&fileName=${removePictureId}`,
      });

      return response.data;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

// Onboarding completion
  public static async updateGroup (): Promise<any> {
    const activeDistrict = localStorage.getItem("activeDistrict");
    const selectedActiveDistrict = activeDistrict && JSON.parse(activeDistrict);
    selectedActiveDistrict.attributes.onboardingCompleted = ["True"];
    const res = await userManagementAPIClient({
      method: "PUT",
      url: `/api/Groups/UpdateGroupAsync/${selectedActiveDistrict.groupGuid}`,
      data: selectedActiveDistrict,
    });

    return res.data;
  }
}
export default UsersTransportLayer;
