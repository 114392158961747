export enum ResponseStatus {
    Initial = "initial",
    Loading = "loading",
    Success = "success",
    Error = "error",
}

export enum ActiveMessageTabValue {
    initialValue = 0,
}

export enum SortType {
    ASC = "asc",
    DESC = "desc",
}
