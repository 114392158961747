import ProfileTransport from "control/transport/profile.transport";
import { isEmpty } from "lodash";
import { put, takeLatest } from "redux-saga/effects";
import { ResponseStatus } from "../../model/entity";
import {
    deleteMessage,
    deleteWorkspaceBroadcast,
    fetchWorkspaceDetails, getRemovePictureFail, getRemovePictureInit, getRemovePictureSuccess, getUserDetailsKeyclockInit, getUserDetailsKeyclockInitFail, getUserDetailsKeyclockInitSuccess, passwordPoliciesFail,
    passwordPoliciesInit, passwordPoliciesSuccess, saveProfileInfoAction,
    saveProfileInfoSuccess, SetTasks,
    setWorkspaceDetails,
    setWorkspaceStatus, userConfirmPasswordFail,
    userConfirmPasswordInit, userConfirmPasswordSuccess, viewRolesFail, viewRolesInit,
    viewRolesSuccess,
} from "../actions";
import BroadcastTransport from "../transport/broadcast.transport";
import WorkspaceDetailsTransportLayer from "../transport/workspace-details.transport";

function* fetchWorkspaceDetailsSaga () {
    try {
        yield put(setWorkspaceStatus(ResponseStatus.Loading));
        const data = yield WorkspaceDetailsTransportLayer.fetchWorkspaceDetails();
        yield put(setWorkspaceDetails(data));
        yield put(SetTasks(data.tasks));
        yield put(setWorkspaceStatus(ResponseStatus.Success));
    } catch (error) {
        console.error(error);
        yield put(setWorkspaceStatus(ResponseStatus.Error));
    }
}

function* saveProfileInfoSaga (action: any) {
    try {
        const updateUserProfile = yield ProfileTransport.updateUser(action.payload);
        if (updateUserProfile && isEmpty(updateUserProfile.vaidation)) {
            yield put(saveProfileInfoSuccess(action.payload));
        } else {
            yield put(saveProfileInfoSuccess(-1));
        }
    } catch (e) {
        yield yield put(saveProfileInfoSuccess(-1));
    }
}

function* deleteMessageSaga (action: any) {
    try {
        yield put(setWorkspaceStatus(ResponseStatus.Loading));
        yield WorkspaceDetailsTransportLayer.deleteMessage(action.payload);
        yield put(fetchWorkspaceDetails());
    } catch (error) {
        console.error(error);
        yield put(setWorkspaceStatus(ResponseStatus.Error));
    }
}

function* deleteBroadcastSaga (action: any) {
    try {
        yield put(setWorkspaceStatus(ResponseStatus.Loading));
        yield BroadcastTransport.deleteBroadcast([action.payload]);
        yield put(fetchWorkspaceDetails());
    } catch (error) {
        console.error(error);
        yield put(setWorkspaceStatus(ResponseStatus.Error));
    }
}

function* viewRolesSaga () {
    try {
        const rolesData = yield ProfileTransport.getRoles();
        if (!isEmpty(rolesData)) {
            yield put(viewRolesSuccess(rolesData));
        } else {
            yield put(viewRolesSuccess([]));
        }
    } catch (error) {
        yield put(viewRolesFail());
    }
}

function* getUserDetailsKeyclockSaga (payload: any) {
    try {
        const userDetails = yield ProfileTransport.getUser(payload.payload);
        if (!isEmpty(userDetails)) {
            yield put(getUserDetailsKeyclockInitSuccess(userDetails));
        } else {
            yield put(getUserDetailsKeyclockInitSuccess([]));
        }
    } catch (error) {
        yield put(getUserDetailsKeyclockInitFail());
    }
}

function* getUserConfirmPasswordSaga (data: any) {
    try {
        const userPassword = yield ProfileTransport.userPasswordTransport(data.payload);
        if (!isEmpty(userPassword)) {
            yield put(userConfirmPasswordSuccess(userPassword));
            data.payload.action(userPassword);
        } else {
            yield put(userConfirmPasswordSuccess([]));
        }
    } catch (error) {
        yield put(userConfirmPasswordFail());
    }
}

function* passwordPoliciesSaga () {
    try {
        const passwordData = yield ProfileTransport.getPasswordPolicies();
        if (!isEmpty(passwordData)) {
            yield put(passwordPoliciesSuccess(passwordData));
        } else {
            yield put(passwordPoliciesSuccess([]));
        }
    } catch (error) {
        yield put(passwordPoliciesFail());
    }
}

function* removePictureSaga (action: any) {
    try {
        const removePicture = yield ProfileTransport.removePicture(action.payload);
        if (removePicture.ResponseEnvelope?.ValidationCollection) {
            yield put(getRemovePictureFail());
        } else {
            yield put({
                type: getRemovePictureSuccess,
                payload: removePicture,
            });
            action.payload.action(removePicture);
        }
    } catch (error) {
        yield put(getRemovePictureFail());
    }
}

export default function* WorkspaceDetailsSaga () {
    try {
        yield takeLatest(fetchWorkspaceDetails, fetchWorkspaceDetailsSaga);
        yield takeLatest(deleteMessage, deleteMessageSaga);
        yield takeLatest(deleteWorkspaceBroadcast, deleteBroadcastSaga);
        yield takeLatest(saveProfileInfoAction, saveProfileInfoSaga);
        yield takeLatest(viewRolesInit, viewRolesSaga);
        yield takeLatest(getUserDetailsKeyclockInit, getUserDetailsKeyclockSaga);
        yield takeLatest(userConfirmPasswordInit, getUserConfirmPasswordSaga);
        yield takeLatest(passwordPoliciesInit, passwordPoliciesSaga);
        yield takeLatest(getRemovePictureInit, removePictureSaga);
    } catch (error) {
        yield null;
    }
}

