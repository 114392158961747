import { isEmpty } from "lodash";
import { put, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/onboarding.actions";
import OnboardingTransportLayer from "../transport/onboarding.transport";

function* getWelcomeSaga (moduleId: any) {
    try {
        const resourceTypeData = yield OnboardingTransportLayer.getWelcome(moduleId);
        if (!isEmpty(resourceTypeData)) {
            yield put(actions.getWelcomeSuccess(resourceTypeData));
        } else {
            yield put(actions.getWelcomeSuccess([]));
        }
    } catch (error) {
        yield put(actions.getWelcomeFail());
    }
}

function* postWelcomeSaga (payload: any) {
    try {
        const resourceTypeData = yield OnboardingTransportLayer.postWelcome(payload);
        if (!isEmpty(resourceTypeData)) {
            yield put(actions.postWelcomeSuccess(resourceTypeData));
        } else {
            yield put(actions.postWelcomeSuccess([]));
        }
    } catch (error) {
        yield put(actions.postWelcomeFail());
    }
}

function* getActivitiesSaga (moduleId: any) {
    try {
        const resourceTypeData = yield OnboardingTransportLayer.getActivities(moduleId);
        if (!isEmpty(resourceTypeData)) {
            yield put(actions.getActivitiesSuccess(resourceTypeData));
        } else {
            yield put(actions.getActivitiesSuccess([]));
        }
    } catch (error) {
        yield put(actions.getActivitiesFail());
    }
}

function* postActivitiesSaga (payload: any) {
    try {
        const resourceTypeData = yield OnboardingTransportLayer.postActivities(payload);
        if (!isEmpty(resourceTypeData)) {
            yield put(actions.postActivitiesSuccess(resourceTypeData));
        } else {
            yield put(actions.postActivitiesSuccess([]));
        }
    } catch (error) {
        yield put(actions.postActivitiesFail());
    }
}


export default function* WelcomeSaga () {
    yield takeLatest(actions.getWelcomeInit, getWelcomeSaga);
    yield takeLatest(actions.postWelcomeInit, postWelcomeSaga);
    yield takeLatest(actions.getActivitiesInit, getActivitiesSaga);
    yield takeLatest(actions.postActivitiesInit, postActivitiesSaga);
}
