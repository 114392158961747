import { deleteCustomReportFavoriteFailed, 
    deleteCustomReportFavoriteInit, 
    deleteCustomReportFavoriteSuccess, 
    getCustomReportByIdInit, 
    getCustomReportByIdSuccess, 
    getFavoriteCustomReportsFail, 
    getFavoriteCustomReportsInit, 
    getFavoriteCustomReportsSuccess} from "control/actions/customReports.actions";
import CustomReportTransport from "control/transport/customReports.transport";
import { isNil, isString } from "lodash";
import { put, takeLatest } from "redux-saga/effects";

export function* getFavoriteCustomReportsSaga() {
    try {
        const response: any = yield CustomReportTransport.getFavoriteCustomReports();
        if (response && response.PayLoad) {
            yield put(getFavoriteCustomReportsSuccess(response.PayLoad));
        }
    } catch (e) {
        yield put(getFavoriteCustomReportsFail());
       
    }
}

export function* getCustomReportByIdSaga(action: any) {
    try {
        
        let response = yield CustomReportTransport.getCustomReportByIdAsync(action.payload);
        
        if (response && response.PayLoad) {
            yield put(getCustomReportByIdSuccess(response));
        } else {
            yield put(getFavoriteCustomReportsFail());
        }
    } catch (e) {
        yield put(getFavoriteCustomReportsFail());
    }
}

export function* deleteCustomReportFavoriteSaga({ payload }: {
    payload: {
        customReportFavoriteId: number;
        successCallback: () => void;
        errorCallback: () => void
    }
}) {
    const {
        customReportFavoriteId,
        successCallback,
        errorCallback,
    } = payload;
    try {
        const response = yield CustomReportTransport.deleteCustomReportFavoriteAsync(customReportFavoriteId);
        if (!isString(response?.data) && response?.data?.Description === 'Success!' && !isNil(response?.data?.PayLoad)) {
            yield put(deleteCustomReportFavoriteSuccess(response.data));
            successCallback?.();
        } else {
            yield put(deleteCustomReportFavoriteFailed("Failed"));
            errorCallback?.();
        }
    } catch (error) {
        yield put(deleteCustomReportFavoriteFailed(error));
        errorCallback?.();
    }
}

export default function* watchCustomReportsSaga() {
    yield takeLatest(getFavoriteCustomReportsInit, getFavoriteCustomReportsSaga);
    yield takeLatest(getCustomReportByIdInit, getCustomReportByIdSaga);
    yield takeLatest(deleteCustomReportFavoriteInit, deleteCustomReportFavoriteSaga);
}