import {
    Avatar,
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Divider,
    IconButton,
    Input,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Paper,
    Portal,
    SvgIcon,
    Typography,
} from "@material-ui/core";
import { AddPhotoAlternateIcon, LoadingScreen, Notification, NotificationWithDialog, Page } from "@primeroedge/ui-components";
import clsx from "clsx";
import { fetchMailUserList, sendMail, setComposeBoXWindowOpenCLose, setUserMail, updateAttachmentArray, uploadAttachment } from "control/actions";
import { IRecipientUser, MailEntity } from "model/entity/mail.entity";
import React, { FC, useEffect, useRef, useState } from "react";
import {
    Maximize as MaximizeIcon,
    Minimize as MinimizeIcon,
    X as XIcon,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import QuillEditor from "./QuillEditor";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: `calc(100% - ${theme.spacing(6)}px)`,
        maxHeight: `calc(100% - ${theme.spacing(6)}px)`,
        width: 600,
        position: "fixed",
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
        outline: "none",
        zIndex: 1300,
        display: "flex",
        flexDirection: "column",
        minHeight: 500,
        overflow: "auto",
    },
    emailsList: {
        position: "absolute",
        background: "white",
        width: "90%",
        height: "50%",
        overflowY: "scroll",
        border: "1px solid #b2b2b2",
        borderRadius: 4,
        padding: 10,
        zIndex: 999,
    },
    loaderPosition: {
        position: "absolute",
        background: "white",
        padding: 10,
        zIndex: 999,
    },
    fullScreen: {
        height: "100%",
        width: "100%",
    },
    input: {
        width: "100%",
    },
    inputAuto: {
        width: "auto",
    },
    toText: {
        margin: "0px 10px 0px 0px",
        padding: "6px 0 7px",
        color: "theme.palette.text.primary",
    },
    editor: {
        flexGrow: 1,
        "& .ql-editor": {
            minHeight: 300,
        },
    },
    userList: {
        position: "absolute",
        background: "white",
        zIndex: 2,
        border: "1px solid",
        maxHeight: "300px",
        overflowX: "auto",
    },
    userListItem: {
        padding: "5px 10px",
        cursor: "pointer",

        "&:hover": {
            backgroundColor: "#f4f6f8",
        },
    },
    senderList: {
        display: "flex",
        flexWrap: "wrap",
    },
    action: {
        marginRight: theme.spacing(1),
    },
    chipEmail: {
        marginLeft: "3px",
        marginRight: "3px",
    },
    emailListItem: {
        borderBottom: "1px solid #cecece",
        "&:hover": {
            cursor: "pointer",
            background: "#cecece",
        },
    },
    attachmentBox: {
        maxHeight: "100px",
        overflowY: "auto",
    },
    attachment: {
        margin: "10px",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: theme.palette.background.default,
        padding: "10px",
    },
    attachmentImage: {
        color: theme.palette.primary.main,
    },
    cross: {
        cursor: "pointer",
    },
}));

const Compose: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const saveComposeMailBoxStatus = useSelector(MailEntity.isMailComponseOpen);
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [messageBody, setMessageBody] = useState<string>("");
    const [to, setTo] = useState("");
    const [userSelect, setUserSelect] = useState<IRecipientUser[]>([]);
    const [closeMessageDialog, setCloseMessageDialog] = useState<any>(false);
    const list = useSelector(MailEntity.getUsersList);
    const [subject, setSubject] = useState<string>("");
    const [showList, setShowList] = useState<boolean>(false);
    const [buttonDisable, setButtonDisable] = useState<boolean>(true);
    const [noUsersMessage, setNoUsersMessage] = useState("");
    const inputRef = useRef<HTMLInputElement | null>(null);
    const isLoading = useSelector(MailEntity.isLoading);
    const [showLoader, setShowLoader] = useState(false);
    const [warning, setWarning] = useState("");
    const attachments: any = useSelector(MailEntity.getAttachments);

    useEffect(() => {
        if (userSelect.length > 0 && (subject || messageBody)) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    }, [userSelect, subject, messageBody]);

    const handleChange = (value: string): void => {
        if (value !== "<p><br></p>") {
            setMessageBody(value);
        } else {
            setMessageBody("");
        }

        // message.length 8 and 11 is checked because editor is giving <p></p> or <p><br></p> when user clear all the text, so we need 
        // to check if messae body is empty or not, doing it by length
        if (userSelect.length > 0 && (subject || value)) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    };

    const handleExitFullScreen = (): void => {
        setFullScreen(false);
    };

    const handleEnterFullScreen = (): void => {
        setFullScreen(true);
    };

    const handleClose = () => {
        if (userSelect.length || subject || messageBody.length || attachments.length > 0) {
            setCloseMessageDialog(true);
        } else {
            confirmClose();
        }
    };

    const confirmClose = () => {
        dispatch(setComposeBoXWindowOpenCLose(false));
        setSubject("");
        setTo("");
        setMessageBody("");
        setUserSelect([]);
        dispatch(setUserMail({}));
        setCloseMessageDialog(false);
        dispatch(updateAttachmentArray([]));
    };

    const cancelClose = () => {
        setCloseMessageDialog(false);
    };

    const handleToChange = (e: any) => {
        e.preventDefault();
        e.persist();
        setTo(e.target.value);
        const value = e.target.value;
        if (value.length >= 3) {
            dispatch(fetchMailUserList(value));
            setNoUsersMessage("We did not find a user with that email");
        } else {
            setShowList(false);
            setNoUsersMessage("");
        }

        if (userSelect.length > 0 && (subject || messageBody)) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    };

    const handleSubjectChange = (e: any) => {
        setSubject(e.target.value);

        // message.length 8 and 11 is checked because editor is giving <p></p> or <p><br></p> when user clear all the text, so we need
        // to check if messae body is empty or not, doing it by length
        if (userSelect.length > 0 && e.target.value.length > 0) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    };

    const handleSend = () => {
        if (messageBody?.length > 2000) {
            setWarning("Content should not be more than 2000 characters");
        } else {
            setWarning("");
            if (userSelect.length > 0 && (subject || messageBody)) {
                const params = {
                    recipient: userSelect,
                    subject: subject ? subject : "[ No Subject ]",
                    content: messageBody,
                    attachments,
                };
                dispatch(sendMail(params));
                setSubject("");
                setTo("");
                setMessageBody("");
                setUserSelect([]);
                dispatch(setComposeBoXWindowOpenCLose(false));
                dispatch(setUserMail({}));
                dispatch(updateAttachmentArray([]));
            }
        }
    };

    const selectEmailToSend = (selectedUser: any) => {
        setShowList(false);
        setTo(() => "");
        const checkIfUserExists = userSelect.filter((user: IRecipientUser) => user.recipientEmail === selectedUser.eMail);
        if (checkIfUserExists.length === 0) {
            const value = {
                recipientId: selectedUser.userGuid,
                recipientFirstName: selectedUser.firstName,
                recipientLastName: selectedUser.lastName,
                recipientEmail: selectedUser.eMail,
                recipientPrimeroUserId: selectedUser.primeroUserId,
            };
            setUserSelect((prevState) => [...prevState, value]);
        }
        setInputRefFocus();
    };

    const removeSelectedUser = (user: IRecipientUser) => {
        const filteredUser = userSelect.filter((tempUser: IRecipientUser) => tempUser.recipientEmail !== user.recipientEmail);
        setUserSelect(() => [...filteredUser]);
    };

    const deleteAttachment = (index: any) => {
        const newAttachment = [...attachments];
        newAttachment.splice(index, 1);
        dispatch(updateAttachmentArray(newAttachment));
    };

    const setInputRefFocus = () => {
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    };

    const handleUpdateImage = (e: any) => {

        const files = e.target.files;
        let fileSizeError = "";
        const imageType = ["txt", "csv", "doc", "docx", "pdf", "jpg", "png", "bmp", "msg", "xls", "xlsx", "json"];
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const fileExtension = files[i].name.split(".")[files[i].name.split(".").length - 1];
                if (!imageType.includes(fileExtension.toLowerCase())) {
                    fileSizeError = "Uploaded file type is not allowed";
                } else if (files[i].size > 2 * 1024 * 1024) {
                    fileSizeError = "Attachments larger than 2 MB are not supported";
                }

            }
            if (!fileSizeError) {
                setShowLoader(true);
                setWarning("");
                dispatch(uploadAttachment(files));
            } else {
                setWarning(fileSizeError);
            }
        }
    };


    useEffect(() => {
        if (list && list.length > 0) {
            setShowList(() => true);
            setNoUsersMessage("");
        }
    }, [list]);

    const displayemails = (listVal: any) => {
        const list = listVal;
        if (isLoading) {
            return (
                <List classes={{ root: classes.loaderPosition }} subheader={<li />}>
                    <LoadingScreen loading={isLoading} />
                </List>
            );
        } else if (list && list.length > 0 && showList) {
            return (
                <List id="send-to-list" classes={{ root: classes.emailsList }} subheader={<li />}>
                    {list.map((emailEntity: any) => (
                        <ListItem
                            role="button"
                            button={
                                true
                            }
                            classes={{ root: classes.emailListItem }}
                            key={`email-${emailEntity.eMail}`}
                            onClick={selectEmailToSend.bind(null, emailEntity)}
                        >
                            <ListItemText primary={`${emailEntity.lastName}, ${emailEntity.firstName} (${emailEntity.eMail})`} />
                        </ListItem>

                    ))}
                </List>

            );
        } else if (list && !list.email && !isLoading && noUsersMessage) {
            return (
                <List classes={{ root: classes.loaderPosition }} subheader={<li />}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography color="error">
                                {noUsersMessage}
                            </Typography>
                        </CardContent>
                    </Card>
                </List>
            );
        }

    };


    useEffect(() => {
        setShowLoader(false);
    }, [attachments]);

    return (
        <Page>
            {saveComposeMailBoxStatus ? (
                <Portal>
                    <Backdrop open={true} className="backdrop-bg" />
                    <Paper
                        className={clsx(
                            classes.root,
                            { [classes.fullScreen]: fullScreen },
                        )}
                        elevation={12}
                    >
                        <Box
                            bgcolor="background.default"
                            display="flex"
                            alignItems="center"
                            py={1}
                            px={2}
                        >
                            <Typography
                                variant="h5"
                                color="textPrimary"
                            >
                                New Message
                            </Typography>
                            <Box flexGrow={1} />
                            {fullScreen ? (
                                <IconButton id="minimizeButton" type="button" title="minimize-button" onClick={handleExitFullScreen}>
                                    <SvgIcon fontSize="small">
                                        <MinimizeIcon />
                                    </SvgIcon>
                                </IconButton>
                            ) : (
                                <IconButton id="maximizeButton" type="button" title="maximize-button" onClick={handleEnterFullScreen}>
                                    <SvgIcon fontSize="small">
                                        <MaximizeIcon />
                                    </SvgIcon>
                                </IconButton>
                            )}
                            <IconButton id="closeButton" type="button" title="close-button" onClick={handleClose}>
                                <SvgIcon fontSize="small">
                                    <XIcon />
                                </SvgIcon>
                            </IconButton>
                        </Box>
                        <Divider />
                        <Box p={2}>
                            <div>
                                <div id="sender-list" className={classes.senderList}>
                                    <span className={classes.toText} >To: </span>
                                    {userSelect.length > 0 && userSelect.map((user: IRecipientUser) => (
                                        <Chip
                                            key={user.recipientEmail}
                                            className={classes.chipEmail}
                                            id={`email-selected-${user.recipientEmail}`}
                                            avatar={(
                                                <Avatar>
                                                    {user.recipientFirstName.charAt(0).toUpperCase()}{user.recipientLastName.charAt(0).toUpperCase()}
                                                </Avatar>
                                            )}
                                            label={`${user.recipientFirstName} ${user.recipientLastName}`}
                                            onDelete={removeSelectedUser.bind(null, user)}
                                        />
                                    ))}
                                    <Input
                                        type="text"
                                        className={classes.inputAuto}
                                        disableUnderline={true}
                                        onChange={handleToChange}
                                        value={to}
                                        inputRef={inputRef}
                                        id="search"
                                        name="search"
                                        title="search"
                                        inputProps={{
                                            "aria-label": "Search",
                                        }}
                                    />
                                </div>
                                {displayemails(list)}
                            </div>
                            <Input
                                className={classes.input}
                                disableUnderline={true}
                                placeholder="Subject"
                                onChange={handleSubjectChange}
                                id="subject"
                                name="subject"
                                title="subject"
                                inputProps={{
                                    "aria-label": "Subject",
                                }}
                            />
                        </Box>
                        <Divider />
                        <QuillEditor
                            className={classes.editor}
                            onChange={handleChange}
                            placeholder="Leave a message"
                            value={messageBody}
                        />
                        {attachments && (
                            <div className={classes.attachmentBox}>
                                {attachments.map((attachment: any, index: number) => {
                                    return (
                                        <>
                                            <div className={classes.attachment}>
                                                <div className={classes.attachmentImage} key={index}>
                                                    {attachment.displayFileName}
                                                </div>
                                                <IconButton
                                                    id="removeAttachmentsButton"
                                                    type="button"
                                                    title="remove-attachments-button"
                                                    style={{ padding: 0 }}
                                                    onClick={() => deleteAttachment(index)}
                                                >
                                                    <SvgIcon fontSize="small">
                                                        <XIcon />
                                                    </SvgIcon>
                                                </IconButton>
                                            </div>
                                        </>
                                    );

                                })}
                            </div>
                        )}
                        <Divider />

                        <Box
                            display="flex"
                            alignItems="center"
                            py={1}
                            px={2}
                        >
                            <Button
                                type="button"
                                color="secondary"
                                variant="contained"
                                disabled={buttonDisable}
                                onClick={handleSend}
                                className={classes.action}
                            >
                                Send
                            </Button>
                            <>
                                {showLoader ? <CircularProgress size={20} /> : (
                                    <div style={{ cursor: "pointer" }}>
                                        <label htmlFor="file">
                                            <AddPhotoAlternateIcon style={{ cursor: "pointer" }} />
                                        </label>
                                        <input
                                            className={classes.input}
                                            id="file"
                                            name="file"
                                            multiple={true}
                                            accept=".txt,.csv,.doc,.docx,.pdf,.jpg,.png,.bmp,.msg,.xls,.xlsx,.json"
                                            type="file"
                                            style={{ display: "none" }}
                                            onInput={(e) => handleUpdateImage(e)}
                                            aria-label="file"
                                        />
                                    </div>
                                )}
                                {warning !== "" && (
                                    <Notification
                                        color="error"
                                        duration={5000}
                                        message={warning}
                                        onClose={() => setWarning("")}
                                        variant="filled"
                                    />
                                )}
                            </>
                        </Box>
                    </Paper>
                </Portal>
            ) : (
                <div />
            )}
            <NotificationWithDialog
                open={Boolean(closeMessageDialog)}
                title="Close New Message"
                message="Closing this window will discard this message. Proceed?"
                color="primary"
                primaryAction={{
                    title: "Yes",
                    callback: () => confirmClose(),
                }}
                secondaryAction={{
                    title: "No",
                    callback: () => cancelClose(),
                }}
            />
        </Page>
    );
};

export default Compose;
