import { CardContent, CardHeader, Checkbox, FormControl, FormLabel, makeStyles, Paper, Radio, RadioGroup, Switch, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Autocomplete } from "@material-ui/lab";
import { AccountStatus, Alert, AlertCircleIcon, AppLoader, Button, CheckFilledSquareIcon, Notification, NotificationWithDialog, PageLayout, SquareIcon, SummaryList, UserCard } from "@primeroedge/ui-components";
import Axios from "axios";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRemovePictureInit, getUserDetailsKeyclockInit, userAddInit, userEditInit, userResetPasswordInit, validateEmailInit, viewFilterSiteDataInit } from "../../../control/actions";
import { API_DATA_EXCHANGE, APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY } from "../../../control/transport/clients/api-constants";
import * as profile from "../../../model/entity/profile.entity";
import * as userEntity from "../../../model/entity/user.entity";
import { UserEntity } from "../../../model/entity/user.entity";
import RequiredLabel from "../required-label/index";
import { maskedPhoneNumber } from "./maskedPhoneNumber";
import { saveImage } from "./profile.utils";

const icon = <SquareIcon fontSize="small" />;
const checkedIcon = <CheckFilledSquareIcon fontSize="small" />;
interface IUserDetails {
    firstName: string;
    middleName: string;
    lastName: string;
    email: any;
    cellPhone: any;
    workPhone: any;
    userId: number;
    userStatusDescription: string;
    authenticationMode: string;
    regions: any;
    areas: any;
    sites: any[];
    roles: any[];
    organizationLevel: any;
    file: string;
    userStatusId: number;
    lastPasswordChangeDate: string;
    userImageUrl: string;
    isBroadcastUser: string;
}
interface INewUserProps {
    userBasicData: userEntity.IUserBasicData;
    onClose: () => void;
    formLayout: boolean;
    formTitle: string;
    userAction: string;
    alertMessage: string;
    setAlertMessage: any;
    regionsAndSites: any;
    rolesList: userEntity.IUserRoles[];
    organizationData: any;
    passwordExpiry: number;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paddingAll: {
        padding: "0px 60px 80px 60px",
    },
    select: {
        marginTop: "20px",
    },
    cardContent: {
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
    title: {
        fontSize: 14,
        marginTop: "13px",
        marginLeft: "5px",
    },
    userCard: {
        marginRight: 16,
        marginLeft: 16,
    },
    uploadButton: {
        marginLeft: "90px",
    },
    userCardTypography: {
        padding: "10px",
        marginLeft: "20px",
    },
    contactInfoGrid: {
        marginRight: 20,
        marginLeft: 16,
    },
    gridInputFields: {
        marginRight: "-16px",
        padding: "12px",
    },
    inputFields: {
        marginTop: 20,
    },
    alertIcon: {
        marginLeft: "33px",
        marginTop: "-27px",
    },
    switch: {
        marginLeft: "-10px",
    },
    switchtoggle: {
        marginLeft: "2px",
        marginTop: "-14px",
    },
    search: {
        marginRight: "-16px",
        marginBottom: "20px",
        marginTop: "20px",
    },
    link: {
        marginLeft: "35px",
    },
    site: {
        marginTop: "19px",
    },
    inputRoot: {
        width: "200px",
        "&:first-child": {
            marginRight: "20px",
        },
    },
    hideFileInput: {
        display: "none",
    },
    activeText: {
        fontWeight: "bold",
    },
    statusContainer: { display: "inline-block" },
    lbl: {
        display: "inline-flex",
        justifyItems: "center",
        alignItems: "center",
        verticalAlign: "super",
        fontSize: "12px",
        fontWeight: "bold",
    },
    statusGrp: {
        display: "inline-flex",
        justifyItems: "center",
        alignItems: "center",
    },
    radioLbl: {
        fontSize: "12px",
    },
    radio: {
        "&$checked": {
            color: theme.palette.primary.main,
        },
    },
    checked: {},
    linkClass: {
        color: theme.palette.secondary.main,
        marginLeft: "5px",
    },
}));

const AddUser = (props: INewUserProps) => {

    const classes = useStyles();
    const user = useSelector(UserEntity.getUserProfile);
    const userData = useSelector(profile.getUserDetailsKeyclockEntity);
    const adduserLoading: string = useSelector(userEntity.getUserFetchStatus);
    // const removePictureStatus: string = useSelector(userEntity.getRemovePictureStatus);
    // const userRemovePictureStatus: string = useSelector(userEntity.getUserRemovePictureStatus);
    const { userBasicData, onClose, formTitle, userAction, setAlertMessage, regionsAndSites, rolesList, passwordExpiry } = props;
    const [newUserDetails, setNewUserDetails] = React.useState<IUserDetails>({
        firstName: userBasicData.firstName ? userBasicData.firstName : "",
        middleName: userBasicData.mi ? userBasicData.mi : "",
        lastName: userBasicData.lastName ? userBasicData.lastName : "",
        email: userBasicData.email ? userBasicData.email : "",
        cellPhone: userBasicData.cellPhone ? userBasicData.cellPhone : "",
        workPhone: userBasicData.workPhone ? userBasicData.workPhone : "",
        userId: userBasicData.userId ? userBasicData.userId : 0,
        userStatusDescription: userBasicData.userStatusDescription ? userBasicData.userStatusDescription : "",
        authenticationMode: userBasicData.authenticationMode ? userBasicData.authenticationMode : "",
        regions: userBasicData.regions ? userBasicData.regions : [],
        areas: userBasicData.areas ? userBasicData.areas : [],
        sites: userBasicData.sites ? userBasicData.sites : [],
        roles: userBasicData.roles ? userBasicData.roles : [],
        organizationLevel: userBasicData.organizationLevel ? userBasicData.organizationLevel : "",
        file: userBasicData.file ? userBasicData.file : "",
        userStatusId: userBasicData.userStatusId ? userBasicData.userStatusId : 0,
        lastPasswordChangeDate: userBasicData.lastPasswordChangeDate ? userBasicData.lastPasswordChangeDate : "",
        userImageUrl: userBasicData.userImageUrl ? userBasicData.userImageUrl : "",
        isBroadcastUser: userBasicData.isBroadcastUser ? userBasicData.isBroadcastUser : "false",
    });
    const [firstNameError, setFirstNameError] = useState<boolean>(false);
    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState<string>("");
    const [lastNameError, setLastNameError] = useState<boolean>(false);
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState<string>("");
    const [emailError, setEmailError] = useState<boolean>(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>("");
    const [workPhoneError] = useState<boolean>(false);
    // const [workPhoneErrorMessage, setWorkPhoneErrorMessage] = useState<string>("");
    const [regionsError, setRegionsError] = useState<boolean>(false);
    const [regionsMessage, setRegionsMessage] = useState<string>("");
    const [areasError, setAreasError] = useState<boolean>(false);
    const [areasMessage, setAreasMessage] = useState<string>("");
    const [sitesError, setSitesError] = useState<boolean>(false);
    const [sitesMessage, setSitesMessage] = useState<string>("");
    const [rolesError, setRolesError] = useState<boolean>(false);
    const [rolesMessage, setRolesMessage] = useState<string>("");
    const [cancelUserConfirmation, setCancelUserConfirmation] = useState<boolean>(false);
    const [distSiteNameList, setDistSiteNameListOptions] = useState<any[]>();
    const [siteBool, setSiteBool] = useState<boolean>(false);
    const [areaSiteBool, setAreaSiteBool] = useState<boolean>(false);
    const [areaBool, setAreaBool] = useState<boolean>(false);
    const [showImageFormatError, canShowImageFormatError] = useState(false);
    const [showImageSizeError, canShowImageSizeError] = useState(false);
    const [file, setFile] = useState<string>("");
    const [showAlert, canShowAlert] = useState<"success" | "error" | undefined>();
    const [passwordAlert, setPasswordAlert] = useState<boolean>(false);
    const [Toggle, setToggle] = useState<boolean>(false);
    const dispatch = useDispatch();
    const fileUploadRef = useRef<HTMLInputElement | null>(null);
    const [newArea, setNewArea] = useState<userEntity.IAreas>({
        id: "",
        name: "",
        organizationLevel: "",
        parentRegionId: "",
        regionId: "",
        sites: [],
    });
    const [newRegions, setNewRegions] = useState<userEntity.IRegionsSites[]>([]);
    const [newRoles, setNewRoles] = useState<userEntity.IUserRoles[]>([]);
    const [newSites, setNewSites] = useState<userEntity.ISites[]>([]);
    const [selectedDistrict, setSelectedDistrict] = useState<any>({});
    const activeDistrict = localStorage.getItem("activeDistrict");
    const selectedActiveDistrict = activeDistrict && JSON.parse(activeDistrict);
    // const userEmail = localStorage.getItem("email");
    const [userImageUrl, setUserImageUrl] = useState<any>(null);
    const [close, setClose] = useState<boolean>(true);
    const [alertMessage, setUserAlertMessage] = useState<boolean>(false);
    const [removePictureCDonfirmation, setRemovePictureConfirmation] = useState<boolean>(false);
    const [alertStatus, setAlertStatus] = React.useState<"success" | "error" | "default" | "primary" | "secondary" | "warning" | "info" | undefined>(undefined);
    const [showPictureAlert, setPictureAlert] = React.useState<boolean>(false);
    const [pictureAlertMessage, setPictureAlertMessage] = React.useState<string>("");
    const [effectTriggered, setEffectTriggered] = useState<boolean>(false);

    useEffect(() => {
        console.warn(showAlert, userImageUrl);
    }, [showAlert, userImageUrl]);

    useEffect(() => {
        if (selectedActiveDistrict && !effectTriggered) {
            setSelectedDistrict(selectedActiveDistrict);
            const regionId: string = selectedActiveDistrict?.attributes?.regionId ? selectedActiveDistrict?.attributes?.regionId[0] : "0";
            const region = [regionsAndSites].find((district: userEntity.IRegionsSites) => district.regionId === regionId);
            if (regionsAndSites) {
                if (!newRegions && userAction === "Edit") {
                    setNewRegions(region ? region : []);

                    if (regionsAndSites.hasOwnProperty("Areas")) {
                        if (regionsAndSites.name) {
                            if (regionsAndSites.Areas && regionsAndSites.Areas.length > 0) {
                                setAreaBool(true);
                            }
                            if (regionsAndSites.Sites && regionsAndSites.Sites.length > 0) {
                                setSiteBool(true);
                            }
                        }
                        if (newRegions === null) {
                            setAreaBool(false);
                            setSiteBool(false);
                        }
                    }
                } else {
                    setNewUserDetails({
                        ...newUserDetails,
                        regions: region ? region : [],
                    });
                    setAreaBool(regionsAndSites && regionsAndSites.Areas && region ? true : false);
                    setSiteBool(regionsAndSites && regionsAndSites.Sites && region ? true : false);
                    setDistSiteNameListOptions(regionsAndSites.Sites);
                }
            }
            setEffectTriggered(true);
        }
    }, [selectedActiveDistrict, regionsAndSites]);

    useEffect(() => {
        if (newUserDetails.regions) {
            setNewRegions([regionsAndSites].find((region: userEntity.IRegionsSites) => region.name === _.toString(newUserDetails.regions)));
        }
        if (newUserDetails.roles) {
            let roles: userEntity.IUserRoles[] = [];
            if (newUserDetails.roles.length > 1) {
                rolesList.forEach((role: userEntity.IUserRoles) => {
                    newUserDetails.roles.forEach((userRole: string) => {
                        if (userRole === role.roleName) {
                            roles.push(role);
                        }
                    });
                });
            } else if (newUserDetails.roles.length === 1) {
                const selectedRoles = newUserDetails.roles[0].split(",");
                rolesList.forEach((role: userEntity.IUserRoles) => {
                    selectedRoles.forEach((userRole: string) => {
                        if (userRole === role.roleName) {
                            roles.push(role);
                        }
                    });
                });
            } else {
                roles = [];
            }
            setNewRoles(roles);
        }
        if (userAction === "Edit") {
            if (regionsAndSites.hasOwnProperty("Areas")) {
                if (regionsAndSites.name) {
                    if (regionsAndSites.Areas && regionsAndSites.Areas.length > 0) {
                        setAreaBool(true);
                    }
                    if (regionsAndSites.Sites && regionsAndSites.Sites.length > 0) {
                        setSiteBool(true);
                    }
                }
                if (newRegions === null) {
                    setAreaBool(false);
                    setSiteBool(false);
                }
            }
        }
    }, [newUserDetails]);

    useEffect(() => {
        
        const lst: any[] = [];
        if (regionsAndSites.hasOwnProperty("Areas")) {
            if (newUserDetails.areas) {
                if (newUserDetails.areas.length > 0) {
                    if (newUserDetails.areas[0] !== "") {
                        setNewArea(regionsAndSites.Areas.find((area: userEntity.IAreas) => area.name === _.toString(newUserDetails.areas)));
                    }
                } else {
                    setNewArea(newArea);
                }
            } else {
                setDistSiteNameListOptions(regionsAndSites.Sites);
            }
            if (userAction === "Edit") {
                if (regionsAndSites) {
                    if (newArea) {
                        if (newUserDetails.areas && newUserDetails.areas[0] !== "") {
                            regionsAndSites.Areas.map((area: any) => (area.name === newUserDetails.areas[0])
                                ? lst.push(area) : "");
                        }
                        setDistSiteNameListOptions(lst);
                    } else {
                        setSiteBool(true);
                        setDistSiteNameListOptions(regionsAndSites.Sites);
                    }

                    if (lst.map((s) => s.Sites !== undefined)[0]) {
                        setSiteBool(false);
                        setAreaSiteBool(true);
                    } else {
                        setAreaSiteBool(false);
                    }
                }
                if (newUserDetails.sites[0] === "" && newUserDetails.areas[0] === "") {
                    const regionEditLst: any[] = [];
                    regionsAndSites.hasOwnProperty("Sites") && regionEditLst.push(regionsAndSites.Sites);
                    setDistSiteNameListOptions(_.flatten(regionEditLst));
                } else if (newUserDetails.areas[0] === "" && newUserDetails.sites.length > 0) {
                    const regionEditLst: any[] = [];
                    regionsAndSites.hasOwnProperty("Sites") && regionEditLst.push(regionsAndSites.Sites);
                    setDistSiteNameListOptions(_.flatten(regionEditLst));
                }
            }
        }
    }, [newUserDetails]);

    useEffect(() => {
        if (regionsAndSites.hasOwnProperty("Sites")) {
            if (newUserDetails.sites[0] !== "") {
                if (!areaSiteBool) {
                    let sites: userEntity.ISites[] = [];
                    if (newUserDetails.sites.length > 1) {
                        if (newUserDetails.regions) {
                            for (let i = 0; i < regionsAndSites.Sites.length; i++) {
                                for (let j = 0; j < newUserDetails.sites.length; j++) {
                                    if (regionsAndSites.Sites.map((s: any) => s.name)[i] === newUserDetails.sites.map((a) => a.name)[j]) {
                                        sites.push(regionsAndSites.Sites[i]);
                                    }
                                }
                            }
                        } else {
                            const multipleSites = newUserDetails.sites[0].split(",");
                            _.flatten([regionsAndSites.Areas.find((a: any) => a.name === newUserDetails.areas[0])].map((site: any) => site.Sites)).forEach((site: any) => {
                                multipleSites.forEach((userSite: string) => {
                                    if (userSite === site.name) {
                                        sites.push(site);
                                    }
                                });
                            });
                        }
                    } else if (newUserDetails.sites.length === 1) {
                        if (userAction === "Edit") {
                            if (newUserDetails.areas[0]) {
                                if (newUserDetails.areas[0] !== "") {
                                    if ([regionsAndSites.Areas.find((a: any) => (a.name === newUserDetails.areas[0]))].map((site: any) => site.hasOwnProperty("Sites"))[0]) {
                                        const selectedSites = newUserDetails.sites[0].split(",");
                                        _.flatten([regionsAndSites.Areas.find((a: any) => (a.name === newUserDetails.areas[0]))].map((site: any) => site.Sites))
                                            .forEach((site: any) => {
                                                selectedSites.forEach((userSite: string) => {
                                                    if (userSite === site.name) {
                                                        sites.push(site);
                                                    }
                                                });
                                            });
                                    }
                                }
                            } else {
                                if (!newUserDetails.sites[0].hasOwnProperty("name")) {
                                    const selectedSites = newUserDetails.sites[0].split(",");
                                    regionsAndSites.Sites.forEach((site: userEntity.ISites) => {
                                        selectedSites.forEach((userSite: string) => {
                                            if (userSite === site.name) {
                                                sites.push(site);
                                            }
                                        });
                                    });
                                }
                            }
                        }
                        if (newUserDetails.regions) {
                            if (newUserDetails.regions) {
                                for (let i = 0; i < regionsAndSites.Sites.length; i++) {
                                    for (let j = 0; j < newUserDetails.sites.length; j++) {
                                        if (regionsAndSites.Sites.map((s: any) => s.name)[i] === newUserDetails.sites.map((a) => a.name)[j]) {
                                            sites.push(regionsAndSites.Sites[i]);
                                        }
                                    }
                                }
                            } else {
                                const selectedSites = newUserDetails.sites[0].split(",");
                                if (distSiteNameList && distSiteNameList.map((a: any) => a.hasOwnProperty("Sites"))[0]) {
                                    _.flatten([regionsAndSites.Areas.find((a: any) =>
                                        (a.name === newUserDetails.areas[0]))].map((site: any) =>
                                            site.Sites)).forEach((site: any) => {
                                                selectedSites.forEach((userSite: string) => {
                                                    if (userSite === site.name) {
                                                        sites.push(site);
                                                    }
                                                });
                                            });
                                } else if (!distSiteNameList) {
                                    _.flatten([regionsAndSites.Areas.find((b: any) =>
                                        (b.name === newUserDetails.areas[0]))].map((site: any) =>
                                            site.Sites)).forEach((site: any) => {
                                                selectedSites.forEach((userSite: string) => {
                                                    if (userSite === site.name) {
                                                        sites.push(site);
                                                    }
                                                });
                                            });
                                }
                            }
                        }
                    } else {
                        sites = [];
                    }
                    setNewSites(sites);
                }
            }
        }
    }, [areaSiteBool, newUserDetails]);

    const handleChange = (name: any, value: any) => {
        if (name === "cellPhone" || name === "workPhone") {
            if (value.length <= 12) {
                setNewUserDetails({
                    ...newUserDetails,
                    [name]: value,
                });
            }
        } else if (name === "firstName" || name === "lastName") {
            if ((/^[a-zA-Z0-9 ]+$/.test(value)) || !value) {
                setNewUserDetails({
                    ...newUserDetails,
                    [name]: value,
                });
            }

        } else {
            setNewUserDetails({
                ...newUserDetails,
                [name]: value,
            });
        }
    };

    const validateUserInfo = () => {
        if (newUserDetails.firstName === "") {
            setFirstNameError(true);
            setFirstNameErrorMessage("Please enter first name");
        } else {
            setFirstNameError(false);
            setFirstNameErrorMessage("");
        }

        if (newUserDetails.lastName === "") {
            setLastNameError(true);
            setLastNameErrorMessage("Please enter last name");
        } else {
            setLastNameError(false);
            setLastNameErrorMessage("");
        }
        if (newUserDetails.email !== "") {
            if (!newUserDetails.email.match(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)) {
                setEmailError(true);
                setEmailErrorMessage("Please enter valid email id");
            }
        } else {
            if (newUserDetails.email === "") {
                setEmailError(true);
                setEmailErrorMessage("Please enter valid email id");
            } else {
                setEmailError(false);
                setEmailErrorMessage("");
            }
        }

        if (!newUserDetails.regions) {
            setRegionsError(true);
            setRegionsMessage("Please select a region");
        } else {
            if (newUserDetails.regions.length === 0 || !newUserDetails.regions) {
                setRegionsError(true);
                setRegionsMessage("Please select a region");
            } else {
                setRegionsError(false);
                setRegionsMessage("");
            }
        }
        if ((newUserDetails.organizationLevel === "Area" || newUserDetails.organizationLevel.accessLevelDescription === "Area")
            && (![newUserDetails.areas][0] || newUserDetails.areas.length === 0)) {
            setAreasError(true);
            setAreasMessage("Please select a area");
        } else {
            setAreasError(false);
            setAreasMessage("");
        }
        if ((newUserDetails.organizationLevel === "Site" || newUserDetails.organizationLevel.accessLevelDescription === "Site")
            && (!newUserDetails.sites[0] || newUserDetails.sites.length === 0)) {
            setSitesError(true);
            setSitesMessage("Please select a site");
        } else {
            setSitesError(false);
            setSitesMessage("");
        }
        if (newUserDetails.roles.length === 0) {
            setRolesError(true);
            setRolesMessage("Please select a role");
        } else {
            setRolesError(false);
            setRolesMessage("");
        }
    };

    const handleFirstNameBlur = () => {
        if (newUserDetails.firstName === "") {
            setFirstNameError(true);
            setFirstNameErrorMessage("Please enter first name");
        } else {
            setFirstNameError(false);
            setFirstNameErrorMessage("");
        }
    };

    const handleEditRegions = (newValue: userEntity.IRegionsSites) => {
        const regionEditLst: any[] = [];
        if (newValue) {
            setNewUserDetails({
                ...newUserDetails,
                regions: [newValue.name],
            });
            setNewRegions([newValue]);
        }
        if (regionsAndSites.name) {
            if (regionsAndSites.Areas.length > 0) {
                setAreaBool(true);
            }
            if (regionsAndSites.Sites.length > 0) {
                setSiteBool(true);
                regionsAndSites.hasOwnProperty("Sites") && regionEditLst.push(regionsAndSites.Sites);
            }
            setDistSiteNameListOptions(_.flatten(regionEditLst));
        }
    };

    const handleRegions = (val: any) => {
        const regionLst: any[] = [];
        setNewUserDetails({
            ...newUserDetails,
            regions: val,
        });

        if (regionsAndSites.name) {
            if (regionsAndSites.Areas.length > 0) {
                setAreaBool(true);
            }
            if (regionsAndSites.Sites.length > 0) {
                setSiteBool(true);
                regionsAndSites.hasOwnProperty("Sites") && regionLst.push(regionsAndSites.Sites);
            }
            setDistSiteNameListOptions(_.flatten(regionLst));
        }

    };

    const handleEditAreas = (newValue: userEntity.IAreas) => {
        const lst: any[] = [];
        if (newValue) {
            setNewUserDetails({
                ...newUserDetails,
                areas: [newValue.name],
            });
            setNewArea(newValue);
            setClose(false);
        } else {
            setNewUserDetails({
                ...newUserDetails,
                areas: [newValue],
            });
            setNewArea(newValue);
            setClose(true);
        }
        if (regionsAndSites) {
            if (newValue !== null) {
                regionsAndSites.Areas.map((area: any) => (area.name === newValue.name)
                    ? lst.push(area) : "");
                setNewSites([]);
                setClose(false);
            } else {
                setSiteBool(true);
                setClose(true);
            }
            setDistSiteNameListOptions(lst);
            if (lst.map((s) => s.Sites !== undefined)[0]) {
                setSiteBool(false);
                setAreaSiteBool(true);
            } else {
                setAreaSiteBool(false);
            }
        }
    };

    const handleAreas = (val: any) => {
        const lst: any[] = [];
        setNewUserDetails({
            ...newUserDetails,
            areas: val,
        });
        if (regionsAndSites) {
            if (val !== null) {
                regionsAndSites.Areas.map((area: any) => (area.name === val.name)
                    ? lst.push(area) : "");
                setNewSites([]);
                setClose(false);
            } else {
                setSiteBool(true);
                setClose(true);
            }
            setDistSiteNameListOptions(lst);
            if (lst.map((s) => s.Sites !== undefined)[0]) {
                setSiteBool(false);
                setAreaSiteBool(true);
            } else {
                setAreaSiteBool(false);
            }
        }
    };

    const handleSiteChange = (val: userEntity.ISites[]) => {
        setNewSites(val);
        setNewUserDetails({
            ...newUserDetails,
            sites: val,
        });

    };

    const handleRoleChange = (val: userEntity.IUserRoles[]) => {
        setNewRoles(val);
        const roles: string[] = [];
        val.forEach((role: userEntity.IUserRoles) => {
            roles.push(role.roleName);
        });
        setNewUserDetails({
            ...newUserDetails,
            roles,
        });
    };

    const handleLastNameBlur = () => {
        if (newUserDetails.lastName === "") {
            setLastNameError(true);
            setLastNameErrorMessage("Please enter last name");
        } else {
            setLastNameError(false);
            setLastNameErrorMessage("");
        }
    };

    const handleEmailValid = (response: any) => {
        if (newUserDetails.email) {
            if (newUserDetails.email.match(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)) {
                if (!response.ResponseEnvelope.MessageInfoCollection) {
                    setEmailError(true);
                    setEmailErrorMessage("Email id already exists");
                } else {
                    setEmailError(false);
                    setEmailErrorMessage("");
                }
            }
        }
    };

    const handleEmailBlur = () => {
        dispatch(validateEmailInit({ email: newUserDetails.email, action: handleEmailValid }));
        if (newUserDetails.email !== "") {
            if (!newUserDetails.email.match(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)) {
                setEmailError(true);
                setEmailErrorMessage("Please enter valid email id");
            } else {
                setEmailError(false);
                setEmailErrorMessage("");
            }
        } else {
            if (newUserDetails.email === "") {
                setEmailError(true);
                setEmailErrorMessage("Please enter valid email id");
            } else {
                setEmailError(false);
                setEmailErrorMessage("");
            }
        }
    };

    const handleRegionsBlur = () => {
        if (!newUserDetails.regions) {
            setRegionsError(true);
            setRegionsMessage("Please select a region");
        } else {
            if (newUserDetails.regions.length === 0 || !newUserDetails.regions) {
                setRegionsError(true);
                setRegionsMessage("Please select a region");
            } else {
                setRegionsError(false);
                setRegionsMessage("");
            }
        }
    };

    const handleAreasBlur = () => {
        if ((newUserDetails.organizationLevel.accessLevelDescription === "Area") && (newUserDetails.areas.length === 0)) {
            setAreasError(true);
            setAreasMessage("Please select a area");
        } else {
            setAreasError(false);
            setAreasMessage("");
        }
    };

    const handleEditAreasBlur = () => {
        if ((newUserDetails.organizationLevel === "Area") && (!newUserDetails.areas[0])) {
            setAreasError(true);
            setAreasMessage("Please select a area");
        } else {
            setAreasError(false);
            setAreasMessage("");
        }
    };

    const handleSitesBlur = () => {
        if ((newUserDetails.organizationLevel.accessLevelDescription === "Site") && (newUserDetails.sites.length === 0)) {
            setSitesError(true);
            setSitesMessage("Please select a site");
        } else {
            setSitesError(false);
            setSitesMessage("");
        }
    };

    const handleEditSitesBlur = () => {
        if ((newUserDetails.organizationLevel === "Site") && (!newUserDetails.sites[0])) {
            setSitesError(true);
            setSitesMessage("Please select a site");
        } else {
            setSitesError(false);
            setSitesMessage("");
        }
    };

    const handleRoleBlur = () => {
        if (newUserDetails.roles.length === 0) {
            setRolesError(true);
            setRolesMessage("Please select a role");
        } else {
            setRolesError(false);
            setRolesMessage("");
        }
    };

    const saveUser = () => {

        let regionObj = {};
        let areaObj = {};
        let roleObj = {};
        let siteObj = {};
        let siteObj1 = {};
        let roleObj1 = {};
        let regionObj1 = {};
        let groupsObj = {};

        if (!(userAction === userEntity.userType.editRole)) {
            for (let i = 0; i < [regionsAndSites].length; i++) {
                if ([regionsAndSites].map((region: any) => region.name)[i] === [newUserDetails.regions].map((region: any) => region.name)[i]) {
                    [regionsAndSites].forEach((item) => {
                        regionObj = {
                            [item.id]: [newUserDetails.regions].map((region: any) => region.name)[i],
                        };
                        regionObj1 = { ...regionObj, ...regionObj1 };
                    });
                }
            }
        } else {
            [regionsAndSites].forEach((item) => {
                regionObj = {
                    [item.id]: item.name,
                };
                regionObj1 = { ...regionObj, ...regionObj1 };
            });
        }

        if (userAction === "Edit") {
            if (distSiteNameList?.length !== 0) {
                distSiteNameList?.map((area: any) => (area.name === newUserDetails.areas[0]) ?
                    areaObj = {
                        [area.id]: newUserDetails.areas[0],
                    } : "");
            } else {
                regionsAndSites.Areas.map((area: any) => (area.name === newUserDetails.areas[0]) ?
                    areaObj = {
                        [area.id]: newUserDetails.areas[0],
                    } : "");
            }
        } else {
            if (newUserDetails.areas !== null) {
                distSiteNameList?.forEach((area: any) => (area.name === newUserDetails.areas.name) ?
                    areaObj = {
                        [area.id]: newUserDetails.areas.name,
                    } : "");
            }
        }
        if (userAction === "Edit") {
            const selectedRoles: any[] = [];
            for (const newDetails of newUserDetails.roles) {
                selectedRoles.push(newDetails.split(","));
            }
            for (let j = 0; j < rolesList.length; j++) {
                for (let k = 0; k < _.flatten(selectedRoles).length; k++) {
                    if (rolesList.map((role: userEntity.IUserRoles) => role.roleName)[j] === _.flatten(selectedRoles)[k]) {
                        roleObj = {
                            [rolesList.map((role: any) => role.roleGuid)[j]]: _.flatten(selectedRoles)[k],
                        };
                        roleObj1 = { ...roleObj, ...roleObj1 };
                    }
                }
            }
        } else {
            for (let j = 0; j < rolesList.length; j++) {
                for (let k = 0; k < newUserDetails.roles.length; k++) {
                    if (rolesList.map((role: userEntity.IUserRoles) => role.roleName)[j] === newUserDetails.roles[k]) {
                        roleObj = {
                            [rolesList.map((role: any) => role.roleGuid)[j]]: newUserDetails.roles[k],
                        };
                        roleObj1 = { ...roleObj, ...roleObj1 };
                    }
                }
            }
        }
        if (userAction === "Edit") {
            if (newUserDetails.areas !== null) {
                if (!newUserDetails.areas[0] && distSiteNameList) {
                    if (newUserDetails.sites.length === 1) {
                        if (!newUserDetails.sites[0].hasOwnProperty("name")) {
                            const selectedSites = newUserDetails.sites[0].split(",");
                            for (let i = 0; i < distSiteNameList.length; i++) {
                                for (let s = 0; s < selectedSites.length; s++) {
                                    if (distSiteNameList.map((a) => a.name)[i] === selectedSites[s]) {
                                        siteObj = {
                                            [distSiteNameList.map((a) => a.id)[i]]: selectedSites[s],
                                        };
                                        siteObj1 = { ...siteObj, ...siteObj1 };
                                    }
                                }
                            }
                        } else {
                            for (let d = 0; d < distSiteNameList.length; d++) {
                                for (let s = 0; s < newUserDetails.sites.length; s++) {
                                    if (distSiteNameList.map((a) => a.name)[d] === newUserDetails.sites.map((st) => st.name)[s]) {
                                        siteObj = {
                                            [distSiteNameList.map((a) => a.id)[d]]: newUserDetails.sites.map((st) => st.name)[s],
                                        };
                                        siteObj1 = { ...siteObj, ...siteObj1 };
                                    }
                                }
                            }
                        }
                    } else if (newUserDetails.sites.length > 1) {
                        for (let d = 0; d < distSiteNameList.length; d++) {
                            for (let s = 0; s < newUserDetails.sites.length; s++) {
                                if (distSiteNameList.map((a) => a.name)[d] === newUserDetails.sites.map((st) => st.name)[s]) {
                                    siteObj = {
                                        [distSiteNameList.map((a) => a.id)[d]]: newUserDetails.sites.map((st) => st.name)[s],
                                    };
                                    siteObj1 = { ...siteObj, ...siteObj1 };
                                }
                            }
                        }
                    }
                } else if (newUserDetails.areas.length > 0 || newUserDetails.areas.length === undefined) {
                    if (newUserDetails.sites.length > 0) {
                        if (newUserDetails.sites[0]) {
                            if (!newUserDetails.sites[0].hasOwnProperty("name") && distSiteNameList) {
                                const selectedSites = newUserDetails.sites[0].split(",");
                                for (let i = 0; i < _.flatten(distSiteNameList?.map((s) => s.Sites)).map((s) => s.name).length; i++) {
                                    for (let s = 0; s < selectedSites.length; s++) {
                                        if (_.flatten(distSiteNameList?.map((site) => site.Sites)).map((sName) => sName.name)[i] === selectedSites[s]) {
                                            siteObj = {
                                                [_.flatten(distSiteNameList?.map((a) => a.Sites)).map((sId) => sId.id)[i]]: selectedSites[s],
                                            };
                                            siteObj1 = { ...siteObj, ...siteObj1 };
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let s = 0; s < newUserDetails.sites.length; s++) {
                            siteObj = {
                                [newUserDetails.sites.map((site) => site.id)[s]]: newUserDetails.sites.map((sites) => sites.name)[s],
                            };
                            siteObj1 = { ...siteObj, ...siteObj1 };
                        }
                    }
                }
            }
            if (newUserDetails.sites.length > 0) {
                if (newUserDetails.sites[0]) {
                    if (newUserDetails.sites[0].hasOwnProperty("name")) {
                        if (distSiteNameList?.length !== 0 && distSiteNameList && distSiteNameList.map((a: any) => a.hasOwnProperty("Sites"))[0]) {
                            for (let site = 0; site < _.flatten(distSiteNameList?.map((s) => s.Sites)).map((s) => s.name).length; site++) {
                                for (let r = 0; r < newUserDetails.sites.length; r++) {
                                    if (_.flatten(distSiteNameList?.map((s) => s.Sites)).map((s) => s.name)[site] === newUserDetails.sites.map((s) => s.name)[r]) {
                                        siteObj = {
                                            [_.flatten(distSiteNameList?.map((s) => s.Sites)).map((s) => s.id)[site]]: newUserDetails.sites.map((s) => s.name)[r],
                                        };
                                        siteObj1 = { ...siteObj, ...siteObj1 };
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                if (distSiteNameList && distSiteNameList.map((a: any) => a.hasOwnProperty("Sites"))[0]) {
                    if (newUserDetails.sites[0]) {
                        const selectedSites = newUserDetails.sites[0].split(",");
                        for (let l = 0; l < _.flatten(distSiteNameList?.map((s) => s.Sites)).map((s) => s.name).length; l++) {
                            for (let m = 0; m < selectedSites.length; m++) {
                                if (_.flatten(distSiteNameList?.map((s) => s.Sites)).map((s) => s.name)[l] === selectedSites[m]) {
                                    siteObj = {
                                        [_.flatten(distSiteNameList?.map((s) => s.Sites)).map((s) => s.id)[l]]: selectedSites[m],
                                    };
                                    siteObj1 = { ...siteObj, ...siteObj1 };
                                }
                            }
                        }
                    }
                }
            }
        } else {
            if (newUserDetails.areas !== null) {
                if (newUserDetails.areas.length > 0 || newUserDetails.areas.length === undefined) {
                    if (distSiteNameList && distSiteNameList.map((a: any) => a.hasOwnProperty("Sites"))[0]) {
                        for (let l = 0; l < _.flatten(distSiteNameList.map((s) => s.Sites)).map((s) => s.name).length; l++) {
                            for (let m = 0; m < newUserDetails.sites.length; m++) {
                                if (_.flatten(distSiteNameList
                                    .map((s) => s.Sites)).map((s) => s.name)[l] === newUserDetails.sites.map((s) => s.name)[m]) {
                                    siteObj = {
                                        [_.flatten(distSiteNameList.map((s) => s.Sites)).map((s) => s.id)[l]]: newUserDetails.sites.map((s) => s.name)[m],
                                    };
                                    siteObj1 = { ...siteObj, ...siteObj1 };
                                }
                            }
                        }
                    }
                } else if (distSiteNameList) {
                    for (let i = 0; i < distSiteNameList.length; i++) {
                        for (let m = 0; m < newUserDetails.sites.length; m++) {
                            if (distSiteNameList.map((a) => a.name)[i] === newUserDetails.sites.map((s) => s.name)[m]) {
                                siteObj = {
                                    [distSiteNameList.map((a) => a.id)[i]]: newUserDetails.sites.map((s) => s.name)[m],
                                };
                                siteObj1 = { ...siteObj, ...siteObj1 };
                            }
                        }
                    }
                }
            } else {
                if (distSiteNameList) {
                    for (let i = 0; i < distSiteNameList.length; i++) {
                        for (let m = 0; m < newUserDetails.sites.length; m++) {
                            if (distSiteNameList.map((a) => a.name)[i] === newUserDetails.sites.map((s) => s.name)[m]) {
                                siteObj = {
                                    [distSiteNameList.map((a) => a.id)[i]]: newUserDetails.sites.map((s) => s.name)[m],
                                };
                                siteObj1 = { ...siteObj, ...siteObj1 };
                            }
                        }
                    }
                }
            }
        }

        groupsObj = { ...regionObj1, ...areaObj, ...siteObj1 };

        validateUserInfo();

        if (newUserDetails.firstName !== "" && newUserDetails.lastName !== "" &&
            (newUserDetails.email !== "" && newUserDetails.email.match(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i))
            &&
            //  (newUserDetails.workPhone !== "" &&
            //     newUserDetails.workPhone.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}/)) &&
            (newUserDetails.regions.length > 0 ? true : newUserDetails.regions.length === 0 ? false : newUserDetails.regions) &&
            (newUserDetails.roles.length > 0) && !emailError && !firstNameError && !lastNameError && !workPhoneError) {
            if (userAction === userEntity.userType.addRole) {
                if (newUserDetails.file) {
                    saveImage(newUserDetails.file).then((data: any) => {
                        dispatch(userAddInit({
                            userName: `${newUserDetails.firstName} ${newUserDetails.lastName}`,
                            firstName: newUserDetails.firstName,
                            lastName: newUserDetails.lastName,
                            middleName: newUserDetails.middleName,
                            email: newUserDetails.email,
                            cellPhone: newUserDetails.cellPhone,
                            workPhone: newUserDetails.workPhone,
                            userStatusDescription: newUserDetails.userStatusDescription,
                            regions: regionObj1,
                            areas: areaObj,
                            sites: siteObj1,
                            roles: roleObj1,
                            groupsObj,
                            attributes: {
                                isBroadcastUser: [newUserDetails.isBroadcastUser],
                                regionId: selectedDistrict.attributes.regionId ? [selectedDistrict.attributes.regionId[0]] : [0],
                                regionName: [selectedDistrict.name],
                                regionAccessList: selectedDistrict.attributes.regionId ? [selectedDistrict.attributes.regionId[0]] : [0],
                            },
                            regionName: regionsAndSites.name,
                            accessLevelDescription: newUserDetails.organizationLevel.accessLevelDescription,
                            authenticationMode: newUserDetails.authenticationMode,
                            organizationalLevel: newUserDetails.organizationLevel,
                            image: `${data[0].blobName}`,
                            regionId: selectedDistrict.attributes.regionId ? parseInt(selectedDistrict.attributes.regionId) : 0,
                            action: handleSaveUserAddInfo,
                        }));
                        downloadImage(data[0].blobName);
                    }).catch(() => {
                        canShowAlert("error");
                    });
                } else {
                    dispatch(userAddInit({
                        userId: Number(user?.UserId),
                        userName: `${newUserDetails.firstName} ${newUserDetails.lastName}`,
                        firstName: newUserDetails.firstName,
                        lastName: newUserDetails.lastName,
                        middleName: newUserDetails.middleName,
                        email: newUserDetails.email,
                        cellPhone: newUserDetails.cellPhone,
                        workPhone: newUserDetails.workPhone,
                        userStatusDescription: newUserDetails.userStatusDescription,
                        regionName: regionsAndSites.name,
                        groupsObj,
                        image: null,
                        roles: roleObj1,
                        attributes: {
                            isBroadcastUser: [newUserDetails.isBroadcastUser],
                            regionId: selectedDistrict.attributes.regionId ? [selectedDistrict.attributes.regionId[0]] : [0],
                            regionName: [selectedDistrict.name],
                            regionAccessList: selectedDistrict.attributes.regionId ? [selectedDistrict.attributes.regionId[0]] : [0],
                        },
                        accessLevelDescription: newUserDetails.organizationLevel.accessLevelDescription,
                        authenticationMode: newUserDetails.authenticationMode,
                        organizationalLevel: newUserDetails.organizationLevel,
                        regionId: selectedDistrict.attributes.regionId ? parseInt(selectedDistrict.attributes.regionId) : 0,
                        action: handleSaveUserAddInfo,
                    }));
                }
            }
            if (userAction === userEntity.userType.editRole) {
                if (file) {
                    saveImage(file).then((data: any) => {
                        dispatch(userEditInit({
                            id: userBasicData.userGuid,
                            firstName: newUserDetails.firstName,
                            lastName: newUserDetails.lastName,
                            email: newUserDetails.email,
                            groups: groupsObj,
                            roles: roleObj1,
                            districtName: regionsAndSites.name,
                            userId: newUserDetails.userId,
                            attributes: {
                                isBroadcastUser: [newUserDetails.isBroadcastUser],
                                regionId: selectedDistrict.attributes.regionId ? [selectedDistrict.attributes.regionId[0]] : [0],
                                regionName: [selectedDistrict.name],
                                regionAccessList: selectedDistrict.attributes.regionId ? [selectedDistrict.attributes.regionId[0]] : [0],
                                lastPasswordChangeDate: userData?.UserMgmatUserDetails?.PayLoad?.length > 0 ? userData?.UserMgmatUserDetails?.PayLoad[0]?.attributes.lastPasswordChangeDate : [],
                                userStatus: [newUserDetails.userStatusDescription],
                                userId: userData?.UserMgmatUserDetails?.PayLoad?.length > 0 ? userData?.UserMgmatUserDetails?.PayLoad[0]?.attributes.userId : [],
                                primeroUserId: userData?.UserMgmatUserDetails?.PayLoad?.length > 0 ? userData?.UserMgmatUserDetails?.PayLoad[0]?.attributes.primeroUserId : [],
                            },
                            mi: newUserDetails.middleName,
                            languagepreference: "English",
                            theme: "Default",
                            workPhone: newUserDetails.workPhone,
                            cellPhone: newUserDetails.cellPhone,
                            image: `${data[0].blobName}`,
                            userStatus: newUserDetails.userStatusDescription,
                            enabled: true,
                            action: handleSaveUserInfo,
                        }));
                        downloadImage(data[0].blobName);
                    });
                } else {
                    dispatch(userEditInit({
                        id: userBasicData.userGuid,
                        firstName: newUserDetails.firstName,
                        lastName: newUserDetails.lastName,
                        email: newUserDetails.email,
                        groups: groupsObj,
                        roles: roleObj1,
                        districtName: regionsAndSites.name,
                        userId: newUserDetails.userId,
                        mi: newUserDetails.middleName,
                        attributes: {
                            isBroadcastUser: [newUserDetails.isBroadcastUser],
                            regionId: selectedDistrict.attributes.regionId ? [selectedDistrict.attributes.regionId[0]] : [0],
                            regionName: [selectedDistrict.name],
                            regionAccessList: selectedDistrict.attributes.regionId ? [selectedDistrict.attributes.regionId[0]] : [0],
                            lastPasswordChangeDate: userData?.UserMgmatUserDetails?.PayLoad?.length > 0 ? userData?.UserMgmatUserDetails?.PayLoad[0]?.attributes.lastPasswordChangeDate : [],
                            userStatus: [newUserDetails.userStatusDescription],
                            userId: userData?.UserMgmatUserDetails?.PayLoad?.length > 0 ? userData?.UserMgmatUserDetails?.PayLoad[0]?.attributes.userId : [],
                            primeroUserId: userData?.UserMgmatUserDetails?.PayLoad?.length > 0 ? userData?.UserMgmatUserDetails?.PayLoad[0]?.attributes.primeroUserId : [],
                        },
                        languagepreference: "English",
                        theme: "Default",
                        workPhone: newUserDetails.workPhone,
                        cellPhone: newUserDetails.cellPhone,
                        image: newUserDetails.file,
                        userStatus: newUserDetails.userStatusDescription,
                        enabled: true,
                        action: handleSaveUserInfo,
                    }));
                }
            }
        }
    };

    const handleSaveUserInfo = (response: any) => {
        if (response.StatusCode === 200) {
            setAlertMessage("User account has been updated successfully");
            setTimeout(() => {
                dispatch(getUserDetailsKeyclockInit(newUserDetails.email));
                onClose();
            }, 900);
        }
    };

    const handleSaveUserAddInfo = (response: any) => {
        if (response.StatusCode === 200) {
            setAlertMessage("User has been added successfully.");
            setTimeout(() => {
                setAlertMessage("");
            }, 3000);
            onClose();
        }
    };

    const handleSwitch = (value: string) => {
        setNewUserDetails({
            ...newUserDetails,
            userStatusDescription: value,
        });
    };

    const cancelUserInfo = () => {
        setCancelUserConfirmation(true);
        dispatch(viewFilterSiteDataInit(selectedDistrict.groupGuid));
    };

    const downloadImage = (blob: string) => {
        const fileURL = `${API_DATA_EXCHANGE}/api/FileStorage/Download?featureName=user&fileName=${blob}`;

        Axios.get(fileURL, {
            responseType: "blob",
            headers: {
                Environment: APIM_ENVIRONMENT,
                "Ocp-Apim-Subscription-Key": APIM_SUBSCRIPTION_KEY,
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": `application/octet-stream`,
            },
        })
            .then((resp) => {
                const dwnldlink = window.URL.createObjectURL(
                    new Blob([resp.data], {
                        type: resp.headers["content-type"],
                    }),
                );
                setUserImageUrl(dwnldlink);
            })
            .catch((ex) => {
                console.info(ex);
            });
    };

    const onFileUpload = (event: any) => {
        if (event.target.files.length > 0 && event.target.files[0].type.includes("image")) {
            if (event.target.files[0].size < 5242880) {
                setNewUserDetails({
                    ...newUserDetails,
                    file: event.target.files[0],
                });
            } else {
                canShowImageSizeError(() => true);
            }
        } else {
            canShowImageFormatError(() => true);
            setTimeout(() => {
                canShowImageFormatError(() => false);
            }, 5000);
        }
    };

    const onUpdateFileUpload = (event: any) => {
        if (event.target.files.length > 0 && event.target.files[0].type.includes("image")) {
            if (event.target.files[0].size < 5242880) {
                setFile(event.target.files[0]);

            } else {
                canShowImageSizeError(() => true);
            }
        } else {
            canShowImageFormatError(() => true);
            setTimeout(() => {
                canShowImageFormatError(() => false);
            }, 5000);
        }
    };

    const handleFileUploadClick = (event: any) => {
        event.target.value = "";
    };

    const onFileUploadTrigger = () => {
        if (fileUploadRef.current) {
            fileUploadRef.current.click();
        }
    };

    const confirmRemovePicturePopup = () => {
        if (newUserDetails.file || file) {
            setRemovePictureConfirmation(true);
        }
    };

    const handleRemoveSuccess = (response: any) => {
        if (response.StatusCode === 200) {
            setPictureAlertMessage("File deleted successfully");
            setAlertStatus("success");
            setPictureAlert(true);
        }
        setTimeout(() => {
            setPictureAlert(false);
            setPictureAlertMessage("");
            setAlertStatus(undefined);
        }, 5000);
    };
    const onFileRemoveTrigger = useCallback(() => {
        if (newUserDetails.file !== "") {
            dispatch(getRemovePictureInit({ payload: newUserDetails.file, action: handleRemoveSuccess }));
            setNewUserDetails({
                ...newUserDetails,
                file: "",
                userImageUrl: "",
            });
        } else if (file) {
            saveImage(file).then((data: any) => {
                dispatch(getRemovePictureInit(data[0].blobName));
                setFile("");
            });
        }
    }, [newUserDetails.file, newUserDetails.userImageUrl, file]);

    const handleAlertMessage = () => {
        setUserAlertMessage(true);
    };

    const handlePasswordClick = () => {
        dispatch(userResetPasswordInit({
            userId: Number(user?.UserId),
            userName: newUserDetails.email,
            userEmail: newUserDetails.email,
            isResetForConfirmPassword: false,
            isTemporaryPassword: true,
            password: "",
            userStatus: "Active",
            district: newUserDetails.regions[0],
            groupId: selectedDistrict.groupGuid,
        }));
        setPasswordAlert(true);
        setUserAlertMessage(false);
    };
    const Isbroadcastuser = (event: any): void => {
        if (Toggle === false) {
            setToggle(event?.target?.checked);
            setNewUserDetails({ ...newUserDetails, isBroadcastUser: "true" });
        } else {
            setNewUserDetails({ ...newUserDetails, isBroadcastUser: "false" });
            setToggle(false);
        }
    };
    useEffect(() => {
        if (userBasicData.attributes !== null) {
            if (userBasicData.attributes[0].isBroadcastUser === undefined) {
                setToggle(false);
            } else if (userBasicData.attributes[0]?.isBroadcastUser[0] === "true") {
                setToggle(true);
            } else {
                setNewUserDetails({ ...newUserDetails, isBroadcastUser: "false" });
                setToggle(false);
            }
        } else {
            setToggle(false);
        }
    }, []);

    return (
        <>
            <PageLayout className={classes.root}
                breadCrumbs={[]}
                title={""}
            >
                {adduserLoading === userEntity.UsersStatus.Loading ? (
                    <div style={{ height: "60vh", padding: "10px" }}>
                        <AppLoader />
                    </div>
                ) : (
                    <>
                        {
                            passwordAlert && (
                                <Notification
                                    color="success"
                                    message="Your password send to your email id."
                                    onClose={() => setPasswordAlert(() => false)}
                                    open={passwordAlert}
                                    variant="filled"
                                />
                            )
                        }
                        {showImageFormatError && (
                            <Notification
                                color="warning"
                                message="Profile image only accepts PNG, JPG, SVG format."
                                onClose={() => canShowImageFormatError(() => false)}
                                open={showImageFormatError}
                                variant="filled"
                            />
                        )}
                        {showImageSizeError && (
                            <Notification
                                color="warning"
                                message="Please select image size less than 5 MB"
                                onClose={() => canShowImageSizeError(() => false)}
                                open={showImageSizeError}
                                variant="filled"
                            />
                        )}
                        {showPictureAlert && <Notification
                            color={alertStatus}
                            message={pictureAlertMessage}
                            onClose={() => setPictureAlert(() => false)}
                            open={showPictureAlert}
                            variant="filled"
                        />
                        }
                        <Grid container={true} spacing={2} className={classes.paddingAll}>
                            <Grid xs={12} md={4} lg={3} item={true}>
                                {formTitle !== "Edit User Information" && (
                                    <UserCard
                                        name={""}
                                        imageSrc={newUserDetails.file ? URL.createObjectURL(newUserDetails.file) : "image"}
                                        className="jss222"
                                        actionButton={() => (
                                            <>
                                                <input ref={fileUploadRef} type="file" onChange={onFileUpload} className={classes.hideFileInput} onClick={handleFileUploadClick} />
                                                <Tooltip title="Supported files types includes .jpg & .png" aria-label="add">
                                                    <span>
                                                        <Button
                                                            color="default"
                                                            dense={true}
                                                            endIcon={<AlertCircleIcon />}
                                                            id="uploadAddButton"
                                                            label="Upload Picture"
                                                            name="Button"
                                                            onClick={onFileUploadTrigger}
                                                            type="button"
                                                            variant="default"
                                                        />
                                                    </span>
                                                </Tooltip>
                                            </>
                                        )}
                                    />
                                )}
                                {formTitle === "Edit User Information" && (
                                    <UserCard
                                        name={`${newUserDetails.firstName} ${newUserDetails.lastName}`}
                                        imageSrc={file ? URL.createObjectURL(file) : newUserDetails.userImageUrl}
                                        className="jss222"
                                        status={(_.toUpper(newUserDetails.userStatusDescription) === _.toUpper("Active")) ? AccountStatus.Active
                                            : AccountStatus.InActive}
                                        statusText={
                                            (newUserDetails.userStatusDescription === "Active") ? "ACTIVE"
                                                : (newUserDetails.userStatusDescription === "Locked") ? "Locked" : "Inactive"
                                        }
                                        actionButton={() => (
                                            <>
                                                <input ref={fileUploadRef} type="file" onChange={onUpdateFileUpload} className={classes.hideFileInput} onClick={handleFileUploadClick} />
                                                <Tooltip title="Supported files types includes .jpg & .png" aria-label="add">
                                                    <span>
                                                        <Button
                                                            color="default"
                                                            dense={true}
                                                            endIcon={<AlertCircleIcon />}
                                                            id="uploadEditButton"
                                                            label="Update Picture"
                                                            name="Button"
                                                            onClick={onFileUploadTrigger}
                                                            type="button"
                                                            variant="default"
                                                        />
                                                    </span>
                                                </Tooltip>
                                                <span style={{ marginTop: "20px" }}>
                                                    <Button
                                                        color="default"
                                                        dense={true}
                                                        id="uploadEditButton"
                                                        label="Remove Picture"
                                                        name="Button"
                                                        onClick={confirmRemovePicturePopup}
                                                        type="button"
                                                        variant="default"
                                                    />
                                                </span>
                                            </>
                                        )}
                                    />
                                )}
                            </Grid>
                            <Grid xs={12} md={8} lg={9} item={true} >
                                <Grid container={true} spacing={1}>
                                    <Grid item={true} xs={12}>
                                        <Paper>
                                            <Card className={classes.root}>
                                                <CardHeader title="Contact Info" />
                                                <CardContent classes={{ root: classes.cardContent }}>
                                                    <Grid container={true} spacing={3}>
                                                        <Grid item={true} xs={12} md={6} lg={4}>
                                                            <TextField
                                                                fullWidth={true}
                                                                error={firstNameError}
                                                                helperText={firstNameErrorMessage}
                                                                id="firstName"
                                                                name="firstName"
                                                                value={newUserDetails.firstName}
                                                                onBlur={handleFirstNameBlur}
                                                                onChange={(event) => handleChange(event.target.name, event.target.value)}
                                                                label={<RequiredLabel inputLabel="First Name" />}
                                                                type={"text"}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item={true} xs={12} md={6} lg={4}>
                                                            <TextField
                                                                fullWidth={true}
                                                                id="middleName"
                                                                name="middleName"
                                                                value={newUserDetails.middleName}
                                                                onChange={(event) => handleChange(event.target.name, event.target.value)}
                                                                label={"Middle Name"}
                                                                type={"text"}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 4 }}
                                                            />
                                                        </Grid>
                                                        <Grid item={true} xs={12} md={6} lg={4}>
                                                            <TextField
                                                                fullWidth={true}
                                                                error={lastNameError}
                                                                helperText={lastNameErrorMessage}
                                                                id="lastName"
                                                                name="lastName"
                                                                value={newUserDetails.lastName}
                                                                onBlur={handleLastNameBlur}
                                                                onChange={(event) => handleChange(event.target.name, event.target.value)}
                                                                label={<RequiredLabel inputLabel="Last Name" />}
                                                                type={"text"}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item={true} xs={12} md={6} lg={4}>
                                                            <TextField
                                                                fullWidth={true}
                                                                error={emailError}
                                                                helperText={emailErrorMessage}
                                                                id="email"
                                                                name="email"
                                                                value={newUserDetails.email}
                                                                onBlur={handleEmailBlur}
                                                                onChange={(event) => handleChange(event.target.name, event.target.value)}
                                                                label={<RequiredLabel inputLabel="Email" />}
                                                                type={"email"}
                                                                variant="outlined"
                                                                disabled={formTitle === "Edit User Information" ? true : false}
                                                            />
                                                        </Grid>
                                                        <Grid item={true} xs={12} md={6} lg={4}>
                                                            <TextField
                                                                fullWidth={true}
                                                                id="cellPhone"
                                                                name="cellPhone"
                                                                value={maskedPhoneNumber(newUserDetails.cellPhone)}
                                                                onChange={(event) => handleChange(event.target.name, event.target.value)}
                                                                label={"Cell Phone"}
                                                                type={"text"}
                                                                variant="outlined"
                                                            />


                                                        </Grid>
                                                        <Grid item={true} xs={12} md={6} lg={4}>
                                                            <TextField
                                                                fullWidth={true}
                                                                id="workPhone"
                                                                name="workPhone"
                                                                value={maskedPhoneNumber(newUserDetails.workPhone)}
                                                                onChange={(event) => handleChange(event.target.name, event.target.value)}
                                                                label="Work Phone"
                                                                type={"text"}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item={true} xs={12} md={12} lg={12}>
                                                            <FormControlLabel
                                                                control={(
                                                                    <Switch
                                                                        onChange={Isbroadcastuser}
                                                                        color="primary"
                                                                        name="broadcast"
                                                                        id="isBroadcastUser"
                                                                        inputProps={{ "aria-label": "true" }}
                                                                        checked={Toggle}
                                                                    />
                                                                )}
                                                                label="Is Broadcast User"
                                                                labelPlacement="start"
                                                                className={classes.switchtoggle}
                                                            />
                                                        </Grid>
                                                        {formTitle !== "Edit User Information" && (
                                                            <Grid md={12} xs={12} item={true}>
                                                                <AlertCircleIcon />
                                                                <Typography
                                                                    variant="h6"
                                                                    color="textSecondary"
                                                                    className={classes.alertIcon}
                                                                >
                                                                    Password will be randomly-generated and sent to this email
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        {formTitle === "Edit User Information" && (
                                                            <Grid xs={12} item={true} md={6}>
                                                                <FormControl
                                                                    component="fieldset"
                                                                    className={classes.statusContainer}
                                                                >
                                                                    <Grid xs={12} item={true} md={12}>
                                                                        <FormLabel className={classes.lbl}>STATUS</FormLabel>
                                                                    </Grid>
                                                                    <Grid xs={12} item={true} md={12}>
                                                                        <RadioGroup
                                                                            className={classes.statusGrp}
                                                                            aria-label="status"
                                                                            name="status"
                                                                            value={newUserDetails.userStatusDescription === "Active" ? "Active" : "Disabled"}
                                                                            onChange={(event: any) => handleSwitch(event.target.value)}
                                                                            row={true}
                                                                        >
                                                                            <FormControlLabel
                                                                                className={classes.radioLbl}
                                                                                value={"Active"}
                                                                                control={(
                                                                                    <Radio
                                                                                        id="userActiveRadio"
                                                                                        disableRipple={true}
                                                                                        classes={{
                                                                                            root: classes.radio,
                                                                                            checked: classes.checked,
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                label="Active"
                                                                                labelPlacement="end"
                                                                            />
                                                                            <FormControlLabel
                                                                                className={classes.radioLbl}
                                                                                value={"Disabled"}
                                                                                control={(
                                                                                    <Radio
                                                                                        disableRipple={true}
                                                                                        classes={{
                                                                                            root: classes.radio,
                                                                                            checked: classes.checked,
                                                                                        }}
                                                                                        id="userInActiveRadio"
                                                                                    />
                                                                                )}
                                                                                label="InActive"
                                                                                labelPlacement="end"
                                                                            />
                                                                        </RadioGroup>
                                                                    </Grid>
                                                                </FormControl>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Paper>
                                    </Grid>
                                    {formTitle === "Edit User Information" && (
                                        <Grid item={true} xs={12} style={{ marginTop: 24 }}>
                                            <SummaryList
                                                header="Security"
                                                items={[
                                                    {
                                                        title: "Password",
                                                        value: (
                                                            <>
                                                                <div style={{ padding: "15px 0" }}>
                                                                    <Alert
                                                                        style={{ marginRight: "15px" }}
                                                                        color="info"
                                                                        onClose={null}
                                                                        open={true}
                                                                        message={(
                                                                            <>
                                                                                <span style={{ lineHeight: "1.5", fontSize: "12px", fontWeight: "bold" }}>
                                                                                    *User's password will expire in {passwordExpiry} days. Would you like to change it now?
                                                                                    <a
                                                                                        href="#"
                                                                                        className={classes.linkClass}
                                                                                        onClick={handleAlertMessage}>CLICK TO RESET PASSWORD</a>
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </>
                                                        ),
                                                    },
                                                ]}
                                                titleClassName={null}
                                                valueClassName={null}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item={true} xs={12} style={{ marginTop: 24 }}>
                                        <Paper>
                                            <Card className={classes.root}>
                                                <CardHeader title="Organization & Permissions" />
                                                <CardContent classes={{ root: classes.cardContent }}>
                                                    <Grid container={true} spacing={3} >
                                                        <Grid item={true} xs={12} md={4} lg={4}>
                                                            {[regionsAndSites] && (
                                                                <Autocomplete
                                                                    id="regions"
                                                                    options={[regionsAndSites]}
                                                                    getOptionLabel={((option: INewUserProps["regionsAndSites"]) =>
                                                                        option.name ? option.name : selectedActiveDistrict.name)}
                                                                    getOptionSelected={((option, value) =>
                                                                        option?.regionId === value?.regionId)
                                                                    }
                                                                    value={newUserDetails.regions}
                                                                    disabled={true}
                                                                    style={{
                                                                        marginRight: "10px",
                                                                        marginTop: "10px",
                                                                        width: "100%",
                                                                        display: "inline-flex",
                                                                    }}
                                                                    renderInput={(params: any) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label={<RequiredLabel inputLabel="District(s)" />}
                                                                            variant="outlined"
                                                                            error={regionsError}
                                                                            helperText={regionsMessage}
                                                                            onBlur={handleRegionsBlur}
                                                                        />
                                                                    )}
                                                                    onChange={(event: any, newValue: any) => {
                                                                        console.warn(event);
                                                                        userAction === "Edit" ? handleEditRegions(newValue) : handleRegions(newValue);
                                                                    }}
                                                                />
                                                            )}
                                                        </Grid>
                                                        {regionsAndSites && regionsAndSites.Sites
                                                            && areaBool
                                                            && (
                                                                <Grid item={true} xs={12} md={4} lg={4}>
                                                                    {regionsAndSites.Areas && (
                                                                        <Autocomplete
                                                                            id="areas"
                                                                            options={regionsAndSites.Areas}
                                                                            getOptionLabel={(area: any) =>
                                                                                area.name ? area.name : ""
                                                                            }
                                                                            getOptionSelected={(option, value) =>
                                                                                option?.areaId === value?.areaId
                                                                            }
                                                                            disableClearable={userAction === "Edit" && newUserDetails.areas[0] ? false : close}
                                                                            value={userAction === "Edit" ? newArea : newUserDetails.areas}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                                marginTop: "10px",
                                                                                width: "100%",
                                                                                display: "inline-flex",
                                                                            }}
                                                                            renderInput={(params: any) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label={"Area(s)"}
                                                                                    variant="outlined"
                                                                                    error={areasError}
                                                                                    helperText={areasMessage}
                                                                                    onBlur={userAction === "Edit" ? handleEditAreasBlur : handleAreasBlur}
                                                                                />
                                                                            )}
                                                                            onChange={(event: any, newValue: any) => {
                                                                                console.warn(event);
                                                                                userAction === "Edit" ? handleEditAreas(newValue) : handleAreas(newValue);
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            )}
                                                        {(siteBool || areaSiteBool) && distSiteNameList
                                                            && regionsAndSites.Sites.length > 0
                                                            && (
                                                                <>
                                                                    <Grid item={true} xs={12} md={4} lg={4}>
                                                                        {siteBool && (
                                                                            <Autocomplete
                                                                                multiple={true}
                                                                                id="sites"
                                                                                options={distSiteNameList}
                                                                                getOptionLabel={(region: any) =>
                                                                                    region.name ? region.name : ""
                                                                                }
                                                                                getOptionSelected={(option, value) =>
                                                                                    option?.id === value?.id
                                                                                }
                                                                                value={newSites}
                                                                                className={classes.select}
                                                                                style={{
                                                                                    marginRight: "10px",
                                                                                    marginTop: "10px",
                                                                                    width: "100%",
                                                                                    display: "inline-flex",
                                                                                }}
                                                                                disableCloseOnSelect={true}
                                                                                renderOption={(option, { selected }) => (
                                                                                    <React.Fragment>
                                                                                        <Checkbox
                                                                                            icon={icon}
                                                                                            color="primary"
                                                                                            checkedIcon={checkedIcon}
                                                                                            style={{ marginRight: 8 }}
                                                                                            checked={selected}
                                                                                        />
                                                                                        {option.name}
                                                                                    </React.Fragment>
                                                                                )}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        label={"Site(s)"}
                                                                                        variant="outlined"
                                                                                        error={sitesError}
                                                                                        helperText={sitesMessage}
                                                                                        onBlur={userAction === "Edit" ? handleEditSitesBlur : handleSitesBlur}
                                                                                        rowsMax={1}
                                                                                        rows={1}
                                                                                        size="medium"
                                                                                    />
                                                                                )}
                                                                                onChange={(event: any, newValue: any) => {
                                                                                    console.warn(event);
                                                                                    handleSiteChange(newValue);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item={true} xs={12} md={4} lg={4}>
                                                                        {areaSiteBool && (
                                                                            <Autocomplete
                                                                                multiple={true}
                                                                                id="sites"
                                                                                options={userAction === "Edit"
                                                                                    ? (_.flatten([regionsAndSites.Areas.find((a: any) =>
                                                                                        a.name === newUserDetails.areas[0])].map((site: any) => site.Sites)))
                                                                                    : (distSiteNameList && _.flatten(distSiteNameList.map((site: any) => site.Sites)))}
                                                                                getOptionLabel={(site: any) =>
                                                                                    site.name ? site.name : ""
                                                                                }
                                                                                getOptionSelected={(option, value) =>
                                                                                    option?.id === value?.id
                                                                                }
                                                                                value={newSites}
                                                                                className={classes.select}
                                                                                style={{
                                                                                    marginRight: "10px",
                                                                                    marginTop: "10px",
                                                                                    width: "100%",
                                                                                    display: "inline-flex",
                                                                                }}
                                                                                disableCloseOnSelect={true}
                                                                                renderOption={(option, { selected }) => (
                                                                                    <React.Fragment>
                                                                                        <Checkbox
                                                                                            icon={icon}
                                                                                            color="primary"
                                                                                            checkedIcon={checkedIcon}
                                                                                            style={{ marginRight: 8 }}
                                                                                            checked={selected}
                                                                                        />
                                                                                        {option.name}
                                                                                    </React.Fragment>
                                                                                )}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        rowsMax={1}
                                                                                        rows={1}
                                                                                        size="medium"
                                                                                        label={"Site(s)"}
                                                                                        variant="outlined"
                                                                                        error={sitesError}
                                                                                        helperText={sitesMessage}
                                                                                        onBlur={userAction === "Edit" ? handleEditSitesBlur : handleSitesBlur}
                                                                                    />
                                                                                )}
                                                                                onChange={(event: any, newValue: any) => {
                                                                                    console.warn(event);
                                                                                    handleSiteChange(newValue);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Grid>
                                                                </>
                                                            )}
                                                        <Grid item={true} xs={12} md={12} lg={12}>
                                                            <Autocomplete
                                                                multiple={true}
                                                                id="roles"
                                                                options={rolesList}
                                                                value={newRoles}
                                                                className={classes.select}
                                                                disableCloseOnSelect={true}
                                                                getOptionLabel={(option: any) =>
                                                                    option.roleName ? option.roleName : ""
                                                                }
                                                                getOptionSelected={(option, value) =>
                                                                    option?.roleGuid === value?.roleGuid
                                                                }
                                                                renderOption={(option, { selected }) => (
                                                                    <React.Fragment>
                                                                        <Checkbox
                                                                            icon={icon}
                                                                            color="primary"
                                                                            checkedIcon={checkedIcon}
                                                                            style={{ marginRight: 8 }}
                                                                            checked={selected}
                                                                        />
                                                                        {option.roleName}
                                                                    </React.Fragment>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={<RequiredLabel inputLabel="Roles" />}
                                                                        variant="outlined"
                                                                        error={rolesError}
                                                                        helperText={rolesMessage}
                                                                        onBlur={handleRoleBlur}
                                                                        rowsMax={1}
                                                                        rows={1}
                                                                        size="medium"
                                                                    />
                                                                )}
                                                                onChange={(event: any, newValue: any) => {
                                                                    console.warn(event);
                                                                    handleRoleChange(newValue);
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Paper>
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        container={true}
                                        style={{ marginTop: 20 }}
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center"
                                        spacing={3}
                                    >
                                        <Grid item={true}>
                                            <Button
                                                color="default"
                                                id="cancelButton"
                                                label="Cancel"
                                                name="Button"
                                                onClick={cancelUserInfo}
                                                type="button"
                                                variant="default"
                                            />
                                        </Grid>
                                        <Grid item={true}>
                                            <Button
                                                color="primary"
                                                id="successButton"
                                                label="Save"
                                                name="Button"
                                                onClick={saveUser}
                                                type="button"
                                                variant="default"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {cancelUserConfirmation && (
                            <NotificationWithDialog
                                message="Are you sure you want to cancel?"
                                open={true}
                                primaryAction={{
                                    callback: onClose,
                                    title: "Yes",
                                }}
                                secondaryAction={{
                                    callback: () => setCancelUserConfirmation(false),
                                    title: "No",
                                }}
                                title=""
                            />
                        )}
                        {alertMessage &&
                            (
                                <NotificationWithDialog
                                    message="Resetting the password will invalidate the user's ability to log in until they set a new password. Proceed?"
                                    open={alertMessage}
                                    primaryAction={{
                                        callback: handlePasswordClick,
                                        title: "Confirm",
                                    }}
                                    secondaryAction={{
                                        callback: () => setUserAlertMessage(false),
                                        title: "Cancel",
                                    }}
                                    title=""
                                />
                            )
                        }
                        {removePictureCDonfirmation && (
                            <NotificationWithDialog
                                message="Are you sure you want to remove the picture?"
                                open={true}
                                primaryAction={{
                                    callback: onFileRemoveTrigger,
                                    title: "Yes",
                                }}
                                secondaryAction={{
                                    callback: () => setRemovePictureConfirmation(false),
                                    title: "No",
                                }}
                                title=""
                            />
                        )}
                    </>
                )}
            </PageLayout>

        </>
    );
};

export default AddUser;
