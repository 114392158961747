import { createAction } from "@reduxjs/toolkit";

export const getFavoriteCustomReportsInit = createAction("GET_FAVORITE_CUSTOM_REPORTS_INIT");

export const getFavoriteCustomReportsSuccess = createAction('GET_FAVORITE_CUSTOM_REPORTS_SUCCESS',
    (payload) => ({ payload })
);
export const getFavoriteCustomReportsFail = createAction('GET_FAVORITE_CUSTOM_REPORTS_FAIL');

//get report
export const getCustomReportByIdInit = createAction("GET_CUSTOM_REPORT_INIT", (data) => ({
    payload: data
}));
export const getCustomReportByIdSuccess = createAction("GET_CUSTOM_REPORT_SUCCESS", (data) => ({
    payload: data
}));
export const getCustomReportByIdFailed = createAction("GET_CUSTOM_REPORT_FAILED", (data) => ({
    payload: data
}));

export const deleteCustomReportFavoriteInit = createAction('DELETE_CUSTOM_REPORT_FAVORITE_INIT', (data: {
    customReportFavoriteId: number;
    successCallback: () => void;
    errorCallback: () => void
}) => ({
    payload: data,
}));
export const deleteCustomReportFavoriteSuccess = createAction('DELETE_CUSTOM_REPORT_FAVORITE_SUCCESS', (data) => ({
    payload: data
}));
export const deleteCustomReportFavoriteFailed = createAction('DELETE_CUSTOM_REPORT_FAVORITE_FAILED', (data) => ({
    payload: data
}));
export const uiShowMainWindowInit = createAction("TOGGLE_SHOW_MAIL_WINDOW", (payload) => ({ payload }));
