import { IAppState } from "../reducers/index";
import { AuthStatus } from "../reducers/user.reducer";
import {
    IPasswordPolicies, IRolesList,
    IUserDetailsKeyclock
} from "./profile.entity";

export enum UsersStatus {
    Initial = "Initial",
    Loading = "InProgress",
    SuccessWithUsers = "SuccessWithUsers",
    Failed = "Failed",
    FailedWithInvalidPassword = "InvalidPassword",
    FailedWithNetworkError = "NetworkError",
    FailedWithFormatError = "ResponseFormatError",
    FailedWithUnknownError = "UnknownError",
    Success = "Success",
}

export enum UserStatus {
    Initial = "Initial",
    Loading = "InProgress",
    SuccessWithUsers = "SuccessWithUsers",
    Failed = "Failed",
    FailedWithInvalidPassword = "InvalidPassword",
    FailedWithNetworkError = "NetworkError",
    FailedWithFormatError = "ResponseFormatError",
    FailedWithUnknownError = "UnknownError",
    Success = "Success",
}

export interface IUserBasicData {
    firstName: string;
    mi: string;
    lastName: string;
    email: any;
    cellPhone: any;
    workPhone: any;
    userId: number;
    userStatusDescription: string;
    authenticationMode: string;
    regions: any[];
    areas: any[];
    sites: any[];
    roles: any[];
    organizationLevel: any;
    file: string;
    userStatusId: number;
    userGuid: string;
    lastPasswordChangeDate: string;
    attributes: any;
    userImageUrl: string;
    isBroadcastUser: string;
    addUserResponse: {};
}

export enum userType {
    addRole = "Add",
    editRole = "Edit",
}
export interface IUser {
    list?: any;
    imageUrl: string | null;
    image: string | null;
    userId: number;
    userName: string;
    firstName: string;
    lastName: string;
    mi?: string;
    email?: any;
    workPhone?: any;
    cellPhone?: any;
    userStatusId: number;
    lastLoginDate: Date;
    themeName: string;
    OrganizationalLevel?: any;
    roles?: string;
    failedLoginAttempts: number;
    creationDate: Date;
    accessLevelId: number;
    windowsUserDomain: string;
    windowsUserName: string;
    regionId: number;
    updateDate: Date;
    updateBy: number;
    authenticationMode: string;
    languagePreference: string;
    password: string;
    lastPasswordChange: Date;
    lastFailedLoginDate: Date;
    createdUserId: number;
    userFetchStatus: UserStatus;
    userEditFail: any;
    defaultRealmId: number;
    defaultRegionId: number;
    primeroUserId: string;
    theme: string;
    userStatusDescription: string;
    regionName: string;
    accessLevelDescription: string;
    totalRecords: number;
    imageFile: string | null;
    imageData: string | null;
    IsPIIEnabled?: boolean;
    navigateOption: string;
}


export interface IGroups {
    attributes: string;
    groupGuid: string;
    name: string;
}

export interface IFilteredSites {
    siteFilterBool: boolean;
    roleFilterBool: boolean;
}


export interface IAttributes {
    userStatus: any[];
    accessLevelId: any[];
}

export interface IRoleFilterData {
    email: string;
    firstName: string;
    lastName: string;
    userstatus: string;
    organizationLevel: string;
}

export interface ISites {
    id: string;
    name: string;
    parentRegionId: string;
    siteCode: string;
    regionId: string;
    siteId: string;
    primeroSchoolIdid: string;
    siteTypeCode: string;
    siteTypeDescription: string;
    organizationLevel: string;
}

export interface IAreas {
    id: string;
    name: string;
    parentRegionId: string;
    organizationLevel: string;
    regionId: string;
    sites: ISites[];
}
export interface IOnCloseProps {
    viewUser: boolean;
    selectedUser?: IUser;
}


export interface IOrganizationList {
    accessLevelId: number;
    accessLevelDescription: string;
    accessLevelNumber: number;
    hasAccessLevel: number;
}

export interface IValidEmail {
    isEmailValid: string;
}

export interface IUserRoles {
    roleGuid: string;
    roleName: string;
    roleType: string;
}

export interface IRegionsSites {
    id: string;
    name: string;
    parentRegionId: string;
    organizationLevel: string;
    regionId: string;
    Areas: IAreas[];
    Sites: ISites[];
}

export interface ISiteFilterData {
    email: string;
    firstName: string;
    lastName: string;
    attributes: IAttributes[];
    roles: IRolesList[];
    groups: IGroups[];
}
export interface IOnCloseFilterSiteProps {
    selectedSiteFilter: ISiteFilterData;
}
export interface ICloseDrawer {
    closeBool: boolean;
    roles: IUserRoles[];
    sites: ISites[];
}
export interface IUsersState {
    list: IUser[];
    themeName: string;
    usersFetchStatus: string;
    userEditStatus: string;
    isLoading: boolean;
    selectedUserData: IUser;
    regionsAndSites: IRegionsSites;
    rolesList: IRolesList[];
    organizationList: IOrganizationList[];
    siteFilterData: ISiteFilterData[];
    roleFilterData: IRoleFilterData[];
    validEmail: string;
    userDetailsKeyclock: IUserDetailsKeyclock;
    siteFilterLoading: boolean;
    roleFilterLoading: boolean;
    siteInitLoading: boolean;
    passwordPolicies: IPasswordPolicies[];
    isUserLoading: boolean;
    removePictureStatus: string;
    userRemovePictureStatus: string;
    addUserResponse: any;
}

export function GetUsers({ users }: IAppState) {
    return users.list;
}

export function getUsersLoading({ users }: IAppState) {
    return users.isLoading;
}

export function getUserFetchStatus({ users }: IAppState) {
    return users.usersFetchStatus;
}

export function getUserEditStatus({ users }: IAppState) {
    return users.userEditStatus;
}

export function getUserData({ users }: IAppState) {
    return users.selectedUserData;
}

export function getRegoinsAndSites({ users }: IAppState) {
    return users.regionsAndSites;
}

export function getOrganizationList({ users }: IAppState) {
    return users.organizationList;
}

export function getSiteFilterData({ users }: IAppState) {
    return users.siteFilterData;
}

export function getRoleFilterData({ users }: IAppState) {
    return users.roleFilterData;
}

export function getValidEmail({ users }: IAppState) {
    return users.validEmail;
}

export function getSiteFilterLoading({ users }: IAppState) {
    return users.siteFilterLoading;
}

export function getRoleFilterLoading({ users }: IAppState) {
    return users.roleFilterLoading;
}

export function getSiteInitLoading({ users }: IAppState) {
    return users.siteInitLoading;
}

export function getRemovePictureStatus({ users }: IAppState) {
    return users.removePictureStatus;
}

export function getUserRemovePictureStatus({ users }: IAppState) {
    return users.userRemovePictureStatus;
}

export function getAddUserResponse({ users }: IAppState) {
    return users.addUserResponse;
}
export class UserEntity {
    public static isUserAuthInProgress(state: IAppState) {
        return state.user.userAuthStatus[0] === AuthStatus.Loading;
    }

    public static isUserAuthSuccess(state: IAppState) {
        return state.user.userAuthStatus[0] === AuthStatus.SuccessWithToken;
    }

    public static isUserAuthFailedWithUnknownError(state: IAppState) {
        return (
            state.user.userAuthStatus[0] === AuthStatus.FailedWithUnknownError
        );
    }

    public static isUserAuthFailedWithInvalidUser(state: IAppState) {
        return (
            state.user.userAuthStatus[0] === AuthStatus.FailedWithInvalidUser
        );
    }

    public static isUserAuthFailedWithFormatError(state: IAppState) {
        return (
            state.user.userAuthStatus[0] === AuthStatus.FailedWithFormatError
        );
    }

    public static getUserProfile(state: IAppState) {
        return state.user.userProfile;
    }

    public static getActiveDistrict(state: IAppState) {
        return state.user.activeDistrict;
    }

    public static getSideNavStatus(state: IAppState) {
        return state.user.sideNavOpenStatus;
    }
}
