import { Box, Grid, makeStyles } from "@material-ui/core";
import { Copyright, DistrictSelection, ITheme, LoginView, useAuth } from "@primeroedge/ui-components";
import React, { FC, useEffect } from "react";
import Version from "./version";
import { APIM_BASE_URL, APIM_SUBSCRIPTION_KEY } from "control/transport/clients/api-constants";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: ITheme) => ({
    loginView: {
        marginBottom: theme.spacing(8),
    },
}));

export const LoginPage: FC = () => {
    const { isAuthenticated,  } = useAuth();
    const classes = useStyles();
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const isDistrictSelectionTrue = queryParams.get('districtSelection') === 'true';
    const validationCollectionFieldForOnboarding = localStorage.getItem("validationCollectionFieldForOnboarding");

    const displayDistrictSelection = () => {
        return (
            <>
            <DistrictSelection
                apimUrl={APIM_BASE_URL as string}
                subscriptionKey={APIM_SUBSCRIPTION_KEY as string}
            />
             <Version />
            <Copyright />
        </>
        );
    };
    
    useEffect(() => {
        if (isAuthenticated && !isDistrictSelectionTrue && 
            validationCollectionFieldForOnboarding !==
            'RedirectToProfilePage') {
                history.push("/");
        }
    }, [isAuthenticated, isDistrictSelectionTrue]);

    if (!isAuthenticated) {
        return (
            <Box
                p={2}
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                style={{ backgroundColor: "#F8F7FD" }}
            >
                <Grid
                    container={true}
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                >
                    <Grid item={true} xs={12} sm={6}>
                        <LoginView className={classes.loginView} />
                    </Grid>
                </Grid>
                <Version />
                <Copyright />
            </Box>
        );
    }

    // Render the DistrictSelection component when isAuthenticated is true
    return (
        <Box p={2} width="100%" height="100%" display="flex" flexDirection="column">
            
                {displayDistrictSelection()}
        </Box>
    );
};

export default LoginPage;
