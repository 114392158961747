import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVerticalIcon, NotificationWithDialog } from "@primeroedge/ui-components";
import React, { MouseEvent, useState } from "react";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteWorkspaceBroadcast, selectedBroadcastMessage } from "../../../../../control/actions";
import ListItem from "../ListItem";

const BroadcastItem = ({ broadcast, notificationCb }: any) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);

    const [broadcastAnchorEl, setBroadcastAnchorEl] = useState<HTMLElement | null>(null);

    const handleBroadcastClick = (ev: MouseEvent<HTMLButtonElement>) => {
        setBroadcastAnchorEl(ev.currentTarget);
    };

    const handleBroadcastClose = () => {
        setBroadcastAnchorEl(null);
    };

    const handleMailClose = () => {
        setBroadcastAnchorEl(null);
    };

    const handleMailDelete = () => {
        handleMailClose();
        dispatch(deleteWorkspaceBroadcast(broadcast.messageId));
        notificationCb(true);
    };

    const openSelectedBroadcast = () => {
        handleBroadcastClose();
        dispatch(selectedBroadcastMessage(broadcast));
        history.push("/workspace/broadcast");
    };

    if (!broadcast) {
        return null;
    }

    return (
        <>
            <ListItem
                title={`${broadcast.senderFirstName || ""} ${broadcast.senderLastName || ""}`}
                subTitle={broadcast.content?.replace(/<[^>]+>/g, "")}
                onClick={() => openSelectedBroadcast()}
                info={(
                    <span className={"font10"}>
                        <Moment format="MM/DD/YYYY">
                            {broadcast.createdOn}
                        </Moment>
                    </span>
                )}
                action={(
                    <>
                        <IconButton
                            aria-controls="broadcastMenu"
                            aria-haspopup="true"
                            onClick={handleBroadcastClick}
                            size="small"
                        >
                            <MoreVerticalIcon />
                        </IconButton>
                        <Menu
                            id="broadcastMenu"
                            anchorEl={broadcastAnchorEl}
                            keepMounted={true}
                            open={Boolean(broadcastAnchorEl)}
                            onClose={handleBroadcastClose}
                        >
                            <MenuItem
                                onClick={() => {
                                    setshowDeleteConfirm(true);
                                    setBroadcastAnchorEl(null);
                                }}>
                                Delete
                            </MenuItem>
                        </Menu>
                    </>
                )}
            />
            <Divider />
            <NotificationWithDialog
                message="Are you sure you want to delete this message?"
                open={showDeleteConfirm}
                primaryAction={{
                    callback: () => {
                        handleMailDelete();
                    },
                    title: "Confirm",
                }}
                secondaryAction={{
                    callback: () => {
                        setshowDeleteConfirm(false);
                    },
                    title: "Cancel",
                }}
                title="Confirmation"
            />
        </>
    );
};

export default BroadcastItem;
