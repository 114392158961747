import { sendReportEmailError, sendReportEmailInit, sendReportEmailSuccess } from "control/actions/customReportMail.actions";
import CustomReportTransport from "control/transport/customReports.transport";
import { isNull } from "lodash";
import { ISendEmailPayload } from "model/entity";
import { put, takeLatest } from "redux-saga/effects";

function* customReportSendEmailSaga({
    payload
}: {
    payload: ISendEmailPayload
}) {
    try {
        const response: any = yield CustomReportTransport.sendReportsEmail(payload);
        if (!isNull(response)) {
            yield put(sendReportEmailSuccess())
        } else {
            throw 'Error';
        }
    } catch (e) {
        yield put(sendReportEmailError());
    }
}

export default function* watchCustomReportsMailSaga() {
    yield takeLatest(sendReportEmailInit, customReportSendEmailSaga)

}