export const maskedPhoneNumber = (value: string) => {
    const phoneArr = value
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const actualPhoneNumberLength =
        phoneArr && phoneArr[0] ? phoneArr[0].length : 0;
    let part1 = "";
    let part2 = "";
    let part3 = "";
    if (actualPhoneNumberLength > 0 && actualPhoneNumberLength <= 3) {
        part1 = `${phoneArr && phoneArr[1] ? phoneArr[1] : ""}`;
    } else if (actualPhoneNumberLength > 3 && actualPhoneNumberLength <= 6) {
        part1 = `${phoneArr && phoneArr[1] ? phoneArr[1] : ""}`;
        part2 = `-${phoneArr && phoneArr[2] ? phoneArr[2] : ""}`;
    } else if (actualPhoneNumberLength > 6) {
        part1 = `${phoneArr && phoneArr[1] ? phoneArr[1] : ""}`;
        part2 = `-${phoneArr && phoneArr[2] ? phoneArr[2] : ""}`;
        part3 = `-${phoneArr && phoneArr[3] ? phoneArr[3] : ""}`;
    }

    return part1 + part2 + part3;
};
