import { put, select, takeLatest } from "redux-saga/effects";
import { ResponseStatus } from "../../model/entity";
import { IAppState } from "../../model/reducers";
import {
    DeleteBroadcast,
    FetchBroadcastMessages, setActiveMessageTabValue, SetBroadcastGridState,
    SetBroadcastMessages,
    SetBroadcastStatus,
    SetBroadcastTotalMessageCount, SetBroadcastTotalPages,
} from "../actions";
import BroadcastTransport from "../transport/broadcast.transport";

function* fetchBroadcastMessagesSaga (action: any) {
    try {
        yield put(SetBroadcastStatus(ResponseStatus.Loading));
        yield put(SetBroadcastGridState(action.payload));
        const data = yield BroadcastTransport.fetchBroadcastMessages(action.payload);
        yield put(SetBroadcastMessages(data.messages));
        yield put(SetBroadcastTotalMessageCount(data.totalMessageCount));
        yield put(SetBroadcastTotalPages(data.totalPages));
        yield put(SetBroadcastStatus(ResponseStatus.Success));
        yield put(setActiveMessageTabValue(1));
    } catch (error) {
        console.error(error);
        yield put(SetBroadcastStatus(ResponseStatus.Error));
    }
}

function* deleteBroadcastSaga (action: any) {
    try {
        yield put(SetBroadcastStatus(ResponseStatus.Loading));
        const actionPayload = [action.payload].flat();
        yield BroadcastTransport.deleteBroadcast(actionPayload);
        const gridState = yield select(
          (state: IAppState) => state.broadcast.gridState,
        );
        yield put(FetchBroadcastMessages(gridState));
        yield put(setActiveMessageTabValue(1));
    } catch (error) {
        console.error(error);
        yield put(SetBroadcastStatus(ResponseStatus.Error));
    }
}

export default function* BroadcastSaga () {
    try {
        yield takeLatest(FetchBroadcastMessages, fetchBroadcastMessagesSaga);
        yield takeLatest(DeleteBroadcast, deleteBroadcastSaga);
    } catch (error) {
        yield null;
    }
}

