// @ts-nocheck
import { Grid, makeStyles } from "@material-ui/core";
import { Paper, Typography } from "@material-ui/core";
import { AppLoader, Notification } from "@primeroedge/ui-components";
import * as profile from "model/entity/profile.entity";
import { getIsUserLoading } from "model/entity/profile.entity";
import * as userEntity from "model/entity/user.entity";
import { UserEntity } from "model/entity/user.entity";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Activities from "../activities-page";
import AddUser from "./addUser";
import ViewUser from "./viewUser";

interface IUserCProps {
    addOrEditUser: string;
    userEmail: string;
}

const useStyles = makeStyles(() => ({
    loaderContainer: {
        height: "60vh",
        padding: "10px",
    },
}));

const AddUserPage: React.FC<IUserCProps> = ({ addOrEditUser, userEmail }) => {
    const styles = useStyles({});
    const [formLayout, setFormLayout] = useState(false);
    const [formTitle, setFormTitle] = useState<string>("");
    const user = useSelector(UserEntity.getUserProfile);
    const usersList = useSelector(userEntity.getSiteFilterData);
    const [selectedUser, setSelectedUser] = useState<userEntity.IUser>("");
    const isUserLoading = useSelector(getIsUserLoading);
    useEffect(() => {
        if (usersList && usersList.length > 0 && selectedUser === "" && userEmail !== "") {
            const targetUser = usersList.find((user) => user.email === userEmail);
            setSelectedUser(targetUser);
        }
    }, [usersList, userEmail])
    const regionsAndSites = useSelector(userEntity.getRegoinsAndSites);
    const rolesList = useSelector(profile.getRolesList);
    const userAddStatus = useSelector(userEntity.getAddUserResponse);
    const organizationData = useSelector(userEntity.getOrganizationList);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [userBasicData, setUserBasicData] = useState<userEntity.IUserBasicData>(
        {
            firstName: "",
            mi: "",
            lastName: "",
            email: "",
            cellPhone: "",
            workPhone: "",
            userId: Number(user?.UserId),
            userStatusDescription: "",
            authenticationMode: "PRIMERO",
            regions: [],
            areas: [],
            sites: [],
            roles: [],
            organizationLevel: "",
            file: "",
            userStatusId: 1,
            userGuid: "",
            lastPasswordChangeDate: "",
            attributes: null,
            userImageUrl: "",
            isBroadcastUser: "",
        },
    );
    const [userAction, setUserAction] = useState<string>("");
    const [onRoute, setOnRoute] = useState<boolean>(false);
    const cancelHandler = () => {
        setOnRoute(true);
        setUserAction("");
        setFormLayout(false);
    };

    useEffect(() => {
        if (userAddStatus?.StatusCode === 200) {
            setAlertStatus("success");
            setSuccessAlertMessage("User has been added successfully.");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSuccessAlertMessage("");
                setAlertStatus("default");
            }, 5000);
        }
    }, [userAddStatus])

    const [successAlertMessage, setSuccessAlertMessage] = React.useState<string>("");
    const [showAlert, setShowAlert] = React.useState<boolean>(false);
    const [alertStatus, setAlertStatus] = React.useState<
        | "success"
        | "error"
        | "default"
        | "primary"
        | "secondary"
        | "warning"
        | "info"
    >("default");
    const handleAlertClose = () => {
        setShowAlert(false);
    };

    const addNewUser = () => {
        setFormLayout(true);
        setUserAction(userEntity.userType.addRole);
        setAlertMessage("");
        setFormTitle("Add User");
        setUserBasicData({
            firstName: "",
            mi: "",
            lastName: "",
            email: "",
            cellPhone: "",
            workPhone: "",
            userId: Number(user?.UserId),
            userStatusDescription: "",
            authenticationMode: "PRIMERO",
            regions: [],
            areas: [],
            sites: [],
            roles: [],
            organizationLevel: "",
            file: "",
            userStatusId: 1,
            userGuid: "",
            lastPasswordChangeDate: "",
            attributes: null,
            userImageUrl: "",
            isBroadcastUser: "",
        });
    };
    
    useEffect(() => {
        if (addOrEditUser === "add") {
            addNewUser();
        }
    }, [addOrEditUser])

    const [passwordExpiry, setPasswordExpiry] = useState<number>(0);
    const editUser = (
        userData: any,
        regions: any,
        areas: any,
        sites: any,
        accessLevelDescription: any,
        roles: any,
        userGuid: string,
        userStatus: string,
        firstName: string,
        lastName: string,
        email: string,
        cellPhone: string,
        workPhone: string,
        primeroUserId: string,
        middleName: string,
        image: string,
        lastPasswordChange: string,
        userImageUrl: string,
        attributes: any,
        minPasswordExpiry: number,
    ) => {
        setPasswordExpiry(minPasswordExpiry);
        setAlertMessage("");
        setFormLayout(true);
        setFormTitle("Edit User Information");
        setUserAction(userEntity.userType.editRole);
        setUserBasicData({
            firstName,
            mi: middleName,
            lastName,
            email,
            cellPhone,
            workPhone,
            userId: parseInt(primeroUserId),
            userStatusDescription: userStatus,
            authenticationMode: "authenticationMode",
            regions: [regions],
            areas: [areas],
            sites: [sites],
            roles: [roles],
            organizationLevel: accessLevelDescription,
            file: image,
            userStatusId: 0,
            userGuid,
            lastPasswordChangeDate: lastPasswordChange,
            attributes,
            userImageUrl,
            isBroadcastUser: "",
        });
    };

    return (
        <>
            {isUserLoading ? (
                <Grid container={true} spacing={2}>
                    <Grid xs={12} item={true} classes={{ root: styles.loaderContainer }}>
                        {<AppLoader />}
                    </Grid>
                </Grid>
            ) : (
                <>
                    {showAlert ? (
                        <Notification
                            color={alertStatus}
                            message={successAlertMessage}
                            onClose={handleAlertClose}
                            open={showAlert}
                            variant="filled"
                        />
                    ) : ""}
                    {formLayout && regionsAndSites && rolesList.length === 0 && (
                        <Paper>
                            <Typography color="textPrimary" variant="h3">
                                Unable to retrieve data. Please try again later.
                            </Typography>
                        </Paper>
                    )}
                    {formLayout && !onRoute &&
                        (
                            <>
                                <AddUser
                                    formLayout={formLayout}
                                    formTitle={formTitle}
                                    regionsAndSites={regionsAndSites}
                                    rolesList={rolesList}
                                    organizationData={organizationData}
                                    alertMessage={alertMessage}
                                    setAlertMessage={setAlertMessage}
                                    userBasicData={userBasicData}
                                    passwordExpiry={passwordExpiry}
                                    userAction={userAction}
                                    onClose={cancelHandler}
                                />
                            </>
                        )
                    }
                    {!onRoute && !formLayout && selectedUser !== "" && 
                        (
                            <>
                                <ViewUser
                                    selectedUser={selectedUser}
                                    editUser={(
                                        value: any,
                                        regions: any,
                                        areas: any,
                                        sites: any,
                                        accessLevelDescription: any,
                                        roles: any,
                                        userGuid: string,
                                        userStatus: string,
                                        firstName: string,
                                        lastName: string,
                                        email: string,
                                        cellPhone: string,
                                        workPhone: string,
                                        primeroUserId: string,
                                        middleName: string,
                                        image: string,
                                        lastPasswordChange: string,
                                        userImageUrl: string,
                                        attributes: any,
                                        minPasswordExpiry: number,
                                    ) =>
                                        editUser(
                                            value,
                                            regions,
                                            areas,
                                            sites,
                                            accessLevelDescription,
                                            roles,
                                            userGuid,
                                            userStatus,
                                            firstName,
                                            lastName,
                                            email,
                                            cellPhone,
                                            workPhone,
                                            primeroUserId,
                                            middleName,
                                            image,
                                            lastPasswordChange,
                                            userImageUrl,
                                            attributes,
                                            minPasswordExpiry,
                                        )
                                    }
                                    organizationData={organizationData}
                                    onClose={() => setOnRoute(true)}
                                />
                            </>
                        )
                    }
                    {onRoute && <Activities />}
                </>
            )}
        </>
    );
};

export default AddUserPage;
