import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: { width: "100%", height: "0%", display: "flex", justifyContent: "center" },
    identifier: { width: 0, height: 0 },
}));

export interface ITourIdentifierElementProps {
    uniqueElementId: string;
}

export default function TourIdentifierElement({
    uniqueElementId,
}: ITourIdentifierElementProps) {
    const {
        root,
        identifier,
    } = useStyles();
    return (
        <div className={root}>
            <div id={uniqueElementId} className={identifier} />
        </div>
    );
}
