import { connectRouter } from "connected-react-router";
import { History } from "history";
import { IGradeGroupState } from "model/entity/gradeGroup.entity";
import { IGlobalMessage, IMessageResponse } from "model/entity/message.entity";
import { IUsersState } from "model/entity/user.entity";
import { combineReducers, Reducer } from "redux";
import {
    IBroadcast,
    INotification,
    ITaskReducer,
    IUserProfileReducer, IWelcomeState, IWorkspaceDetails,
} from "../entity";
import { ISites } from "./../entity/sites.entity";
import { broadcastReducer } from "./broadcast.reducer";
import { gradeGroupReducer } from "./gradegroup.reducer";
import { mailReducer } from "./mail.reducer";
import { GlobalMessageReducer, messagesReducer } from "./message.reducer";
import { notificationReducer } from "./notification.reducer";
import { onboardingReducer } from "./onboarding.reducer";
import { IPOSConfigState, posConfigReducer } from "./posconfiguration.reducer";
import { profileReducer } from "./profile.reducer";
import { SitesReducer } from "./sites.reducer";
import { ISiteTypeState, sitetypeReducer } from "./sitetype.reducer";
import { tasksReducer } from "./tasks.reducer";
import { IUserState, userReducer, usersReducer } from "./user.reducer";
import { workSpaceDetailsReducer } from "./workspace-details.reducer";
import { ICustomReportsData, ICustomReportsMail } from "model/entity/CustomReports.entity";
import { customReportsReducer } from "./customReports.reducer";
import { customReportMailReducer } from "./customReportsMail.reducer";
import { IMyPages } from "model/entity/myPages.entity";
import { myPagesReducer } from "./myPages.reducer";
export interface IAppState {
    router: Reducer;
    notification: INotification;
    workspace: IWorkspaceDetails;
    mail: any;
    tasks: ITaskReducer;
    profile: IUserProfileReducer;
    messages: IMessageResponse;
    broadcast: IBroadcast;
    welcome: IWelcomeState;
    user: IUserState;
    users: IUsersState;
    sites: ISites;
    sitetype: ISiteTypeState;
    gradeGroup: IGradeGroupState;
    posConfig: IPOSConfigState;
    isGlobalMessage: IGlobalMessage;
    customReports: ICustomReportsData;
    customReportsMail: ICustomReportsMail;
    myPages:IMyPages;
}

const createRootReducer = (history: History): Reducer =>
    combineReducers({
        router: connectRouter(history),
        notification: notificationReducer,
        workspace: workSpaceDetailsReducer,
        mail: mailReducer,
        tasks: tasksReducer,
        profile: profileReducer,
        messages: messagesReducer,
        broadcast: broadcastReducer,
        welcome: onboardingReducer,
        user: userReducer,
        users: usersReducer,
        sites: SitesReducer,
        sitetype: sitetypeReducer,
        gradeGroup: gradeGroupReducer,
        posConfig: posConfigReducer,
        isGlobalMessage: GlobalMessageReducer,
        customReports: customReportsReducer,
        customReportsMail: customReportMailReducer,
        myPages: myPagesReducer,
    });

export default createRootReducer;
