import { IAppState } from "model/reducers";
import { ResponseStatus } from "./common.entity";

export interface INewTask {
  title: string;
  dueDate: string;
  status: "New" | "InProgess" | "Done";
  description: string;
}

export interface ITask extends INewTask {
  id: string;
  createdOn?: string;
  updatedOn?: string;
  isDeleted?: boolean;
  userId?: string;
  taskId?: string;
}

export interface ITaskResponse {
  totalPages: number;
  totalTaskCount: number;
  tasks: ITask[];
  updatedTasks: ITask[];
  status?: ResponseStatus;
  updated?: boolean;
}

export interface ITasksPayload {
  size: number;
  duration: string;
  currentDate: string;
}

export interface ITaskReducer extends ITaskResponse {
  newTask: INewTask | null;
  tasksDuration?: string;
}

export interface ITasksFetchQuery {
  pageSize: number;
  pageNumber: number;
  sortColumn: string;
}

export function getTasks (state: IAppState) {
  return state.tasks.tasks;
}

export function getUpdatedTasks (state: IAppState) {
  return state.tasks.updatedTasks;
}

export function getTotalTaskCount (state: IAppState) {
  return state.tasks.totalTaskCount;
}

export function isTasksLoading (state: IAppState) {
  return state.tasks.status === ResponseStatus.Loading;
}

export function isTaskUpdated (state: IAppState) {
  return state.tasks.updated;
}

export function tasksDuration (state: IAppState) {
  return state.tasks.tasksDuration;
}
