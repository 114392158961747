import { Box, CardActions, Divider, AppBar, Tabs, Tab, List, CircularProgress, TextField } from "@material-ui/core";
import StarOutlineOutlinedIcon from "@material-ui/icons/StarRate";
import React, { useState, Fragment } from "react";
import BaseCard from "../../../../../components/BaseCard/BaseCard";
import TabBadge from '../TabBadge';
import ListItem from "../ListItem";
import { ViewAllFavoritesList } from "./Favorites/ViewAllFavoritesList";
import { FavoriteActions } from "./Favorites/FavoriteActions";
import { useSelector } from "react-redux";
import { isFavoritesLoading } from "model/entity/myPages.entity";
import { useStyles } from "../../style";
import { useFavorites } from "./hooks/useFavorites";
import { useRecents } from "./hooks/useRecents";
import SwipeableViews from 'react-swipeable-views';
import TabPanel from "components/TabPanel/TabPanel";
import { ViewAllRecentsList } from "./Recents/ViewAllRecentsList";
import { Button, PageAlert, Tooltip } from "@primeroedge/ui-components";

const MyPages: React.FC = () => {
    const classes = useStyles();
    const { recentsData } = useRecents();
    const [value, setValue] = useState(0);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openRecentsDrawer, setRecentsOpenDrawer] = useState(false);
    const isLoading = useSelector(isFavoritesLoading);
    const [editingIndex, setEditingIndex] = useState(0);
    const [editedName, setEditedName] = useState("");
    const { favoritesData, favoritesCount } = useFavorites(openDrawer);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        console.error(event);
        setValue(newValue);
    };

    const handleViewAll = () => {
        setOpenDrawer(true);
    };

    const handleViewAllRecents = () => {
        setRecentsOpenDrawer(true);
    };

    const closeViewRecentsDrawer = () => {
        setRecentsOpenDrawer(false);
    }

    const closeDrawer = () => {
        setOpenDrawer(false);
        setEditingIndex(0);
    };

    const handleNameChange = (e: any) => {
        setEditedName(e.target.value);
    }

    const handleRowClick = (url: any, id: any) => {
        if (editingIndex !== id) {
            const baseUrl = window.location.origin;
            const sanitizedUrl = url?.startsWith('/') ? url : `/${url}`;
            window.open(`${baseUrl}${sanitizedUrl}`, '_blank');
        }
    };

    const handleRecentsRowClick = (url: any) => {
        const baseUrl = window.location.origin;
        const sanitizedUrl = url?.startsWith('/') ? url : `/${url}`;
        window.open(`${baseUrl}${sanitizedUrl}`, '_blank');
    };

    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                marginRight="1.5rem"
            >
                <BaseCard
                    title="MY PAGES"
                    CardIcon={<StarOutlineOutlinedIcon color="primary" />}
                >
                    <AppBar position="static" color="transparent">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            aria-label="My Tasks"
                            variant="fullWidth"
                        >
                            <Tab
                                id="favoutites"
                                label={(
                                    <TabBadge
                                        badgeContent={favoritesCount}
                                        max={99}
                                        showZero={true}
                                        color="primary"
                                    >
                                        MY PAGES
                                    </TabBadge>
                                )}
                                aria-controls="favorites-tab"
                            />
                            <Tab
                                id="recents"
                                label={(
                                    <TabBadge
                                        max={99}
                                        showZero={true}
                                        color="primary"
                                    >
                                        RECENTS
                                    </TabBadge>
                                )}
                                aria-controls="recents-tab"
                            />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        index={value}
                        className={`${classes.msgContentHeight}`}
                    >
                        <TabPanel value={value} index={0}>
                            {isLoading && (
                                <Box className={classes.tabContainer}>
                                    <CircularProgress color="secondary" />
                                </Box>
                            )}
                            {!isLoading &&
                                (favoritesData && favoritesData?.length > 0) ? (
                                <List style={{ padding: "10px" }}>
                                    {favoritesData.slice(0, 5).map((favorite: any, i: number) => (
                                        <Fragment key={i}>
                                            <ListItem
                                                onClick={() => {
                                                    handleRowClick(favorite.url, favorite.id);
                                                }}
                                                title={editingIndex === favorite.id ? (
                                                    <TextField
                                                        name="FirstName"
                                                        id="edit-favorite-firstName"
                                                        value={editedName}
                                                        variant="outlined"
                                                        onChange={(event) => handleNameChange(event)}
                                                        inputProps={{ maxLength: 25 }}
                                                    />
                                                ) : (
                                                    <span style={{ color: 'rgb(102,90,216)' }}>
                                                        {favorite.favoriteName}
                                                    </span>
                                                )
                                                }
                                                info={(
                                                    <span className={classes.favoriteModuleName}>
                                                        {favorite.moduleName.toUpperCase()}
                                                    </span>
                                                )}
                                                action={<FavoriteActions
                                                    favoriteId={favorite?.id}
                                                    favoriteName={favorite.favoriteName}
                                                    setEditedName={setEditedName}
                                                    editedName={editedName}
                                                    setEditingIndex={setEditingIndex}
                                                    editingIndex={editingIndex}
                                                    closeDrawer={closeDrawer}
                                                    url={favorite.url}
                                                    moduleName={favorite.moduleName} />}
                                            />
                                            <Divider />
                                        </Fragment>
                                    ))}
                                </List>
                            ) : (
                                <>
                                    <Box className={classes.tabContainer} style={{ minHeight: "auto", paddingBottom: "5px", alignItems: 'center', alignContent: 'center', display: 'flex', marginTop: '100px' }}>
                                        <PageAlert
                                            message="Add your favorites to show up here!"
                                            variant="transparent"
                                        />
                                    </Box>
                                    <p className={classes.pageAlertTxt} >
                                        Click on View All to browse all favorites!</p>
                                </>
                            )}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {isLoading && (
                                <Box className={classes.tabContainer}>
                                    <CircularProgress color="secondary" />
                                </Box>
                            )}
                            {!isLoading &&
                                (recentsData && recentsData?.length > 0) ? (
                                <List>
                                    {recentsData.slice(0, 5).map((recent: any, i: number) => (
                                        <Fragment key={i}>
                                            <ListItem
                                                onClick={() => {
                                                    handleRecentsRowClick(recent.url);
                                                }}
                                                title={
                                                    <Tooltip title={recent.url.includes(recent.pageName) && recent.url}>
                                                        <span style={{ color: 'rgb(102,90,216)' }}>
                                                            {recent.pageName}
                                                        </span>
                                                    </Tooltip>
                                                }
                                            />
                                            <Divider />
                                        </Fragment>
                                    ))}
                                </List>
                            ) : (
                                <>
                                    <Box className={classes.tabContainer} style={{ minHeight: "auto", paddingBottom: "5px", alignItems: 'center', alignContent: 'center', display: 'flex', marginTop: '100px' }}>
                                        <PageAlert
                                            message="You don't have any Recents to show"
                                            variant="transparent"
                                        />
                                    </Box>
                                    <p className={classes.pageAlertTxt} >
                                        Click on View All to browse all custom reports!</p>
                                </>
                            )}
                        </TabPanel>
                    </SwipeableViews>
                    <CardActions
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: 12
                        }}
                    >
                        <Button id='pages-view-all'
                            type="button"
                            variant="default"
                            dense={true}
                            onClick={value === 0 ? handleViewAll : handleViewAllRecents}>
                            View All
                        </Button>
                    </CardActions>
                </BaseCard>
                {openDrawer &&
                    <ViewAllFavoritesList
                        setEditedName={setEditedName}
                        editedName={editedName}
                        editingIndex={editingIndex}
                        setEditingIndex={setEditingIndex}
                        openDrawer={openDrawer}
                        closeDrawer={closeDrawer}
                    />}
                {
                    openRecentsDrawer &&
                    <ViewAllRecentsList
                        openDrawer={handleViewAllRecents}
                        closeDrawer={closeViewRecentsDrawer}
                        recentsData={recentsData} />
                }
            </Box>
        </>
    );
};

export default MyPages;
