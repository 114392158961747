import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Box, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { Accordion, Switch } from '@primeroedge/ui-components';

const bannerLocations = ['', 'Upper Left','Upper Right','Bottom Left','Bottom Right']

const bannerVisibilityDuration = [0, 5, 10, 30, 60]

export interface INotification {
    audioPing: boolean;
    bannerDuration: number | null
    bannerLocation: string;
    showBanner: boolean
}

export default forwardRef(function MyPreferenceNotifications({
    initialNotifications
}: {
    initialNotifications: INotification
}, ref: any) {

    const [notifications, setNotifications] = useState<INotification>(initialNotifications ?? {
        showBanner: false,
        audioPing: false,
        bannerLocation: '',
        bannerDuration: 5
    })

    console.log('notifications.bannerLocation', notifications.bannerLocation)

    useImperativeHandle(ref, () => ({
        getValues: () => notifications
    }))

    return (
        <Accordion className='accordion-preferences' title="Notifications">
            <Box style={{ width: '100%', marginTop: 16 }}>
                <Grid container item spacing={5} style={{ marginBottom: 40 }}>
                    <Grid item sm={12} style={{ padding: '8px 20px', width: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', padding: '0 20px' }}>
                            <Typography>Show banner for new messages</Typography>
                        </Grid>

                        <Grid item sm={12} style={{ padding: '8px 20px' }}>
                            <Switch id='new-banner'
                                color='primary'
                                labelPlacement='top'
                                checked={!!notifications.showBanner}
                                onChange={(_: any, checked: boolean) => {
                                    setNotifications({
                                        ...notifications,
                                        showBanner: checked
                                    })
                                }} />
                        </Grid>
                    </Grid>
                    <Grid item sm={12} style={{ padding: '8px 20px', width: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', padding: '0 20px' }}>
                            <Typography>Audio ping with banner</Typography>
                        </Grid>
                        <Grid item sm={12} style={{ padding: '8px 20px' }}>
                            <Switch id='banner-audio'
                                color='primary'
                                labelPlacement='top'
                                checked={!!notifications.audioPing}
                                onChange={(_: any, checked: boolean) => {
                                    setNotifications({
                                        ...notifications,
                                        audioPing: checked
                                    })
                                }} />
                        </Grid>
                    </Grid>
                    <Grid item sm={12} style={{ padding: '8px 20px', width: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', padding: '0 20px' }}>
                            <Typography>Banner location</Typography>
                        </Grid>
                        <Grid item sm={12} style={{ padding: '8px 20px' }}>
                            <Select
                                id="banner-location"
                                label=''
                                value={notifications.bannerLocation}
                                variant='outlined'
                                onChange={(event: any) => {
                                    setNotifications({
                                        ...notifications,
                                        bannerLocation: event.target.value
                                    })
                                }}
                                inputProps={{
                                    name: "banner-location",
                                    id: "banner-location-select",
                                }}
                                style={{ width: '200px', height: 42 }}
                            >
                                {bannerLocations.map(data => (
                                    <MenuItem key={`banner-location-${data}`} value={data}>{data}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} style={{ padding: '8px 20px', width: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', padding: '0 20px' }}>
                            <Typography>Banner visibility in seconds</Typography>
                        </Grid>
                        <Grid item sm={12} style={{ padding: '8px 20px' }}>
                            <Select
                                id="banner-duration"
                                label=''
                                value={`${notifications.bannerDuration}`}
                                variant='outlined'
                                onChange={(event: any) => {
                                    setNotifications({
                                        ...notifications,
                                        bannerDuration: parseInt(event.target.value)
                                    })
                                }}
                                inputProps={{
                                    name: "banner-duration",
                                    id: "banner-duration-select",
                                }}
                                style={{ width: '200px', height: 42 }}
                            >
                                {bannerVisibilityDuration.map(data => (
                                    <MenuItem key={`banner-duration-${data}`} value={data}>{data}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Accordion>
    )
});