import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { delay, isNil } from 'lodash';
import { fetchMailUserList } from 'control/actions/mail.actions';
import { MailEntity } from 'model/entity/mail.entity';
import { IRecipientUser } from 'types/mail';
import { CustomReportsEntity } from 'model/entity/CustomReports.entity';
import { sendReportEmailInit } from 'control/actions/customReportMail.actions';
import { useProfile } from '@primeroedge/ui-components';

const useMailWindowEmailList = function () {
    const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const senderListRef = useRef<HTMLDivElement | null>(null);
    const senderListContainerRef = useRef<HTMLDivElement | null>(null);
    const { user } = useProfile();
    const isLoading = useSelector(MailEntity.isLoading);
    const list = useSelector(MailEntity.getUsersList);
    const isSendingEmail = useSelector(CustomReportsEntity.isEmailSending);
    const metaData = useSelector(CustomReportsEntity.getMetaData);
    const [to, setTo] = useState("");
    const [noUsersMessage, setNoUsersMessage] = useState("");
    const [buttonDisable, setButtonDisable] = useState<boolean>(true);
    const [showList, setShowList] = useState<boolean>(false);
    const [subject, setSubject] = useState<string>("");
    const [messageBody, setMessageBody] = useState<string>("");
    const [userSelect, setUserSelect] = useState<IRecipientUser[]>([]);
    const [emailInputWidth, setEmailInputWidth] = useState<number>(0);


    const removeSelectedUser = (user: IRecipientUser) => {
        const filteredUser = userSelect.filter((tempUser: IRecipientUser) => tempUser.recipientEmail !== user.recipientEmail);
        setUserSelect(() => [...filteredUser]);

        delay(setInputHeight, 500);
        if (filteredUser.length > 0) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    };

    const handleSubjectChange = (e: any) => {
        setSubject(e.target.value);

        // message.length 8 and 11 is checked because editor is giving <p></p> or <p><br></p> when user clear all the text, so we need
        // to check if messae body is empty or not, doing it by length
        if (userSelect.length > 0 && e.target.value.length > 0) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    };


    const handleToChange = (e: any) => {
        e.persist();
        setTo(e.target.value);
        const value = e.target.value;
        if (value.length >= 3) {
            dispatch(fetchMailUserList(value));
            setNoUsersMessage("We did not find a user with that email");
        } else {
            setShowList(false);
            setNoUsersMessage("");
        }

        if (userSelect.length > 0 && (subject || messageBody)) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    };

    const setInputRefFocus = () => {
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    };

    const setInputHeight = function () {
        const totalContaninerWidth = senderListContainerRef.current?.clientWidth as number;
        const selectedEmailsListWidth = senderListRef.current?.clientWidth as number;
        if (selectedEmailsListWidth < ((totalContaninerWidth * 2 / 3) + 35)) {
            setEmailInputWidth(totalContaninerWidth - selectedEmailsListWidth - 35)
        } else {
            setEmailInputWidth(totalContaninerWidth - 35)
        }
    }

    const selectEmailToSend = (selectedUser: any) => {
        setShowList(false);
        setTo(() => "");
        const checkIfUserExists = userSelect.filter((user: IRecipientUser) => user.recipientEmail === selectedUser.eMail);
        if (checkIfUserExists.length === 0) {
            const value = {
                recipientId: selectedUser.userGuid,
                recipientFirstName: selectedUser.firstName,
                recipientLastName: selectedUser.lastName,
                recipientEmail: selectedUser.eMail,
                recipientPrimeroUserId: selectedUser.primeroUserId,
            };
            setUserSelect((prevState) => [...prevState, value]);
            delay(setInputHeight, 500);
            setButtonDisable(false);
        }
        setInputRefFocus();
    };


    const handleChange = (value: string): void => {
        setMessageBody(value);

        // message.length 8 and 11 is checked because editor is giving <p></p> or <p><br></p> when user clear all the text, so we need 
        // to check if messae body is empty or not, doing it by length
        if (userSelect.length > 0) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    };

    const handleSend = function () {
        try {
            dispatch(sendReportEmailInit({
                customReportId: metaData?.dataItem?.customReportId,
                customEmailBody: messageBody,
                customEmailSubject: subject,
                emailList: userSelect.reduce((acc: string[], user: IRecipientUser) => {
                    acc.push(user.recipientEmail);
                    return acc;
                }, []).join(',')
            }))
        } catch (e) {
            console.log("e === ", e);
        }
    }

    useEffect(() => {
        if (list && list.length > 0) {
            setShowList(() => true);
            setNoUsersMessage("");
        }
    }, [list]);
    useEffect(() => {
        setShowList(false);
        if (metaData && subject.length === 0) {
            setSubject(`Email report - ${metaData.dataItem.customReportName}`);
            setMessageBody(`<p>Hi,</p><p>${user?.Name} has shared a custom Report <b>${metaData.dataItem.customReportName}</b>.</p><p><br></p>`)
        }
        const initialWidth = (senderListContainerRef.current?.clientWidth as number) - (senderListRef.current?.clientWidth as number);
        if (!isNil(initialWidth)) {
            setEmailInputWidth(initialWidth - 35)
        }
        return () => {
            setShowList(false);
            setSubject("");
            setMessageBody("");
        }
    }, []);


    return {
        to,
        subject,
        inputRef,
        list,
        isLoading,
        showList,
        noUsersMessage,
        userSelect,
        messageBody,
        buttonDisable,
        emailInputWidth,
        isSendingEmail,
        senderListRef,
        senderListContainerRef,
        handleToChange,
        removeSelectedUser,
        selectEmailToSend,
        handleSubjectChange,
        handleChange,
        handleSend
    };
}

export default useMailWindowEmailList;