import React, { useState } from 'react';
import { Tooltip, EditIcon, Trash2Icon, NotificationWithDialog, CheckCircleFilledIcon, useProfile } from '@primeroedge/ui-components';
import { IconButton, SvgIcon } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import { deleteFavoriteInit, updateFavoriteInit } from 'control/actions/myPages.action';
import {
    X as XIcon,
} from "react-feather";
type FavoriteActionsProps = {
    favoriteId?: any;
    favoriteName?: any;
    editedName?: any;
    setEditingIndex?: any;
    editingIndex?: any;
    setEditedName?: any;
    closeDrawer?: any;
    url?: any;
    moduleName?: any;
};
export const FavoriteActions: React.FC<FavoriteActionsProps> = ({
    favoriteId,
    favoriteName,
    editedName,
    setEditingIndex,
    setEditedName,
    closeDrawer,
    url,
    moduleName,
    editingIndex
}) => {
    const dispatch = useDispatch();
    const [openDialogBox, setOpenDialogBox] = useState(false);
    const [isrowEditable, setIsRowEditable] = useState(false);
    const { user }: any = useProfile();
    const activeDistrict = localStorage.getItem("activeDistrict");
    let selectedDistrict: any;
    selectedDistrict = activeDistrict && JSON.parse(activeDistrict);
    const handleDelete = () => {
        setOpenDialogBox(true);
    };
    const handleRemoveFavorite = (favoriteId: number) => {
        dispatch(deleteFavoriteInit(favoriteId));
        closeDrawer();
    }
    const closeRemoveFavorite = () => {
        setOpenDialogBox(false);
    };
    const handleEdit = (favoriteId: number, favoriteName: any) => {
        setEditingIndex(favoriteId);
        setEditedName(favoriteName);
        setIsRowEditable(true);
    }
    const handleClose = () => {
        setEditingIndex(0);
        setIsRowEditable(false);
    }
    const updateFavoriteName = (favoriteId: number, editedName: string, url: string, moduleName: any) => {
        const payload = {
            id: favoriteId,
            userId: user ? Number(user?.UserId) : 0,
            regionId: selectedDistrict?.attributes?.regionId ? Number(selectedDistrict?.attributes?.regionId[0]) : 0,
            favoriteName: editedName,
            moduleName: moduleName,
            url: url,
            displayorder: 0,
            createdBy: null,
            createdDate: null,
            modifiedBy: user?.UserId,
            modifiedDate: new Date(),
            isDeleted: false,
        }
        dispatch(updateFavoriteInit(payload));
        setEditingIndex(0);
        setIsRowEditable(false);
        closeDrawer();
    }
    return (
        <>
            <NotificationWithDialog
                open={openDialogBox}
                onClose={() => {
                    setOpenDialogBox(false);
                }}
                title="Confirmation"
                message="Are you sure you want to delete?"
                primaryAction={{
                    title: 'yes',
                    callback: () => handleRemoveFavorite(favoriteId),
                }}
                secondaryAction={{
                    title: 'No',
                    callback: closeRemoveFavorite,
                }}
                color="primary"
            />
            <div style={{ display: "flex" }}>

                {
                    isrowEditable && editingIndex === favoriteId ?
                        <>
                            <IconButton
                                type="button"
                                name="editFavorite"
                                color="primary"
                                id="editFavoriteName"
                                onClick={() => updateFavoriteName(favoriteId, editedName, url, moduleName)}
                                style={{ padding: '7px' }}
                            >
                                <CheckCircleFilledIcon />
                            </IconButton>
                            <IconButton id="closeButton"
                                type="button"
                                title="close-button"
                                onClick={handleClose}
                                style={{ padding: '7px' }}>
                                <SvgIcon fontSize="small">
                                    <XIcon />
                                </SvgIcon>
                            </IconButton>
                        </> :
                        <>
                            <Tooltip title={"Edit"}>
                                <IconButton
                                    type="button"
                                    color="default"
                                    name="Edit"
                                    id="editbutton"
                                    onClick={() => handleEdit(favoriteId, favoriteName)}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Delete"}>
                                <IconButton
                                    type="button"
                                    color="default"
                                    name="Delete"
                                    id="deletebutton"
                                    onClick={handleDelete}
                                >
                                    <Trash2Icon />
                                </IconButton>
                            </Tooltip>
                        </>
                }

            </div>
        </>
    )
}