import { INewTask, ITask } from "../../model/entity";
import workspaceAPIClient from "./clients/workspace-api.client";

class TasksTransportLayer {
    public static async addNewTask (newTask: INewTask): Promise<ITask> {
        const response = await workspaceAPIClient({
            method: "POST",
            url: `/api/create-task`,
            data: newTask,
        });
        const { status }: any = response;
        if (status === 200) {
            return status;
        } else {
            return Promise.reject("Error");
        }
    }

    public static async updateTask (data: any): Promise<ITask> {
        const response = await workspaceAPIClient({
            method: "PATCH",
            url: `/api/update-task`,
            data,
        });
        const { status }: any = response;
        if (status === 204) {
            return status;
        } else {
            return Promise.reject("Error");
        }
    }

    public static async deleteTask (taskId: any) {
        const deleteTaskId = [].concat(...taskId);
        const response = await workspaceAPIClient({
            method: "DELETE",
            url: `/api/delete-task`,
            data: { id: deleteTaskId },
        });
        const { status }: any = response;
        if (status === 204) {
            return status;
        } else {
            return Promise.reject("Error");
        }
    }
}

export default TasksTransportLayer;
