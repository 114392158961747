export enum ISystemPagePermissions {
    API_Configuration = "API Configuration",
    Attendance = "Attendance",
    Configuration_Setup = "Configuration Setup",
    Contact_Info = "Contact Info",
    Customer_Onboarding = "Customer Onboarding",
    Data_Query = "Data Query",
    DataExchange = "DataExchange",
    DataExchange_Config = "DataExchange Config",
    DataExchange_ConfigId = "DataExchange ConfigId",
    DataExchange_Editconfig = "DataExchange Editconfig",
    Districts = "Districts",
    Export_Dashboard = "Export Dashboard",
    Export_Monitor = "Export Monitor",
    File_Export_DataExchange = "File Export DataExchange",
    File_Export_DataExchange_Config = "File Export DataExchange Config",
    File_Export_Setup = "File Export Setup",
    File_Import_Setup = "File Import Setup",
    Generate_Periods = "Generate Periods",
    Import_Api_Config = "Import Api Config",
    Import_Dashboard = "Import Dashboard",
    Imports_Monitor = "Imports Monitor",
    Languages = "Languages",
    License_Management = "License Management",
    Licensing_Summary = "Licensing Summary",
    Manage_Periods = "Manage Periods",
    Onboarding = "Onboarding",
    Program_Configuration = "Program Configuration",
    Regions = "Regions",
    Reimbursement_Rates = "Reimbursement Rates",
    Resource_Catalog = "Resource Catalog",
    Roles = "Roles",
    Security = "Security",
    Settings = "Settings",
    Site_Configuration = "Site Configuration",
    Sites = "Sites",
    Special_Assistance_Program = "Special Assistance Program",
    Special_Assistance_Program_Entry = "Special Assistance Program Entry",
    Special_Assistance_Program_Proentry = "Special Assistance Program Proentry",
    System_Dashboard = "System Dashboard",
    System_Notifications = "System Notifications",
    System_Device_Catalog = "System_Device_Catalog",
    System_Generate_Periods = "System_Generate_Periods",
    System_License_Managment = "System_License_Managment",
    System_Reimbursement_Rates = "System_Reimbursement_Rates",
    System_Reports = "System_Reports",
    System_Resource_Catalog = "System_Resource_Catalog",
    System_Roles = "System_Roles",
    System_Templates = "System_Templates",
    Templates = "Templates",
    Users = "Users",
}
