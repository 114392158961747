import { Box } from "@material-ui/core";
import React, { FC } from "react";

interface ITabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className?: any;
}

const TabPanel: FC<ITabPanelProps> = ({
    children,
    value,
    index,
    className,
    ...other
}) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box className={className}>
                    {children}
                </Box>
            )}
        </div>
    );
};

export default TabPanel;
