import { put, takeLatest } from "redux-saga/effects";
import { ResponseStatus } from "../../model/entity";
import {
    deletedMailsSuccess,
    deleteMail, downloadAttachment, fetchInboxMessages,
    fetchMail,
    fetchMailUserList, fetchSentMessages,
    fetchWorkspaceDetails,
    getMail,
    getReadMail,
    readMail,
    readStatusMail,
    searchMail,
    selectedMail,
    sendMail,
    sendReply, setMailListServiceCallStatus, setUserMail, updateAttachment, updateReadStatus,
    updateReadStatusSuccess, uploadAttachment,
} from "../actions";
import MailTransportLayer from "../transport/mail.transport";

export interface IResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
    PayLoad?: any;
    payLoad?: any;
}

function* fetchMailUserSaga (action: any) {
    try {
        yield put(setMailListServiceCallStatus(ResponseStatus.Loading));
        const mailUserList: IResponseGenerator = yield MailTransportLayer.fetchMailUser(action.payload);
        yield put(setUserMail(mailUserList));
        yield put(setMailListServiceCallStatus(ResponseStatus.Success));
    } catch (error) {
        yield null;
        yield put(setMailListServiceCallStatus(ResponseStatus.Error));
    }
}

function* sendMailSaga (action: any) {
    try {
        const mailUserList: IResponseGenerator = yield MailTransportLayer.sendMail(action.payload);
        yield put(setUserMail(mailUserList));
        // yield put(updateMailList([]));
        yield put(fetchWorkspaceDetails());
    } catch (error) {
        yield null;
    }
}

function* sendReplySaga (action: any) {
    try {
        const mailUserList: IResponseGenerator = yield MailTransportLayer.sendReply(action.payload);
        yield put(setUserMail(mailUserList));
    } catch (error) {
        yield null;
    }
}

function* getMailSaga (action: any) {
    try {
        const mailList: IResponseGenerator = yield MailTransportLayer.getMail(action.payload);
        yield put(getMail(mailList));
    } catch (error) {
        yield null;
    }
}

function* getListMessagesSaga (action: any) {
    try {
        const mailList: IResponseGenerator = yield MailTransportLayer.getListMessages(action.payload);
        yield put(getMail(mailList));
    } catch (error) {
        yield null;
    }
}

function* getSentMessagesSaga (action: any) {
    try {
        const mailList: IResponseGenerator = yield MailTransportLayer.getSentMessages(action.payload);
        yield put(getMail(mailList));
    } catch (error) {
        yield null;
    }
}

function* readMailSaga (action: any) {
    try {
        const readMailData: IResponseGenerator = yield MailTransportLayer.readMail(action.payload);
        yield put(getReadMail(readMailData));
    } catch (error) {
        yield null;
    }
}

function* searchMailSaga (action: any) {
    try {
        const mailList: IResponseGenerator = yield MailTransportLayer.searchMail(action.payload);
        yield put(getMail(mailList));
    } catch (error) {
        yield null;
    }
}

function* deleteMailSaga (action: any) {


    try {
        const mailList: IResponseGenerator = yield MailTransportLayer.deleteMail(action.payload);
        yield put(selectedMail(mailList));
        yield put(deletedMailsSuccess(action.payload));
    } catch (error) {
        yield null;
    }
}

function* readStatusMailSaga (action: any) {
    try {
        yield MailTransportLayer.readStatusMail(action.payload);
        const params = {
            pageSize: action.payload.pageSize,
            pageNumber: action.payload.pageNumber,
            sortColumn: "CreatedOn",
            sortType: "DESC",
            type: "inbox",
        };
        yield put(fetchMail(params));
    } catch (error) {
        yield null;
    }
}

function* updateReadStatusSaga (action: any) {
    try {
        const read: IResponseGenerator = yield MailTransportLayer.updateReadStatus(action.payload.messageId);
        if (read) {
            yield put(updateReadStatusSuccess({ messageId: action.payload.messageId }));
        }
    } catch (error) {
        yield null;
    }
}

function* uploadAttachmentSaga (action: any) {
    try {
        const read: IResponseGenerator = yield MailTransportLayer.uploadAttachment(action.payload);

        if (read) {
            yield put(updateAttachment(read.PayLoad));
        }
    } catch (error) {
        yield null;
    }
}

function* downloadAttachmentSaga (action: any) {
    try {
        const read: IResponseGenerator = yield MailTransportLayer.downloadAttachment(action.payload);
        if (read) {
            yield put(updateAttachment(read.PayLoad));
        }
    } catch (error) {
        yield null;
    }
}

export default function* MailSaga () {
    try {
        yield takeLatest(fetchMailUserList, fetchMailUserSaga);
        yield takeLatest(sendMail, sendMailSaga);
        yield takeLatest(sendReply, sendReplySaga);
        yield takeLatest(fetchMail, getMailSaga);
        yield takeLatest(fetchInboxMessages, getListMessagesSaga);
        yield takeLatest(fetchSentMessages, getSentMessagesSaga);
        yield takeLatest(readMail, readMailSaga);
        yield takeLatest(searchMail, searchMailSaga);
        yield takeLatest(deleteMail, deleteMailSaga);
        yield takeLatest(readStatusMail, readStatusMailSaga);
        yield takeLatest(updateReadStatus, updateReadStatusSaga);
        yield takeLatest(uploadAttachment, uploadAttachmentSaga);
        yield takeLatest(downloadAttachment, downloadAttachmentSaga);
    } catch (error) {
        yield null;
    }
}

