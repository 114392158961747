import { IconButton } from "@material-ui/core";
import {
    Button, IGridCellProps, IGridColumnProps,
    IGridDataState,
    IGridDataStateChangeEvent,
    IGridRowClickEvent, KGrid, NotificationWithDialog, PageLayout,
    PlusIcon,
    Trash2Icon,
} from "@primeroedge/ui-components";
import { DeleteBroadcast, FetchBroadcastMessages, selectedBroadcastMessage } from "control/actions";
import { BroadcastEntity, MailEntity, WorkSpaceDetailsEntity } from "model/entity";
import React, { FC, useCallback, useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import ComposeBroadcast from "./ComposeBroadcast";

// const useStyles: any = makeStyles(() => ({
//     iconDivider : {
//         height: "30px",
//         background: "#424242",
//         width: "1px !important",
//         minWidth: "1px",
//         marginLeft: "20px",
//         marginRight: "20px",
//         display: "inline-block",
//     },
// }));

const BroadcastMessages: FC = () => {
    const dispatch = useDispatch();
    // const classes = useStyles();

    const messages = useSelector(BroadcastEntity.messages);
    const isLoading = useSelector(BroadcastEntity.isLoading);
    const totalMessagesCount = useSelector(BroadcastEntity.totalMessageCount);
    const bMessage = useSelector(MailEntity.selectedMessage);

    const [showCompose, setShowCompose] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState({});
    const [messageInfo, setMessagesInfo] = useState<any>({sel: []});
    const [editable, setEditable] = useState(false);
    const [deletableTask, setDeletableTask] = useState<string | null>(null);
    const [view, setView] = useState(false);

    const initialGridState = {
        skip: 0,
        take: 5,
    };
    const [gridState, setGridState] = useState<IGridDataState>(initialGridState);
    const isBroadcastUser = useSelector(WorkSpaceDetailsEntity.isBroadcastUser);

    const gridColumns: IGridColumnProps[] = [
        { field: "Selected", width: "80px" },
        {
            field: "senderFirstName",
            title: "Display To",
            cell: (rowData: IGridCellProps) => {
                const recipient = rowData.dataItem.recipient;
                let msg: any;
                if(recipient.length === 0) {
                    msg = "Global Message";
                } else {
                 msg = rowData.dataItem.recipient
                    .map((ele: any) => ele.recipientFirstName)
                    .join(", ");
                if (msg.length > 25) {
                    msg = msg.substring(0, 25);
                    const count = msg.match(/,/g).length;
                    msg =
                        rowData.dataItem.recipient.length > count + 1
                            ? `${msg} ... +${rowData.dataItem.recipient.length - count + 1} others`
                            : msg;
                }
            }

                return <td role="gridcell">{msg}</td>;
            },
        },
        {
            field: "content",
            title: "Message",
            cell: (rowData: IGridCellProps) => (
                <td role="gridcell" className="ellipsis">
                    {rowData?.dataItem?.content?.replace(/<[^>]+>/g, "")}
                </td>
            ),
        },
        {
            field: "createdOn",
            title: "Posted On",
            cell: (rowData: IGridCellProps) => (
                <td role="gridcell">
                    <Moment format="MM/DD/YYYY">{rowData.dataItem.createdOn}</Moment>
                </td>
            ),
        },
        {
            field: "actions",
            title: " Actions",
            width: "90px",
            cell: (rowData: IGridCellProps) => (
              <td role="gridcell">
                    {/* <EditIcon onClick={onEditClick.bind(null, rowData.dataItem)} />
                    <span className={classes.iconDivider}></span>                */}
                    <IconButton title="delete-button" onClick={onDeleteClick.bind(null, rowData.dataItem.messageId)} ><Trash2Icon /></IconButton>
              </td>
            ),
          },
    ];

    useEffect(() => {
        return () => {
            dispatch(selectedBroadcastMessage({}));
        };
    }, []);

    const fetchMessages = useCallback(() => {
        const take = gridState.take || 5;
        const pageNumber = ((gridState.skip || 0) / take) + 1;
        dispatch(FetchBroadcastMessages({
            pageSize: take,
            pageNumber,
        }));
    }, [dispatch, gridState]);

    useEffect(() => {
        fetchMessages();
    }, [fetchMessages]);

    useEffect(() => {
        if (messageInfo) {
            setMessagesInfo({ sel: messageInfo});
        }
    }, []);

    useEffect(() => {
        if (bMessage.id) {
            setShowCompose(true);
            setSelectedMessage(bMessage);
            setEditable(false);
            setView(true);
        }
    }, [bMessage]);

    // const onEditClick = (item: any) => {
    //     setSelectedMessage(item);
    //     setShowCompose(true);
    //     setEditable(true);
    //     setView(false);
    // };

    const onDeleteClick = (id: string) => {
        setDeletableTask(id);
    };

    const cancelDelete = () => {
        setDeletableTask(null);
    };

    const deleteAllMessages = (ev: any) => {
        console.error(ev);
        if (messageInfo) {
            const { sel } = messageInfo;
            if (sel && sel.length) {
                const selMsgs = messageInfo.sel.filter((e: any) => e.Selected).map((e: any) => e.messageId);
                setDeletableTask(selMsgs);
            }
        }
    };

    const confirmDelete = (id: string | null) => {
        if (id) {
            setDeletableTask(null);
            dispatch(DeleteBroadcast(id));
        }
    };

    const handleDataStateChange = (ev: IGridDataStateChangeEvent) => {
        setGridState(ev.dataState);
    };


    const handleComposeClose = () => {
        setShowCompose(false);
        setSelectedMessage({});
        setEditable(false);
        setView(false);
    };

    const handleSelectionChange = (ev: any) => {
        if (ev) {
            setMessagesInfo({sel: ev});
        }
    };

    const handleComposePost = () => {
        handleComposeClose();
        fetchMessages();
    };

    const handleRowClick = (ev: IGridRowClickEvent) => {
        setSelectedMessage(ev.dataItem);
        setShowCompose(true);
        setEditable(false);
        setView(true);
    };

    return (
        <PageLayout
            action={{
                id: "broadcastButton",
                label: "Broadcast Message",
                onClick: () => {
                    setShowCompose(!showCompose);
                    setEditable(false);
                    setView(false);
                },
                startIcon: <PlusIcon />,
                disabled: !isBroadcastUser,
                buttonTooltip: "You do not have permissions to perform this action"
            }}
            breadCrumbs={[
                {
                    path: "/",
                    title: "Workspace",
                },
            ]}
            title="Broadcast"
        >
            {showCompose && (
                <ComposeBroadcast
                    onPost={handleComposePost}
                    onClose={handleComposeClose}
                    message={selectedMessage}
                    editable={editable}
                    view={view}
                />
            )}

            <KGrid
                columns={gridColumns}
                data={messages || []}
                primaryField="id"
                pageSizes={true}
                pageSize={3}
                pageable={true}
                selectedField="Selected"
                {...gridState}
                processedData={true}
                onDataStateChange={handleDataStateChange}
                headerStyles={["no-background"]}
                loading={isLoading}
                total={totalMessagesCount}
                onRowClick={handleRowClick}
                onSelectionChange={handleSelectionChange}
                selectionActions={<Button id="deleteButton" onClick={deleteAllMessages} > Delete </Button>}
            />
            <NotificationWithDialog
                open={Boolean(deletableTask)}
                title="Delete Broadcast"
                message="Are you sure you want to delete this broadcast message?"
                color="primary"
                primaryAction={{
                    title: "Delete",
                    callback: () => confirmDelete(deletableTask),
                }}
                secondaryAction={{
                    title: "Cancel",
                    callback: cancelDelete,
                }}
            />
        </PageLayout>
    );
};

export default BroadcastMessages;
