import { Paper } from "@material-ui/core";
import { Edit2Icon, Notification, PageLayout } from "@primeroedge/ui-components";
import { isEmpty } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInboxMessages, selectedMail, setComposeBoXWindowOpenCLose } from "../../../../control/actions";
import { MailEntity, MessageType } from "../../../../model/entity";
import Compose from "../MailView/Compose";
import MailDetails from "../MailView/MailDetails";
import MailList from "../MailView/MailList";

const InboxPage: FC = () => {
    const dispatch = useDispatch();
    const mailToViewDetails = useSelector(MailEntity.mailToViewDetails);
    const [showNotification, setShowNotification] = useState(false);
    const mailItemFromNotification = localStorage.getItem("mailToOpenData");

    useEffect(() => {
        dispatch(fetchInboxMessages({
            pageSize: 10,
            pageNumber: 1,
            sortColumn: "CreatedOn",
            sortType: "DESC",
            type: MessageType.Inbox,
        }));

        return () => {
            dispatch(selectedMail({}));
        };
    }, []);

    useEffect(() => {
        if (mailItemFromNotification) {
            const mailItemToRead = JSON.parse(mailItemFromNotification);
            dispatch(selectedMail(mailItemToRead));
        } else {
            dispatch(selectedMail({}));
        }
    }, [mailItemFromNotification])

    const handleComposeClick = () => {
        dispatch(setComposeBoXWindowOpenCLose(true));
    };

    const notificationCb = (msg: any) => {
        setShowNotification(msg);
    };

    return (
        <>
            <Notification
                style={{
                    position: "relative",
                    top: "-67px",
                }}
                color="success"
                duration={5000}
                message="Deleted successfully!"
                onClose={() => setShowNotification(false)}
                open={showNotification}
                variant="filled"
            />
            <PageLayout
                title="Inbox"
                breadCrumbs={[{ title: "Workspace", path: "/" }]}
                action={{
                    id: "composeMail",
                    label: "Compose",
                    startIcon: <Edit2Icon />,
                    onClick: handleComposeClick,
                }}
            >
                <Paper style={{ marginBottom: "100px" }}>

                    {mailToViewDetails && !isEmpty(mailToViewDetails) ? (
                        <MailDetails notificationCb={notificationCb} messageType={MessageType.Inbox} />
                    ) : (
                        <MailList messageType={MessageType.Inbox} notificationCb={notificationCb} />
                    )}
                    <Compose />
                </Paper>
            </PageLayout>
        </>
    );
};

export default InboxPage;
