import { useProfile } from '@primeroedge/ui-components';
import { getFavoritesInit } from 'control/actions/myPages.action';
import { getFavorites } from 'model/entity/myPages.entity';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
export const useFavorites = (openDrawer: any) => {
    const dispatch = useDispatch();
    const { user } = useProfile();
    const activeDistrict = localStorage.getItem("activeDistrict");
    let selectedDistrict: any;
    selectedDistrict = activeDistrict && JSON.parse(activeDistrict);

    const [favoritesCount, setFavoritesCount] = useState(0);
    const [favoritesData, setFavoritesData] = useState([]);
    const getFavoritesList = useSelector(getFavorites);

    useEffect(() => {
        dispatch(getFavoritesInit({
            userId: user ? Number(user?.UserId) : 0,
            regionId: selectedDistrict?.attributes?.regionId ? Number(selectedDistrict?.attributes?.regionId[0]) : 0,
        }))
    }, [openDrawer]);

    useEffect(() => {
        setFavoritesData(getFavoritesList);
    }, [getFavoritesList]);

    useEffect(() => {
        if (favoritesData?.length === 0) {
            setFavoritesCount(0);
        } else {
            setFavoritesCount(favoritesData.length);
        }
    }, [favoritesData]);

    return {
        favoritesData,
        favoritesCount
    }
}