import { APIInstance } from "@primeroedge/ui-components";
import {
    API_ITEM_MANAGEMENT,
    APIM_ENVIRONMENT,
    APIM_SUBSCRIPTION_KEY,
} from "./api-constants";

const itemManagementAPIClient = new APIInstance({
    baseURL: API_ITEM_MANAGEMENT,
    environment: APIM_ENVIRONMENT,
    subscriptionKey: APIM_SUBSCRIPTION_KEY,
    headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
}).axios;

export default itemManagementAPIClient;
