import { Notification, PageAlert, PageLayout, TourPageWrapper, useProfile } from "@primeroedge/ui-components";
import { getUserDetailsKeyclockInit } from "control/actions";
import _ from "lodash";
import { checkProfileUpdateStatus, getUserDetailsKeyclockEntity, IAdminServiceUserDetails, IUserMgmatUserDetails, IUserProfile } from "model/entity";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfilePage from "./index";
import SecurityInfo from "./security";
import MyPreferences from "./MyPreferences";
import profilePageTours from "view/tours/profilePageTours";

const TabsPage = () => {

    const dispatch = useDispatch();
    const { user } = useProfile();
    const isProfileUpdated = useSelector(checkProfileUpdateStatus);
    const userProfile = useSelector(getUserDetailsKeyclockEntity);
    const [profileImageUrl] = useState<any>(null);
    const [editableProfile, setEditedProfile] = useState<IUserProfile>({
        attributes: null,
        accessLevelDescription: "",
        authenticationMode: null,
        regions: null,
        roles: null,
        area: null,
        sites: null,
        enabled: false,
        regionID: null,
        accessLevelId: 0,
        cellPhone: "",
        createdUserId: 0,
        creationDate: "",
        defaultRealmId: 0,
        defaultRegionId: 0,
        email: "",
        firstName: "",
        image: "",
        languagepreference: null,
        lastName: "",
        mi: "",
        primeroUserId: "",
        regionId: 0,
        regionName: null,
        theme: null,
        totalRecords: 0,
        updateBy: null,
        updateDate: "",
        userId: 0,
        userName: "",
        userStatusDescription: "",
        userStatusId: 0,
        userStatus: 'Active',
        windowsUserDomain: "",
        windowsUserName: "",
        workPhone: "",
        keyCloakID: null,
    });
    const [selectedDistrict, setSelectedDistrict] = useState<any>({});
    const [keyCloakData, setKeyCloakData] = useState<IUserMgmatUserDetails[]>([]);
    const [adminData, setAdminData] = useState<IAdminServiceUserDetails>();
    const activeDistrict = localStorage.getItem("activeDistrict");
    const [isApiFetchError, setApiFetchError] = useState(false);
    const [userDistrict, setUserDistrict] = useState<boolean>(true);
    const [tabsBool, setTabsBool] = useState<boolean>(false);
    const [cancelUserConfirmation, setCancelUserConfirmation] = useState<boolean>(false);
    const [isEditable, canEdit] = useState(false);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [showAlert, canShowAlert] = useState<"success" | "error" | undefined>();

    useEffect(() => {
        if (activeDistrict) {
            setSelectedDistrict(JSON.parse(activeDistrict));
        }
    }, [activeDistrict]);

    useEffect(() => {
        if (user) {
            dispatch(getUserDetailsKeyclockInit(user.Email));
            canEdit(false);
        }
    }, [dispatch, user, activeDistrict]);

    useEffect(() => {
        if (userProfile.UserMgmatUserDetails || userProfile.AdminServiceUserDetails) {
            setKeyCloakData(userProfile.UserMgmatUserDetails.PayLoad);
            setAdminData(userProfile.AdminServiceUserDetails.PayLoad);
        }
    }, [userProfile, isEditable]);

    useEffect(() => {
        if (keyCloakData.length > 0 && adminData) {
            if (selectedDistrict.name === keyCloakData.map((a) => a.attributes.regionName[0])[0]) {
                setUserDistrict(true);
            } else {
                setUserDistrict(false);
            }
            setEditedProfile({
                attributes: keyCloakData.map((a) => a.attributes),
                accessLevelDescription: "accessLevelDescription",
                authenticationMode: "authenticationMode",
                regions: "regions",
                roles: "roles",
                area: "areas",
                sites: "sites",
                enabled: true,
                regionID: 0,
                accessLevelId: 0,
                cellPhone: adminData.cellPhone,
                createdUserId: 0,
                creationDate: "creationDate",
                defaultRealmId: 0,
                defaultRegionId: 0,
                email: keyCloakData.map((a) => a.email).toString(),
                firstName: keyCloakData.map((first) => first.firstName).toString(),
                image: adminData.image,
                languagepreference: adminData.languagepreference,
                lastName: keyCloakData.map((last) => last.lastName).toString(),
                mi: adminData.mi,
                primeroUserId: keyCloakData.map((userId) => userId.primeroUserId).toString(),
                regionId: 0,
                regionName: selectedDistrict.name,
                theme: adminData.theme,
                totalRecords: 0,
                updateBy: "updateBy",
                updateDate: "updateDate",
                userId: adminData.userId,
                userName: keyCloakData.map((a) => a.email).toString(),
                userStatusDescription: _.flatten(keyCloakData.map((a) => a.attributes).map((s) => s.userStatus)).toString(),
                userStatusId: 0,
                windowsUserDomain: "windowsUserDomain",
                windowsUserName: "windowsUserName",
                workPhone: adminData.workPhone,
                keyCloakID: user && user.UserGuid,
                navigateOption: adminData.navigateOption,
                dashboardV2: adminData.dashboardV2,
                userStatus:  keyCloakData[0].attributes.userStatus[0],
                audioPing: adminData.audioPing,
                bannerDuration: adminData.bannerDuration,
                bannerLocation: adminData.bannerLocation,
                showBanner: adminData.showBanner
            });
            // downloadImage(adminData.image);
        } else if (!keyCloakData) {
            setApiFetchError(() => true);
        }
    }, [keyCloakData, adminData]);

    useEffect(function() {
        if (isProfileUpdated && isEditable) {
            canEdit(false);
            setActiveTab(0)
        }
    }, [isProfileUpdated])


    return (
        <TourPageWrapper tourName={"Profile Page"}
            //@ts-ignore
            steps={profilePageTours}
            uniqueDisplayEnableElement={"workspace-profile-tour"}
        >
        <div>
           
            {showAlert === "success" || showAlert === "error" ? (
                <Notification
                    color={showAlert === "success" ? "success" : "error"}
                    duration={5000}
                    message={showAlert === "success" ? "User Password updated successfully" : "Error in updating user password."}
                    open={!!showAlert}
                    onClose={() => canShowAlert(() => undefined)}
                    variant="filled"
                />
            ) : ""}
            <PageLayout
                breadCrumbs={[{ title: "Workspace", path: "/" }]}
                pageTabs={{
                    tabs: [
                        {
                            component:
                                (
                                    <ProfilePage
                                        setTabsBool={setTabsBool}
                                        setCancelUserConfirmation={setCancelUserConfirmation}
                                        cancelUserConfirmation={cancelUserConfirmation}
                                        isEditable={isEditable}
                                        canEdit={canEdit}
                                    />
                                ),
                            label: "BASIC INFO",
                        },
                        {
                            component:
                                (
                                    <>
                                        <SecurityInfo
                                            profileImageUrl={profileImageUrl}
                                            editableProfile={editableProfile as IUserProfile}
                                            setCancelUserConfirmation={setCancelUserConfirmation}
                                            cancelUserConfirmation={cancelUserConfirmation}
                                            setTabsBool={setTabsBool}
                                            canEdit={canEdit}
                                            setActiveTab={setActiveTab}
                                            canShowAlert={canShowAlert}

                                        />
                                    </>
                                ),
                            label: "Change Password",
                            disabled: !userDistrict || tabsBool,
                        },
                        {
                            component: <MyPreferences
                                editableProfile={editableProfile as IUserProfile}
                                setTabsBool={setTabsBool}
                                canEdit={canEdit}
                                setActiveTab={setActiveTab}
                            />,
                            label: 'My Preferences',
                            disabled: !userDistrict || tabsBool,
                        }
                    ],
                    value: activeTab,
                }}
                title={"My Profile"}
            >
                {isApiFetchError && (
                    <PageAlert title="Network Error" message="Unable to retrieve data. Please try again later." />
                )}
            </PageLayout >
        </div >
        </TourPageWrapper>

    );
};

export default TabsPage;
