import { PageNotFound } from "@primeroedge/ui-components";
import InboxPage from "../mail/inbox-page";
import BroadcastMessages from "../mail/MailView/BroadcastMessages";
import Messages from "../mail/MailView/Messages/Messages";
import SentPage from "../mail/sent-page";
import TabsPage from "../profile-page/Tabs";
import TasksPage from "../tasks-page";

const workspaceRoutes = [
    {
        path: "/workspace/inbox",
        component: InboxPage,
    },
    {
        path: "/workspace/sent",
        component: SentPage,
    },
    {
        path: "/workspace/messages",
        component: Messages,
    },
    {
        path: "/workspace/tasks",
        component: TasksPage,
    },
    {
        path: "/workspace/profile",
        component: TabsPage,
    },
    {
        path: "/workspace/broadcast",
        component: BroadcastMessages,
    },
    {
        path: "/workspace/*",
        component: PageNotFound,
    },
];

export default workspaceRoutes;
