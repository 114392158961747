import {
    Checkbox, FormControl,
    FormControlLabel, FormGroup, FormLabel, Grid, Radio,
    RadioGroup,
} from "@material-ui/core";
import * as siteActions from "control/actions/sites.actions";
import { cloneDeep, get, set } from "lodash";
import {
    SitesDataEntity,
} from "model/entity/sites.entity";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getSiteCharactersticsJSON from "./siteCharacteristicsJSON";

interface IProps {
  generalInfo: any;
}

const SiteCharacteristics = (props: IProps) => {
  const dispatch = useDispatch();
  const siteCharateristics = useSelector(SitesDataEntity.getSiteCharacterstics);
  const [JSON, setJSON] = useState([] as any);

  useEffect(() => {
    if (siteCharateristics.length === 0) {
      setJSON(getSiteCharactersticsJSON(props?.generalInfo.siteId));
    } else {
      if (siteCharateristics.length > 0) {
        const siteCharData = cloneDeep(siteCharateristics);
        siteCharData.forEach((e: any) => {
          e.siteId = props?.generalInfo.siteId;
        });
        setJSON(siteCharData);
      }
    }
  }, [siteCharateristics]);

  const handleUpdates = (location: string, value: any, type: string, subLoc: string, ind: number) => {
    const SC = cloneDeep(JSON);

    if (value && (type !== "parent" && type !== "checkbox")) {
      const subSets = get(SC, subLoc);
      subSets.forEach((subSet: any) => {
        if (type === "radio") {
          subSet.isSelected = false;
        } else if (type === "child") {
          subSet.isOptionSelected = false;
        }
      });
      set(SC, subLoc, subSets);
    }

    if (type === "parent") {
      const subSets = get(SC, subLoc);
      if (subSets.length > 0) {
        subSets.forEach((subSet: any, index: number) => {
          if (index === 0 && value) {
            subSet.isSelected = true;
          } else {
            subSet.isSelected = false;
          }
        });
      }
      set(SC, subLoc, subSets);
    }

    set(SC, location, value);

    if (type === "radio" && value) {
      SC[ind].isActive = true;
    }

    if (type === "checkbox") {
      const subSets = get(SC, subLoc);
      let siteActive = false;
      subSets.forEach((subSet: any) => {
        if (subSet.isSelected) {
          siteActive = true;
        }

      });
      SC[ind].isActive = siteActive;
      set(SC, subLoc, subSets);
    }

    dispatch(siteActions.storeSiteCharacterstics(SC));
  };

  return (
    <Grid container={true}>
      {/*- Section 1 */}
      <Grid item={true} xs={4}>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox value="K-12_Schools"
                onClick={(e: any) => {
                  handleUpdates(`[0].isActive`, e.target.checked, "parent", "[0].siteCharacteristicData", 0);
                }}
                checked={JSON[0]?.isActive}
              />}
              label="K-12 Schools"
              checked={true}
            />
            <RadioGroup
              name="K-12_Schools_Radio"
              style={{ marginLeft: "25px" }}
            >
              <FormControlLabel
                value="public"
                control={
                  <Radio
                    onClick={(e: any) => {
                      handleUpdates(`[0].siteCharacteristicData[0].isSelected`, e.target.checked, "radio", "[0].siteCharacteristicData", 0);
                    }}
                  />
                }
                label="Public"
                checked={JSON[0]?.siteCharacteristicData[0].isSelected}
              />
              <FormControlLabel
                value="Parochial"
                control={
                  <Radio
                    onClick={(e: any) => {
                      handleUpdates(`[0].siteCharacteristicData[1].isSelected`, e.target.checked, "radio", "[0].siteCharacteristicData", 0);
                    }}
                  />
                }
                label="Parochial"
                checked={JSON[0]?.siteCharacteristicData[1].isSelected}
              />
              <FormControlLabel
                value="charter"
                control={
                  <Radio
                    onClick={(e: any) => {
                      handleUpdates(`[0].siteCharacteristicData[2].isSelected`, e.target.checked, "radio", "[0].siteCharacteristicData", 0);
                    }}
                  />
                }
                label="Charter"
                checked={JSON[0]?.siteCharacteristicData[2].isSelected}
              />
              <FormControlLabel
                value="RCCI"
                control={
                  <Radio
                    onClick={(e: any) => {
                      handleUpdates(`[0].siteCharacteristicData[3].isSelected`, e.target.checked, "radio", "[0].siteCharacteristicData", 0);
                    }}
                  />
                }
                label="RCCI"
                checked={JSON[0]?.siteCharacteristicData[3].isSelected}
              />
              {JSON[0]?.siteCharacteristicData[3].isSelected && (
                <RadioGroup name="RCCI_Radio" style={{ marginLeft: "25px" }}>
                  <FormLabel component="legend" style={{ color: "#546e7a" }}>
                    Are there Day Students?
                  </FormLabel>
                  <Grid item={true} style={{ display: "inline-flex" }}>
                    <FormControlLabel
                      value="Yes"
                      control={<Radio
                        onClick={(e: any) => {
                          handleUpdates(`[0].siteCharacteristicData[3].siteCharacteristicDataOptions[0].isOptionSelected`, e.target.checked,
                            "child", "[0].siteCharacteristicData[3].siteCharacteristicDataOptions", 0);
                        }}
                      />}
                      label="Yes"
                      checked={JSON[0]?.siteCharacteristicData[3].siteCharacteristicDataOptions[0].isOptionSelected}
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio
                        onClick={(e: any) => {
                          handleUpdates(`[0].siteCharacteristicData[3].siteCharacteristicDataOptions[1].isOptionSelected`, e.target.checked,
                            "child", "[0].siteCharacteristicData[3].siteCharacteristicDataOptions", 0);
                        }}
                      />}
                      label="No"
                      checked={JSON[0]?.siteCharacteristicData[3].siteCharacteristicDataOptions[1].isOptionSelected}
                    />
                  </Grid>
                </RadioGroup>
              )}
            </RadioGroup>
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Checkbox value="RCCI_with_no_School"
                onClick={(e: any) => {
                  handleUpdates(`[1].isActive`, e.target.checked, "parent", "[1].siteCharacteristicData", 1);
                }}
                checked={JSON[1]?.isActive}
              />}
              label="RCCI with no School"
              checked={true}
            />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Checkbox value="Camp"
                onClick={(e: any) => {
                  handleUpdates(`[2].isActive`, e.target.checked, "parent", "[2].siteCharacteristicData", 2);
                }}
                checked={JSON[2]?.isActive}
              />}
              label="Camp"
              checked={true}
            />
            <RadioGroup name="Camp_Radio" style={{ marginLeft: "25px" }}>
              <FormControlLabel
                value="Day"
                control={
                  <Radio
                    onClick={(e: any) => {
                      handleUpdates(`[2].siteCharacteristicData[0].isSelected`, e.target.checked, "radio", "[2].siteCharacteristicData", 2);
                    }}
                  />
                }
                label="Day"
                checked={JSON[2]?.siteCharacteristicData[0].isSelected}
              />
              <FormControlLabel
                value="Residential"
                control={
                  <Radio
                    onClick={(e: any) => {
                      handleUpdates(`[2].siteCharacteristicData[1].isSelected`, e.target.checked, "radio", "[2].siteCharacteristicData", 2);
                    }}
                  />
                }
                label="Residential"
                checked={JSON[2]?.siteCharacteristicData[1].isSelected}
              />
              <FormControlLabel
                value="Residential_-_year_round"
                control={
                  <Radio
                    onClick={(e: any) => {
                      handleUpdates(`[2].siteCharacteristicData[2].isSelected`, e.target.checked, "radio", "[2].siteCharacteristicData", 2);
                    }}
                  />
                }
                label="Residential - year round"
                checked={JSON[2]?.siteCharacteristicData[2].isSelected}
              />
            </RadioGroup>
          </FormGroup>
        </FormControl>
      </Grid>
      {/*- End of Section 1 */}

      {/*- Section 2 */}
      <Grid item={true} xs={4}>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox value="gilad"
                onClick={(e: any) => {
                  handleUpdates(`[3].isActive`, e.target.checked, "parent", "[3].siteCharacteristicData", 3);
                }}
                checked={JSON[3]?.isActive}
              />}
              label="CACFP Facility"
              checked={true}
            />
          </FormGroup>
          <FormControl component="fieldset" style={{ marginLeft: "25px" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value="Adult_Day_Care_Center"
                    onClick={(e: any) => {
                      handleUpdates(`[3].siteCharacteristicData[0].isSelected`, e.target.checked, "checkbox", "[3].siteCharacteristicData", 3);
                    }}
                    checked={JSON[3]?.siteCharacteristicData[0].isSelected}
                  />
                }
                label="Adult Day Care Center"
                checked={true}
              />
              {JSON[3]?.siteCharacteristicData[0].isSelected && (
                <RadioGroup
                  name="RCCI_Radio"
                  style={{ marginLeft: "25px" }}
                  row={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio
                      onClick={(e: any) => {
                        handleUpdates(`[3].siteCharacteristicData[0].siteCharacteristicDataOptions[0].isOptionSelected`, e.target.checked,
                          "child", "[3].siteCharacteristicData[0].siteCharacteristicDataOptions", 3);
                      }}
                    />}
                    label="Yes"
                    checked={JSON[3]?.siteCharacteristicData[0].siteCharacteristicDataOptions[0].isOptionSelected}
                  />
                  <FormControlLabel value="No" control={<Radio
                    onClick={(e: any) => {
                      handleUpdates(`[3].siteCharacteristicData[0].siteCharacteristicDataOptions[1].isOptionSelected`, e.target.checked,
                        "child", "[3].siteCharacteristicData[0].siteCharacteristicDataOptions", 3);
                    }}
                  />}
                    label="No"
                    checked={JSON[3]?.siteCharacteristicData[0].siteCharacteristicDataOptions[1].isOptionSelected}
                  />
                </RadioGroup>
              )}
              <FormControlLabel
                control={<Checkbox value="At-Risk_After_School_Care_Center"
                  onClick={(e: any) => {
                    handleUpdates(`[3].siteCharacteristicData[1].isSelected`, e.target.checked, "checkbox", "[3].siteCharacteristicData", 3);
                  }}
                  checked={JSON[3]?.siteCharacteristicData[1].isSelected}
                />}
                label="At-Risk After School Care Center"
                checked={true}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="Child_Care_Center"
                    onClick={(e: any) => {
                      handleUpdates(`[3].siteCharacteristicData[2].isSelected`, e.target.checked, "checkbox", "[3].siteCharacteristicData", 3);
                    }}
                    checked={JSON[3]?.siteCharacteristicData[2].isSelected}
                  />
                }
                label="Child Care Center"
                checked={true}
              />
              {JSON[3]?.siteCharacteristicData[2].isSelected && (
                <RadioGroup
                  name="RCCI_Radio"
                  style={{ marginLeft: "25px" }}
                  row={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio
                      onClick={(e: any) => {
                        handleUpdates(`[3].siteCharacteristicData[2].siteCharacteristicDataOptions[0].isOptionSelected`, e.target.checked,
                          "child", "[3].siteCharacteristicData[2].siteCharacteristicDataOptions", 3);
                      }}
                    />}
                    label="Yes"
                    checked={JSON[3]?.siteCharacteristicData[2].siteCharacteristicDataOptions[0].isOptionSelected}
                  />
                  <FormControlLabel value="No" control={<Radio
                    onClick={(e: any) => {
                      handleUpdates(`[3].siteCharacteristicData[2].siteCharacteristicDataOptions[1].isOptionSelected`, e.target.checked,
                        "child", "[3].siteCharacteristicData[2].siteCharacteristicDataOptions", 3);
                    }}
                  />}
                    label="No"
                    checked={JSON[3]?.siteCharacteristicData[2].siteCharacteristicDataOptions[1].isOptionSelected}
                  />
                </RadioGroup>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    value="Day_Care_Home"
                    onClick={(e: any) => {
                      handleUpdates(`[3].siteCharacteristicData[3].isSelected`, e.target.checked, "checkbox", "[3].siteCharacteristicData", 3);
                    }}
                    checked={JSON[3]?.siteCharacteristicData[3].isSelected}
                  />
                }
                label="Day Care Home"
                checked={true}
              />
              {JSON[3]?.siteCharacteristicData[3].isSelected && (
                <RadioGroup
                  name="RCCI_Radio"
                  style={{ marginLeft: "25px" }}
                  row={true}
                >
                  <FormControlLabel
                    value="Tier_I"
                    control={<Radio
                      onClick={(e: any) => {
                        handleUpdates(`[3].siteCharacteristicData[3].siteCharacteristicDataOptions[0].isOptionSelected`, e.target.checked,
                          "child", "[3].siteCharacteristicData[3].siteCharacteristicDataOptions", 3);
                      }}
                    />}
                    label="Tier I"
                    checked={JSON[3]?.siteCharacteristicData[3].siteCharacteristicDataOptions[0].isOptionSelected}
                  />
                  <FormControlLabel
                    value="Tier_II"
                    control={<Radio
                      onClick={(e: any) => {
                        handleUpdates(`[3].siteCharacteristicData[3].siteCharacteristicDataOptions[1].isOptionSelected`, e.target.checked,
                          "child", "[3].siteCharacteristicData[3].siteCharacteristicDataOptions", 3);
                      }}
                    />}
                    label="Tier II"
                    checked={JSON[3]?.siteCharacteristicData[3].siteCharacteristicDataOptions[1].isOptionSelected}
                  />
                  <FormControlLabel
                    value="Tier_II_Mixed"
                    control={<Radio
                      onClick={(e: any) => {
                        handleUpdates(`[3].siteCharacteristicData[3].siteCharacteristicDataOptions[2].isOptionSelected`, e.target.checked,
                          "child", "[3].siteCharacteristicData[3].siteCharacteristicDataOptions", 3);
                      }}
                    />}
                    label="Tier II Mixed"
                    checked={JSON[3]?.siteCharacteristicData[3].siteCharacteristicDataOptions[2].isOptionSelected}
                  />
                </RadioGroup>
              )}
              <FormControlLabel
                control={<Checkbox value="Head_Start"
                  onClick={(e: any) => {
                    handleUpdates(`[3].siteCharacteristicData[4].isSelected`, e.target.checked, "checkbox", "[3].siteCharacteristicData", 3);
                  }}
                  checked={JSON[3]?.siteCharacteristicData[4].isSelected}
                />}
                checked={true}
                label="Head Start"
              />
              <FormControlLabel
                control={<Checkbox value="Homeless/Emergency_Shelter"
                  onClick={(e: any) => {
                    handleUpdates(`[3].siteCharacteristicData[5].isSelected`, e.target.checked, "checkbox", "[3].siteCharacteristicData", 3);
                  }}
                  checked={JSON[3]?.siteCharacteristicData[5].isSelected}
                />}
                label="Homeless/Emergency Shelter"
                checked={true}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="Outside_School_Hours_Care_Center(OSHCC)"
                    onClick={(e: any) => {
                      handleUpdates(`[3].siteCharacteristicData[6].isSelected`, e.target.checked, "checkbox", "[3].siteCharacteristicData", 3);
                    }}
                    checked={JSON[3]?.siteCharacteristicData[6].isSelected}
                  />
                }
                checked={true}
                label="Outside School Hours Care Center(OSHCC)"
              />
              {JSON[3]?.siteCharacteristicData[6].isSelected && (
                <RadioGroup
                  name="RCCI_Radio"
                  style={{ marginLeft: "25px" }}
                  row={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio
                      onClick={(e: any) => {
                        handleUpdates(`[3].siteCharacteristicData[6].siteCharacteristicDataOptions[0].isOptionSelected`, e.target.checked,
                          "child", "[3].siteCharacteristicData[6].siteCharacteristicDataOptions", 3);
                      }}
                    />}
                    label="Yes"
                    checked={JSON[3]?.siteCharacteristicData[6].siteCharacteristicDataOptions[0].isOptionSelected}
                  />
                  <FormControlLabel value="No" control={<Radio
                    onClick={(e: any) => {
                      handleUpdates(`[3].siteCharacteristicData[6].siteCharacteristicDataOptions[1].isOptionSelected`, e.target.checked,
                        "child", "[3].siteCharacteristicData[6].siteCharacteristicDataOptions", 3);
                    }}
                  />}
                    label="No"
                    checked={JSON[3]?.siteCharacteristicData[6].siteCharacteristicDataOptions[1].isOptionSelected}
                  />
                </RadioGroup>
              )}
            </FormGroup>
          </FormControl>
        </FormControl>
      </Grid>
      {/*- End of Section 2 */}

      {/*- Section 3 */}
      <Grid item={true} xs={4}>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox value="Summer Site Eligibility"
                onClick={(e: any) => {
                  handleUpdates(`[4].isActive`, e.target.checked, "parent", "[4].siteCharacteristicData", 4);
                }}
                checked={JSON[4]?.isActive}
              />}
              label="Summer Site Eligibility"
              checked={true}
            />
            <RadioGroup
              name="Summer Site Eligibility_Radio"
              style={{ marginLeft: "25px" }}
            >
              <FormControlLabel value="Open" control={<Radio
                onClick={(e: any) => {
                  handleUpdates(`[4].siteCharacteristicData[0].isSelected`, e.target.checked, "radio", "[4].siteCharacteristicData", 4);
                }}
              />}
                label="Open"
                checked={JSON[4]?.siteCharacteristicData[0].isSelected}
              />
              <FormControlLabel
                value="Restricted_Open"
                control={<Radio
                  onClick={(e: any) => {
                    handleUpdates(`[4].siteCharacteristicData[1].isSelected`, e.target.checked, "radio", "[4].siteCharacteristicData", 4);
                  }}
                />}
                label="Restricted Open"
                checked={JSON[4]?.siteCharacteristicData[1].isSelected}
              />

              <FormControlLabel
                value="Closed_Enrolled"
                control={<Radio
                  onClick={(e: any) => {
                    handleUpdates(`[4].siteCharacteristicData[2].isSelected`, e.target.checked, "radio", "[4].siteCharacteristicData", 4);
                  }}
                />}
                label="Closed Enrolled"
                checked={JSON[4]?.siteCharacteristicData[2].isSelected}


              />
              <FormControlLabel value="Camp" control={<Radio
                onClick={(e: any) => {
                  handleUpdates(`[4].siteCharacteristicData[3].isSelected`, e.target.checked, "radio", "[4].siteCharacteristicData", 4);
                }}
              />}
                label="Camp"
                checked={JSON[4]?.siteCharacteristicData[3].isSelected}
              />
              <FormControlLabel
                value="Migrant_Site"
                control={<Radio
                  onClick={(e: any) => {
                    handleUpdates(`[4].siteCharacteristicData[4].isSelected`, e.target.checked, "radio", "[4].siteCharacteristicData", 4);
                  }}
                />}
                label="Migrant Site"
                checked={JSON[4]?.siteCharacteristicData[4].isSelected}

              />
              <FormControlLabel
                value="National_Youth_Sports_Program"
                control={<Radio
                  onClick={(e: any) => {
                    handleUpdates(`[4].siteCharacteristicData[5].isSelected`, e.target.checked, "radio", "[4].siteCharacteristicData", 4);
                  }}
                />}
                label="National Youth Sports Program"
                checked={JSON[4]?.siteCharacteristicData[5].isSelected}

              />
            </RadioGroup>
          </FormGroup>
        </FormControl>
      </Grid>
      {/*- End of Section 3 */}
    </Grid>
  );
};

export default SiteCharacteristics;
