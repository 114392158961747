import { useEffect, useState } from 'react';
import { isNull, isNil } from 'lodash';
import { useProfile } from '@primeroedge/ui-components';
import { State } from "@progress/kendo-data-query";
import { GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import CustomReportTransport from 'control/transport/customReports.transport';

const useFetchReportSqlData = function (props: any) {
    const { user } = useProfile();
    const [sqlData, setSqlData] = useState<any>(null);
    const [sqlDataError, setSqlDataError] = useState<any>(null);
    const [sqlColumns, setSqlColumns] = useState<{
        field: string;
        width: string;
        title: string;
    }[]>([]);

    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [gridState, setGridState] = useState<State>({
        take: 10,
    });

    const generateColumns = function (rowData: { [key: string]: any }) {
        const columns = Object.keys(rowData);
        const gridColumns = columns.map((item: string) => ({
            field: item,
            width: `${99.5 / columns.length}%`,
            title: item,
        }));
        setSqlColumns(gridColumns);
    }

    const getSqlData = async function () {
        try {
            if (!isNil(props.moduleName)) {
                // const piiStatus = !isNil(formPII) ? formPII : !isNil(reportsMetaData?.isPIIExists) ? reportsMetaData.isPIIExists : false;
                const piiStatus = props.containsPii ? user?.IsPIIEnabled : true;
                if (piiStatus) {
                    const response = await CustomReportTransport.getSqlData(props.sql, props.moduleName);
                    if (!isNull(response?.ResponseEnvelope?.ValidationCollection)) {
                        setSqlDataError(response?.ResponseEnvelope?.ValidationCollection[0].Description);
                        setSqlData([]);
                        setSqlColumns([]);
                    } else {
                        try {
                            const parsedData = JSON.parse(response?.PayLoad?.data);
                            setSqlData(parsedData);
                            setSqlDataError(null);
                            if (parsedData?.length > 0) {
                                generateColumns(parsedData[0]);
                            }
                        }
                        catch (e) {
                            console.log('e', e)
                        }
                    }
                } else {
                    setSqlDataError('User cannot view information containing Personal Information!');
                    setSqlData([]);
                }
            } else {
                setSqlDataError('Module not configured. Please configure this module!');
                setSqlData([]);
                setSqlColumns([]);
            }
        } catch (e) {
        } finally {
            setIsDataLoading(false);
        }
    }

    const handleDataStateChange = (ev: GridDataStateChangeEvent) => {
        setGridState(ev.dataState);
    };


    useEffect(function () {
        if (!isNil(props.moduleName)) {
            setIsDataLoading(true);
            getSqlData();
        }
    }, [props]);

    return {
        gridState,
        sqlData,
        sqlColumns,
        isDataLoading,
        sqlDataError,
        getSqlData,
        handleDataStateChange
    }
}


export default useFetchReportSqlData;