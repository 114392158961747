import { IGridDataState } from "@primeroedge/ui-components";
import { createAction } from "@reduxjs/toolkit";
import { IBroadcastMessage, IFetchBroadcastPayload, ResponseStatus } from "../../model/entity";

export const FetchBroadcastMessages = createAction(
    "FETCH_BROADCAST_MESSAGES",
    (data: IFetchBroadcastPayload) => ({
        payload: data,
    }),
);

export const SetBroadcastMessages = createAction(
    "SET_BROADCAST_MESSAGES",
    (data: IBroadcastMessage[]) => ({
        payload: data,
    }),
);

export const SetBroadcastGridState = createAction(
    "SET_BROADCAST_GRID_STATE",
    (data: IGridDataState) => ({
        payload: data,
    }),
);

export const SetBroadcastTotalMessageCount = createAction(
    "SET_BROADCAST_TOTAL_MESSAGE_COUNT",
    (data: number) => ({
        payload: data,
    }),
);

export const SetBroadcastTotalPages = createAction(
    "SET_BROADCAST_TOTAL_PAGES",
    (data: number) => ({
        payload: data,
    }),
);

export const SetBroadcastStatus = createAction(
    "SET_BROADCAST_STATUS",
    (status: ResponseStatus) => ({
        payload: status,
    }),
);

export const DeleteBroadcast = createAction(
    "DELETE_BROADCAST",
    (id: string) => ({
        payload: id,
    }),
);

export const DeleteAllBroadcast = createAction(
    "DELETE_BROADCAST_ALL",
    (ids: any) => ({
      payload: ids,
    }),
  );
