import { APIInstance } from "@primeroedge/ui-components";
import { API_MENU_PLANNING, APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY } from "./api-constants";

const menuPlanningAPIClient = new APIInstance({
    baseURL: API_MENU_PLANNING,
    environment: APIM_ENVIRONMENT,
    subscriptionKey: APIM_SUBSCRIPTION_KEY,
}).axios;

export default menuPlanningAPIClient;
