import { IAppState } from "../reducers";

export interface IWelcomeState {
    isLoading: boolean;
    welcomeList: IWelcomeList[];
    activitiesList: IActivitiesList[];
}

export interface IWelcomeList {
    districtOnboardingId: number;
    onboardingFeatureId: number;
    regionId: number;
    moduleId: number;
    moduleName: string;
    moduleType: string;
    feature: string;
    featureStatus: string;
    isFeatureSelected: boolean;
    featureDueBy: string;
}
export interface IActivitiesList {
    districtOnboardingId: number;
    onboardingFeatureId: number;
    regionId: number;
    moduleId: number;
    moduleName: string;
    moduleType: string;
    feature: string;
    featureStatus: string;
    isFeatureSelected: boolean;
    featureDueBy: string;
}

export enum resourceType {
    addResource = "Add",
    editResource = "Edit",
}

export enum ResourceStatus {
    initial = "Initial",
    success = "Success",
    fail = "Fail",
    deleted = "Deleted",
}

export function getWelcome ({ welcome }: IAppState) {
    return welcome.welcomeList;
}

export function getIsLoading ({ welcome }: IAppState) {
    return welcome.isLoading;
}

export function getActivities ({ welcome }: IAppState) {
    return welcome.activitiesList;
}
