import { Grid, makeStyles } from "@material-ui/core";
import {
    AccountStatus,
    Alert, AppLoader, Button, InfoIcon, Notification, NotificationWithDialog, PageAlert, SummaryList, useProfile, UserCard,
} from "@primeroedge/ui-components";
import Axios from "axios";
import { getRemovePictureInit, getUserDetailsKeyclockInit, resetProfileUpdateStatus, saveProfileInfoAction } from "control/actions";
import { API_DATA_EXCHANGE, APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY } from "control/transport/clients/api-constants";
import _, { isNil } from "lodash";
import {
    checkProfileUpdateStatus,
    ContactInfoFields, getIsUserLoading, getUserDetailsKeyclockEntity, IAdminServiceUserDetails, IProfileErrors,
    IUserMgmatUserDetails, IUserProfile,
} from "model/entity/profile.entity";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditContactInfo from "./edt-contact-info";
import { saveImage } from "./profile.utils";
import RolesInfo from "./roles";
import useProfileImageSaver from "hooks/useProfileImageSaver";
import TourIdentifierElement from "./TourIdentifierElement";

const styles = makeStyles(() => ({
    controlsRoot: {
        display: "flex",
        justifyContent: "space-between",
    },
    hideFileInput: {
        display: "none",
    },
    loaderContainer: {
        width: "100%",
        height: "60vh",
        padding: "10px",
    },
}));

interface IProfileProps {
    setTabsBool: any;
    setCancelUserConfirmation: any;
    cancelUserConfirmation: boolean;
    isEditable: boolean;
    canEdit: any;
}

const ProfilePage = (props: IProfileProps) => {
    const {
        setTabsBool,
        setCancelUserConfirmation,
        cancelUserConfirmation,
        canEdit,
        isEditable,
    } = props;
    const dispatch = useDispatch();
    const { user }: any = useProfile();
    const [profileImageUrl, setProfileImageUrl] = useState<any>(null);
    const isUserLoading = useSelector(getIsUserLoading);

    const [profile, setProfile] = useState<IUserProfile>({
        attributes: null,
        accessLevelDescription: "",
        authenticationMode: null,
        regions: null,
        roles: null,
        area: null,
        sites: null,
        enabled: false,
        regionID: null,
        accessLevelId: 0,
        cellPhone: "",
        createdUserId: 0,
        creationDate: "",
        defaultRealmId: 0,
        defaultRegionId: 0,
        email: "",
        firstName: "",
        image: "",
        languagepreference: null,
        lastName: "",
        mi: "",
        primeroUserId: "",
        regionId: 0,
        regionName: null,
        theme: null,
        totalRecords: 0,
        updateBy: null,
        updateDate: "",
        userId: 0,
        userName: "",
        userStatusDescription: "",
        userStatusId: 0,
        windowsUserDomain: "",
        windowsUserName: "",
        workPhone: "",
        keyCloakID: null,
        navigateOption: "",
    });
    const [editableProfile, setEditedProfile] = useState<IUserProfile>({
        attributes: null,
        accessLevelDescription: "",
        authenticationMode: null,
        regions: null,
        roles: null,
        area: null,
        sites: null,
        enabled: false,
        regionID: null,
        accessLevelId: 0,
        cellPhone: "",
        createdUserId: 0,
        creationDate: "",
        defaultRealmId: 0,
        defaultRegionId: 0,
        email: "",
        firstName: "",
        image: "",
        languagepreference: null,
        lastName: "",
        mi: "",
        primeroUserId: "",
        regionId: 0,
        regionName: null,
        theme: null,
        totalRecords: 0,
        updateBy: null,
        updateDate: "",
        userId: 0,
        userName: "",
        userStatusDescription: "",
        userStatusId: 0,
        windowsUserDomain: "",
        windowsUserName: "",
        workPhone: "",
        keyCloakID: null,
        navigateOption: ""
    });
    const [profileErrors, setProfileErrors] = useState<IProfileErrors>({
        firstNameError: false,
        lastNameError: false,
        workPhoneError: false,
    });
    const [isApiFetchError, setApiFetchError] = useState(false);
    const [showAlert, canShowAlert] = useState<"success" | "error" | undefined>();
    const userProfile = useSelector(getUserDetailsKeyclockEntity);
    const updateStatus = useSelector(checkProfileUpdateStatus);
    const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
    const fileUploadRef = useRef<HTMLInputElement | null>(null);
    const [file, setFile] = React.useState("");
    const classes = styles();
    const [showImageFormatError, canShowImageFormatError] = useState(false);
    // const [passwordAlert, setPasswordAlert] = useState<boolean>(false);
    const activeDistrict = localStorage.getItem("activeDistrict");
    const [selectedDistrict, setSelectedDistrict] = useState<any>({});
    const [keyCloakData, setKeyCloakData] = useState<IUserMgmatUserDetails[]>([]);
    const [adminData, setAdminData] = useState<IAdminServiceUserDetails>();
    const [showImageSizeError, canShowImageSizeError] = useState(false);
    const [removePictureCDonfirmation, setRemovePictureConfirmation] = useState<boolean>(false);
    const [alertStatus, setAlertStatus] = React.useState<"success" | "error" | "default" | "primary" | "secondary" | "warning" | "info" | undefined>(undefined);
    const [showPictureAlert, setPictureAlert] = React.useState<boolean>(false);
    const [pictureAlertMessage, setPictureAlertMessage] = React.useState<string>("");
    const [removePicture, setRemovePicture] = useState<boolean>(false);

    let groupsObj = {};
    let groupsObj1 = {};
    let rolesObj = {};
    let rolesObj1 = {};

    const onUserProfileWorkerMessageSuccess = function (result: any) {
        if (result) {
            localStorage.setItem("rememberMeProfileImage", result);
        } else {
            localStorage.removeItem("rememberMeProfileImage");
        }
    };

    const {
        sendMessage,
    } = useProfileImageSaver({
        onMessageSuccess: onUserProfileWorkerMessageSuccess,
    });

    useEffect(() => {
        if (activeDistrict) {
            setSelectedDistrict(JSON.parse(activeDistrict));
        }
        setTabsBool(true);
    }, [activeDistrict, setTabsBool]);

    useEffect(() => {
        if (keyCloakData.length > 0) {
            for (let i = 0; i < _.flatten(keyCloakData.map((a) => a.groups)).length; i++) {
                if (_.flatten(keyCloakData.map((group) => group.groups)).map((a) => a.name)[i]) {
                    _.flatten(keyCloakData.map((group) => group.groups)).map((groupName) => {
                        groupsObj = {
                            [groupName.groupGuid]: groupName.name,
                        };
                        groupsObj1 = { ...groupsObj, ...groupsObj1 };
                    });
                }
            }
            for (let r = 0; r < _.flatten(keyCloakData.map((role) => role.roles)).length; r++) {
                if (_.flatten(keyCloakData.map((roleName) => roleName.roles)).map((rName) => rName.name)[r]) {
                    _.flatten(keyCloakData.map((roleName) => roleName.roles)).map((roleGroup) => {
                        rolesObj = {
                            [roleGroup.roleId]: roleGroup.name,
                        };
                        rolesObj1 = { ...rolesObj, ...rolesObj1 };
                    });
                }
            }
        }
    }, [rolesObj1, groupsObj1]);

    useEffect(() => {
        if (user) {
            dispatch(getUserDetailsKeyclockInit(user.Email));
        }
    }, [dispatch, user]);

    useEffect(() => {
        if (userProfile.UserMgmatUserDetails || userProfile.AdminServiceUserDetails) {
            setKeyCloakData(userProfile.UserMgmatUserDetails.PayLoad);
            setAdminData(userProfile.AdminServiceUserDetails.PayLoad);
        }
    }, [userProfile]);


    useEffect(() => {
        if (keyCloakData.length > 0 && adminData) {
            setEditedProfile({
                attributes: keyCloakData.map((a) => a.attributes),
                accessLevelDescription: "accessLevelDescription",
                authenticationMode: "authenticationMode",
                regions: "regions",
                roles: "roles",
                area: "areas",
                sites: "sites",
                enabled: true,
                regionID: 0,
                accessLevelId: 0,
                cellPhone: adminData.cellPhone,
                createdUserId: 0,
                creationDate: "creationDate",
                defaultRealmId: 0,
                defaultRegionId: 0,
                email: keyCloakData.map((a) => a.email).toString(),
                firstName: keyCloakData.map((first) => first.firstName).toString(),
                image: adminData.image,
                languagepreference: adminData.languagepreference,
                lastName: keyCloakData.map((last) => last.lastName).toString(),
                mi: adminData.mi,
                primeroUserId: keyCloakData.map((userId) => userId.primeroUserId).toString(),
                regionId: 0,
                regionName: selectedDistrict.name,
                theme: adminData.theme,
                totalRecords: 0,
                updateBy: "updateBy",
                updateDate: "updateDate",
                userId: adminData.userId,
                userName: keyCloakData.map((a) => a.email).toString(),
                userStatusDescription: _.flatten(keyCloakData.map((a) => a.attributes).map((s) => s.userStatus)).toString(),
                userStatusId: 0,
                windowsUserDomain: "windowsUserDomain",
                windowsUserName: "windowsUserName",
                workPhone: adminData.workPhone,
                keyCloakID: user && user.UserGuid,
                navigateOption: adminData.navigateOption,
                dashboardV2: adminData.dashboardV2,
                showBanner: adminData.showBanner,
                audioPing: adminData.audioPing,
                bannerLocation: adminData.bannerLocation,
                bannerDuration: adminData.bannerDuration
            });
            setProfile({
                attributes: keyCloakData.map((a) => a.attributes),
                accessLevelDescription: "accessLevelDescription",
                authenticationMode: "authenticationMode",
                regions: "regions",
                roles: "roles",
                area: "areas",
                sites: "sites",
                enabled: true,
                regionID: 0,
                accessLevelId: 0,
                cellPhone: adminData.cellPhone,
                createdUserId: 0,
                creationDate: "creationDate",
                defaultRealmId: 0,
                defaultRegionId: 0,
                email: keyCloakData.map((a) => a.email).toString(),
                firstName: keyCloakData.map((first) => first.firstName).toString(),
                image: adminData.image,
                languagepreference: adminData.languagepreference,
                lastName: keyCloakData.map((last) => last.lastName).toString(),
                mi: adminData.mi,
                primeroUserId: keyCloakData.map((userId) => userId.primeroUserId).toString(),
                regionId: 0,
                regionName: selectedDistrict.name,
                theme: adminData.theme,
                totalRecords: 0,
                updateBy: "updateBy",
                updateDate: "updateDate",
                userId: adminData.userId,
                userName: keyCloakData.map((a) => a.email).toString(),
                userStatusDescription: _.flatten(keyCloakData.map((a) => a.attributes).map((s) => s.userStatus)).toString(),
                userStatusId: 0,
                windowsUserDomain: "windowsUserDomain",
                windowsUserName: "windowsUserName",
                workPhone: adminData.workPhone,
                keyCloakID: user && user.UserGuid,
                navigateOption: adminData.navigateOption,
                dashboardV2: adminData.dashboardV2,
                showBanner: adminData.showBanner,
                audioPing: adminData.audioPing,
                bannerLocation: adminData.bannerLocation,
                bannerDuration: adminData.bannerDuration
            });

            if (adminData.image !== "" && adminData.image !== null) {
                if (adminData.image.toLowerCase() !== "null") {
                    downloadImage(adminData.image);
                }
            }

        } else if (!keyCloakData) {
            setApiFetchError(() => true);
        }
    }, [keyCloakData, adminData]);
    useEffect(() => {
        setProfile(editableProfile);
    }, [editableProfile]);

    const onFileUploadTrigger = () => {
        if (fileUploadRef.current) {
            fileUploadRef.current.click();
        }
    };

    const onFileUpload = (event: any) => {
        if (event.target.files.length > 0 && event.target.files[0].type.includes("image")) {
            if (event.target.files[0].size < 5242880) {
                setFile(event.target.files[0]);
            } else {
                canShowImageSizeError(() => true);
            }
        } else {
            canShowImageFormatError(() => true);
        }
    };

    const onProfileChange = (name: ContactInfoFields, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        event.preventDefault();
        event.persist();
        switch (name) {
            case ContactInfoFields.CellPhone:
                setEditedProfile((prevState) => ({
                    ...prevState,
                    [name]: event.target.value,
                }));
                break;
            case ContactInfoFields.WorkPhone:
                setEditedProfile((prevState) => ({
                    ...prevState,
                    [name]: event.target.value,
                }));
                setProfileErrors((prevState) => {
                    return ({
                        ...prevState,
                        workPhoneError: event.target.value.length < 12,
                    });
                });
                break;
            case ContactInfoFields.FirstName:
                setEditedProfile((prevState) => ({
                    ...prevState,
                    [name]: event.target.value,
                }));
                setProfileErrors((prevState) => {
                    return ({
                        ...prevState,
                        firstNameError: event.target.value === "",
                    });
                });
                break;
            case ContactInfoFields.MiddleName:
                setEditedProfile((prevState) => ({
                    ...prevState,
                    [name]: event.target.value,
                }));
                break;
            case ContactInfoFields.LastName:
                setEditedProfile((prevState) => ({
                    ...prevState,
                    [name]: event.target.value,
                }));
                setProfileErrors((prevState) => {
                    return ({
                        ...prevState,
                        lastNameError: event.target.value === "",
                    });
                });
                break;
        }
    };

    const checkForValue = (object: { [key: string]: any }, property: string) => {
        if (Object.keys(object).length > 0 && object[property]) {
            return object[property];
        } else {
            return "";
        }
    };

    const onSaveProfile = () => {
        setDisableSaveButton(true);
        setTabsBool(true);
        let error: boolean = false;
        if (profileErrors.firstNameError || profileErrors.lastNameError || profileErrors.workPhoneError) {
            error = true;
        } else {
            error = false;
        }
        if (removePicture) {
            setEditedProfile({
                ...editableProfile,
                image: "",
            });
            setProfile({
                ...profile,
                image: "",
            });
            setFile("");
            if (!isNil(localStorage.getItem('rememberedUser'))) {
                sendMessage("");
            }
        }

        if (!error) {
            if (file) {
                saveImage(file).then((data: any) => {
                    const payload = {
                        id: editableProfile.keyCloakID,
                        firstName: editableProfile.firstName,
                        lastName: editableProfile.lastName,
                        email: editableProfile.email,
                        roles: rolesObj1,
                        groups: groupsObj1,
                        attributes: _.head(editableProfile.attributes),
                        districtName: selectedDistrict.name,
                        userId: editableProfile.userId,
                        mi: editableProfile.mi,
                        languagepreference: editableProfile?.languagepreference ? editableProfile?.languagepreference?.toString() : "English",
                        theme: editableProfile.theme ? editableProfile.theme : "Perseus",
                        workPhone: editableProfile.workPhone,
                        cellPhone: editableProfile.cellPhone,
                        image: `${data[0].blobName}`,
                        userStatus: editableProfile.userStatusDescription,
                        navigateOption: editableProfile.navigateOption,
                        enabled: true,
                        dashboardV2: editableProfile.dashboardV2,
                        showBanner: editableProfile.showBanner,
                        audioPing: editableProfile.audioPing,
                        bannerLocation: editableProfile.bannerLocation,
                        bannerDuration: editableProfile.bannerDuration
                    };
                    dispatch(saveProfileInfoAction(payload));
                    setEditedProfile((prevState) => ({
                        ...prevState,
                        image: `${encodeURIComponent(data[0].blobName)}`,
                    }));
                    downloadImage(data[0].blobName);
                }).catch(() => {
                    canShowAlert(() => "error");
                });
                if (!isNil(localStorage.getItem('rememberedUser'))) {
                    sendMessage(file);
                }
            } else {
                const payload = {
                    id: editableProfile.keyCloakID,
                    firstName: editableProfile.firstName,
                    lastName: editableProfile.lastName,
                    email: editableProfile.email,
                    roles: rolesObj1,
                    groups: groupsObj1,
                    attributes: _.head(editableProfile.attributes),
                    districtName: selectedDistrict.name,
                    userId: editableProfile.userId,
                    mi: editableProfile.mi,
                    languagepreference: editableProfile?.languagepreference?.toString(),
                    theme: editableProfile.theme,
                    workPhone: editableProfile.workPhone,
                    cellPhone: editableProfile.cellPhone,
                    image: editableProfile.image,
                    userStatus: editableProfile.userStatusDescription,
                    enabled: true,
                    navigateOption: editableProfile.navigateOption,
                    dashboardV2: editableProfile.dashboardV2,
                    showBanner: editableProfile.showBanner,
                    audioPing: editableProfile.audioPing,
                    bannerLocation: editableProfile.bannerLocation,
                    bannerDuration: editableProfile.bannerDuration
                };
                dispatch(saveProfileInfoAction(payload));
            }
        }
    };

    useEffect(() => {
        if (!isNil(updateStatus)) {
            setDisableSaveButton(false);
            if (updateStatus) {
                canShowAlert(() => "success");
                dispatch(resetProfileUpdateStatus());
                canEdit(false);
            } else {
                canShowAlert(() => "error");
                dispatch(resetProfileUpdateStatus());
                canEdit(false);
            }
        }
    }, [canEdit, dispatch, updateStatus]);

    const getPhoneNumberPattern = (phone: string) => {
        if (phone.length === 12) {
            return `+1 (${phone.slice(0, 3)}) ${phone.slice(4, 7)}-${phone.slice(8, 12)}`;
        } else if (phone.length === 17) {
            return `+1 ${phone}`;
        } else {
            return "";
        }
    };

    const onEditCancelPopUp = () => {
        setCancelUserConfirmation(true);
    };

    const onEditCancel = () => {
        setTabsBool(true);
        setCancelUserConfirmation(false);
        canEdit(false);
        setFile("");
        if (user) {
            dispatch(getUserDetailsKeyclockInit(user.Email));
        }
        // setPasswordAlert(false);
    };

    const downloadImage = (blob: string) => {
        const fileURL = `${API_DATA_EXCHANGE}/api/FileStorage/Download?featureName=user&fileName=${blob}`;

        Axios.get(fileURL, {
            responseType: "blob",
            headers: {
                Environment: APIM_ENVIRONMENT,
                "Ocp-Apim-Subscription-Key": APIM_SUBSCRIPTION_KEY,
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": `application/octet-stream`,
            },
        })
            .then((resp) => {
                const dwnldlink = window.URL.createObjectURL(
                    new Blob([resp.data], {
                        type: resp.headers["content-type"],
                    }),
                );
                setProfileImageUrl(dwnldlink);
            })
            .catch((ex) => {
                console.error(ex);
            });
    };

    const handleEdit = () => {
        canEdit(true);
        setTabsBool(false);
    };

    const confirmRemovePicturePopup = () => {
        if (file || profile.image) {
            setRemovePictureConfirmation(true);
        }
    };

    const onFileRemoveTrigger = () => {
        if (profile.image !== "") {
            dispatch(getRemovePictureInit({ payload: profile.image, action: handleRemoveSuccess }));
            setEditedProfile({
                ...editableProfile,
                image: "",
            });
            setProfile({
                ...profile,
                image: "",
            });

            setFile("");
            setProfileImageUrl("");
        } else if (file) {
            saveImage(file).then((data: any) => {
                dispatch(getRemovePictureInit({ payload: data[0].blobName, action: handleRemoveSuccess }));
                setFile("");
            });
        }
        setRemovePictureConfirmation(false);
    };

    const handleRemoveSuccess = (response: any) => {
        if (response.StatusCode === 200) {
            setPictureAlertMessage("File deleted successfully");
            setAlertStatus("success");
            setPictureAlert(true);
            setRemovePicture(true);
            setEditedProfile({
                ...editableProfile,
                image: "",
            });
            setProfile({
                ...profile,
                image: "",
            });
            setFile("");
        } else {
            setRemovePicture(false);
        }
        setTimeout(() => {
            setPictureAlert(false);
            setPictureAlertMessage("");
            setAlertStatus(undefined);
        }, 5000);
    };

    const handleFileUploadClick = (event: any) => {
        event.target.value = "";
    };

    return (
       
        <div>
            <div style={{display: "flex", justifyContent: 'ceneter'}}>
            </div>
            {showAlert === "success" || showAlert === "error" ? (
                <Notification
                    color={showAlert === "success" ? "success" : "error"}
                    duration={5000}
                    message={showAlert === "success" ? "Profile updated successfully" : "Error in updating profile."}
                    open={!!showAlert}
                    onClose={() => canShowAlert(() => undefined)}
                    variant="filled"
                />
            ) : ""}
            {showImageFormatError && (
                <div style={{ margin: "10px 0" }}>
                    <Alert
                        color={"warning"}
                        message={"Profile image only accepts PNG, JPG, SVG format."}
                        onClose={() => canShowImageFormatError(() => false)}
                        open={showImageFormatError}
                        variant="filled"
                    />
                </div>
            )}
            {showImageSizeError && (
                <Notification
                    color="warning"
                    message="Please select image size less than 5 MB"
                    onClose={() => canShowImageSizeError(() => false)}
                    open={showImageSizeError}
                    variant="filled"
                />
            )}
            {showPictureAlert &&
                <Notification
                    color={alertStatus}
                    message={pictureAlertMessage}
                    onClose={() => setPictureAlert(() => false)}
                    open={showPictureAlert}
                    variant="filled"
                />
            }
            {!user && (
                <div className={classes.loaderContainer}>
                    <AppLoader />
                </div>
            )}
            {isUserLoading ?
                <Grid container={true} spacing={2}>
                    <Grid xs={12} item={true} className={classes.loaderContainer}>
                        {<AppLoader />}
                    </Grid>
                </Grid>
                :
                (keyCloakData && user && adminData) && (
                    <>
                    <Grid item={true} xs={12} md={12} sm={12} lg={12} style={{justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                            <TourIdentifierElement uniqueElementId={"workspace-profile-tour"} />
                    </Grid>
                    <Grid container={true} spacing={2}>
                        <Grid item={true} xs={12} md={12} sm={12} lg={12}>
                            <Alert
                                color="primary"
                                message="To change your password or settings, please select the edit button first."
                                onClose={null}
                                open
                                style={{ width: '50%' }}
                                icon={<InfoIcon />}
                            />
                        </Grid>
                        
                        <Grid xs={12} md={4} lg={3} item={true}>
                           
                            <UserCard
                                imageSrc={isEditable && file ? URL.createObjectURL(file) : profileImageUrl}
                                isUserCard={true}
                                name={editableProfile ? `${checkForValue(editableProfile, "firstName")} ${checkForValue(editableProfile, "lastName")}` : "N/A"}
                                status={
                                    checkForValue(editableProfile, "userStatusDescription") !== "Disabled"
                                        ? AccountStatus.Active
                                        : AccountStatus.InActive
                                }
                                statusText={
                                    checkForValue(editableProfile, "userStatusDescription") !== "Disabled"
                                        ? "Active"
                                        : "Disabled"
                                }
                                actionButton={
                                    isEditable ?
                                        () => (
                                            <>
                                                <input
                                                    id="upload-picture"
                                                    name="upload-picture"
                                                    ref={fileUploadRef}
                                                    type="file"
                                                    onChange={onFileUpload}
                                                    className={classes.hideFileInput}
                                                    onClick={handleFileUploadClick}
                                                    aria-label="upload-picture"
                                                />
                                                <Button
                                                    id={"upload-user-picture"}
                                                    color={"default"}
                                                    onClick={onFileUploadTrigger}
                                                    dense={true}
                                                    label={"Upload Picture"}
                                                />
                                                <span style={{ marginTop: "20px" }}>
                                                    <Button
                                                        color="default"
                                                        dense={true}
                                                        id="uploadEditButton"
                                                        label="Remove Picture"
                                                        name="Button"
                                                        onClick={confirmRemovePicturePopup}
                                                        type="button"
                                                        variant="default"
                                                    />
                                                </span>
                                            </>
                                        )
                                        : () => (
                                            <Button
                                                id={"edit-user"}
                                                color={"primary"}
                                                label={"Edit"}
                                                onClick={handleEdit}
                                            />
                                        )
                                }
                            />
                        </Grid>
                        <Grid xs={12} md={8} lg={9} item={true}>
                            <Grid container={true} spacing={1}>
                                <Grid item={true} xs={12}>
                                    {isEditable ? (
                                        <EditContactInfo
                                            errors={profileErrors}
                                            profile={editableProfile as IUserProfile}
                                            onChange={onProfileChange}
                                        />
                                    ) : (
                                        <SummaryList
                                            header="Contact Info"
                                            canEdit={false}
                                            items={[
                                                {
                                                    title: "Name",
                                                    value: editableProfile ? `${checkForValue(editableProfile, "firstName")} ${checkForValue(editableProfile, "lastName")}` : "N/A",
                                                    type: "text",
                                                },
                                                {
                                                    title: "Email",
                                                    value: editableProfile ? `${checkForValue(editableProfile, "email")}` : "N/A",
                                                    type: "text",
                                                },
                                                {
                                                    title: "Cell Phone",
                                                    value: getPhoneNumberPattern(checkForValue(editableProfile, "cellPhone")),
                                                    type: "text",
                                                },
                                                {
                                                    title: "Work Phone",
                                                    value: getPhoneNumberPattern(checkForValue(editableProfile, "workPhone")),
                                                    type: "text",
                                                },
                                            ]}
                                        />
                                    )
                                    }
                                </Grid>
                                <Grid item={true} xs={12} style={{ marginTop: 20 }}>
                                        <RolesInfo
                                            roles={userProfile && userProfile.UserMgmatUserDetails && userProfile.UserMgmatUserDetails?.PayLoad?.[0]?.roles
                                                ? userProfile?.UserMgmatUserDetails?.PayLoad?.[0].roles.reduce((acc: string, cur: any) => `${acc.length > 0 ? `${acc},` : acc} ${cur.name}`, "")
                                                : "N/A"}
                                            access={user && user.Groups ? user.Groups : []}
                                            sites={user && user?.Sites ? user?.Sites : []}
                                        />
                                </Grid>
                                {isEditable && (
                                    <Grid
                                        xs={12}
                                        item={true}
                                        classes={{ root: classes.controlsRoot }}
                                        style={{ marginTop: 20 }}
                                    >
                                        <Button
                                            id="cancel-profile-edit"
                                            color="default"
                                            onClick={onEditCancelPopUp}
                                        >
                                            CANCEL
                                        </Button>
                                        <Button disabled={disableSaveButton} id="ave-profile-edit" color="primary" onClick={onSaveProfile}>SAVE CHANGES</Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    </>
                )}
            {isApiFetchError && (
                <PageAlert title="Network Error" message="Unable to retrieve data. Please try again later." />
            )}
            {cancelUserConfirmation && (
                <NotificationWithDialog
                    message="Are you sure you want to cancel?"
                    open={true}
                    primaryAction={{
                        callback: onEditCancel,
                        title: "Yes",
                    }}
                    secondaryAction={{
                        callback: () => setCancelUserConfirmation(false),
                        title: "No",
                    }}
                    title=""
                />
            )}
            {removePictureCDonfirmation && (
                <NotificationWithDialog
                    message="Are you sure you want to remove the picture?"
                    open={true}
                    primaryAction={{
                        callback: onFileRemoveTrigger,
                        title: "Yes",
                    }}
                    secondaryAction={{
                        callback: () => setRemovePictureConfirmation(false),
                        title: "No",
                    }}
                    title=""
                />
            )}
        </div>
    );
};

export default ProfilePage;
