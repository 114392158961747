import { APIInstance } from "@primeroedge/ui-components";
import { API_ACCOUNT_MANAGEMENT, APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY } from "./api-constants";

const accountManagementAPIClient = new APIInstance({
    baseURL: API_ACCOUNT_MANAGEMENT,
    environment: APIM_ENVIRONMENT,
    subscriptionKey: APIM_SUBSCRIPTION_KEY,
}).axios;

export default accountManagementAPIClient;
