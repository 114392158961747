import React from 'react'
import smartViewStyles from '../smartViewStyles';
import { ModuleName, getModuleIcon } from '@primeroedge/ui-components';
import { Box, Typography } from '@material-ui/core';

export const moduleNameIcons: Record<string, any> = {
    [ModuleName.AccountManagement]: getModuleIcon(ModuleName.AccountManagement),
    [ModuleName.Accountability]: getModuleIcon(ModuleName.Accountability),
    [ModuleName.Eligibility]: getModuleIcon(ModuleName.Eligibility),
    [ModuleName.Insights]: getModuleIcon(ModuleName.Insights),
    [ModuleName.Inventory]: getModuleIcon(ModuleName.Inventory),
    [ModuleName.ItemManagement]: getModuleIcon(ModuleName.ItemManagement),
    [ModuleName.MenuPlanning]: getModuleIcon(ModuleName.MenuPlanning),
    [ModuleName.Production]: getModuleIcon(ModuleName.Production),
    [ModuleName.Reports]: getModuleIcon(ModuleName.Reports),
    [ModuleName.System]: getModuleIcon(ModuleName.System),
    [ModuleName.Workspace]: getModuleIcon(ModuleName.Workspace)
}

export default function SmartViewPageDisplay({
    onClick,
    pageName,
    moduleName,
    displayColor
}: {
    onClick: () => void;
    pageName: string;
    moduleName: string;
    displayColor: string;
}) {

    const classes = smartViewStyles();
    const Comp = moduleNameIcons[moduleName];
    return (<Box className={classes.itemContainer}
        onClick={onClick ?? (() => { })}
    >
        <Box className={classes.items} style={{ backgroundColor: displayColor }}>
            {<Comp  selected={'white'} size={30} />}
        </Box>
        <Typography variant="body1" align="center" color="textPrimary" style={{ lineHeight: '1.2', fontSize: 12, fontWeight: 500 }}>
            {pageName}
        </Typography>
    </Box>)
}