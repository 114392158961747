import React from 'react';
import { Menu, MenuItem } from "@material-ui/core";
import { IconButton, MoreVerticalIcon } from "@primeroedge/ui-components";
import { useFavoriteReportMenu } from "./hooks/useFavoriteReportMenu";

const DistributedReportMenu = (props: any) => {

    const {
        handleFavoriteMenuClick,
        favoritesMenuAnchorElement,
        sendEmail, 
        handleFavoriteMenuClose
    } = useFavoriteReportMenu(props);
    return (
        <>
            <IconButton
                aria-controls="messagesMenu"
                aria-haspopup="true"
                onClick={handleFavoriteMenuClick}
                size="small"
            >
                <MoreVerticalIcon />
            </IconButton>
            <Menu
                id="messagesMenu"
                anchorEl={favoritesMenuAnchorElement}
                keepMounted={false}
                open={Boolean(favoritesMenuAnchorElement)}
                onClose={handleFavoriteMenuClose}
            >
                <MenuItem onClick={() => sendEmail()}>
                    Email
                </MenuItem>
            </Menu>
        </>

    )
}

export default DistributedReportMenu;