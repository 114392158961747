import { useState } from 'react';
import CustomReportTransport from 'control/transport/customReports.transport';

const fileFormats: { [key: number]: string } = {
    1: 'text/csv',
    2: 'application/pdf',
}

const useDownloadReport = function (customReportName: string, moduleName: string, sqlQuery: string) {
    const [dropdownRef, setDropdownRef] = useState(null);

    const onDropdownClick = function (e: any) {
        setDropdownRef(e.currentTarget);
    }

    const onDropdownClose = function () {
        setDropdownRef(null);
    }
    const validateFileName = function (name: string) {
        return !(/[<>.,'";:"/\\|?*\u0000-\u001F]/g.test(name)) &&
            !(/^(con|prn|aux|nul|com\d|lpt\d)$/i.test(name)) &&
            name !== '..' && name !== '.';
    }

    const callDownload = function (type: 1 | 2) {
        return async () => {
            try {
                const isValidName = validateFileName(customReportName);
                if (isValidName && sqlQuery) {
                    const response = await CustomReportTransport.getSqlDataDownloadUrl(
                        sqlQuery,
                        `${type}`,
                        customReportName,
                        moduleName
                    );
                    if (response) {
                        let blob = new Blob([response], { type: fileFormats[type] }),
                            url = window.URL.createObjectURL(blob)
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = customReportName;
                        link.click();
                    } else {
                        throw 'Error';
                    }
                } else if (!isValidName) {
                    console.error("not valid name");
                } else {
                    //@ts-ignore
                    throw 'Error';
                }
            } catch (e) {
                console.error("error ====")
            } finally {
                onDropdownClose();
            }
        }
    }

    return {
        dropdownRef,
        callDownload,
        onDropdownClose,
        onDropdownClick
    }
}

export default useDownloadReport;