import { createReducer } from "@reduxjs/toolkit";
import * as actions from "control/actions/sites.actions";
import produce from "immer";
import { ISites } from "./../entity/sites.entity";
import { POSConfigUpdateStatus } from "./posconfiguration.reducer";

const currentSiteInitialData = {
    usdaSiteTypeId: 0,
    addressLine1: "",
    addressLine2: "",
    zipCode: "",
    city: "",
    stateId: -1,
    siteId: -1,
    siteName: "",
    contactPerson: "",
    contactPersonPosition: "",
    contactPhone: "",
    siteDescription: "",
    regionId: 0,
    siteTypeId: 0,
    siteCode: "",
    isActive: true,
    addedBy: 0,
    addedOn: "",
    addressId: null,
    breakFastReimbursementCategoryId: null,
    cacfpfacility: null,
    cacfppricing: null,
    canOrderBrokenUnits: false,
    canPosusePrincipalAccount: false,
    cependDate: null,
    cepstartDate: null,
    characteristic: null,
    characteristicDetail: null,
    checkOtherSiteSecMeal: false,
    commaSeparatedDeliveryLocations: null,
    connectToServer: null,
    deliveryCost: null,
    districtRegionId: 0,
    enableBin: null,
    enableOrdering: false,
    foodPreparationSiteId: null,
    importKey: null,
    includeForReimbursement: false,
    includeForVerification: false,
    isFeedingSite: false,
    menuGroupEffectiveDate: null,
    menuGroupId: null,
    modifiedBy: 0,
    modifiedOn: "",
    nslpexportCode: null,
    numLines: 0,
    orderComments: null,
    orderingGroupId: null,
    parentSiteId: null,
    phoneNumber: null,
    primeroSchoolId: "",
    sendSiteToSchoolCafe: false,
    showOtherSiteMsg: null,
    stateSiteCode: null,
    storageCost: null,
    subscriptionTerminalId: 0,
    synchronizeDate: "",
    timeClockLogInType: 0,
    updateDate: "",
    usdasiteTypeId: 0,
    useCustomMealType: false,
    groupGuid: "",
    parentSites: [],
    childSites: [],
    siteCharacteristics: null,
};

const initialState: ISites = {
    isLoading: false,
    siteList: [],
    // areaNames: [],
    areaNames: [{
        addedBy: null,
        addedOn: null,
        description: null,
        district: null,
        districtRegionId: null,
        isActive: false,
        isIsd: false,
        modifiedBy: null,
        modifiedOn: null,
        name: "",
        organizationLevelId: null,
        parentRegionId: null,
        regionId: 0,
        subscriptionTerminalId: 0,
        synchronizeDate: "",
        updateDate: "",
    }],
    siteTypes: [],
    allStates: [],
    mealTypes: [],
    sitesConfigurationTypes: [],
    characteristics: [],
    currentSite: currentSiteInitialData,
    currentSiteExpSettings: [],
    addSiteError: undefined,
    addSiteResponse: undefined,
    siteGeneralInfoError: undefined,
    updateSiteGeneralInfoResp: undefined,
    siteCorrAddrError: undefined,
    updateSiteCorrAddrResp: undefined,
    updateSiteMealTypeResp: undefined,
    updateSiteMealTypeError: undefined,
    updateFeedingSiteResp: undefined,
    updateFeedingSiteError: undefined,
    academicYears: [],
    currentAcademicYear: null,
    programsList: [],
    updateProgramResp: undefined,
    updateProgramError: undefined,
    programSitesList: {},
    updateProgramSiteResp: undefined,
    updateProgramSiteError: undefined,
    copyProgramSaveStatus: POSConfigUpdateStatus.Initial,
    siteCharacterstics: [],
    storeSiteCharacterstics: [],
    siteCharacteristicsError: undefined,
    updateSiteCharacteristicsResp: undefined,
    parentSites: undefined,
};

export const SitesReducer = createReducer(initialState, {
    [actions.getSiteListInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
        }),

    [actions.getSiteListSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.siteList = action.payload;
            draft.isLoading = false;
        }),

    [actions.getSiteListUpdateSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.siteList = action.payload.siteList;
            draft.isLoading = false;
        }),

    [actions.getSiteListFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
        }),

    [actions.getAreaNamesSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.areaNames = action.payload;
            draft.isLoading = false;
        }),

    [actions.getSiteTypesByRegionSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.siteTypes = action.payload;
            draft.isLoading = false;
        }),

    [actions.getAllStatesSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.allStates = action.payload;
            draft.isLoading = false;
        }),

    [actions.getCharacteristicsSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.characteristics = action.payload;
            draft.isLoading = false;
        }),

    [actions.getSiteBySiteID.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
        }),

    [actions.getExpSettingsBySiteID.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
        }),

    [actions.getSiteBySiteIDFailure.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
        }),

    [actions.getExpSettingsBySiteIDFailure.toString()]: (state) =>
        produce(state, (draft) => {
            draft.currentSiteExpSettings = [];
            draft.isLoading = false;
        }),

    [actions.getSiteBySiteID.toString()]: (state) =>
        produce(state, (draft) => {
            draft.currentSite = currentSiteInitialData;
            draft.isLoading = true;
        }),
    [actions.getSiteBySiteIDSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            const currSite = action.payload;
            currSite.usdaSiteTypeId = action.payload && action.payload.usdasiteTypeId ? action.payload.usdasiteTypeId : 0;
            draft.currentSite = currSite;
            draft.isLoading = false;
        }),

    [actions.getExpSettingsBySiteIDSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            const expSettings = action.payload;
            // const currentSite = Object.assign({}, draft.currentSite);
            // currentSite.expSettings = expSettings;
            draft.currentSiteExpSettings = expSettings;
            draft.isLoading = false;
        }),

    [actions.clearCurrentSiteSelection.toString()]: (state) =>
        produce(state, (draft) => {
            draft.currentSite = currentSiteInitialData;
        }),

    [actions.addSiteInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.addSiteResponse = undefined;
            draft.addSiteError = undefined;
        }),

    [actions.addSiteSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            if (action.payload.response && action.payload.response.ResponseEnvelope.ValidationCollection.length !== 0) {
                draft.addSiteError = action.payload.response.ResponseEnvelope.ValidationCollection;
            } else {
                draft.addSiteResponse = action.payload.response;
                draft.addSiteError = undefined;
            }
        }),

    [actions.addSiteFail.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.addSiteError = action.payload && action.payload.error;
        }),

    [actions.updateSiteGeneralInfoRequest.toString()]: (state) =>
        produce(state, (draft) => {
            draft.updateSiteGeneralInfoResp = undefined;
        }),

    [actions.updateSiteCorrAddrRequest.toString()]: (state) =>
        produce(state, (draft) => {
            draft.updateSiteCorrAddrResp = undefined;
        }),

    [actions.updateSiteGeneralInfoSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            if (action.payload && action.payload.ResponseEnvelope && action.payload.ResponseEnvelope.ValidationCollection.length !== 0) {
                draft.updateSiteGeneralInfoResp = action.payload;
                draft.siteGeneralInfoError = action.payload.ResponseEnvelope.ValidationCollection;
            } else {
                draft.updateSiteGeneralInfoResp = action.payload;
                draft.siteGeneralInfoError = undefined;
            }
        }),

    [actions.updateSiteCorrAddrSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            if (action.payload && action.payload.ResponseEnvelope && action.payload.ResponseEnvelope.ValidationCollection.length !== 0) {
                draft.siteCorrAddrError = action.payload.ResponseEnvelope.ValidationCollection;
            } else {
                draft.updateSiteCorrAddrResp = action.payload;
                draft.siteCorrAddrError = undefined;
            }
            if (action.payload && action.payload.ResponseEnvelope && action.payload.ResponseEnvelope.ValidationCollection.length !== 0) {
                draft.siteCorrAddrError = action.payload.ResponseEnvelope.ValidationCollection;
            } else {
                draft.updateSiteCorrAddrResp = action.payload;
                draft.siteCorrAddrError = undefined;
            }
        }),

    [actions.updateSiteGeneralInfoFailure.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.siteGeneralInfoError = action.payload;
        }),

    [actions.updateSiteCorrAddrFailure.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.siteCorrAddrError = action.payload.ResponseEnvelope.ValidationCollection;
        }),

    [actions.getMealTypesByRegionSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.mealTypes = action.payload;
            draft.mealTypes.forEach((meal) => {
                let isValid = true;
                draft.mealTypes.forEach((mt) => {
                    if (mt.mealTypeId !== meal.mealTypeId) {
                        if ((((mt.startTime >= meal.startTime) && (mt.startTime <= meal.endTime)) || ((mt.endTime >= meal.startTime) && (mt.endTime <= meal.endTime)))) {
                            isValid = false;
                        }
                    }
                });
                meal.isValid = isValid;

                return meal;
            });
            draft.isLoading = false;
        }),

    [actions.getMealTypesFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
        }),

    [actions.getSitesConfigurationTypesByRegionSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.sitesConfigurationTypes = action.payload;

            draft.isLoading = false;
        }),

    [actions.getSitesConfigurationTypesFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
        }),

    [actions.updateSiteMealTypeRequest.toString()]: (state) =>
        produce(state, (draft) => {
            draft.updateSiteMealTypeResp = undefined;
        }),

    [actions.updateSiteMealTypeSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            if (action.payload && action.payload.ResponseEnvelope && action.payload.ResponseEnvelope.ValidationCollection.length !== 0) {
                draft.updateSiteMealTypeError = action.payload.ResponseEnvelope.ValidationCollection;
            } else {
                draft.updateSiteMealTypeResp = action.payload;
                draft.updateSiteMealTypeError = undefined;
            }
        }),

    [actions.updateSiteMealTypeFailure.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.updateSiteMealTypeError = action.payload.ResponseEnvelope.ValidationCollection;
        }),

    [actions.clearSiteMealTypeActions.toString()]: (state) =>
        produce(state, (draft) => {
            draft.updateSiteMealTypeResp = undefined;
            draft.updateSiteMealTypeError = undefined;
            draft.mealTypes = [];
            draft.updateFeedingSiteResp = undefined;
            draft.updateFeedingSiteError = undefined;
        }),

    [actions.updateFeedingSiteSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            if (action.payload && action.payload.ResponseEnvelope && action.payload.ResponseEnvelope.ValidationCollection.length !== 0) {
                draft.updateFeedingSiteError = action.payload.ResponseEnvelope.ValidationCollection;
            } else {
                draft.updateFeedingSiteResp = action.payload;
                draft.updateFeedingSiteError = undefined;
            }
        }),

    [actions.updateFeedingSiteFailure.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.updateSiteMealTypeError = action.payload.ResponseEnvelope.ValidationCollection;
        }),

    [actions.getAcademicYearsSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.academicYears = action.payload;
        }),

    [actions.getCurrentAcademicYearSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.currentAcademicYear = action.payload;
        }),

    [actions.getProgramsByAcademicYear.toString()]: (state) =>
        produce(state, (draft) => {
            draft.programsList = [];
            draft.isLoading = true;
        }),

    [actions.getProgramsByAcademicYearSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.programsList = action.payload;
            draft.isLoading = false;
        }),

    [actions.getProgramsByAcademicYearFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
            draft.programsList = [];
        }),

    [actions.clearProgramsByAcademicYear.toString()]: (state) =>
        produce(state, (draft) => {
            draft.programsList = [];
        }),
    [actions.updateProgramRequest.toString()]: (state) =>
        produce(state, (draft) => {
            draft.updateProgramResp = undefined;
        }),

    [actions.updateProgramSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            if (action.payload && action.payload.ResponseEnvelope && action.payload.ResponseEnvelope.ValidationCollection.length !== 0) {
                draft.updateProgramError = action.payload.ResponseEnvelope.ValidationCollection;
            } else {
                draft.updateProgramResp = action.payload;
                draft.updateProgramError = undefined;
            }
        }),

    [actions.updateProgramFailure.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.updateProgramError = action.payload.ResponseEnvelope?.ValidationCollection ?
                action.payload.ResponseEnvelope?.ValidationCollection : "Failed to update";
        }),

    [actions.getProgramSitesByAcademicYear.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
        }),

    [actions.getProgramSitesByAcademicYearSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            const programId = action.payload.programId;
            const data = action.payload.data;
            draft.programSitesList[programId] = data;
            draft.isLoading = false;
        }),

    [actions.getProgramSitesByAcademicYearFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
        }),

    [actions.updateProgramRequest.toString()]: (state) =>
        produce(state, (draft) => {
            draft.updateProgramResp = undefined;
        }),

    [actions.updateProgramSiteSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            if (action.payload && action.payload.ResponseEnvelope && action.payload.ResponseEnvelope.ValidationCollection.length !== 0) {
                draft.updateProgramError = action.payload.ResponseEnvelope.ValidationCollection;
            } else {
                draft.updateProgramSiteResp = action.payload;
                draft.updateProgramSiteError = undefined;
            }
        }),

    [actions.updateProgramSiteFailure.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.updateProgramSiteError = action.payload.ResponseEnvelope.ValidationCollection;
        }),

    [actions.copyProgramRequest.toString()]: (state) =>
        produce(state, (draft) => {
            draft.copyProgramSaveStatus = POSConfigUpdateStatus.Loading;
        }),

    [actions.copyProgramSuccess.toString()]: (state) =>
        produce(state, (draft) => {
            draft.copyProgramSaveStatus = POSConfigUpdateStatus.SuccessWithResponseData;
        }),

    [actions.copyProgramFailedForProgramsNotConfigured.toString()]: (state) =>
        produce(state, (draft) => {
            draft.copyProgramSaveStatus = POSConfigUpdateStatus.FailedWithProgramsNotConfiguredError;
        }),

    [actions.copyProgramFailure.toString()]: (state) =>
        produce(state, (draft) => {
            draft.copyProgramSaveStatus = POSConfigUpdateStatus.FailedWithUnknownError;
        }),
    [actions.clearCopyProgramSaveStatus.toString()]: (state) =>
        produce(state, (draft) => {
            draft.copyProgramSaveStatus = POSConfigUpdateStatus.Initial;
        }),
    [actions.getSiteCharactersticsSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.siteCharacterstics = action.payload;
        }),
    [actions.storeSiteCharacterstics.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.siteCharacterstics = action.payload;
        }),
    [actions.updateSiteCharacteristicsSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            if (action.payload && action.payload.ResponseEnvelope && action.payload.ResponseEnvelope.ValidationCollection.length !== 0) {
                draft.siteCharacteristicsError = action.payload.ResponseEnvelope.ValidationCollection;
            } else {
                draft.updateSiteCharacteristicsResp = action.payload;
                draft.siteCharacteristicsError = undefined;
            }
        }),
    [actions.updateSiteCharacteristicsFailure.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.siteCharacteristicsError = action.payload.ResponseEnvelope.ValidationCollection;
        }),
    [actions.getParentSitesSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.parentSites = action.payload;
        }),
});
