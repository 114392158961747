import { IAppState } from "model/reducers";

export interface ISiteTypeByRegion {
    siteTypeId: string;
    siteTypeDescription: string;
    regionId: number;
    siteTypeTypeCode: string;
    grades: [];
    sitetypeAddSuccess?: any;
}

export interface ISiteTypesData {
    siteTypes: ISiteTypes[];
    totalPages: number;
    totalSiteTypesCount: number;
}

export interface ISiteTypes {
    siteTypeId: string;
    siteTypeDescription: string;
    regionId: number;
    siteTypeTypeCode: string;
    grades: [];
    sitetypeAddSuccess?: any;
}

export interface IUsdaSiteTypes {
    description: string;
    notes: string;
    order: number;
    usdaSiteTypeId: number;
}


export class SiteTypeEntity {
    public static sitetypeAddSuccess (state: any) {
        return state.sitetype.sitetypeAddSuccess;
    }

    public static getAllUSDASiteTypes ({ sitetype }: IAppState) {
        return sitetype.allUSDASiteTypes;
    }

    public static getSiteTypeByRegion ({ sitetype }: IAppState) {
        return sitetype.siteTypeByRegion;
    }

    public static getSiteTypes ({ sitetype }: IAppState) {
        return sitetype.siteTypeTypes;
    }

    public static getSiteGrades ({ sitetype }: IAppState) {
        return sitetype.siteTypeGradesAddForm;
    }

    public static getSiteTypeStatus ({ sitetype }: IAppState) {
        return sitetype.siteTypeStatus;
    }
}
