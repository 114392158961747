import {
    InboxIcon,
    ListIcon,
    NavItem,
    RadioIcon,
    SendIcon,
} from "@primeroedge/ui-components";
import React, { FC, Fragment } from "react";
import { useSelector } from "react-redux";
import { WorkSpaceDetailsEntity } from "../../../model/entity";

const Sidenav: FC = () => {
    const isBroadcastUser = useSelector(WorkSpaceDetailsEntity.isBroadcastUser);

    return (
        <Fragment>
            <NavItem title="Inbox" icon={InboxIcon} path="/workspace/inbox" />
            <NavItem title="Sent" icon={SendIcon} path="/workspace/sent" />
            {isBroadcastUser && (
                <NavItem
                    title="Broadcast"
                    icon={RadioIcon}
                    path="/workspace/broadcast"
                />
            )}
            <NavItem title="Tasks" icon={ListIcon} path="/workspace/tasks" />
        </Fragment>
    );
};

export default Sidenav;
