import {
    Avatar,
    Box,
    Checkbox,
    colors,
    makeStyles,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { ITheme } from "@primeroedge/ui-components";
import { selectedMail, updateReadStatus } from "control/actions/mail.actions";
import { IMailEntity, MessageType } from "model/entity";
import moment from "moment";
import React, { FC } from "react";
import { useDispatch } from "react-redux";

interface IMailItemProps {
    className?: string;
    mail: IMailEntity;
    onSelect: () => void;
    selected: boolean;
    selectedOption: string;
    messageType: MessageType;
}

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: "flex",
        alignItems: "center",
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
    unread: {
        borderLeft: "8px solid #950303",
        "& $name, & $subject": {
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    unreadMessage: {
        backgroundColor: theme.palette.grey[200],
    },
    readMessage: {
        backgroundColor: theme.palette.background.light,
    },
    selected: {
        backgroundColor: theme.palette.action.selected,
    },
    filterActive: {
        color: colors.amber[400],
    },
    content: {
        cursor: "pointer",
        textDecoration: "none",
    },
    details: {
        [theme.breakpoints.up("md")]: {
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "space-between",
        },
    },
    subjectInfo: {
        width: "75%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontWeight: "bold",
    },
    avatar: {
        backgroundColor: theme.palette.grey[700],
        color: theme.palette.common.white,
    },
    avatarName: {
        display: "flex",
        alignItems: "center",
    },
    name: {
        marginLeft: 20,
        fontWeight: "bold",
        [theme.breakpoints.up("md")]: {
            minWidth: 180,
            flexBasis: 180,
        },
    },
    subject: {
        maxWidth: 400,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        padding: "0",
        fontWeight: "bold",
    },
    contentPreview: {
        width: "100%",
        overflow: "hidden",
        height: "20px",
    },
    message: {
        maxWidth: 800,
        flexGrow: 1,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginRight: "auto",
    },
    label: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.common.white,
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 2,
        "& + &": {
            marginLeft: theme.spacing(1),
        },
    },
    date: {
        whiteSpace: "nowrap",
    },
    isUnReadCell: {
        padding: 0,
    },
}));

const MailItem: FC<IMailItemProps> = ({
    mail,
    onSelect,
    selected,
    selectedOption,
    messageType,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const openSelectedEmail = (input: IMailEntity) => {
        if (!input.isRead) {
            dispatch(updateReadStatus([input.messageId]));
        }
        dispatch(selectedMail({
            ...input,
            selectedOption,
        }));
    };

    const getRecipientNames = () => {
        if (mail) {
            if (messageType === MessageType.Sent) {
                if (mail.recipient) {

                    if (mail.recipient <= 3) {
                        const recipients = mail.recipient.reduce((acc: string, recp: any) => {
                            return `${acc.length > 1 ? `${acc};` : ""} ${recp.recipientFirstName} ${recp.recipientLastName}`;
                        }, "");

                        return recipients;
                    } else {

                        const recipients = mail.recipient.map((recp: any, index: number) => {
                            if (index <= 3) {
                                return `${index <= 2 ? `${recp.recipientFirstName} ${recp.recipientLastName} ${index !== 2 ? ";" : ""}` : ` ... +${mail.recipient.length - 3} Others`} `;
                            }
                        }, "");

                        return recipients;
                    }

                } else {
                    return `${mail.recipientFirstName} ${mail.recipientLastName}`;
                }
            } else {
                return mail.senderFirstName;
            }
        }
    };

    return (
        <TableRow
            hover={true}
            onClick={openSelectedEmail.bind(null, mail)}
            role="checkbox"
            aria-checked={false}
            tabIndex={-1}
            selected={selected}
            classes={{
                root: messageType !== MessageType.Sent && !mail.isRead ? classes.unreadMessage : classes.readMessage,
                selected: classes.selected,
            }}
        >
            <TableCell
                padding="checkbox"
                classes={{ root: messageType !== MessageType.Sent && !mail.isRead ? classes.isUnReadCell : "" }}
            >
                <Checkbox
                    inputProps={{ "aria-label": `${mail.id}` }}
                    id={mail.subject}
                    checked={selected}
                    onClick={(event: any) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onSelect();
                    }}
                />
            </TableCell>
            <TableCell component="th" id={mail.messageId} scope="row" padding="none">
                <Box className={classes.avatarName}>
                    {messageType !== MessageType.Sent && (
                        <Tooltip
                            title={
                                `${mail.senderFirstName} ${mail.senderLastName}`
                            }
                        >
                            <Avatar className={classes.avatar}>
                                {mail.senderFirstName ? mail.senderFirstName.charAt(0) : ""}{mail.senderLastName ? mail.senderLastName.charAt(0) : ""}
                            </Avatar>
                        </Tooltip>
                    )}
                    <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.name}
                    >
                        <>{getRecipientNames()}</>
                    </Typography>
                </Box>
            </TableCell>
            <TableCell align="left">
                <Typography
                    variant="body2"
                    color="textPrimary"
                    className={classes.subject}
                >
                    {mail.subject}
                </Typography>
                <p
                    className={classes.contentPreview}
                    dangerouslySetInnerHTML={{ __html: mail.content ? mail.content : "" }}
                />
            </TableCell>
            <TableCell align="right">
                <Typography
                    className={classes.date}
                    color="textSecondary"
                    variant="caption"
                >
                    {moment(mail.createdOn).format("MM/DD/YYYY")}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default MailItem;
