import React from "react";
import DistrictSelctionPage from "./districtSelection";

const DistrictSelection = () => {
    return (
        <>
            <DistrictSelctionPage />
        </>
    );
};

export default DistrictSelection;
