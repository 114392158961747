import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVerticalIcon, NotificationWithDialog } from "@primeroedge/ui-components";
import React, { FC, MouseEvent, useState } from "react";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteMessage, selectedMail } from "../../../../../control/actions";
import { IMailEntity } from "../../../../../model/entity";
import ListItem from "../ListItem";


const MailItem: FC<{ mail: IMailEntity, notificationCb: any }> = ({ mail, notificationCb }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);

    const [mailAnchorEl, setMailAnchorEl] = useState<HTMLElement | null>(null);

    const handleMailClick = (ev: MouseEvent<HTMLButtonElement>) => {
        setMailAnchorEl(ev.currentTarget);
    };

    const handleMailClose = () => {
        setMailAnchorEl(null);
    };

    const handleMailDelete = () => {
        handleMailClose();
        dispatch(deleteMessage([mail.messageId]));
        notificationCb(true);
    };

    const openSelectedMail = () => {
        handleMailClose();
        dispatch(selectedMail(mail));
        history.push("/workspace/inbox");
    };

    if (!mail) {
        return null;
    }

    return (
        <>
            <ListItem
                title={mail.subject}
                subTitle={mail.content?.replace(/<[^>]+>/g, "")}
                onClick={() => openSelectedMail()}
                info={(
                    <span className={"font10"}>
                        <Moment format="MM/DD/YYYY">
                            {mail.createdOn}
                        </Moment>
                    </span>
                )}
                action={(
                    <>
                        <IconButton
                            aria-controls="messagesMenu"
                            aria-haspopup="true"
                            onClick={handleMailClick}
                            size="small"
                        >
                            <MoreVerticalIcon />
                        </IconButton>
                        <Menu
                            id="messagesMenu"
                            anchorEl={mailAnchorEl}
                            keepMounted={false}
                            open={Boolean(mailAnchorEl)}
                            onClose={handleMailClose}
                        >
                            <MenuItem onClick={openSelectedMail}>
                                Reply
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setshowDeleteConfirm(true);
                                    setMailAnchorEl(null);
                                }}>
                                Delete
                            </MenuItem>
                        </Menu>
                    </>
                )}
            />
            <Divider />
            <NotificationWithDialog
                message="Are you sure you want to delete this message?"
                open={showDeleteConfirm}
                primaryAction={{
                    callback: () => {
                        handleMailDelete();
                    },
                    title: "Confirm",
                }}
                secondaryAction={{
                    callback: () => {
                        setshowDeleteConfirm(false);
                    },
                    title: "Cancel",
                }}
                title="Confirmation"
            />
        </>
    );
};

export default MailItem;
