import { CustomReportsEntity, IDistributedCustomReports, IFavoriteCustomReports } from "model/entity/CustomReports.entity";
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { IAlertContent } from "./useFavoriteReportMenu";
interface IReportsCount {
    favoritesCount: number;
    distributedCount: number;
}


export const useMyCustomReports = () => {
    const [value, setValue] = useState<number>(0);
    const isLoading = useSelector(CustomReportsEntity.isReportsDataLoading);
    const customReportsData = useSelector(CustomReportsEntity.getCustomReports);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [alertContent, setAlertContent] = useState<IAlertContent | null>(null);

    const [selectedFavorite, setSelectedFavorite] = useState<any>({
        moduleName: null,
        sql: null,
        customReportName: null,
        containsPii: false,
    });

    const [reportsCount, setReportsCount] = useState<IReportsCount>({
        favoritesCount: 0,
        distributedCount: 0
    });

    useEffect(() => {
        setReportsCount({
            favoritesCount: customReportsData?.customReportFavorites?.length,
            distributedCount: customReportsData?.customReportDistributeds?.length
        });
    }, [customReportsData]);

    const closeDrawer = () => {
        setOpenDrawer(false);
    }
    //@ts-ignore
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };
    
    
    const onFavoriteReportClick = (favorite: IFavoriteCustomReports) => {
        setOpenDrawer(true);
        setSelectedFavorite({
            moduleName: favorite.moduleName,
            sql: favorite.sqlQuery,
            customReportName: favorite.customReportName,
            containsPii: favorite.containsPii
        });
    }
    const downloadFile = async (report: IDistributedCustomReports) => {
            if (report.url) {
                try {
                    const link = document.createElement('a');
                    link.href = report.url;
                    const filename = report.customReportName.replace(/\s+/g, "_") + "_" + report.distributedOn.split("T")[0];
                    link.download = filename;
                    link.click();
                } catch (e) {
                    console.error("Unable to download the file")
                }
            }
    };

    return {
        value,
        handleChange,
        setValue,
        isLoading,
        handleChangeIndex,
        customReportsData,
        reportsCount,
        onFavoriteReportClick,
        closeDrawer,
        openDrawer, 
        selectedFavorite,
        downloadFile,
        alertContent,
        setAlertContent
    }
}