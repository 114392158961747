import { MainLayout, ModuleName, renderRoutes } from "@primeroedge/ui-components";
import { APIM_BASE_URL, APIM_SUBSCRIPTION_KEY } from "control/transport/clients/api-constants";
import React from "react";

import Sidenav from "./Sidenav";
import workspaceRoutes from "./workspaceRoutes";

const WorkspacePages = () => {
    return (
        <>
            <MainLayout
                apimUrl={`${APIM_BASE_URL}`}
                sidenav={() => <Sidenav />}
                moduleName={ModuleName.Workspace}
                subscriptionKey={`${APIM_SUBSCRIPTION_KEY}`}
            >                   
                {renderRoutes(workspaceRoutes)}
            </MainLayout>
        </>
        
    );
};

export default WorkspacePages;
