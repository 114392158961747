import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { Button, DownloadIcon, FileIcon, isImage, ITheme } from "@primeroedge/ui-components";
import React, { FC, MouseEventHandler } from "react";

interface IAttachmentPreviewProps {
    attachment: IAttachment;
    onDownload: MouseEventHandler<HTMLButtonElement>;
}

export interface IAttachment {
    link: string;
    displayFileName: string;
}

const useStyles = makeStyles((theme: ITheme) => ({
    image: {
        width: "100%",
        height: "100%",
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
    },
}));

const AttachmentPreview: FC<IAttachmentPreviewProps> = ({ attachment, onDownload }) => {
    const classes = useStyles();

    return (
        <Box
            display="flex"
            flexDirection="column"
            bgcolor="background.paper"
            width="200px"
            boxShadow={4}
            borderRadius="borderRadius"
        >
            <Box
                height="120px"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {isImage(attachment.displayFileName) ? (
                    <img
                        src={attachment.link}
                        alt=""
                        className={classes.image}
                    />
                ) : (
                    <Typography
                        component="p"
                        align="center"
                        color="textSecondary"
                    >
                        No Preview Available
                    </Typography>
                )}
            </Box>
            <Divider />
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                p={1}
            >
                <FileIcon size={16} />
                <Typography
                    variant="body2"
                    component="p"
                    noWrap={true}
                    title={attachment.displayFileName}
                >
                    {attachment.displayFileName || "Unknown"}
                </Typography>
            </Box>
            <Divider />
            <Button
                id={`downloadAttachment${attachment.displayFileName}`}
                variant="text"
                onClick={onDownload}
                startIcon={<DownloadIcon size={20} />}
            >
                Download
            </Button>
        </Box>
    );
};

export default AttachmentPreview;
