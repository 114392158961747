import { createAction } from "@reduxjs/toolkit";
import { ISendEmailPayload } from 'model/entity/mail.entity';
import { ResponseStatus } from 'types/mail';

export const fetchMailUserList = createAction(
    "FETCH_MAIL_USER_LIST",
    (data: any) => ({
        payload: data,
    }),
);


export const setMailListServiceCallStatus = createAction(
    "SET_MAIL_LIST_STATUS",
    (status: ResponseStatus) => ({
        payload: status,
    }),
);

export const sendMailInit = createAction(
    "SEND_MAILI_INIT",
    (params: any, callbacks: {
        successCallback: () => void;
        errorCallback: () => void;
    }) => ({
        payload: {
            params,
            callbacks
        }
    })
)

export const setUserMail = createAction("GET_MAIL_USER", (data: any) => ({
    payload: data,
}));


export const sendReportEmailInit = createAction("SEND_EMAIL_INIT", (payload: ISendEmailPayload) => ({ payload }));
export const sendReportEmailSuccess = createAction("SEND_EMAIL_SUCCESS");
export const sendReportEmailError = createAction("SEND_EMAIL_ERROR");
export const sendReportEmailReset = createAction("SEND_EMAIL_RESET");