import { ResponseStatus } from "model/entity/common.entity";
import { put, takeLatest } from "redux-saga/effects";
import {
    deleteTaskAction,
    FetchTasksAction,
    FetchTasksByDuration,
    FetchTasksSuccessAction,
    SetTasksDuration,
    SetTasksStatus,
    SetUpdatedTasks,
    updateDeletedTask,
} from "../actions";
import { fetchInitialTasks, fetchTasksByDurationAPI } from "../transport/tasks-api";
import TasksTransportLayer from "../transport/tasks.transport";

function* fetchTasksSaga(action: any) {
    try {
        const {
            payload: {
                pageSize,
                pageNumber,
                sortColumn,
            },
        } = action;
        yield put(SetTasksStatus(ResponseStatus.Loading));
        const data = yield fetchInitialTasks(pageNumber, pageSize, sortColumn);
        yield put(FetchTasksSuccessAction(data));
        yield put(SetTasksStatus(ResponseStatus.Success));
    } catch (e) {
        yield put(FetchTasksSuccessAction({
            tasks: [],
            updatedTasks: [],
            totalPages: 0,
            totalTaskCount: 0,
            status: ResponseStatus.Error,
        }));
    }
}

function* fetchTasksByDurationSaga(action: any) {
    try {
        yield put(SetTasksStatus(ResponseStatus.Loading));
        const data = yield fetchTasksByDurationAPI(action.payload);
        yield put(SetUpdatedTasks(data.tasks));
        yield put(SetTasksDuration(action.payload.duration));
        yield put(SetTasksStatus(ResponseStatus.Success));
    } catch (error) {
        console.error(error);
        yield put(SetTasksStatus(ResponseStatus.Error));
    }
}

function* deleteTaskSaga(action: any) {
    try {
        yield TasksTransportLayer.deleteTask(action.payload.id);
        yield put(updateDeletedTask(action.payload.index));
    } catch (error) {
        console.error(error);
        yield null;
    }
}

export default function* TasksSaga() {
    try {
        yield takeLatest(FetchTasksAction, fetchTasksSaga);
        yield takeLatest(FetchTasksByDuration, fetchTasksByDurationSaga);
        yield takeLatest(deleteTaskAction, deleteTaskSaga);
    } catch (error) {
        console.error(error);
        yield null;
    }
}
