import { createAction } from "@reduxjs/toolkit";
import { IUser } from "../../types/user-types";

export const usersFetchInit = createAction("USERS_FETCH_INIT", (data: any) => ({
  payload: data,
}));

export const usersFetchSuccess = createAction(
  "USERS_FETCH_SUCCESS",
  (data: IUser[]) => ({
    payload: data,
  }),
);

export const usersFetchFail = createAction("USERS_FETCH_FAIL");

// Adding a user
export const userAddInit = createAction("USER_ADD_INIT", (user) => ({
  payload: user,
}));

export const userAddSuccess = createAction("USER_ADD_SUCCESS", (data) => ({
  payload: data,
}));

export const userAddFail = createAction("USER_ADD_FAIL", (error) => ({
  payload: {
    error,
  },
}));

// Editing a user
export const userEditInit = createAction("USER_EDIT_INIT", (user) => ({
  payload: user,
}));

export const userEditSuccess = createAction("USER_EDIT_SUCCESS", (data) => ({
  payload: data,
}));

export const userEditFail = createAction("USER_EDIT_FAIL", (data) => ({
  payload: data,
}));

// Update  user status

export const userUpdateStatusInit = createAction(
  "USER_UPDATESTATUS_INIT",
  (userId: number, userStatusId: number) => ({
    payload: {
      userId,
      userStatusId,
    },
  }),
);
export const userUpdateStatusSuccess = createAction(
  "USER_UPDATESTATUS_SUCCESS",
  (userId: number, userStatusId: number) => ({
    payload: {
      userId,
      userStatusId,
    },
  }),
);

export const userUpdateStatusFail = createAction("USER_UPDATESTATUS_FAIL");

export const userFetchInit = createAction(
  "USER_FETCH_INIT",
  (data: number) => ({
    payload: data,
  }),
);

export const userFetchSuccess = createAction(
  "USER_FETCH_SUCCESS",
  (data: IUser) => ({
    payload: data,
  }),
);

export const userFetchFail = createAction("USER_FETCH_FAIL");

export const viewUsersInit = createAction("VIEW_USERS_INIT", (data: any) => ({
  payload: data,
}));

export const viewUsersSuccess = createAction(
  "VIEW_USERS_SUCCESS",
  (data: IUser[]) => ({
    payload: data,
  }),
);

export const viewUsersFail = createAction("VIEW_USERS_FAIL");

export const viewRegionsAndSites = createAction("VIEW_REGIONS_AND_SITES", (data: any) => ({
  payload: data,
}));

export const viewRegionsAndSitesSuccess = createAction(
  "VIEW_REGIONS_AND_SITES_SUCCESS",
  (data) => ({
    payload: data,
  }),
);

export const viewRegionsAndSitesFail = createAction("VIEW_REGIONS_AND_SITES_FAIL");

export const viewOrganizationInit = createAction("VIEW_ORGANIZATION_INIT");

export const viewOrganizationSuccess = createAction(
  "VIEW_ORGANIZATION_SUCCESS",
  (payload) => ({
    payload,
  }),
);

export const viewOrganizationFail = createAction("VIEW_ORGANIZATION_FAIL");

export const viewFilterSiteDataInit = createAction("VIEW_FILTER_SITE_DATA_INIT", (data: any) => ({
  payload: data,
}));

export const viewFilterSiteDataSuccess = createAction(
  "VIEW_FILTER_SITE_DATA_SUCCESS",
  (data) => ({
    payload: data,
  }),
);

export const viewFilterSiteDataFail = createAction("VIEW_FILTER_SITE_DATA_FAIL");

export const viewFilterRoleDataInit = createAction("VIEW_FILTER_ROLE_DATA_INIT", (data: any) => ({
  payload: data,
}));

export const viewFilterRoleDataSuccess = createAction(
  "VIEW_FILTER_ROLE_DATA_SUCCESS",
  (data) => ({
    payload: data,
  }),
);

export const viewFilterRoleDataFail = createAction("VIEW_FILTER_ROLE_DATA_FAIL");

export const validateEmailInit = createAction("VALIDATE_EMAIL_INIT", (data: any) => ({
  payload: data,
}));

export const validateEmailSuccess = createAction(
  "VALIDATE_EMAIL_SUCCESS",
  (data) => ({
    payload: data,
  }),
);

export const validateEmailFail = createAction("VALIDATE_EMAIL_FAIL");

export const userResetPasswordInit = createAction("USER_RESET_PASSWORD_INIT", (user) => ({
  payload: user,
}));

export const userResetPasswordSuccess = createAction("USER_RESET_PASSWORD_SUCCESS", (data) => ({
  payload: data,
}));

export const userResetPasswordFail = createAction("USER_RESET_PASSWORD_FAIL", (data) => ({
  payload: data,
}));

export const updateUserProfileInit = createAction("UPDATE_USER_PROFILE_INIT", (payload) => ({
  payload,
}));

export const updateUserProfileSuccess = createAction("UPDATE_USER_PROFILE_SUCCESS", (payload) => ({
  payload,
}));

export const updateUserProfileFail = createAction("UPDATE_USER_PROFILE_FAIL");

export const updateActiveDistrictSuccess = createAction("UPDATE_ACTIVE_DISTRICT_SUCCESS", (payload) => ({
  payload,
}));

export const viewFilteredSiteDataInit = createAction("VIEW_FILTERED_SITE_DATA_INIT", (data: any) => ({
  payload: data,
}));

export const viewFilteredSiteDataSuccess = createAction(
  "VIEW_FILTERED_SITE_DATA_SUCCESS",
  (data) => ({
    payload: data,
  }),
);

export const viewFilteredSiteDataFail = createAction("VIEW_FILTERED_SITE_DATA_FAIL");

export const viewFilteredRoleDataInit = createAction("VIEW_FILTERED_ROLE_DATA_INIT", (data: any) => ({
  payload: data,
}));

export const viewFilteredRoleDataSuccess = createAction(
  "VIEW_FILTERED_ROLE_DATA_SUCCESS",
  (data) => ({
    payload: data,
  }),
);

export const viewFilteredRoleDataFail = createAction("VIEW_FILTERED_ROLE_DATA_FAIL");

// Onboarding completion action
export const updateGroup = createAction("UPDATE_GROUP");

export const updateGroupSuccess = createAction(
  "UPDATE_GROUP_SUCCESS",
  (data) => ({
    payload: data,
  }),
);

export const updateGroupFail = createAction("UPDATE_GROUP_FAIL");
