import { AxiosPromise } from "axios";
import { IWorkspaceDetails } from "../../model/entity";
import workspaceAPIClient from "./clients/workspace-api.client";

class WorkspaceDetailsTransportLayer {

    public async fetchWorkspaceDetails (maxRecords: number = 5): Promise<IWorkspaceDetails> {
        const response = await workspaceAPIClient({
            method: "GET",
            url: `/api/workspace-details?maxRecords=${maxRecords}`,
        });
        if (response.data) {
            return Promise.resolve(response.data);
        } else {
            return Promise.reject("Failed to get workspace details");
        }
    }

    public deleteMessage (id: string[]): AxiosPromise {
        return workspaceAPIClient({
            method: "DELETE",
            url: `/api/delete-message`,
            data: { id },
        });
    }

}

export default new WorkspaceDetailsTransportLayer();
