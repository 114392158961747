import { ModuleName } from "@primeroedge/ui-components";
import administrationAPIClient from "./clients/administration-api.client";
import posAPIClient from "./clients/pos-api.client";
import eligibilityAPIClient from "./clients/eligibility-api.client";
import menuPlanningAPIClient from "./clients/menu-planning-api.client";
import accountManagementAPIClient from "./clients/account-management-api.client";
import inventoryAPIClient from "./clients/inventory-api.client";
import itemManagementAPIClient from "./clients/item-management-api.client";
import { ISendEmailPayload } from "model/entity";

class CustomReportTransport {
    private static async performDataQuery(
        commonData: any,
        moduleName: string
    ): Promise<any> {
        try {
            let response;
            switch (moduleName) {
                case ModuleName.Accountability:
                    response = await posAPIClient(commonData);
                    break;
                case ModuleName.AccountManagement:
                    response = await accountManagementAPIClient(commonData);
                    break;
                case ModuleName.System:
                    response = await administrationAPIClient(commonData);
                    break;
                case ModuleName.Inventory:
                    response = await inventoryAPIClient(commonData);
                    break;
                case ModuleName.Eligibility:
                    response = await eligibilityAPIClient(commonData);
                    break;
                case ModuleName.ItemManagement:
                    response = await itemManagementAPIClient(commonData);
                    break;
                case ModuleName.MenuPlanning:
                    response = await menuPlanningAPIClient(commonData);
                    break;
                default:
                    throw new Error('Invalid moduleName');
            }
            return response.data;
        } catch (e) {
            return null;
        }
    }
    public static async getFavoriteCustomReports(): Promise<any> {
        try {
            const response = await administrationAPIClient({
                method: "GET",
                url: `/api/CustomReport/GetStarredReportsAsync`,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    public static async getCustomReportByIdAsync(payload: any) {
        try {
            const response = await administrationAPIClient({
                method: "GET",
                url: `/api/CustomReport/GetByIdAsync/${payload}`,
            });
            return response.data;
        } catch (error) {
           throw error
        }
    }
    public static async getSqlData(sql: string, moduleName: string) {
        const commonData = {
            method: 'POST',
            url: '/api/DataQuery/GetDataAsync',
            data: {
                sql,
                noOfRows: 1000
            }
        };
        return this.performDataQuery(commonData, moduleName);
    }

    public static async getSqlDataDownloadUrl(
        sql: string,
        reportFormatId: string,
        reportName: string,
        moduleName: string): Promise<any | null> {
        const commonData = {
            method: 'POST',
            url: '/api/DataQuery/DownloadAsync',
            data: {
                sql,
                reportName,
                reportFormatId
            }
        };
        return this.performDataQuery(commonData, moduleName);
    }

    public static async deleteCustomReportFavoriteAsync(customReportFavoriteId: number): Promise<any> {
        const resp = await administrationAPIClient({
            method: "DELETE",
            url: `/api/CustomReport/DeleteCustomReportFavoriteAsync?customReportFavoriteId=${customReportFavoriteId}`,
        });
        const { data } = resp;
        if (data !== undefined) {
            return Promise.resolve({
                data,
            });
        } else {
            return Promise.reject(
                "Failed to authenticate, response data is in a different format"
            );
        }
    }
    public static async sendReportsEmail(payload: ISendEmailPayload): Promise<any> {
        try {
            const response = await administrationAPIClient({
                method: "POST",
                url: '/api/CustomReport/EmailCustomReport',
                data: payload
            });
            return response.data;
        } catch (e) {
            return null;
        }
    }
}

export default CustomReportTransport;