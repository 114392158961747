import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import { GradeGroupStatus, IGradeGroupState } from "model/entity/gradeGroup.entity";
import { userAuthLogoutDone } from "../../control/actions/auth.actions";
import * as actions from "../../control/actions/gradegroup.actions";

const initialState: IGradeGroupState = {
    gradeGroups: [],
    availableGrades: [],
    gradeGroupStatus: GradeGroupStatus.Initial,
    addGradeGroupSuccess: "",
    isLoading: false,
};

export const gradeGroupReducer = createReducer(initialState, {
    [actions.fetchGradeGroupsInit.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.gradeGroupStatus = GradeGroupStatus.Initial;
            draft.isLoading = true;
        }),
    [actions.fetchGradeGroupsSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.gradeGroups = action.payload;
            draft.isLoading = false;
        }),
    [actions.fetchGradeGroupsFail.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.isLoading = false;
        }),
    [actions.fetchAvailableGradeGroupsInit.toString()]: (state) =>
        produce(state, () => {
        }),
    [actions.fetchAvailableGradesSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.availableGrades = action.payload;
        }),
    [actions.fetchAvailableGradesFail.toString()]: (state) =>
        produce(state, () => {
        }),
    [actions.addGradeGroupInit.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.gradeGroupStatus = GradeGroupStatus.Initial;
        }),
    [actions.addGradeGroupSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.gradeGroupAddSuccess = action.payload;
            draft.gradeGroupStatus = GradeGroupStatus.addSuccessful;
        }),
    [actions.addGradeGroupFail.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.gradeGroupStatus = GradeGroupStatus.addFail;
        }),
    [actions.editGradeGroupInit.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.gradeGroupStatus = GradeGroupStatus.Initial;
        }),
    [actions.editGradeGroupSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.gradeGroupAddSuccess = action.payload;
            draft.gradeGroupStatus = GradeGroupStatus.editSuccessful;
        }),
    [actions.editGradeGroupFail.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.gradeGroupStatus = GradeGroupStatus.editFail;
        }),

    [userAuthLogoutDone.toString()]: () => initialState,
});
