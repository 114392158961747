import moment from "moment-timezone";

export const timeZones: {[key: string]: string} = {
    "Eastern Standard Time": "America/New_York",
    "Eastern Daylight Time": "America/New_York",
    "Central Standard Time": "America/Chicago",
    "Central Daylight Time": "America/Chicago",
    "Mountain Standard Time": "America/Denver",
    "Mountain Daylight Time": "America/Denver",
    "Pacific Standard Time": "America/Los_Angeles",
    "Pacific Daylight Time": "America/Los_Angeles",
    "Samoa Standard Time": "Pacific/Apia",
    "Hawaiian Standard Time": "Pacific/Honolulu",
    "Alaskan Standard Time": "America/Anchorage",
    "Atlantic Standard Time": "America/Halifax",
    "West Pacific Standard Time": "Pacific/Port_Moresby",
};

export const convertToDistrictDateTimeFormat = (date: string, selectedDistrictTimezone: string, format: string) => {
    let newDate = '';
    //@ts-ignore
    const timeZoneIdentifier = timeZones[selectedDistrictTimezone];
    if (timeZoneIdentifier) {
        newDate = moment.utc(date).tz(timeZoneIdentifier).format(format);
    } else {
        console.error(`Time zone not found for ${selectedDistrictTimezone}`);
    }
    return newDate;
};
