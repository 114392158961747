import administrationAPIClient from "./clients/administration-api.client";
import logger from "util/logger";
class FavoritesTransportLayer {

public static async getFavoritesAsync (userId: number, regionId:number): Promise<any> {
    try {
      const response = await administrationAPIClient({
        method: "GET",
        url: `/api/Favorite/GetFavoritesByUserIdAsync?userId=${userId}&regionId=${regionId}`,
      });
      return response.data;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }
  public static async deleteFavorite(favoriteId:number): Promise<any> {
    try {
      const response = await administrationAPIClient({
        method: "DELETE",
        url: `/api/Favorite/DeleteFavorite?favoriteId=${favoriteId}`,
      });
      return response.data;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }
  public static async updateFavorite(payload: any): Promise<any> {
    try {
      const response = await administrationAPIClient({
        method: "PATCH",
        url: `/api/Favorite/UpdateFavorite`,
        data: payload
      });
      return response.data;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

  public static async getRecentActivityByIdAsync(userId: number, regionId:number): Promise<any> {
    try {
      const response = await administrationAPIClient({
        method: "GET",
        url: `/api/RecentActivity/GetRecentActivitiesByUserIdAsync?userId=${userId}&regionId=${regionId}`,
      });
      return response.data;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

  public static async updateFavoritesOrderAsync(payload: any): Promise<any> {
    try {
      const response = await administrationAPIClient({
        method: "POST",
        url: `/api/Favorite/UpdateFavoriteOrderAsync`,
        data: payload
      });
      return response.data;
    } catch (error) {
      logger.info(error);
      throw error;
    }
  }

}
export default FavoritesTransportLayer;