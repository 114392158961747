import { createAction } from "@reduxjs/toolkit";

// Fetching SIte Type for Region
export const fetchSiteTypesByRegionInit = createAction(
    "FETCH_SITE_TYPE_INIT",
    (payload) => ({
        payload,
    }),
);

export const fetchSiteTypesByRegionSuccess = createAction(
    "FETCH_SITE_TYPE_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);

export const fetchSiteTypesByRegionFail = createAction("FETCH_SITE_TYPE_FAIL");

// Get All Site Type Types for Add Form
export const fetchAllSiteTypeTypesInit = createAction(
    "FETCH_ALL_SITE_TYPE_TYPES_INIT",
);
export const fetchAllSiteTypeTypesSuccess = createAction(
    "FETCH_ALL_SITE_TYPE_TYPES_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);
export const fetchAllSiteTypeTypeseFail = createAction(
    "FETCH_ALL_SITE_TYPE_TYPES_FAIL",
);

// Get all grades available for Add Form based on regionId
export const fetchSiteTypeGradesAddFormInit = createAction(
    "FETCH_SITE_TYPE_GRADES_ADD_FORM_INIT",
);
export const fetchSiteTypeGradesAddFormSuccess = createAction(
    "FETCH_SITE_TYPE_GRADES_ADD_FORM_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);
export const fetchSiteTypeGradesAddFormFail = createAction(
    "FETCH_SITE_TYPE_GRADES_ADD_FORM_FAIL",
);

// Get all Site Types
export const fetchAllSiteTypesInit = createAction("FETCH_ALL_SITE_TYPES_INIT");

export const fetchAllSiteTypesSuccess = createAction(
    "FETCH_ALL_SITE_TYPES_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);

export const fetchAllSiteTypesFail = createAction("FETCH_ALL_SITE_TYPES_FAIL");

// Get all USDA Site Types
export const fetchAllUSDASiteTypesInit = createAction(
    "FETCH_ALL_USDA_SITE_TYPES_INIT",
);

export const fetchAllUSDASiteTypesSuccess = createAction(
    "FETCH_ALL_USDA_SITE_TYPES_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);

export const fetchAllUSDASiteTypesFail = createAction(
    "FETCH_ALL_USDA_SITE_TYPES_FAIL",
);

// Adding a Site Type
export const sitetypeAddInit = createAction(
    "SITETYPE_ADD_INIT",
    (data: {
        description?: string;
        regionId?: number;
        siteTypeTypeCode?: string;
        addedBy?: number;
        grades?: number[];
    }) => ({
        payload: data,
    }),
);

// export const sitetypeAddInit = createAction("SITETYPE_ADD_INIT");

export const sitetypeAddSuccess = createAction(
    "SITETYPE_ADD_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);
export const sitetypeAddFail = createAction("SITETYPE_ADD_FAIL");

// Editing a Site Type
export const sitetypeEditInit = createAction(
    "SITETYPE_EDIT_INIT",
    (data: {
        description?: string;
        regionId?: number;
        siteTypeTypeCode?: string;
        modifiedBy?: number;
        grades?: number[];
        siteTypeId: number;
    }) => ({
        payload: data,
    }),
);
export const sitetypeEditSuccess = createAction(
    "SITETYPE_EDIT_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);
export const sitetypeEditFail = createAction("SITETYPE_EDIT_FAIL");
