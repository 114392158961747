import { deleteFavoriteFail, deleteFavoriteInit, deleteFavoriteSuccess, getFavoritesFail, getFavoritesInit, getFavoritesSuccess, getRecentsFail, getRecentsInit, getRecentsSuccess, updateFavoriteFail, updateFavoriteInit, updateFavoriteSuccess, updateFavoritesOrderFail, updateFavoritesOrderInit, updateFavoritesOrderSuccess } from "control/actions/myPages.action";
import FavoritesTransportLayer from "control/transport/myPages.transport";
import { isEmpty } from "lodash";
import { put, takeLatest } from "redux-saga/effects";
function* getFavoritesSaga(payload: any) {
  try {
    const response = yield FavoritesTransportLayer.getFavoritesAsync(payload?.payload?.userId, payload?.payload?.regionId);
    if (response.Description==="Success!" && response.StatusCode===200) {
      yield put(getFavoritesSuccess(response?.PayLoad));
    }
  } catch (error) {
    yield put(getFavoritesFail());
  }
}
function* deleteFavoriteSaga(payload: any) {
  try {
    const response = yield FavoritesTransportLayer.deleteFavorite(payload?.payload);
    if (response.Description === "Success!" && response.StatusCode === 200) {
      yield put(deleteFavoriteSuccess(true));
    }
  } catch (error) {
    yield put(deleteFavoriteFail());
  }
}
function* updateFavoriteSaga(payload: any) {
  try {
    const response = yield FavoritesTransportLayer.updateFavorite(payload?.payload);
    if (response.Description === "Success!" && response.StatusCode === 200) {
      yield put(updateFavoriteSuccess(true));
    }
  } catch (error) {
    yield put(updateFavoriteFail());
  }
}

function* updateFavoritesOrderSaga(payload: any) {
  try {
    const response = yield FavoritesTransportLayer.updateFavoritesOrderAsync(payload?.payload);
    if (response.Description === "Success!" && response.StatusCode === 200) {
      yield put(updateFavoritesOrderSuccess(true));
    }
  } catch (error) {
    yield put(updateFavoritesOrderFail());
  }
}

function* getRecentActivitiesSaga(payload: any) {
  try {
    const response = yield FavoritesTransportLayer.getRecentActivityByIdAsync(payload?.payload?.userId, payload?.payload?.regionId);
    if (!isEmpty(response)) {
      yield put(getRecentsSuccess(response?.PayLoad));
    }
  } catch (error) {
    yield put(getRecentsFail());
  }
}

export default function* watchMyPagesSagas() {
  yield takeLatest(getFavoritesInit, getFavoritesSaga);
  yield takeLatest(deleteFavoriteInit, deleteFavoriteSaga);
  yield takeLatest(updateFavoriteInit, updateFavoriteSaga);
  yield takeLatest(updateFavoritesOrderInit, updateFavoritesOrderSaga);
  yield takeLatest(getRecentsInit, getRecentActivitiesSaga);
}