import {
    Checkbox,
    Divider,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableRow,
    TableSortLabel,
} from "@material-ui/core";
import { Button, NotificationWithDialog, PageAlert } from "@primeroedge/ui-components";
import { deleteMail, readStatusMail, selectedMail, updateReadStatus } from "control/actions";
import { IMailEntity, MailEntity, MessageType } from "model/entity/mail.entity";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MailItem from "./MailItem";
import MailListToolbar from "./MailListToolbar";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        overflowY: "auto",
    },
    list: {
        height: "90%",
    },
}));

interface ISortType {
    type: "ASC" | "DESC";
    column: string;
}

interface IMailListProps {
    messageType: MessageType;
    notificationCb: (e: any) => void;
}

const MailList: FC<IMailListProps> = ({ messageType, notificationCb }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const mails = useSelector(MailEntity.getComposeMailOpenStatus);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedMails, setSelectedMails] = useState<string[]>([]);
    const [sortType, setSortType] = useState<ISortType>({
        type: "ASC",
        column: "UpdatedOn",
    });
    const [mailsData, setMailsData] = useState([]);
    const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);

    const handleSelectOneMail = (mailId: string): void => {
            setSelectedMails((prevSelectedMails) => {
                if (!prevSelectedMails.includes(mailId)) {
                    return [...prevSelectedMails, mailId];
                } else {
                    const deSelectedMails = prevSelectedMails.filter((id: string) => id !== mailId);

                    return [...deSelectedMails];
                }
            });
        };

    const selectAllMail = () => {
        setSelectedMails((prevSelectedMails: any) => {
            if (prevSelectedMails.length === mails.length) {
                return [];
            } else {
                const tempSelectedMails = mails.map((mail: IMailEntity) => mail.messageId);

                return [...tempSelectedMails];
            }
        });
    };

    const handleDeleteSelectedMails = () => {
        dispatch(deleteMail({
            id: selectedMails,
            type: messageType,
        }));
        setSelectedMails([]);
        notificationCb(true);
    };

    const handleReadSelectedMails = (mailParams: any) => {
        dispatch(readStatusMail({ id: selectedMails, ...mailParams }));

        setSelectedMails([]);
    };


    const openSelectedEmail = (mail: IMailEntity, selectedValue: string) => {
        const mailObject = {
            ...mail,
            selectedOption: selectedValue,
        };

        if (!mail.isRead) {
            dispatch(updateReadStatus([mail.messageId]));
        }

        dispatch(selectedMail(mailObject));
    };

    const handleOtherMenuOption = (value: string) => {
        setSelectedOption(value);
        const mail = mails.find((x: any) => x.messageId === selectedMails[0]);
        openSelectedEmail(mail, value);
    };

    useEffect(() => {
        const mailsData = mails ? mails : [];
        setMailsData(mailsData);
    }, [mails]);

    return (
        <div>

            <div className={classes.root}>

                <MailListToolbar
                    selectedMails={selectedMails}
                    mails={mailsData && mailsData.length}
                    handleDeleteSelectedMails={handleDeleteSelectedMails}
                    handleReadSelectedMails={handleReadSelectedMails}
                    handleOtherMenuOption={handleOtherMenuOption}
                    messageType={messageType}
                />
                <Divider />
                {mailsData && mailsData.length > 0 ?
                    (
                        <div className={classes.list}>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell padding="checkbox" width="5%">
                                                <Checkbox
                                                    indeterminate={mailsData?.length > 0 && selectedMails?.length > 0 && selectedMails?.length !== mailsData?.length}
                                                    checked={mailsData?.length > 0 && selectedMails?.length === mailsData?.length}
                                                    onChange={selectAllMail}
                                                    inputProps={{ "aria-label": "select all email" }}
                                                />
                                            </TableCell>
                                            <TableCell style={{ display: selectedMails?.length > 0 ? "inline" : "none" }} colSpan={4} width="5%">
                                                <Button
                                                    id="delete"
                                                    variant="default"
                                                    color="default"
                                                    label={`${selectedMails?.length > 1 ? "Delete All" : "Delete"}`}
                                                    onClick={() => {
                                                        setshowDeleteConfirm(true);
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell width="25%" style={{ display: selectedMails?.length > 0 ? "none" : "" }}>
                                                {messageType === MessageType.Sent ? "To" : "From"}
                                            </TableCell>
                                            <TableCell align="left" width="50%" style={{ display: selectedMails?.length > 0 ? "none" : "" }}>
                                                Subject
                                            </TableCell>
                                            <TableCell align="right" width="20%" style={{ display: selectedMails?.length > 0 ? "none" : "" }} sortDirection={sortType.type === "ASC" ? "asc" : "desc"}>
                                                <TableSortLabel
                                                    active={sortType.column === "createdOn"}
                                                    direction={sortType.type === "ASC" ? "asc" : "desc"}
                                                    onClick={(e: any) => {
                                                        e.stopPropagation();
                                                        const sortedMailsData = sortType.type === "ASC" ? mails.slice().sort((a: any, b: any) => (b.createdOn < a.createdOn ? -1 : 1))
                                                            : mails.slice().sort((a: any, b: any) => (a.createdOn < b.createdOn ? -1 : 1));
                                                        setSortType(() => ({
                                                            column: "createdOn",
                                                            type: sortType.type === "ASC" ? "DESC" : "ASC",
                                                        }));
                                                        setMailsData(sortedMailsData);
                                                    }}
                                                >
                                                    {messageType === MessageType.Sent ? "Sent on" : "Received on"}
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                        {mailsData.map((mail: IMailEntity) => (
                                            <MailItem
                                                key={mail.id}
                                                mail={mail}
                                                onSelect={handleSelectOneMail.bind(null, mail.messageId)}
                                                selected={selectedMails?.includes(mail.messageId)}
                                                selectedOption={selectedOption}
                                                messageType={messageType}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    ) : (
                        <PageAlert
                            title="No Messages"
                            message="Your messages will show up here!"
                        />
                    )
                }
                <NotificationWithDialog
                    message="Are you sure you want to delete the selected?"
                    open={showDeleteConfirm}
                    primaryAction={{
                        callback: () => {
                            handleDeleteSelectedMails();
                        },
                        title: "Confirm",
                    }}
                    secondaryAction={{
                        callback: () => {
                            setshowDeleteConfirm(false);
                        },
                        title: "Cancel",
                    }}
                    title="Confirmation"
                />
            </div>

        </div>
    );
};

export default MailList;
