import React from 'react';
import {
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { LoadingScreen } from "@primeroedge/ui-components";

import useMailWindowStyles from './useMailWindowStyles';

const DisplayEmailSelection = function ({
    isLoading,
    showList,
    list,
    selectEmailToSend,
    noUsersMessage
}: {
    isLoading: boolean;
    showList: boolean;
    list: any[];
    selectEmailToSend: (email: any) => void;
    noUsersMessage: string;
}) {
    const classes = useMailWindowStyles();

    if (isLoading) {
        return (
            <List classes={{ root: classes.loaderPosition }} subheader={<li />}>
                <LoadingScreen loading={isLoading} />
            </List>
        );
    } else if (list && list.length > 0 && showList) {
        return (
            <List id="send-to-list" classes={{ root: classes.emailsList }} subheader={<li />}>
                {list.map((emailEntity: any) => (
                    <ListItem
                        role="button"
                        button={
                            true
                        }
                        classes={{ root: classes.emailListItem }}
                        key={`email-${emailEntity.eMail}`}
                        onClick={selectEmailToSend.bind(null, emailEntity)}
                    >
                        <ListItemText primary={`${emailEntity.lastName}, ${emailEntity.firstName} (${emailEntity.eMail})`} />
                    </ListItem>

                ))}
            </List>

        );
    } else if (list && !isLoading && noUsersMessage) {
        return (
            <List classes={{ root: classes.loaderPosition }} subheader={<li />}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography color="error">
                            {noUsersMessage}
                        </Typography>
                    </CardContent>
                </Card>
            </List>
        );
    } else {
        return null;
    }
}

export default DisplayEmailSelection;