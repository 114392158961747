import { createReducer } from "@reduxjs/toolkit";
import * as actions from "control/actions/posconfig.actions";
import produce from "immer";

export enum POSConfigUpdateStatus {
    Initial = "Initial",
    Loading = "InProgress",
    SuccessWithResponseData = "SuccessWithResponseData",
    FailedWithNetworkError = "NetworkError",
    FailedWithUnknownError = "UnknownError",
    FailedWithProgramsNotConfiguredError = "ProgramsNotConfiguredError",
}

export interface IPOSConfigSetting {
    id: number;
    name: string;
    code: string;
    defaultValue: boolean;
    sites: number[];
}
export interface IGroupAreaSites {
    Sites: [];
}
export interface IGroupSites {
    Areas: IGroupAreaSites[];
    Sites: [];
}
export interface IPOSConfigState {
    isLoading: boolean;
    isUpdateLoading: POSConfigUpdateStatus;
    configSettings: IPOSConfigSetting[];
    siteTypeByRegion: [];
    sitesConfigurationTypes: IGroupSites[];
}

const initialState: IPOSConfigState = {
    isLoading: false,
    isUpdateLoading: POSConfigUpdateStatus.Initial,
    configSettings: [],
    siteTypeByRegion: [],
    sitesConfigurationTypes: [],
};

export const posConfigReducer = createReducer(initialState, {

    [actions.fetchPOSConfigInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
        }),

    [actions.fetchPOSConfigSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.configSettings = action.payload?.data;
            draft.isLoading = false;
        }),

    [actions.fetchPOSConfigFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
        }),

    [actions.fetchPOSRegionSiteTypesSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.siteTypeByRegion = action.payload?.data?.siteTypes;
        }),

    [actions.fetchPOSRegionSiteTypesFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.siteTypeByRegion = [];
        }),
    [actions.fetchPOSSitesConfigurationTypesByRegionSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.sitesConfigurationTypes = action.payload?.data;
        }),
    [actions.fetchPOSSitesConfigurationTypesByRegionFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.sitesConfigurationTypes = [];
        }),

    [actions.clearPOSConfigUpdateStatus.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isUpdateLoading = POSConfigUpdateStatus.Initial;
        }),
    [actions.updatePOSSitesConfigurationRequest.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isUpdateLoading = POSConfigUpdateStatus.Loading;
        }),
    [actions.updatePOSSitesConfigurationSuccess.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isUpdateLoading = POSConfigUpdateStatus.SuccessWithResponseData;
        }),
    [actions.updatePOSSitesConfigurationFailure.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isUpdateLoading = POSConfigUpdateStatus.FailedWithUnknownError;
        }),
});
