import {
    Button, IButtonProps,
    IGridCellProps, IGridDataStateChangeEvent, KGrid,
    Notification,
    NotificationWithDialog,
    PageLayout,
    PlusIcon,
} from "@primeroedge/ui-components";
import { State } from "@progress/kendo-data-query";
import { GridRowClickEvent } from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import { deleteTaskAction, FetchTasksAction } from "control/actions";
import { getTasks, getTotalTaskCount, isTasksLoading } from "model/entity";
import { ITask } from "model/entity/tasks.entity";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddNewTask from "./add-task";
import TableActions from "./table-actions";
import { tasksTableColumns } from "./task-utils";
import moment from "moment";

interface IGridState extends State {
  skip: number;
  take: number;
}

const TasksPage = () => {
  const tasks = useSelector(getTasks);
  const totalTaskCount = useSelector(getTotalTaskCount);
  const gridLoading = useSelector(isTasksLoading);
  const [openNewTask, canOpenNewTask] = useState(false);
  const [showAlert, canShowAlert] = useState(false);
  const [editableTask, setEditableTask] = useState<ITask | null | undefined>(
    null,
  );
  const [alertMessage, setAlertMessage] = useState("");
  const [gridState, setGridState] = useState<IGridState>({
    skip: 0,
    take: 5,
    sort: [{ field: "dueDate", dir: "asc" }],
  });
  const [deletableTask, setDeletableTask] = useState("");
  const [selectedTasks, setSelectedTasks] = useState<any>({ selected: [] });
  const [deleteButtonLabel, setDeleteButtonLabel] = useState<string>("Delete");
  const [gridData, setGridData] = useState<any[]>([]);

  const dispatch = useDispatch();
  const tableColumns = [...tasksTableColumns];
  if (tableColumns.length === 5) {
    tableColumns.push({
      cell: (rowData: IGridCellProps) => (
        <TableActions
          {...rowData}
          onEdit={onEditClick.bind(null, rowData.dataItem.id)}
          onDelete={onDeleteClick.bind(null, rowData.dataItem.taskId)}
        />
      ),
      field: "Actions",
      sortable: false,
      title: "Action(s)",
      width: "7%",
    });
  }

  const onEditClick = (id: string) => {
    setEditableTask(tasks.find((task: ITask) => task.id === id));
    canOpenNewTask(() => true);
  };

  const onDeleteClick = (id: string) => {
    setDeletableTask(() => id);
  };

  const confirmDelete = (ids: string) => {
    if (tasks) {
      const index = tasks.findIndex((task: ITask) => task.taskId === ids);

      const id = [ids];

      dispatch(deleteTaskAction({ id, index }));
      setAlertMessage(() => "Deleted successfully!");
      canShowAlert(() => true);
    }
  };

  const cancelDelete = () => {
    setDeletableTask(() => "");
  };

  const actionButtonProps: IButtonProps = {
    id: "newTask",
    label: "New Task",
    name: "NewTask",
    type: "button",
    onClick: () => {
      canOpenNewTask(true);
    },
    startIcon: <PlusIcon />,
  };

  const dispatchTasks = () => {
    dispatch(
      FetchTasksAction({
        pageSize: gridState.take,
        pageNumber: gridState.skip / gridState.take + 1,
        sortColumn:
          gridState.sort && gridState.sort.length > 0
            ? `${gridState.sort[0].field}-${gridState.sort[0].dir}`
            : "dueDate-asc",
      }),
    );
  };

  const handleDataStateChange = (ev: any) => {
    setGridState(ev.dataState);
  };

  const onCloseAdd = (success: boolean) => {
    canOpenNewTask(false);
    setEditableTask(null);
    if (success) {
      setAlertMessage(() => Boolean(editableTask) ? "Task has been updated successfully!": "Task has been created successfully!");
      canShowAlert(() => true);
      dispatchTasks();
    }
  };

  const closeAlert = () => {
    canShowAlert(() => false);
    setAlertMessage(() => "");
  };

  useEffect(() => {
    dispatchTasks();
  }, []);

  useEffect(() => {
    dispatchTasks();
  }, [gridState]);

  useEffect(() => {
    if (tasks?.length < gridState.take && deletableTask !== "") {
      setDeletableTask(() => "");
      dispatch(
        FetchTasksAction({
          pageSize: gridState.take,
          pageNumber: gridState.skip / gridState.take + 1,
          sortColumn:
            gridState.sort && gridState.sort.length > 0
              ? `${gridState.sort[0].field}-${gridState.sort[0].dir}`
              : "dueDate-asc",
        }),
      );
    }
  }, [tasks]);

  useEffect(() => {
    const { selected } = selectedTasks;
    const selectedTaskIds = selected.filter((e: any) => e.Selected).map((e: any) => e.id);
    if (selectedTaskIds.length > 1) {
      setDeleteButtonLabel("Delete All");
    } else {
      setDeleteButtonLabel("Delete");
    }

  }, [selectedTasks]);

  const onRowClick = (event: GridRowClickEvent) => {
      setEditableTask(tasks.find((task: ITask) => task.id === event.dataItem.id));
      canOpenNewTask(true); 
  };

  const deleteAllTasks = () => {
    if (selectedTasks) {
      const { selected } = selectedTasks;
      if (selected && selected.length > 0) {
        const selectedTaskIds = selected.filter((e: any) => e.Selected).map((e: any) => e.taskId);
        setDeletableTask(selectedTaskIds);
      }
    }
  };

  const handleSelectionChange = (ev: IGridDataStateChangeEvent) => {
    setSelectedTasks({ selected: ev });
  };

  useEffect(() => {
        if (tasks && tasks.length > 0) {
            const data = tasks.map((task: any) => {
                const item = { ...task };
                item.dueDate =moment(task.dueDate).format("MM/DD/YYYY")
                return item;
            });
            setGridData(data);
        } else {
          setGridData([])
        }
}, [tasks]);

  return (
    <>
      <Notification
        color="success"
        message={alertMessage}
        onClose={closeAlert}
        open={showAlert}
        duration={5000}
        variant={"filled"}
      />
      <PageLayout
        title="Tasks"
        breadCrumbs={[{ title: "Workspace", path: "/" }]}
        action={actionButtonProps}
      >
        <div style={{width: '100%',height:"100vh", marginBottom:"70px"}}>
        <KGrid
          columns={tableColumns}
          data={gridData}
          primaryField="id"
          selectedField="Selected"
          pageSizes={true}
          pageable={true}
          {...gridState}
          processedData={true}
          onDataStateChange={handleDataStateChange}
          headerStyles={["no-background"]}
          loading={gridLoading}
          total={totalTaskCount}
          onRowClick={onRowClick}
          sortable={true}
          rowClick={true}
          onSelectionChange={handleSelectionChange}
          selectionActions={(
            <Button
              id="defaultButton"
              label={deleteButtonLabel}
              color="default"
              onClick={deleteAllTasks}
            />
          )}
        />
        </div>
        <AddNewTask
          open={openNewTask}
          onClose={onCloseAdd}
          editable={Boolean(editableTask)}
          editableTask={editableTask}
        />
        <NotificationWithDialog
          open={deletableTask !== ""}
          title="Delete Task"
          message="Are you sure you want to delete this task?"
          color="primary"
          primaryAction={{
            title: "Delete",
            callback: () => confirmDelete(deletableTask),
          }}
          secondaryAction={{
            title: "Cancel",
            callback: cancelDelete,
          }}
        />
      </PageLayout>
    </>
  );
};

export default TasksPage;
