import { createAction } from "@reduxjs/toolkit";
import { IUserProfile } from "../../model/entity/profile.entity";

export const setUserProfile = createAction(
    "SET_PROFILE",
    (data: IUserProfile) => ({ payload: data }),
);

export const updateUserProfile = createAction(
    "UPDATE_PROFILE",
    (data: Partial<IUserProfile>) => ({ payload: data }),
);

export const saveProfileInfoAction = createAction(
    "SAVE_UPDATED_PROFILE",
    (data: any) => ({ payload: data }),
);

export const saveProfileInfoSuccess = createAction(
    "SAVE_UPDATED_PROFILE_SUCCESS",
    (data: any) => ({ payload: data }),
);

export const resetProfileUpdateStatus = createAction(
    "RESET_PROFILE_STATUS",
);

export const viewRolesInit = createAction("VIEW_ROLES_INIT");

export const viewRolesSuccess = createAction(
    "VIEW_ROLES_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const viewRolesFail = createAction("VIEW_ROLES_FAIL");

export const getUserDetailsKeyclockInit = createAction("GET_USER_DETAILS_KEYCLOCK_INIT", (data: any) => ({
    payload: data,
}));

export const getUserDetailsKeyclockInitSuccess = createAction(
    "GET_USER_DETAILS_KEYCLOCK_SUCCESS",
    (data) => ({
        payload: data,
    }),
);

export const getUserDetailsKeyclockInitFail = createAction("GET_USER_DETAILS_KEYCLOCK_FAIL");

export const userConfirmPasswordInit = createAction("USER_CONFIRM_PASSWORD_INIT", (user) => ({
    payload: user,
}));

export const userConfirmPasswordSuccess = createAction("USER_CONFIRM_PASSWORD_SUCCESS", (data) => ({
    payload: data,
}));

export const userConfirmPasswordFail = createAction("USER_CONFIRM_PASSWORD_FAIL");

export const passwordPoliciesInit = createAction("PASSWORD_POLICIES_INIT");

export const passwordPoliciesSuccess = createAction(
    "PASSWORD_POLICIES_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const passwordPoliciesFail = createAction("PASSWORD_POLICIES_FAIL");

export const getRemovePictureInit = createAction(
    "GET_REMOVE_PICTURE_INIT", (data: any) => ({
        payload: data,
    }));

export const getRemovePictureSuccess = createAction(
    "GET_REMOVE_PICTURE_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const getRemovePictureFail = createAction(
    "GET_REMOVE_PICTURE_FAIL",
);
