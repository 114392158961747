import { APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY } from "control/transport/clients/api-constants";
import { IRegionLanguage } from "model/entity/profile.entity";
import logger from "util/logger";
import administrationAPIClient from "./clients/administration-api.client";
import dataExchangeAPIClient from "./clients/data-exchange-api.client";
import userManagementAPIClient from "./clients/user-management-api.client";

class ProfileTransport {
    public static async getUser (email: any): Promise<any> {
        const res = await userManagementAPIClient({
            method: "GET",
            url: `/api/Users/GetUserInfoByEmail?email=${email}`,
        });

        return res.data;
    }

    public static async updateUser (userInfo: any): Promise<any> {
        try {
            delete userInfo.userStatus
            const res = await userManagementAPIClient({
                method: "PUT",
                url: `/api/Users/UpdateUserAsync`,
                data: userInfo,
            });

            const { StatusCode, PayLoad } = res.data;
            if (StatusCode === 200) {
                return PayLoad;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    public static async getGlobalLanguages (): Promise<[] | IRegionLanguage[]> {
        try {
            const response = await administrationAPIClient({
                method: "GET",
                url: `/api/Languages/Region`,
            });
            const { StatusCode, PayLoad } = response.data;
            if (StatusCode === 200) {
                return PayLoad;
            } else return [];
        } catch (e) {
            return [];
        }
    }

    public static async saveImageFile (image: any) {
        try {
            const formData = new FormData();
            formData.append("file", image);
            const response = await dataExchangeAPIClient({
                method: "POST",
                url: `/api/FileStorage/Upload`,
                data: formData,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "multipart/form-data",
                    Environment: APIM_ENVIRONMENT,
                    "Ocp-Apim-Subscription-Key": APIM_SUBSCRIPTION_KEY,
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    FeatureName: "user",
                    foldername: "profilepicture",
                },
            });
            const { PayLoad } = response.data;

            return PayLoad;
        } catch (e) {
            return "";
        }
    }

    public static async getRoles (): Promise<any> {
        try {
            const response = await userManagementAPIClient({
                method: "GET",
                url: `/api/Roles/GetRolesAsync`,
            });

            return response.data.PayLoad;
        } catch (e) {
            return "";
        }
    }

    public static async userPasswordTransport (user: any): Promise<any> {
        const dynamicPayload = user.payload;
        const res = await userManagementAPIClient({
            method: "POST",
            url: `/api/Users/UserProfilePasswordResetEmailAsync`,
            data: dynamicPayload,
        });

        return res.data;
    }

    public static async getPasswordPolicies (): Promise<any> {
        const res = await administrationAPIClient({
            method: "GET",
            url: `/api/Security/PasswordPolicesByRegion`,
        });

        return res.data.PayLoad;
    }

    public static async removePicture (payload: any): Promise<any> {
        const removePictureId = payload.payload;
        try {
            const response = await dataExchangeAPIClient({
                method: "DELETE",
                url: `/api/FileStorage/Delete?featureName=user&fileName=${removePictureId}`,
            });

            return response.data;
        } catch (error) {
            logger.info(error);
            throw error;
        }
    }
}

export default ProfileTransport;
