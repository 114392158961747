import { createAction } from "@reduxjs/toolkit";

// GET WELCOME
export const getWelcomeInit = createAction("GET_WELCOME_INIT",
(payload) => ({
    payload,
}),
);

export const getWelcomeSuccess = createAction(
"GET_WELCOME_SUCCESS",
(payload) => ({
    payload,
}),
);

export const getWelcomeFail = createAction("GET_WELCOME_FAIL");

// POST WELCOME
export const postWelcomeInit = createAction("POST_WELCOME_INIT",
(payload) => ({
    payload,
}),
);

export const postWelcomeSuccess = createAction(
"POST_WELCOME_SUCCESS",
(payload) => ({
    payload,
}),
);

export const postWelcomeFail = createAction("POST_WELCOME_FAIL");

// GET UPDATED WELCOME
export const getActivitiesInit = createAction("GET_ACTIVITIES_INIT",
(payload) => ({
    payload,
}),
);

export const getActivitiesSuccess = createAction(
"GET_ACTIVITIES_SUCCESS",
(payload) => ({
    payload,
}),
);

export const getActivitiesFail = createAction("GET_ACTIVITIES_FAIL");

// POST UPDATED WELCOME
export const postActivitiesInit = createAction("POST_ACTIVITIES_INIT",
(payload) => ({
    payload,
}),
);

export const postActivitiesSuccess = createAction(
"POST_ACTIVITIES_SUCCESS",
(payload) => ({
    payload,
}),
);

export const postActivitiesFail = createAction("POST_ACTIVITIES_FAIL");
