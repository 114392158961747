export interface PositionInterface {
    right: number;
    top: number;
    width: number;
    height: number;
}

export interface IRecipientUser {
    recipientId: string;
    recipientFirstName: string;
    recipientLastName: string;
    recipientEmail: string;
    recipientPrimeroUserId: string;
}

export enum ResponseStatus {
    Initial = "initial",
    Loading = "loading",
    Success = "success",
    Error = "error",
}

export interface IResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
    PayLoad?: any;
    payLoad?: any;
}