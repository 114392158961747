import { Label } from "@primeroedge/ui-components";
import { ITask } from "model/entity/tasks.entity";
import React from "react";

interface ITaskStatusLabelProps {
    task: ITask;
}

const TaskStatusLabel = ({ task }: ITaskStatusLabelProps) => {
    const statusColor = (status: string) => {
        if (status === "Done") {
            return "success";
        } else if (status === "InProgress") {
            return "default";
        } else return "primary";
    };

    return (
        <td role="gridcell">
            <Label color={statusColor(task.status)} id={task.id} style={{ fontSize: 11 }}>
                {task.status}
            </Label>
        </td>
    );
};

export default TaskStatusLabel;
