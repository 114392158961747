import { createReducer } from "@reduxjs/toolkit";
import { deleteFavoriteFail, deleteFavoriteInit, deleteFavoriteSuccess, getFavoritesFail, getFavoritesInit, getFavoritesSuccess, getRecentsFail, getRecentsInit, getRecentsSuccess, updateFavoriteFail, updateFavoriteInit, updateFavoriteSuccess } from "control/actions/myPages.action";
import produce from "immer";
import { IMyPages } from "model/entity/myPages.entity";
const initialState: IMyPages = {
    favoritesList: [],
    recentsList: [],
    isLoading: false,
    isFavoriteDeleted: false,
    updateFavoriteStatus: false
}
export const myPagesReducer = createReducer(initialState, {
    [getFavoritesInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
            draft.favoritesList = [];
        }),
    [getFavoritesSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.favoritesList = action.payload;
            draft.isLoading = false;
        }),
    [getFavoritesFail.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.isLoading = false;
            action.payload = [];
        }),
    [deleteFavoriteInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
            draft.isFavoriteDeleted = false;
        }),
    [deleteFavoriteSuccess.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isFavoriteDeleted = true;
            draft.isLoading = false;
        }),
    [deleteFavoriteFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
            draft.isFavoriteDeleted = false;
        }),
    [updateFavoriteInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
            draft.updateFavoriteStatus = false;
        }),
    [updateFavoriteSuccess.toString()]: (state) =>
        produce(state, (draft) => {
            draft.updateFavoriteStatus = true;
            draft.isLoading = false;
        }),
    [updateFavoriteFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
            draft.updateFavoriteStatus = false;
        }),
    [getRecentsInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
            draft.recentsList = [];
        }),
    [getRecentsSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.recentsList = action.payload;
            draft.isLoading = false;
        }),
    [getRecentsFail.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.isLoading = false;
            action.payload = [];
        }),
});