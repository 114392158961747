import { IAppState } from "../reducers";

export const getSelectedUsers = (state: IAppState) => {
  return state.messages.selectedUsers;
};

export interface IMessageResponse {
  selectedUsers: any[];
}

export interface IGlobalMessage {
  isGlobalMessage: boolean;
  messagesDismissed: string[]
}

export const getIsGlobalMessage = (state: IAppState) => {
  return state.isGlobalMessage.isGlobalMessage
}
export const getGlobalMessagesDismissed = (state: IAppState) => {
  return state.isGlobalMessage.messagesDismissed;
}