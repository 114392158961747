import { isNil } from "lodash";


export interface IUseWorkerProps {
    onMessageSuccess: (result: any) => void;
    onMessageError?: () => void;
}

export interface IUseWorkerResult {
    sendMessage: (message: any) => void;
}

const useProfileImageSaver = function ({
    onMessageSuccess,
    onMessageError,
}: IUseWorkerProps) {

     const convertDataToString = async (blob: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = () => reject(null);
                reader.readAsDataURL(blob);
            } catch (e) {
                console.log('error in reading ', e);
            }
        });
    };

    const storeProfileImage = async function (imageBlob: any) {
        if (imageBlob) {
            const imageAsString = await convertDataToString(imageBlob);
            if (!isNil(imageAsString)) {
                localStorage.setItem('rememberMeProfileImage', imageAsString);
                return true;
            }
            return false;
        }
        return false;
    };

    const sendMessage = async function (message: any) {
        const result = await storeProfileImage(message)
        if (result) {
            onMessageError?.();
        } else {
            onMessageSuccess(result);
        }
    };


    return {
        sendMessage,
    };
};

export default useProfileImageSaver;
