import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import * as actions from "../../control/actions/customReportMail.actions";
import { ResponseStatus } from 'types/mail';
import { ICustomReportsMail } from "model/entity/CustomReports.entity";

const initialState: ICustomReportsMail = {
    userList: [],
    attachments: [],
    status: ResponseStatus.Initial,
    email: {
        isSending: false,
        isSent: false,
        isError: false,
    }
};

export const customReportMailReducer = createReducer(initialState, {
    [actions.setUserMail.toString()]: (state, action) =>
        produce(state, (draft: ICustomReportsMail) => {
            draft.userList = action.payload;
        }),
    [actions.setMailListServiceCallStatus.toString()]: (state, action) =>
        produce(state, (draft: ICustomReportsMail) => {
            draft.status = action.payload;
        }),
    [actions.sendReportEmailInit.toString()]: (state) =>
        produce(state, (draft: ICustomReportsMail) => {
            draft.email.isSending = true;
        }),
    [actions.sendReportEmailSuccess.toString()]: (state) =>
        produce(state, (draft: ICustomReportsMail) => {
            draft.email.isSending = false;
            draft.email.isSent = true;
        }),
    [actions.sendReportEmailError.toString()]: (state) =>
        produce(state, (draft: ICustomReportsMail) => {
            draft.email.isSending = false;
            draft.email.isError = true;
        }),

    [actions.sendReportEmailReset.toString()]: (state) =>
        produce(state, (draft: ICustomReportsMail) => {
            draft.email.isSending = false;
            draft.email.isError = false;
            draft.email.isSent = false;
            draft.userList = []
        }),
});
