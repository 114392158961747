import { PlusIcon, TableSearchLayout } from "@primeroedge/ui-components";
import React, { useEffect, useState } from "react";
import fetchMessages from "../../../../../control/transport/message-api";



const columns: any[] = [
    { field: "Selected", filterable: false, width: "50px" },
    { field: "recipientFirstName", title: "Shown To", width: 200 },
    { field: "content", title: "Message", width: 650 },
    {
        field: "createdOn",
        title: "Posted On",
        width: 250,
        template:
            "#= kendo.toString(kendo.parseDate(createdOn, 'YYYY-MM-DD'), 'MM/dd/yyyy') #",
    },
];

export default function Messages () {
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(0);
    const [messages, setMessages] = useState([]);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(5);
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        fetchMessages(take, page, setMessages, setSize, setLoading);
    }, []);

    const pageChange = (event: any) => {
        setLoading(true);
        setSkip(event.page.skip);
        setTake(event.page.take);
        setPage(skip / take + 1);
        fetchMessages(
            event.page.take,
            event.page.skip / event.page.take + 1,
            setMessages,
            setSize,
            setLoading
        );
    };

    return (
       <>
        <TableSearchLayout
            action={{
                id: "addButton",
                label: "Messages",
                onClick: function noRefCheck () {},
                startIcon: <PlusIcon />,
            }}
            breadCrumbs={[
                {
                    path: "/",
                    title: "Workspace",
                },
            ]}
            kGridData={{
                columns,
                data: messages,
                filterable: false,
                groupable: false,
                onSelectionChange: function noRefCheck () {},
                skip,
                total: size,
                pageSize: take,
                pageSizes: [5, 10, 20, 30],
                pageable: true,
                primaryField: "message",
                selectedField: "Selected",
                sortable: true,
                onPageChange: pageChange,
                loading: isLoading
            }}
            title="Messages"
            filterOnSubmit={() => {}}
            gridPadding={false}
            children={undefined}
        />
       </>
    );
}
