import { createAction } from "@reduxjs/toolkit";

export const fetchPOSConfigInit = createAction("FETCH_POS_CONFIG_INIT");
export const fetchPOSConfigSuccess = createAction("FETCH_POS_CONFIG_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);
export const fetchPOSConfigFail = createAction("FETCH_POS_CONFIG_FAIL");
export const fetchPOSRegionSiteTypes = createAction("FETCH_POS_REGION_SITE_TYPES",
    (data) => ({
        payload: {
            data,
        },
    }),
);
export const fetchPOSRegionSiteTypesSuccess = createAction("FETCH_POS_REGION_SITE_TYPES_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);
export const fetchPOSRegionSiteTypesFail = createAction("FETCH_POS_REGION_SITE_TYPES_FAIL");

export const fetchPOSSitesConfigurationTypesByRegion = createAction("FETCH_POS_SITES_CONFIGURATION_TYPES",
    (data) => ({
        payload: {
            data,
        },
    }),
);
export const fetchPOSSitesConfigurationTypesByRegionSuccess = createAction("FETCH_POS_SITES_CONFIGURATION_TYPES_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);
export const fetchPOSSitesConfigurationTypesByRegionFail = createAction("FETCH_POS_SITES_CONFIGURATION_TYPES_FAIL");

export const updatePOSSitesConfigurationRequest = createAction(
    "UPDATE_POS_SITES_CONFIGURATION_REQUEST",
    (payload) => ({
        payload,
    }),
);
export const updatePOSSitesConfigurationSuccess = createAction(
    "UPDATE_POS_SITES_CONFIGURATION_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const updatePOSSitesConfigurationFailure = createAction(
    "UPDATE_POS_SITES_CONFIGURATION_FAILURE",
    (payload) => ({
        payload,
    }),
);

export const clearPOSConfigUpdateStatus = createAction("CLEAR_POS_CONFIG_UPDATE_STATUS");
