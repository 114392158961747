import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import {
    addTaskAction, AuthLogout,
    FetchTasksSuccessAction,
    SetTasks,
    SetTasksDuration,
    SetTasksStatus,
    SetUpdatedTasks,
    updatedAction,
    updateDeletedTask,
    updateTaskAction,
} from "../../control/actions";
import { ITask, ITaskReducer, ResponseStatus } from "../entity";

const initialState: ITaskReducer = {
    totalPages: -1,
    totalTaskCount: -1,
    tasks: [],
    updatedTasks: [],
    newTask: null,
    status: ResponseStatus.Initial,
    updated: false,
};

export const tasksReducer = createReducer(initialState, {
    [FetchTasksSuccessAction.toString()]: (state, action) =>
        produce(state, (draft: ITaskReducer) => {
            draft.tasks = action.payload.tasks;
            if (action.payload.totalPages !== -1) {
                draft.totalPages = action.payload.totalPages;
                draft.totalTaskCount = action.payload.totalTaskCount;
            }
        }),
    [SetTasksStatus.toString()]: (state, action) =>
        produce(state, (draft: ITaskReducer) => {
            draft.status = action.payload;
        }),
    [SetTasks.toString()]: (state, action) =>
        produce(state, (draft: ITaskReducer) => {
            draft.tasks = action.payload;
        }),
    [SetUpdatedTasks.toString()]: (state, action) =>
        produce(state, (draft: ITaskReducer) => {
            draft.updatedTasks = action.payload;
        }),
    [SetTasksDuration.toString()]: (state, action) =>
        produce(state, (draft: ITaskReducer) => {
            draft.tasksDuration = action.payload;
        }),
    [updateDeletedTask.toString()]: (state, action) =>
        produce(state, (draft: ITaskReducer) => {
            draft.tasks.splice(action.payload, 1);
        }),
    [updateTaskAction.toString()]: (state, action) =>
        produce(state, (draft: ITaskReducer) => {
            const tasks = draft.tasks.map((task: ITask) => {
                if (task.id === action.payload.id) {
                    task.status = action.payload.status;
                    task.dueDate = action.payload.dueDate;
                    task.description = action.payload.description;
                    task.title = action.payload.title;
                }

                return task;
            });
            draft.tasks = [...tasks];
        }),
    [addTaskAction.toString()]: (state, action) =>
        produce(state, (draft: ITaskReducer) => {
            draft.tasks = [...draft.tasks, action.payload];
        }),
    [updatedAction.toString()]: (state, action) =>
        produce(state, (draft: ITaskReducer) => {
            draft.updated = action.payload;
        }),
    [AuthLogout.toString()]: () => initialState,
});
