import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// NOTE: At this moment, this ReactQuill does not export
// the types for props and we cannot extend them
interface IQuillEditorProps {
    className?: string;

    [key: string]: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& .ql-toolbar": {
            borderLeft: "none",
            borderTop: "none",
            borderRight: "none",
            color: theme.palette.primary.main,
            borderBottom: `1px solid ${theme.palette.divider}`,
            "& .ql-picker-label:hover": {
                color: theme.palette.secondary.main,
            },
            "& .ql-picker-label.ql-active": {
                color: theme.palette.secondary.main,
            },
            "& .ql-picker-item:hover": {
                color: theme.palette.secondary.main,
            },
            "& .ql-picker-item.ql-selected": {
                color: theme.palette.secondary.main,
            },
            "& button:hover": {
                color: theme.palette.secondary.main,
                "& .ql-stroke": {
                    stroke: theme.palette.secondary.main,
                },
            },
            "& button:focus": {
                color: theme.palette.secondary.main,
                "& .ql-stroke": {
                    stroke: theme.palette.secondary.main,
                },
            },
            "& button.ql-active": {
                "& .ql-stroke": {
                    stroke: theme.palette.secondary.main,
                },
            },
            "& .ql-stroke": {
                stroke: theme.palette.primary.main,
            },
            "& .ql-picker": {
                color: theme.palette.text.primary,
            },
            "& .ql-picker-options": {
                padding: theme.spacing(2),
                backgroundColor: theme.palette.background.default,
                border: "none",
                boxShadow: theme.shadows[10],
                borderRadius: theme.shape.borderRadius,
            },
        },
        "& .ql-container": {
            border: "none",
            "& .ql-editor": {
                fontFamily: theme.typography.fontFamily,
                fontSize: 16,
                color: theme.palette.text.primary,
                overflowY: "auto",
                maxHeight: "300px",
                "&.ql-blank::before": {
                    color: theme.palette.text.secondary,
                },
            },
        },
        "& .ql-snow .ql-tooltip": {
            marginLeft: 140,
        },
    },
}));

const QuillEditor: FC<IQuillEditorProps> = ({
    className,
    ...rest
}: IQuillEditorProps) => {
    const classes = useStyles();

    return <ReactQuill className={clsx(classes.root, className)} {...rest} modules={{
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }]
        ],
    }} />;
};

export default QuillEditor;
