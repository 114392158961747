import DateFnsUtils from "@date-io/date-fns";
import { FormControl, Grid, makeStyles, Typography } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    Button, Card, Checkbox, CheckCircleFilledIcon,
    CheckCircleOutlineIcon,
    ChevronRightIcon,
} from "@primeroedge/ui-components";
import { getWelcomeInit, postWelcomeInit } from "control/actions";
import { getIsUserLoading, IWelcomeList } from "model/entity";
import { getWelcome } from "model/entity/onboarding.entity";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Activities from "../activities-page";

const useStyles = makeStyles(() => ({
    root: {
    },
    paddingAll: {
        padding: "100px 0px 0px 0px",
    },
    header: {
        color: "#7266DB",
    },
    date: {
        width: "100%",
    },
    selectAll: {
        padding: "1opx",
    },
    fRight: {
        float: "right",
    },
    borderNth: {
        borderBottom: "1px solid #CCCCCC",
        "&:last-child": {
            borderBottom: "0px",
        },
    },
    fw: {
        fontWeight: "normal",
    },
    cards: {
        minHeight: "auto",
        width: "100%",
        alignContent: "center",
    },
    greyHeaders: {
        color: "lightgrey",
    }
}));

const WelcomePage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const welcomeList: IWelcomeList[] = useSelector(getWelcome);
    const isUserLoading = useSelector(getIsUserLoading);
    const [triggerSelection, setTriggerSelection] = useState<any>({});
    const [targetDate, setTargetDate] = useState<any>(new Date());
    const [readyDate, setReadyDate] = useState<any>(new Date());
    const [targetDateUpdated, setTargetDateUpdated] = useState<boolean>(false);
    const [readytDateUpdated, setReadyDateUpdated] = useState<boolean>(false);
    const [welcomeScreen, setWelcomeScreen] = useState<boolean>(true);
    const [activitiesScreen, setActivitiesScreen] = useState<boolean>(false);
    const [enableStart, setEnableStart] = useState<boolean>(false);

    const selectFunction = () => {
        return (
            <p className={classes.selectAll}>Select All</p>
        );
    };
    const triggerNavigation = () => {
        setWelcomeScreen(false);
        setActivitiesScreen(true);
    };
    const onTargetDateChange = (date: any) => {

        let parsedDate = new Date(date);
        let adjustedDate = new Date(parsedDate.getTime() - parsedDate.getTimezoneOffset() * 10000);
        setTargetDate(adjustedDate);
        setTargetDateUpdated(!targetDateUpdated);
        setReadyDateUpdated(false);
    };
    const onReadyDateChange = (date: any) => {
        setTargetDateUpdated(false);
        let parsedDate2 = new Date(date);
        let adjustedDate2 = new Date(parsedDate2.getTime() - parsedDate2.getTimezoneOffset() * 10000);
        setReadyDate(adjustedDate2);
        setReadyDateUpdated(!readytDateUpdated);
    };

    useEffect(() => {
        welcomeList.find((module) => module.feature === "Target Go-Live Date" && setTargetDate(module.featureDueBy));
        welcomeList.find((module) => module.feature === "Readiness Date" && setReadyDate(module.featureDueBy));
    }, [welcomeList]);
    const activeDistrict = localStorage.getItem("activeDistrict");
    const selectedActiveDistrict = activeDistrict && JSON.parse(activeDistrict);
    const regionId = selectedActiveDistrict.attributes.regionId[0];
    useEffect(() => {
        if (regionId) {
            dispatch(getWelcomeInit(regionId));
        }
    }, [isUserLoading, regionId]);

    useEffect(() => {
        if (triggerSelection !== {} && triggerSelection.moduleId !== undefined) {
            let tempSelection = {};
            if (targetDateUpdated) {
                tempSelection = {
                    ...triggerSelection,
                    isFeatureSelected: !triggerSelection.isFeatureSelected,
                    featureDueBy: targetDate,
                };
            } else if (readytDateUpdated) {
                tempSelection = {
                    ...triggerSelection,
                    isFeatureSelected: !triggerSelection.isFeatureSelected,
                    featureDueBy: readyDate,
                };
            } else {
                tempSelection = { ...triggerSelection, isFeatureSelected: !triggerSelection.isFeatureSelected };
            }
            dispatch(postWelcomeInit(tempSelection));
            setReadyDateUpdated(false);
            setTargetDateUpdated(false);
            setTriggerSelection({});
        }
    }, [triggerSelection]);

    const activityList = () => {
        let activities: any = [];
        welcomeList.map((moduleItem: any) => {
            if (moduleItem.moduleType === "System" && moduleItem.feature !== "Target Go-Live Date" && moduleItem.feature !== "Readiness Date") {
                let tempItem = { ...moduleItem };
                tempItem.isFeatureSelected = !enableStart;
                activities.push(tempItem);
            }
        });
    };

    return (
        <>
            {welcomeScreen &&
                <Grid container={true} className={classes.paddingAll}>
                    <Grid container={true} spacing={2}>
                        <Grid item={true} xs={12} style={{ textAlign: "center" }}>
                            <Typography variant="h3" style={{ paddingBottom: "10px" }} className={classes.header}>
                                Set up your SchoolCafé Suite
                            </Typography>
                            <Typography variant="h4" style={{ paddingBottom: "20px", color: "black" }} className={classes.fw}>
                                Below, select your readiness date (the date you would like to begin allowing other users to use SchoolCafé),
                                your target go-live date, and specific features you would like to set up.
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={4}>
                            {/* Need this button for 4.1 release */}
                            {/* <Button
                                buttonGroupColor="secondary"
                                buttonGroupVariant="text"
                                color="secondary"
                                startIcon={<PlayCircleIcon id={"plusAddNewButtonIcon"} />}
                                id="watchTourVideo"
                                name="watchTourVideo"
                                label="Watch quick tour (2 Min)"
                                onClick={function noRefCheck() { }}
                                type="button"
                                useEllipsis
                                variant="default"
                                className={classes.fRight}
                            /> */}
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3} style={{ padding: "20px 0" }}>
                        <Grid item={true} xs={12}>
                            <Card
                                id="dates"
                                className={classes.cards}
                                content={(
                                    <Grid item={true} container={true} spacing={2}>
                                        <Grid lg={3} md={3} sm={false} item={true} xs={false}>
                                        </Grid>
                                        <Grid lg={3} md={3} sm={6} item={true} xs={12}>
                                            {welcomeList.map((moduleItem: any) => {
                                                if (moduleItem.feature === "Readiness Date") {
                                                    return (
                                                        <FormControl fullWidth={true} >
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    margin="normal"
                                                                    label="Readiness Date"
                                                                    name="readinessDate"
                                                                    id="readinessDate"
                                                                    minDate={new Date()}
                                                                    format="MM/dd/yyyy"
                                                                    inputVariant="outlined"
                                                                    value={readyDate}
                                                                    onChange={(date) => {
                                                                        onReadyDateChange(date);
                                                                        setTriggerSelection(moduleItem);
                                                                    }}
                                                                    KeyboardButtonProps={{
                                                                        "aria-label": "Readiness Date",
                                                                    }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </FormControl>
                                                    );
                                                }
                                            })
                                            }
                                        </Grid>
                                        <Grid lg={3} md={3} sm={6} item={true} xs={12}>
                                            {welcomeList.map((moduleItem: any) => {
                                                if (moduleItem.feature === "Target Go-Live Date") {
                                                    return (
                                                        <FormControl fullWidth={true} >
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    margin="normal"
                                                                    id="target-go-live-date"
                                                                    label="Target Go-Live Date"
                                                                    minDate={new Date()}
                                                                    format="MM/dd/yyyy"
                                                                    inputVariant="outlined"
                                                                    value={targetDate}
                                                                    onChange={(date) => {
                                                                        onTargetDateChange(date);
                                                                        setTriggerSelection(moduleItem);
                                                                    }}
                                                                    KeyboardButtonProps={{
                                                                        "aria-label": "Target Go-Live Date",
                                                                    }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </FormControl>
                                                    );
                                                }
                                            })
                                            }
                                        </Grid>
                                        <Grid lg={3} md={3} sm={false} item={true} xs={false}>
                                        </Grid>
                                    </Grid>
                                )}
                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={4}>
                            <Card
                                id="system"
                                title="System"
                                titleAction={selectFunction}
                                titleColor="primary"
                                onClick={() => {
                                    setEnableStart(!enableStart);
                                    activityList();
                                }}
                                style={{ border: enableStart ? "2px solid #665AD8" : "" }}
                                content={(
                                    <>
                                        <div style={{}}>
                                            {welcomeList.map((moduleItem: any) => {
                                                if (moduleItem.moduleType === "System" && moduleItem.feature !== "Target Go-Live Date" && moduleItem.feature !== "Readiness Date") {
                                                    return (
                                                        <Grid container={true} xs={12} spacing={1} style={{ padding: "10px 0" }} className={classes.borderNth}>
                                                            <Grid item={true} xs={10}>
                                                                <p style={{ fontWeight: "bold", paddingBottom: "5px" }}>{moduleItem.feature}</p>
                                                                {moduleItem.feature === "Create Sites" &&
                                                                    <p>Select this option to Create Sites within your district (~3 minutes)</p>
                                                                }
                                                                {moduleItem.feature === "Create Roles" &&
                                                                    <p>Select this option to create various roles for your users (~1 minute each)</p>
                                                                }
                                                                {moduleItem.feature === "Create Users" &&
                                                                    <p>Select this option to create additional users and assign them specific roles (~1 minute per user)</p>
                                                                }
                                                                {moduleItem.feature === "Manage Settings" &&
                                                                    <p>Select this option to view and modify default settings (~5 minutes)</p>
                                                                }
                                                            </Grid>
                                                            <Grid item={true} xs={2}>
                                                                <Checkbox
                                                                    color="default"
                                                                    id={moduleItem.feature}
                                                                    key={moduleItem.feature}
                                                                    onChange={() => setTriggerSelection(moduleItem)}
                                                                    size="medium"
                                                                    checked={enableStart}
                                                                    labelPlacement="start"
                                                                    checkedIcon={<CheckCircleFilledIcon color="green" />}
                                                                    icon={<CheckCircleOutlineIcon color="grey" />}
                                                                    edge="end"
                                                                    disabled={false}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                            })
                                            }
                                        </div>
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={4}>
                            <Card
                                id="backOffice"
                                customTitle={<span className={classes.greyHeaders}>Back Office</span>}
                                titleAction={selectFunction}
                                className={classes.greyHeaders}
                                aria-disabled={true}
                                content={(
                                    <>
                                        {welcomeList.map((moduleItem: any) => {
                                            if (moduleItem.moduleType === "BackOffice") {
                                                return (
                                                    <Grid container={true} xs={12} spacing={1} style={{ padding: "10px 0" }} className={classes.borderNth}>
                                                        <Grid item={true} xs={10}>
                                                            <p style={{ fontWeight: "bold", paddingBottom: "5px" }}>{moduleItem.feature}</p>
                                                            {moduleItem.feature === "Item Imports" &&
                                                                <p>Select this activity if you like to import menu items for your inventory module</p>
                                                            }
                                                            {moduleItem.feature === "Add Menus" &&
                                                                <p>Select this activity for creating & managing menus for your menu planning</p>
                                                            }
                                                            {moduleItem.feature === "Add Recipes" &&
                                                                <p>Select this activity to design your recipe</p>
                                                            }
                                                            {moduleItem.feature === "Update Stock Information" &&
                                                                <p>Select this activity to update your inventory stock</p>
                                                            }
                                                        </Grid>
                                                        <Grid item={true} xs={2}>
                                                            <Checkbox
                                                                color="default"
                                                                id={moduleItem.feature}
                                                                key={moduleItem.feature}
                                                                onChange={() => setTriggerSelection(moduleItem)}
                                                                size="medium"
                                                                checked={moduleItem.isFeatureSelected}
                                                                labelPlacement="start"
                                                                checkedIcon={<CheckCircleFilledIcon color="green" />}
                                                                icon={<CheckCircleOutlineIcon color="grey" />}
                                                                edge="end"
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                );
                                            }
                                        })
                                        }
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={4}>
                            <Card
                                id="frontOffice"
                                customTitle={<span className={classes.greyHeaders}>Front Office</span>}
                                titleAction={selectFunction}
                                aria-disabled={true}
                                className={classes.greyHeaders}
                                content={(
                                    <>
                                        {welcomeList.map((moduleItem: any) => {
                                            if (moduleItem.moduleType === "FrontOffice") {
                                                return (
                                                    <Grid container={true} xs={12} spacing={1} style={{ padding: "10px 0" }} className={classes.borderNth}>
                                                        <Grid item={true} xs={10}>
                                                            <p style={{ fontWeight: "bold", paddingBottom: "5px" }}>{moduleItem.feature}</p>
                                                            {moduleItem.feature === "Student Import" && <p>Select this activity to import students data from your source file or system - ~ 10 mins</p>}
                                                            {moduleItem.feature === "Eligibility Import" && <p>Select this activity to import eligibility info of students - 10 -15 mins</p>}
                                                            {moduleItem.feature === "Balance Import" && <p>Select this activity to import student account balances - ~ 10-15 mins </p>}
                                                        </Grid>
                                                        <Grid item={true} xs={2}>
                                                            <Checkbox
                                                                color="default"
                                                                id={moduleItem.feature}
                                                                key={moduleItem.feature}
                                                                onChange={() => setTriggerSelection(moduleItem)}
                                                                onFocusVisible={function noRefCheck() { }}
                                                                size="medium"
                                                                checked={moduleItem.isFeatureSelected}
                                                                labelPlacement="start"
                                                                checkedIcon={<CheckCircleFilledIcon color="green" />}
                                                                icon={<CheckCircleOutlineIcon color="grey" />}
                                                                edge="end"
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                );
                                            }
                                        })
                                        }
                                    </>
                                )}
                            />
                        </Grid>
                        {/* Need this button for 4.1 release */}
                        {/* <Grid item={true} xs={12} sm={6} md={3}>
                            <Card id="addOns" title="Add-Ons" titleAction={selectFunction} titleColor="primary"
                                content={(
                                    <>
                                        {welcomeList.map((moduleItem: any) => {
                                            if (moduleItem.moduleType === "AddOns") {
                                                return (
                                                    <Grid container={true} xs={12} spacing={1} style={{ padding: "10px 0" }} className={classes.borderNth}>
                                                        <Grid item={true} xs={10}>
                                                            <p style={{ fontWeight: "bold", paddingBottom: "5px" }}>{moduleItem.feature}</p>
                                                            {moduleItem.feature === "Financials" && <p>Select this activity to configure reports related to financials module</p>}
                                                            {moduleItem.feature === "Catering" && <p>Select this activity to configure catering module</p>}
                                                            {moduleItem.feature === "Inspection" && <p>Select this activity to setup auditing for inspection management</p>}
                                                        </Grid>
                                                        <Grid item={true} xs={2}>
                                                            <Checkbox
                                                                color="default"
                                                                id={moduleItem.feature}
                                                                key={moduleItem.feature}
                                                                onChange={() => setTriggerSelection(moduleItem)}
                                                                onFocusVisible={function noRefCheck() { }}
                                                                size="medium"
                                                                checked={moduleItem.isFeatureSelected}
                                                                labelPlacement="start"
                                                                checkedIcon={<CheckCircleFilledIcon color="green" />}
                                                                icon={<CheckCircleOutlineIcon color="grey" />}
                                                                edge="end"
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                        })
                                        }
                                    </>
                                )}
                            />
                        </Grid> */}
                    </Grid>
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12}>
                            <Button
                                buttonGroupColor="secondary"
                                buttonGroupVariant="text"
                                color="primary"
                                id="getStarted"
                                name="getStarted"
                                label="Let's get started!"
                                onClick={(e) => {
                                    e.preventDefault();
                                    triggerNavigation();
                                }}
                                type="button"
                                useEllipsis={true}
                                variant="default"
                                endIcon={<ChevronRightIcon />}
                                className={classes.fRight}
                                disabled={!enableStart}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            {activitiesScreen &&
                <Activities />
            }
        </>
    );
};

export default WelcomePage;
