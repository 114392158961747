import { IconButton, ListItemText, MenuItem, Popover } from "@material-ui/core";
import { MoreVerticalIcon } from "@primeroedge/ui-components";
import { GridCellProps } from "@progress/kendo-react-grid/dist/npm/interfaces/GridCellProps";
import React, { FC } from "react";

interface ITableActionsProps extends GridCellProps {
    onEdit: () => void;
    onDelete: () => void;
}

const TableActions: FC<ITableActionsProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handleMenuClose();
        props.onEdit();
    };

    const handleDelete = () => {
        handleMenuClose();
        props.onDelete();
    };

    return (
        <td>
            <IconButton
                id={`actions-${props.dataIndex}`}
                aria-label="Actions"
                onClick={handleClick}
            >
                <MoreVerticalIcon />
            </IconButton>
            <Popover
                id={`menu-${props.dataIndex}`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <MenuItem
                    id={`menu-item-edit-${props.dataIndex}`}
                    onClick={handleEdit}
                >
                    <ListItemText primary="Edit" />
                </MenuItem>
                <MenuItem
                    id={`menu-item-delete-${props.dataIndex}`}
                    onClick={handleDelete}
                >
                    <ListItemText primary="Delete" />
                </MenuItem>
            </Popover>
        </td>
    );
};

export default TableActions;
