import { IAppState } from "model/reducers";
import { Reducer } from "redux";
import { ResponseStatus } from "../../model/entity";
export enum MessageType {
    Inbox = "Inbox",
    Sent = "Sent",
}

export interface IMail {
    setComposeMailOpen: Reducer;
    userList: Reducer;
    mailList: IMailEntity[];
    selectedMail: Reducer;
    readMail: Reducer;
    selectedMessage: Reducer;
    totalMessageCount: number;
    totalPages: number;
    status: ResponseStatus;
    attachments: any;
    messageId: string;
    isLoading: boolean;
}

export interface IMailEntity {
    id: string;
    messageType: string;
    messageId: string;
    recipient?: any;
    recipientId: string;
    recipientFirstName?: string;
    recipientLastName?: string;
    recipientEmail: string;
    recipientPrimeroUserId: string;
    senderFirstName?: string;
    senderLastName?: string;
    senderEmail: string;
    senderPrimeroUserId: string;
    subject?: string;
    content?: string;
    isRead: boolean;
    isDeleted: boolean;
    isStarred: boolean;
    isImportant: boolean;
    hasAttachment: boolean;
    tags: any;
    broadcastStartDateTime: string;
    broadcastEndDateTime?: string;
    deletedOn?: string;
    createdOn?: string;
    updatedOn?: string;
    notificationCb: (event: any) => void;
}


export interface ISendEmailPayload {
    customReportId: number;
    customEmailBody: string;
    customEmailSubject: string;
    emailList: string;
}
export interface IRecipientUser {
    recipientId: string;
    recipientFirstName: string;
    recipientLastName: string;
    recipientEmail: string;
    recipientPrimeroUserId: string;
}

export class MailEntity {

    public static getComposeMailOpenStatus (state: IAppState) {
        return state.mail.mailList;
    }

    public static getTotalPages (state: IAppState) {
        return state.mail.totalPages;
    }

    public static getTotalCount (state: IAppState) {
        return state.mail.totalMessageCount;
    }

    public static getAttachments (state: IAppState) {
        return state.mail.attachments;
    }

    public static mailToViewDetails (state: IAppState) {
        return state.mail.selectedMail;
    }

    public static isMailComponseOpen (state: IAppState) {
        return state.mail.setComposeMailOpen;
    }

    public static getReadMail (state: IAppState) {
        return state.mail.readMail;
    }

    public static getUserList (state: IAppState) {
        return state.mail.userList;
    }

    public static selectedMessage (state: IAppState) {
        return state.mail.selectedMessage;
    }

    public static getUsersList (state: IAppState) {
        return state.mail.userList.PayLoad;
    }

    public static getLoadingStatus (state: IAppState) {
        return state.mail.userList.isLoading;
    }

    public static status ({ mail }: IAppState) {
        return mail.status;
    }

    public static isLoading ({ mail }: IAppState) {
        return mail.status === ResponseStatus.Loading;
    }

    public static isError ({ mail }: IAppState) {
        return mail.status === ResponseStatus.Error;
    }
}
