import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";
import { AAPINSIGHTS_INSTRUMENTATION_KEY, REACT_APP_ENABLE_APP_INSIGHTS } from "control/transport/clients/api-constants";
import { createBrowserHistory } from "history";

const appInsightsEnabled = REACT_APP_ENABLE_APP_INSIGHTS;
const browserHistory = createBrowserHistory({ basename: "" });

export const reactPlugin = new ReactPlugin();

const ai = new ApplicationInsights({
    config: {
        instrumentationKey: AAPINSIGHTS_INSTRUMENTATION_KEY,
        // connectionString: "https://westus2-2.in.applicationinsights.azure.com/",
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
});
if (appInsightsEnabled === "true") {
    ai.loadAppInsights();
    // ai.trackPageViewPerformance();
    ai.addTelemetryInitializer((envelope: ITelemetryItem) => {
        envelope.tags = envelope.tags || [];
        envelope.tags.push({ "ai.cloud.role": "PrimeroedgeWorkspaceUI" });
    });

}

export default (Component: any) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
