import { Card, CardContent, CardHeader, Grid, makeStyles, Paper, TextField } from "@material-ui/core";
import { ContactInfoFields, IProfileErrors, IUserProfile } from "model/entity/profile.entity";
import React from "react";
import RequiredLabel from "view/pages/required-label";
import { maskedPhoneNumber } from "./maskedPhoneNumber";

interface IEditContactInfoProps {
    errors: IProfileErrors;
    profile: IUserProfile;
    onChange: (name: ContactInfoFields, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const styles = makeStyles(() => ({
    root: {
        width: "100%",
    },
    cardContent: {
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
    editContainer: {
        display: "flex",
        flexDirection: "row",
    },
    inputRoot: {
        width: "200px",
        "&:first-child": {
            marginRight: "20px",
        },
    },
}));

const EditContactInfo = ({
    errors,
    profile,
    onChange,
}: IEditContactInfoProps) => {
    const classes = styles();

    return (
        <Paper>
            <Card className={classes.root}>
                <CardHeader title="Contact Info" />
                <CardContent classes={{ root: classes.cardContent }}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                name="FirstName"
                                id="profile-edit-firstName"
                                value={profile.firstName}
                                variant="outlined"
                                label={<RequiredLabel inputLabel="First Name" />}
                                onChange={(event) => onChange(ContactInfoFields.FirstName, event)}
                                error={errors.firstNameError ? errors.firstNameError : false}
                                helperText={errors.firstNameError ? "First name is required" : ""}
                                style={{ width: "100%" }}
                                inputProps={{
                                    "aria-label": "First Name",
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                name="MiddleName"
                                id="profile-edit-mName"
                                value={profile.mi || ""}
                                variant="outlined"
                                label="Middle Name"
                                onChange={(event) => onChange(ContactInfoFields.MiddleName, event)}
                                style={{ width: "100%" }}
                                inputProps={{
                                    "aria-label": "Middle Name",
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                name="LastName"
                                id="profile-edit-LName"
                                value={profile.lastName}
                                variant="outlined"
                                label={<RequiredLabel inputLabel="Last Name" />}
                                onChange={(event) => onChange(ContactInfoFields.LastName, event)}
                                error={errors.lastNameError ? errors.lastNameError : false}
                                helperText={errors.lastNameError ? "Last name is required" : ""}
                                style={{ width: "100%" }}
                                inputProps={{
                                    "aria-label": "Last Name",
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                label="Email"
                                name="email"
                                id="profile-edit-email"
                                value={profile.email || ""}
                                variant="outlined"
                                type={"email"}
                                disabled={true}
                                style={{ width: "100%" }}
                                inputProps={{
                                    "aria-label": "Email",
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                label="Cell Phone"
                                name="cellPhone"
                                id="profile-edit-cphone"
                                value={maskedPhoneNumber(profile.cellPhone)}
                                onChange={(event) => onChange(ContactInfoFields.CellPhone, event)}
                                variant="outlined"
                                type={"tel"}
                                inputProps={{
                                    maxLength: 12,
                                    "aria-label": "Cell Phone",
                                }}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                            <TextField
                                label={<RequiredLabel inputLabel="Work Phone" />}
                                name="workphone"
                                id="profile-edit-wphone"
                                value={maskedPhoneNumber(profile.workPhone)}
                                onChange={(event) => onChange(ContactInfoFields.WorkPhone, event)}
                                variant="outlined"
                                type={"tel"}
                                error={errors.workPhoneError ? errors.workPhoneError : false}
                                helperText={errors.workPhoneError ? "Work phone is required" : ""}
                                inputProps={{
                                    maxLength: 12,
                                    "aria-label": "Work Phone",
                                }}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Paper>
    );
};

export default EditContactInfo;
