import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertCircleIcon } from '@primeroedge/ui-components';
import { PositionInterface } from 'types/mail';
import { CustomReportsEntity } from 'model/entity/CustomReports.entity';
import { uiShowMainWindowInit } from 'control/actions/customReports.actions';
import { sendReportEmailReset } from 'control/actions/customReportMail.actions';
import { IAlertContent } from './useFavoriteReportMenu';

const useMailWindow = function () {
    const dispatch = useDispatch();
    const canShowMailWindow = useSelector(CustomReportsEntity.getMailWindowStatus);
    const isMailSent = useSelector(CustomReportsEntity.isEmailSent);
    const isMailSentError = useSelector(CustomReportsEntity.isEmailError);
    const [alertContent, setAlertContent] = useState<IAlertContent | null>(null);
    const [position, setPosition] = useState<PositionInterface>({
        right: window?.innerWidth - 610,
        top: (window?.innerHeight - 600),
        width: 600,
        height: 600,
    });
    const [stage, setStage] = useState("DEFAULT");

    const onClose = function () {
        dispatch(uiShowMainWindowInit(null));
        dispatch(sendReportEmailReset())
    }

    const handleStageChange = function (e: any) {
        setStage(e.state);
    };

    const onResize = function () {
        setPosition({
            ...position,
            right: window?.innerWidth - 610,
            width: window?.innerWidth > 600 ? 600 : 400,
            top: window?.innerHeight > 600 ? window?.innerHeight - 600 : 0
        })
    }

    useEffect(function () {
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    useEffect(function () {
        if (isMailSent) {
            onClose();
            setAlertContent({
                icon: AlertCircleIcon,
                message: "Report email sent successfully!!",
                color: "success",
            });
        }

        if (isMailSentError) {
            setAlertContent({
                icon: AlertCircleIcon,
                message: "Failed to send Report email!!",
                color: "error",
            });
        }
    }, [isMailSent, isMailSentError])


    return {
        canShowMailWindow,
        position,
        stage,
        onClose,
        handleStageChange,
        alertContent,
        setAlertContent
    }
}

export default useMailWindow;