import { IAppState } from "../reducers/index";
export interface IMyPages {
    favoritesList:any;
    recentsList:any
    isLoading:boolean;
    isFavoriteDeleted:boolean;
    updateFavoriteStatus:boolean;
}
export function getFavorites({ myPages }: IAppState) {
    return myPages.favoritesList;
}
export function getFavoriteDeleteStatus({ myPages }: IAppState) {
    return myPages.isFavoriteDeleted;
}
export function isFavoritesLoading ({ myPages }: IAppState) {
    return myPages?.isLoading;
  }
  export function getFavoriteUpdateStatus({ myPages }: IAppState) {
    return myPages.updateFavoriteStatus;
}

export function getRecents({ myPages }: IAppState) {
    return myPages.recentsList;
}