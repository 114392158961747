import { Avatar, Box, Divider, Grid, Hidden, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { DownloadFileIcon, ITheme, MoreVerticalIcon, NotificationWithDialog, ReplyIcon, ReplyAllIcon, TrashIcon } from "@primeroedge/ui-components";
import Axios from "axios";
import clsx from "clsx";
import Loader from "components/Loader/Loader";
import { deleteMail, fetchMail, readMail, selectedMail, updateReadStatus } from "control/actions";
import { API_DATA_EXCHANGE, APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY } from "control/transport/clients/api-constants";
import { MailEntity, MessageType } from "model/entity/mail.entity";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AttachmentPreview from "./AttachmentPreview";
import MailReply from "./MailReply";
import Toolbar from "./Toolbar";

// interface IMailDetailsProps {
//     messageType: MessageType;
// }

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflowY: "auto",
    },
    avatar: {
        height: 56,
        width: 56,
    },
    date: {
        whiteSpace: "nowrap",
    },
    message: {
        color: theme.palette.text.secondary,
        "& > p": {
            ...theme.typography.body1,
            marginBottom: theme.spacing(2),
        },
    },
    mailInfoControlContainer: {
        minHeight: "110px",
        margin: "0px",
        width: "100%",
    },
    messageHeader: {
        display: "flex",
        flexDirection: "column",
        "&:hover": {
            cursor: "pointer",
        },
    },
    messageContent: {
        minHeight: 400,
        padding: "40px 24px",
    },
    mailControls: {
        justifyContent: "flex-end",
        marginTop: "20px",
    },
    avatarRoot: {
        marginLeft: "20px",
        height: "70px",
        width: "70px",
        marginTop: "20px",
        backgroundColor: theme.palette.grey[700],
        color: theme.palette.common.white,
    },
    singleAttachment: {
        maxWidth: "200px",
        margin: "10px",
        border: " 1px solid",
        borderRadius: "10px",
        padding: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
    },
    downloadButton: {
        cursor: "pointer",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        border: "none",
    },
    image: {
        width: "100%",
    },
    loader: {
        marginLeft: "40px",
        marginTop: "40px",
    },
}));

const MailDetails: FC<{ messageType: any, notificationCb: any }> = ({ messageType, notificationCb }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showReply, setShowReply] = useState(false);
    const [isReplyAll, setIsReplyAll] = useState(false);
    const [showMessageBody, canShowMessageBody] = useState(true);
    const [showLoader, setShowLoader] = useState(false);

    const selectedMailId = useSelector(MailEntity.getReadMail);
    const mail = useSelector(MailEntity.mailToViewDetails);
    const [attachments, setAttachments]: any = useState([]);
    const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
    const [replyAllEnabled, setReplyAllEnabled] = useState(true);
    const [singleReply, setSingleReply] = useState(false);
    const [multipleReply, setMultipleReply] = useState(false);


    async function getAttachment() {

        let image: any = [];

        image = image.concat(selectedMailId.message.attachments);

        setShowLoader(true);

        for (let i = 0; i < selectedMailId.message.attachments.length; i++) {

            const fileURL = `${API_DATA_EXCHANGE}/api/FileStorage/Download?featureName=${selectedMailId.message.attachments[i].containerName}&fileName=${selectedMailId.message.attachments[i].blobName}`;

            await Axios.get(fileURL, {
                responseType: "blob",
                headers: {
                    Environment: APIM_ENVIRONMENT,
                    "Ocp-Apim-Subscription-Key": APIM_SUBSCRIPTION_KEY,
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    "Content-Type": `application/octet-stream`,
                },
            })
                .then((resp) => {
                    const dwnldlink = window.URL.createObjectURL(
                        new Blob([resp.data], {
                            type: resp.headers["content-type"],
                        }),
                    );

                    if (image[i] && image[i].displayFileName) {
                        image[i] = {
                            ...image[i],
                            link: dwnldlink,
                        };
                    }
                })
                .catch((ex) => {
                    console.error(ex);
                });
        }

        setAttachments(image);
        setShowLoader(false);
    }

    useEffect(() => {
        if (selectedMailId.message && selectedMailId.message.attachments && selectedMailId.message.attachments.length > 0) {

            getAttachment();
        }
    }, [selectedMailId]);

    useEffect(() => {
        localStorage.setItem("mailToOpenData", "null");
        dispatch(readMail({
            messageId: mail.messageId,
        }));

        if (!mail.isRead) {
            dispatch(updateReadStatus([mail.messageId]));
            dispatch(fetchMail({
                pageSize: 10,
                pageNumber: 1,
                sortColumn: "createdOn",
                sortType: "DESC",
                type: messageType,
            }));
        }

    }, []);

    const handleReplyClick = (value: any) => {
        // setShowReply(showReply);
        setShowReply(value);
        canShowMessageBody(() => true);
        if (value) {
            setIsReplyAll(true);
        } else {
            setIsReplyAll(false);
        }
    };

    const handleDelete = () => {
        const params = {
            id: [mail.messageId],
            type: messageType,
        };
        dispatch(deleteMail(params));
        dispatch(selectedMail({}));
        notificationCb(true);
    };

    const handleDownload = (attachment: any) => {
        const link = document.createElement("a");
        link.href = attachment.link;
        link.setAttribute(
            "download", `${attachment.displayFileName}`,
        );
        link.click();
    };

    const handleDownloadAll = () => {
        if (attachments && attachments.length > 0) {

            for (let i = 0; i < attachments.length; i++) {

                handleDownload(attachments[i]);
            }
        }
    };

    const replyCB = (propsFlag: any) => {
        setSingleReply(propsFlag);
        setMultipleReply(propsFlag);
    };

    return (
        <div className={classes.root}>
            <Toolbar />
            <Divider />
            <Grid container={true} spacing={2} classes={{ root: classes.mailInfoControlContainer }}>
                <Grid item={true} sm={12} xs={12} md={8} lg={8}>
                    <div style={{ display: "flex", margin: 0, height: "100%", gap: 10 }}>
                        <div style={{ height: "100%", width: "100px" }}>
                            <Tooltip
                                title={(
                                    <>
                                        {`${mail.senderFirstName} ${mail.senderLastName}`}
                                        <br />
                                        <span>Email:</span> {mail.senderEmail}
                                    </>
                                )}
                            >
                                <Avatar classes={{ root: classes.avatarRoot }}>
                                    {mail.senderFirstName ? mail.senderFirstName.charAt(0) : ""}{mail.senderLastName ? mail.senderLastName.charAt(0) : ""}
                                </Avatar>
                            </Tooltip>
                        </div>
                        <div style={{ height: "100%" }}>
                            <Box display="flex" alignItems="center" style={{ padding: "25px 0px 0px" }}>
                                <Typography
                                    display="inline"
                                    variant="h5"
                                    color="textPrimary"
                                    component={"span"}
                                >
                                    {mail.senderFirstName ? mail.senderFirstName : ""}{" "}{mail.senderLastName ? mail.senderLastName : ""}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" flexWrap={'wrap'}>
                                {
                                    messageType !== MessageType.Sent ? (
                                        <>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                component="span"
                                            >
                                                To:
                                            </Typography>

                                            {replyAllEnabled && selectedMailId.message && selectedMailId.message.recipient &&
                                                selectedMailId.message.recipient.map((user: any, idx: number) => {
                                                    return (
                                                        <>
                                                            <Tooltip
                                                                key={idx}
                                                                style={{ marginLeft: 2, marginRight: 2 }}
                                                                title={(
                                                                    <>
                                                                        {`${user.recipientFirstName} ${user.recipientLastName}`}
                                                                        <br />
                                                                        <span>Email:</span> {user.recipientEmail}
                                                                    </>
                                                                )}
                                                            >
                                                                <Typography
                                                                    display="inline"
                                                                    variant="h5"
                                                                    color="textPrimary"
                                                                    component={"span"}
                                                                >
                                                                    {user.recipientFirstName ? user.recipientFirstName : ""};
                                                                </Typography>
                                                            </Tooltip><br />
                                                        </>
                                                    );
                                                })}
                                            {!replyAllEnabled && selectedMailId.message && selectedMailId.message.recipient &&
                                                (
                                                    <Tooltip
                                                        style={{ marginLeft: 2, marginRight: 2 }}
                                                        title={(
                                                            <>
                                                                {`${selectedMailId.message.recipient[0].recipientFirstName} ${selectedMailId.message.recipient[0].recipientLastName}`}
                                                                <br />
                                                                <span>Email:</span> {selectedMailId.message.recipient[0].recipientEmail}
                                                            </>
                                                        )}
                                                    >
                                                        <Typography
                                                            display="inline"
                                                            variant="h5"
                                                            color="textPrimary"
                                                            component={"div"}
                                                        >
                                                            {selectedMailId.message.recipient[0].recipientFirstName ? selectedMailId.message.recipient[0].recipientFirstName : ""};
                                                        </Typography>
                                                    </Tooltip>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                component="span"
                                            >
                                                To:
                                            </Typography>
                                            {replyAllEnabled && selectedMailId.message && selectedMailId.message.recipient &&
                                                selectedMailId.message.recipient.map((user: any, idx: number) => {
                                                    return (<>
                                                        <Tooltip
                                                            key={idx}
                                                            style={{ marginLeft: 2, marginRight: 2 }}
                                                            title={(
                                                                <>
                                                                    {`${user.recipientFirstName} ${user.recipientLastName}`}
                                                                    <br />
                                                                    <span>Email:</span> {user.recipientEmail}
                                                                </>
                                                            )}
                                                        >
                                                            <Typography
                                                                display="inline"
                                                                variant="h5"
                                                                color="textPrimary"
                                                                component={"span"}
                                                            >
                                                                {user.recipientFirstName ? user.recipientFirstName : ""};
                                                            </Typography>
                                                        </Tooltip><br />
                                                    </>
                                                    );
                                                })}
                                            {!replyAllEnabled && selectedMailId.message && selectedMailId.message.recipient &&
                                                (
                                                    <Tooltip
                                                        style={{ marginLeft: 2, marginRight: 2 }}
                                                        title={(
                                                            <>
                                                                {`${selectedMailId.message.recipient[0].recipientFirstName} ${selectedMailId.message.recipient[0].recipientLastName}`}
                                                                <br />
                                                                <span>Email:</span> {selectedMailId.message.recipient[0].recipientEmail}
                                                            </>
                                                        )}
                                                    >
                                                        <Typography
                                                            display="inline"
                                                            variant="h5"
                                                            color="textPrimary"
                                                            component={"span"}
                                                        >
                                                            {selectedMailId.message.recipient[0].recipientFirstName ? selectedMailId.message.recipient[0].recipientFirstName : ""};
                                                        </Typography>
                                                    </Tooltip>
                                                )
                                            }
                                        </>
                                    )}
                            </Box>
                            <Typography
                                className={classes.date}
                                color="textSecondary"
                                variant="caption"
                                component="h5"
                            >
                                {moment(mail.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
                            </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item={true} sm={12} xs={12} md={4} lg={4}>
                    <Box
                        display="flex"
                        alignItems="left"
                        className={classes.mailControls}
                    >
                        <Hidden smDown={true}>
                            <Tooltip title="Reply">
                                <IconButton
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        setSingleReply(true);
                                        setReplyAllEnabled(false);
                                        handleReplyClick(true);
                                    }}
                                >
                                    <ReplyIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Reply all"
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                    setMultipleReply(true);
                                    setReplyAllEnabled(true);
                                    handleReplyClick(true);
                                }}
                            >
                                <IconButton>
                                    <ReplyAllIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        handleDelete();
                                        setshowDeleteConfirm(true);
                                    }}
                                >
                                    <TrashIcon size={20} />
                                </IconButton>
                            </Tooltip>
                        </Hidden>
                        <Tooltip title="More Options">
                            <div>
                                <IconButton disabled={true} aria-label="More Options">
                                    <MoreVerticalIcon size={20} />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Box>
                    <NotificationWithDialog
                        message="Are you sure you want to delete this message?"
                        open={showDeleteConfirm}
                        primaryAction={{
                            callback: () => {
                                handleDelete();
                            },
                            title: "Confirm",
                        }}
                        secondaryAction={{
                            callback: () => {
                                setshowDeleteConfirm(false);
                            },
                            title: "Cancel",
                        }}
                        title="Confirmation"
                    />
                </Grid>
            </Grid>
            <Divider />
            {
                showMessageBody && (
                    <>
                        <Box
                            flexGrow={1}
                            bgcolor="background.dark"
                            className={clsx("ql-editor", classes.messageContent)}
                        >
                            <Typography
                                variant="h2"
                                color="textPrimary"
                            >
                                {mail.subject}
                            </Typography>
                            <Box mt={2}>
                                <div dangerouslySetInnerHTML={{ __html: mail.content }} />

                                <Divider />

                                {selectedMailId.message?.attachments?.length > 0 && (
                                    <div>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Typography variant="h5" component="p">
                                                {`${selectedMailId.message.attachments.length} Attachments`}
                                            </Typography>
                                            <Tooltip title="Download All Attachments" placement="top">
                                                <IconButton
                                                    aria-label="Download All Attachments"
                                                    onClick={handleDownloadAll}
                                                >
                                                    <DownloadFileIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            flexWrap="wrap"
                                        >
                                            {showLoader ? <div className={classes.loader}><Loader /></div> : attachments.map((attachment: any, index: number) => (
                                                <Box
                                                    key={index}
                                                    mr={1}
                                                    mb={1}
                                                >
                                                    <AttachmentPreview
                                                        attachment={attachment}
                                                        onDownload={() => handleDownload(attachment)}
                                                    />
                                                </Box>
                                            ))}
                                        </Box>
                                    </div>
                                )}

                                {selectedMailId.messageHistoryList?.map((message: any, idx: number) => {
                                    return (
                                        <div key={idx}>
                                            =================

                                            <div>
                                                <b> From: </b> {message.senderFirstName} {message.senderLastName}
                                            </div>
                                            <div>
                                                <b>To:</b> {message.recipient && message.recipient.map((user: any) => {
                                                    return (<>{user.recipientFirstName} {user.recipientLastName}; &nbsp;</>);
                                                })}
                                            </div>
                                            <div>
                                                <b>Date:</b> {moment(message.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
                                            </div>
                                            <div>
                                                <b>Subject:</b> {message.subject}
                                            </div>
                                            <div>
                                                <b> Body: </b>
                                                <div dangerouslySetInnerHTML={{ __html: message.content }} />
                                            </div>

                                            {message.attachments && message.attachments.length > 0 && (
                                                <div>
                                                    {message.attachments.map((attachment: any, index: number) => {
                                                        return (
                                                            <div
                                                                className={classes.singleAttachment}
                                                                key={index}
                                                            >
                                                                <div>
                                                                    {attachment.displayFileName}
                                                                </div>
                                                                <Divider />
                                                                <button className={classes.downloadButton}>
                                                                    DOWNLOAD
                                                                </button>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}

                                            =================
                                        </div>
                                    );
                                })}

                            </Box>
                        </Box>
                        <Divider />
                    </>
                )}
            {(showReply || mail.selectedOption) && <MailReply replyCB={replyCB} singleReply={singleReply} multipleReply={multipleReply} isReplyAll={isReplyAll} />}
        </div>
    );
};

export default MailDetails;
