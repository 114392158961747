import { Grid, makeStyles, Typography } from "@material-ui/core";
import {
    AlertTriangleIcon,
    Button, Card, CheckCircleFilledIcon, ChevronRightIcon, ProgressDoughnut
} from "@primeroedge/ui-components";
import {
    getActivitiesInit, getAllStates, getAreaNames, getSiteBySiteID, getSiteListInit,
    getUserDetailsKeyclockInit, postWelcomeInit, updateGroup, viewFilterSiteDataInit, viewRegionsAndSites
} from "control/actions";
import { getIsUserLoading } from "model/entity";
import { getActivities, IActivitiesList } from "model/entity/onboarding.entity";
import * as userEntity from "model/entity/user.entity";
import { SitesDataEntity } from "model/entity/sites.entity";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSiteTypeTypesInit, fetchAllUSDASiteTypesInit, fetchSiteTypeGradesAddFormInit, fetchSiteTypesByRegionInit } from "../../../control/actions/sitetype.actions";
import SiteConfigurationPage from "../siteconfiguration-page";
import AddUserPage from "../users-page";
import WelcomePage from "../welcome-page";

const useStyles = makeStyles(() => ({
    paddingAll: {
        padding: "100px 60px 80px 60px",
    },
    header: {
        color: "#ffffff",
    },
    date: {
        width: "100%",
    },
    selectAll: {
        padding: "10px",
    },
    setup: {
        color: "blue",
        float: "right",
        cursor: "pointer",
    },
    atag: {
        paddingRight: "8px",
        color: "blue",
        cursor: "pointer",
        lineHeight: "1.5",
    },
    fRight: {
        float: "right",
    },
    borderNth: {
        borderBottom: "1px solid #CCCCCC",
        padding: "10px 0",
        "&:last-child": {
            borderBottom: "0px",
        },
    },
    strikeOut: {
        textDecoration: "line-through",
    },
}));

const ActivitiesPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const welcomeList: IActivitiesList[] = useSelector(getActivities);
    const [activityList, setActivityList] = useState<any>([]);
    const isUserLoading = useSelector(getIsUserLoading);
    const [showActivities, setShowActivities] = useState<boolean>(true);
    const [showAddUser, setShowAdduser] = useState<boolean>(false);
    const [showSiteConfig, setShowSiteConfig] = useState<boolean>(false);
    const [welcomeScreen, setWelcomeScreen] = useState<boolean>(false);
    const activeDistrict = localStorage.getItem("activeDistrict");
    const selectedActiveDistrict = activeDistrict && JSON.parse(activeDistrict);
    const regionId = selectedActiveDistrict.attributes.regionId[0];
    const userAddStatus = useSelector(userEntity.getAddUserResponse);
    const siteAddStatus = useSelector(SitesDataEntity.getAddSiteResp);
    const regionsAndSites = useSelector(userEntity.getRegoinsAndSites);
    const [dataSet, setDataSet] = useState<number>(0);
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const [fulfillmentId, setFulfillmentId] = useState<number>(0);
    const [addOrEditSite, setAddOrEditSite] = useState<string>("add");
    const [addOrEditUser, setAddOrEditUser] = useState<string>("add");
    const [daysRemaining, setDaysRemaining] = useState<number>(0);
    useEffect(() => {
        if (regionId) {
            setTimeout(() => {
                dispatch(getActivitiesInit(regionId));
            }, 500);
        }
        dispatch(getSiteListInit({ sortOrder: 0, pageSize: 10, pageNumber: 1, sortColumn: "siteCode" }));
        dispatch(fetchSiteTypesByRegionInit({ sortOrder: 0, pageSize: 10, pageNumber: 1, sortColumn: "description" }));
        dispatch(fetchAllSiteTypeTypesInit());
        dispatch(fetchSiteTypeGradesAddFormInit());
        dispatch(getAreaNames());
        dispatch(getAllStates());
        dispatch(fetchAllUSDASiteTypesInit());
        if (regionsAndSites) {
            dispatch(viewRegionsAndSites(regionsAndSites.id));
        }
    }, [isUserLoading, regionId]);

    const [siteDataTrigger, setSiteDataTrigger] = useState<boolean>(false);
    useEffect(() => {
        if (siteDataTrigger === false) {
            dispatch(viewFilterSiteDataInit(selectedActiveDistrict.groupGuid));
            setSiteDataTrigger(true);
        }
    }, [selectedActiveDistrict]);

    useEffect(() => {
        if (fulfillmentId !== 0 && fulfillmentId !== null ) {
            if (addOrEditSite === "edit") {
                dispatch(getSiteBySiteID(fulfillmentId));
            } else if (addOrEditUser === "edit") {
                dispatch(getUserDetailsKeyclockInit(fulfillmentId));
            }
        }
    }, [fulfillmentId, addOrEditUser, addOrEditSite]);

    useEffect(() => {
        let list: any = [];
        welcomeList.map((moduleItem: any) => {
            if (moduleItem.feature === "Create Sites" || moduleItem.feature === "Create Users") {
                return list.push(moduleItem);
            }
        });
        setActivityList(list);
    }, [welcomeList]);

    useEffect(() => {
        validateForm();
    }, [activityList]);

    useEffect(() => {
        dataSet !== 100 && welcomeList.map((moduleItem: any) => {
            if (moduleItem.moduleType === "System" && moduleItem.feature === "Target Go-Live Date" && dataSet !== 100) {
                const targetDate = new Date(moduleItem.featureDueBy);
                const newDate = new Date();
                if (targetDate < newDate) {
                    setShowBanner(true);
                } else if (targetDate > newDate && dataSet !== 100) {
                    setDaysRemaining(Math.floor((targetDate.getTime() - newDate.getTime()) / (24 * 3600 * 1000)));
                }
            }
        })
    }, [welcomeList, dataSet]);

    const [userStatusUpdated, setUserStatusUpdated] = useState<boolean>(false);
    useEffect(() => {
        if (userAddStatus?.StatusCode === 200 && userStatusUpdated === false) {
            setUserStatusUpdated(true);
            const userInfo: any = welcomeList.find(
                (list) =>
                    list.feature === "Create Users",
            );
            let tempInfo = { ...userInfo };
            if (tempInfo?.featureStatus !== "Completed") {
                tempInfo.featureStatus = "Completed";
                dispatch(postWelcomeInit(tempInfo));
            }
            if (regionId) {
                setTimeout(() => {
                    dispatch(getActivitiesInit(regionId));
                }, 500);
            }
            validateForm();
        }
    }, [userAddStatus, welcomeList]);

    useEffect(() => {
        if (siteAddStatus?.StatusCode === 200 && userStatusUpdated === false) {
            setUserStatusUpdated(true);
            const userInfo: any = welcomeList.find(
                (list) =>
                    list.feature === "Create Sites",
            );
            let tempInfo = { ...userInfo };
            if (tempInfo?.featureStatus !== "Completed") {
                tempInfo.featureStatus = "Completed";
                dispatch(postWelcomeInit(tempInfo));
            }
            if (regionId) {
                setTimeout(() => {
                    dispatch(getActivitiesInit(regionId));
                }, 500);
            }
            validateForm();
        }
    }, [siteAddStatus, welcomeList]);

    const triggerActivity = (moduleItem: any) => {
        if (moduleItem.feature === "Create Users") {
            setShowActivities(false);
            setShowAdduser(true);
            setShowSiteConfig(false);
        } else if (moduleItem.feature === "Create Sites") {
            setShowActivities(false);
            setShowAdduser(false);
            setShowSiteConfig(true);
        }
    };
    const [userEmail, setUserEmail] = useState<string>("");
    const triggerEdit = (moduleItem: any) => {
        if (moduleItem.feature === "Create Users") {
            setShowActivities(false);
            setShowAdduser(true);
            setShowSiteConfig(false);
            setAddOrEditUser("edit");
            setUserEmail(moduleItem.fulfillmentId);
            setFulfillmentId(moduleItem.fulfillmentId);
        } else if (moduleItem.feature === "Create Sites") {
            setShowActivities(false);
            setShowAdduser(false);
            setShowSiteConfig(true);
            setAddOrEditSite("edit");
            setFulfillmentId(moduleItem.fulfillmentId);
        }
    };
    const toDashboard = () => {
        dispatch(updateGroup());
        setTimeout(() => {
            window.location.href = `/system/Onboarding`;
        }, 2000);
    };
    const [buttonStatus, setButtonStatus] = useState<boolean>(false);
    const validateForm = () => {
        const statusList: any = [];
        activityList.map((moduleItem: any) => {
            if (moduleItem.feature === "Create Sites" || moduleItem.feature === "Create Users") {
                return statusList.push(moduleItem.featureStatus);
            }
        });
        const trueLength = statusList.filter((val: any) => (val === "Completed")).length;
        if (trueLength === 0) {
            setDataSet(0)
        } else if (trueLength === 1) {
            setDataSet(50)
        } else if (trueLength === 2) {
            setDataSet(100)
        }
        const flagStatus = statusList.length > 0 && statusList.every((val: any) => val === "Completed");
        setButtonStatus(flagStatus);
    };

    return (
        <>
            {showActivities &&
                (
                    <Grid container={true} className={classes.paddingAll}>
                        <Grid container={true}>
                            <Grid item={true} xs={2}>
                            </Grid>
                            <Grid item={true} md={8} xs={12} style={{ padding: "0 8px 16px 0" }}>
                                <Grid container={true} style={{ backgroundColor: "#665AD8" }}>
                                    <Grid item={true} xs={5}>
                                        <Typography variant="h3" style={{ padding: "35px 0 0 16px" }} className={classes.header}>
                                            Lets Finish your Settings
                                        </Typography>
                                    </Grid>

                                    <Grid item={true} xs={5}>
                                        {dataSet !== 100 &&
                                            <p style={{ padding: "35px 0 0 16px", float: "right", fontWeight: "bold" }}>{daysRemaining} Days Remaining To Complete</p>}
                                    </Grid>

                                    <Grid item={true} xs={2}>
                                        <div
                                            style={{
                                                paddingTop: "10px",
                                                height: "100px",
                                                maxHeight: "100px",
                                                width: 'auto',
                                            }}
                                        >
                                            <ProgressDoughnut
                                                data={{
                                                    datasets: [
                                                        {
                                                            data: [
                                                                dataSet
                                                            ],
                                                            backgroundColor: showBanner && dataSet !== 100 ? ["#D14200"] : ["#3AC191", "#F4F7FA"],
                                                        }
                                                    ]
                                                }}
                                                options={{
                                                    maintainAspectRatio: false,
                                                    responsive: true,
                                                    tooltips: {
                                                        enabled: false,
                                                    },
                                                }}
                                                font="bold 18px Roboto #FFFFFF"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                {showBanner && dataSet !== 100 &&
                                    (
                                        <Grid container={true} style={{ backgroundColor: "#FFAA84", margin: "16px 0", lineHeight: "1.5" }}>
                                            <div style={{ padding: "10px", display: "inline-flex" }}>
                                                <AlertTriangleIcon /> <p>Your onboarding deadline is past due, please review and complete onboarding activities</p>
                                            </div>
                                        </Grid>
                                    )
                                }
                            </Grid>
                            <Grid item={true} xs={2}>
                                {/* Need this button for 4.1 release */}
                                {/* <Button
                                    buttonGroupColor="secondary"
                                    buttonGroupVariant="text"
                                    color="secondary"
                                    startIcon={<PlayCircleIcon id={"plusAddNewButtonIcon"} />}
                                    id="watchTourVideo"
                                    name="watchTourVideo"
                                    label="Watch quick tour (2 Min)"
                                    onClick={function noRefCheck() { }}
                                    type="button"
                                    useEllipsis
                                    variant="default"
                                    className={classes.fRight}
                                /> */}
                            </Grid>
                        </Grid>
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={2}>
                            </Grid>
                            <Grid item={true} xs={12} sm={12} md={8}>
                                <Card
                                    id="system"
                                    title="System"
                                    titleColor="primary"
                                    content={
                                        (
                                            <>
                                                <div>
                                                    {welcomeList.map((moduleItem: any) => {
                                                        if (moduleItem.moduleType === "System" && moduleItem.feature !== "Target Go-Live Date" && moduleItem.feature !== "Readiness Date") {
                                                            return (
                                                                <Grid item={true} xs={12} className={classes.borderNth}>
                                                                    <p style={{ fontWeight: "bold", paddingBottom: "5px" }}>{moduleItem.feature}</p>
                                                                    {moduleItem.featureStatus === "Completed" ? (
                                                                        <div style={{ display: "inline-block", float: "right" }}>
                                                                            <a className={classes.atag} onClick={() => triggerEdit(moduleItem)}>Edit</a>
                                                                            <CheckCircleFilledIcon color="green" className={classes.fRight} />
                                                                        </div>
                                                                    ) : (
                                                                        <a className={classes.setup} onClick={() => triggerActivity(moduleItem)}>Lets Setup</a>
                                                                    )}
                                                                    {moduleItem.feature === "Create Sites" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}>Click on “Lets Setup” to quickly Create Sites for your region which takes about ~3 mins</p>}
                                                                    {moduleItem.feature === "Create Roles" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}>Click on “Lets Setup” to for creating various in your district - ~1 min per role</p>}
                                                                    {moduleItem.feature === "Create Users" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}>Click on “Lets Setup” to create users and assign them to specific roles - ~ 1 min per user</p>}
                                                                    {moduleItem.feature === "Manage Settings" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}>Click on “Lets Setup” to load settings by default or custom - ~ 4 mins</p>}
                                                                </Grid>
                                                            );
                                                        }
                                                    })
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item={true} xs={2}>
                            </Grid>
                            {/* Need this button for 4.1 release */}
                            {/* <Grid item={true} xs={12} sm={12} md={8}>
                                <Card id="backOffice" title="Back Office" titleColor="primary"
                                    content={(
                                        <>
                                            {welcomeList.map((moduleItem: any) => {
                                                if (moduleItem.moduleType === "BackOffice") {
                                                    return (
                                                        <Grid item={true} xs={12} className={classes.borderNth}>
                                                            <p style={{ fontWeight: "bold", paddingBottom: "5px" }}>{moduleItem.feature}</p>
                                                            <a className={classes.setup}>Lets Setup  </a>
                                                            {moduleItem.feature === "Item Imports" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}> Click on “Lets Setup” to import menu items for your inventory module</p>}
                                                            {moduleItem.feature === "Add Menus" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}> Click on “Lets Setup” to creating & managing menus for your menu planning</p>}
                                                            {moduleItem.feature === "Add Recipes" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}> Click on “Lets Setup” to design your recipe</p>}
                                                            {moduleItem.feature === "Update Stock Information" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}>Click on “Lets Setup” to update your inventory stock</p>}
                                                        </Grid>
                                                    )
                                                }
                                            })
                                            }
                                        </>
                                    )}
                                />
                            </Grid>
                            <Grid item={true} xs={12} sm={12} md={8}>
                                <Card id="frontOffice" title="Front Office" titleColor="primary"
                                    content={(
                                        <>
                                            {welcomeList.map((moduleItem: any) => {
                                                if (moduleItem.moduleType === "FrontOffice") {
                                                    return (
                                                        <Grid item={true} xs={12} className={classes.borderNth}>
                                                            <p style={{ fontWeight: "bold", paddingBottom: "5px" }}>{moduleItem.feature}</p>
                                                            <a className={classes.setup}>Lets Setup  </a>
                                                            {moduleItem.feature === "Student Import" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}>Click on “Lets Setup” to import students data from your source file or system - ~ 10 mins</p>}
                                                            {moduleItem.feature === "Eligibility Import" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}>Click on “Lets Setup” to import eligibility info of students - 10 -15 mins</p>}
                                                            {moduleItem.feature === "Balance Import" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}>Click on “Lets Setup” to import student account balances - ~ 10-15 mins</p>}
                                                        </Grid>
                                                    )
                                                }
                                            })
                                            }
                                        </>
                                    )}
                                />
                            </Grid>
                            <Grid item={true} xs={12} sm={12} md={8}>
                                <Card id="addOns" title="Add-Ons" titleColor="primary"
                                    content={(
                                        <>
                                            {welcomeList.map((moduleItem: any) => {
                                                if (moduleItem.moduleType === "AddOns") {
                                                    return (
                                                        <Grid item={true} xs={12} className={classes.borderNth}>
                                                            <p style={{ fontWeight: "bold", paddingBottom: "5px" }}>{moduleItem.feature}</p>
                                                            <a className={classes.setup}>Lets Setup  </a>
                                                            {moduleItem.feature === "Financials" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}>Click on “Lets Setup” to configure reports related to financials module</p>}
                                                            {moduleItem.feature === "Catering" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}>Click on “Lets Setup” to configure catering module</p>}
                                                            {moduleItem.feature === "Inspection" && <p className={moduleItem.featureStatus === "Completed" ? classes.strikeOut : ""}>Click on “Lets Setup” to setup auditing for inspection management</p>}
                                                        </Grid>
                                                    )
                                                }
                                            })
                                            }
                                        </>
                                    )}
                                />
                            </Grid> */}
                        </Grid>
                        <Grid container={true} spacing={1} style={{ paddingTop: "20px" }}>
                            <Grid item={true} xs={6}>
                                <Button
                                    buttonGroupColor="secondary"
                                    buttonGroupVariant="text"
                                    color="secondary"
                                    id="questionnaire"
                                    name="questionnaire"
                                    label="Return to Setup Screen"
                                    onClick={() => {
                                        setWelcomeScreen(true);
                                        setShowActivities(false);
                                    }}
                                    type="button"
                                    useEllipsis={true}
                                    variant="default"
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Button
                                    buttonGroupColor="secondary"
                                    buttonGroupVariant="text"
                                    color="primary"
                                    id="getStarted"
                                    name="getStarted"
                                    label="View Dashboard"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toDashboard();
                                    }}
                                    type="button"
                                    useEllipsis={true}
                                    variant="default"
                                    endIcon={<ChevronRightIcon />}
                                    className={classes.fRight}
                                    disabled={!buttonStatus}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            {showAddUser && <AddUserPage addOrEditUser={addOrEditUser} userEmail={userEmail} />}
            {showSiteConfig && <SiteConfigurationPage addOrEditSite={addOrEditSite} />}
            {welcomeScreen && <WelcomePage />}
        </>
    );
};

export default ActivitiesPage;
