import { createReducer } from "@reduxjs/toolkit";
import {
    AuthLogout,
    getUserDetailsKeyclockInit,
    getUserDetailsKeyclockInitSuccess,
    passwordPoliciesSuccess,
    resetProfileUpdateStatus,
    saveProfileInfoSuccess,
    setUserProfile,
    updateUserProfile,
    viewRolesSuccess,
} from "control/actions";
import produce from "immer";
import { IUserProfileReducer } from "../entity";

const initialState: IUserProfileReducer = {
    profile: null,
    profileUpdateStatus: null,
    rolesList: [],
    userDetailsKeyclock: {
        UserMgmatUserDetails: {
            PayLoad: [],
            StatusCode: 0,
            access: [],
            attributes: [],
            createdTimestamp: [],
            disableableCredentialTypes: [],
            email: "",
            emailVerified: true,
            enabled: true,
            firstName: "",
            groups: [],
            lastName: "",
            primeroUserId: "",
            roles: [],
            userGuid: "",
            userName: "",
        },
        AdminServiceUserDetails: {
            PayLoad: [],
            StatusCode: 0,
            userId: 0,
            cellPhone: "",
            email: "",
            firstName: "",
            image: "",
            languagepreference: "",
            lastName: "",
            mi: "",
            theme: "",
            workPhone: "",
            primaryRegionName:"",
            navigateOption: "",            
        },
    },
    passwordPolicies: [],
    isUserLoading: false,
};

export const profileReducer = createReducer(initialState, {
    [setUserProfile.toString()]: (state, action) =>
        produce(state, (draft: IUserProfileReducer) => {
            draft.profile = action.payload;
        }),
    [updateUserProfile.toString()]: (state, action) =>
        produce(state, (draft: IUserProfileReducer) => {
            const data = action.payload;
            draft.profile = { ...draft.profile, ...data };
        }),
    [saveProfileInfoSuccess.toString()]: (state, action) =>
        produce(state, (draft: IUserProfileReducer) => {
            if (action.payload !== -1) {
                draft.profile = action.payload;
                draft.profileUpdateStatus = true;
            } else {
                draft.profileUpdateStatus = false;
            }
        }),
    [resetProfileUpdateStatus.toString()]: (state) =>
        produce(state, (draft: IUserProfileReducer) => {
            draft.profileUpdateStatus = null;
        }),
    [viewRolesSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.rolesList = action.payload;
        }),
    [getUserDetailsKeyclockInit.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.isUserLoading = true;
        }),
    [getUserDetailsKeyclockInitSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.userDetailsKeyclock = action.payload;
            draft.isUserLoading = false;
        }),
    [AuthLogout.toString()]: () => initialState,
    [passwordPoliciesSuccess.toString()]: (state, action) =>
        produce(state, (draft: IUserProfileReducer) => {
            draft.passwordPolicies = action.payload;
        }),
});
