import { Maybe } from "lib/maybe";
import logger from "util/logger";
import administrationAPIClient from "./clients/administration-api.client";

export interface ISiteTypeByRegion {
    siteTypeId: number;
    siteTypeDescription?: string;
    regionId: number;
    siteTypeTypeCode: string;
    grades: [];
}

interface IAllSiteTypeTypes {
    siteTypeTypeCode: string;
    siteTypeTypeDescription: string;
}

interface IAllUSDASiteTypes {
    usdaSiteTypeId: number;
    description: string;
    order: number;
    notes: string;
}

interface IAddFormGrades {
    gradeId: number;
    gradeCd: string;
    description: number;
    isAutoLookup: string;
    gradeOrder: number;
    regionId: number;
    siteTypeId: number;
}

interface ISiteTypeByRegionResponse {
    data: Maybe<ISiteTypeByRegion>;
}

interface IAllSiteTypeTypesResponse {
    data: Maybe<IAllSiteTypeTypes>;
}

interface IAllUSDASiteTypesResponse {
    data: Maybe<IAllUSDASiteTypes>;
}

interface ISiteTypeAddFormGrades {
    data: Maybe<IAddFormGrades>;
}

// Auth transport layer handles all the authentication and validation processes

class SiteTypeTransportLayer {
    public async siteTypeInit (payload: any): Promise<ISiteTypeByRegionResponse> {
        const activeDistrict = localStorage.getItem("activeDistrict");
        let selectedDistrict: any;
        if (activeDistrict) {
            selectedDistrict = JSON.parse(activeDistrict);
            const resp = await administrationAPIClient({
                method: "POST",
                url: "api/SiteType/RegionSiteTypes",
                data: {
                    sortOrder: payload?.sortOrder ? payload?.sortOrder : payload?.sort,
                    sortColumn: payload.sortColumn,
                    pageSize: payload.pageSize,
                    pageNumber: payload?.page ? payload?.page : payload.pageNumber,
                    regionId: selectedDistrict?.attributes?.regionId ? Number(selectedDistrict?.attributes?.regionId[0]) : 0,
                },
            });

            const data = resp.data.PayLoad;
            if (data.siteTypes !== undefined) {
                data.siteTypes.map((el: any) => {
                    if (el.grades?.length > 0) {
                        el.grades.sort((a: any, b: any) => {
                            return a.gradeId - b.gradeId;
                        });
                    }
                });

                return Promise.resolve({
                    data,
                });
            } else {
                return Promise.reject(
                    "Failed to authenticate, response data is in a different format",
                );
            }
        } else {
            return Promise.reject(
                "District not selected",
            );
        }
    }
    public async getAllSiteTypeTypes (): Promise<IAllSiteTypeTypesResponse> {
        const resp = await administrationAPIClient({
            method: "GET",
            url: "api/SiteType/AllSiteTypeTypes",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });

        const data = resp.data.PayLoad;
        if (data !== undefined) {
            return Promise.resolve({
                data,
            });
        } else {
            return Promise.reject(
                "Failed to authenticate, response data is in a different format",
            );
        }
    }
    public async getAllUSDASiteTypes (): Promise<IAllUSDASiteTypesResponse> {
        try {
            const response = await administrationAPIClient({
                method: "POST",
                url: "api/SiteType/USDASiteTypes",
                data: {},
            });

            const data: any = response;

            if (data) {
                return Promise.resolve({
                    ...data,
                });
            } else {
                return Promise.reject("Failed to get all sites data");
            }
        } catch (error) {
            logger.info(error);
            throw error;
        }
    }
    public async addSiteTypeFormGrades (): Promise<ISiteTypeAddFormGrades> {
        const activeDistrict = localStorage.getItem("activeDistrict");
        let selectedDistrict: any;
        if (activeDistrict) {
            selectedDistrict = JSON.parse(activeDistrict);
            try {
                const response = await administrationAPIClient({
                    method: "GET",
                    url: `api/SchoolGradeType/region/${selectedDistrict?.attributes?.regionId ? Number(selectedDistrict?.attributes?.regionId[0]) : 0}`,
                });

                const data: any = response;

                if (data) {
                    return Promise.resolve({
                        ...data,
                    });
                } else {
                    return Promise.reject("Failed to get all sites data");
                }
            } catch (error) {
                logger.info(error);
                throw error;
            }
        } else {
            return Promise.reject("District not selected");
        }
    }

    public async addSiteType (payload: any, userId: number): Promise<any> {
        const dynamicPayload = payload.payload;
        const activeDistrict = localStorage.getItem("activeDistrict");
        let selectedDistrict: any;
        if (activeDistrict) {
            selectedDistrict = JSON.parse(activeDistrict);
            try {
                const response = await administrationAPIClient({
                    method: "POST",
                    url: "api/SiteType",
                    data: {
                        description: dynamicPayload.description,
                        regionId: selectedDistrict?.attributes?.regionId ? Number(selectedDistrict?.attributes?.regionId[0]) : 0,
                        siteTypeTypeCode: dynamicPayload.typetypeCode,
                        addedBy: userId,
                        siteTypeTypeDescription:
                            dynamicPayload.siteTypeTypeDescription,
                        grades: dynamicPayload.typetypeCode === "SCHL" ? dynamicPayload.gradeId : [],
                    },
                });

                const data: any = response.data;

                if (data) {
                    return Promise.resolve({
                        ...data,
                    });
                } else {
                    return Promise.reject("Failed to get all sites data");
                }
            } catch (error) {
                logger.info(error);
                throw error;
            }
        } else {
            return Promise.reject("District not selected");
        }
    }
    public async editSiteType (payload: any, userId: number): Promise<any> {
        const dynamicPayload = payload.payload;
        const activeDistrict = localStorage.getItem("activeDistrict");
        let selectedDistrict: any;
        if (activeDistrict) {
            selectedDistrict = JSON.parse(activeDistrict);
            try {
                const response = await administrationAPIClient({
                    method: "PATCH",
                    url: "api/SiteType",
                    data: {
                        siteTypeId: dynamicPayload.siteTypeId,
                        description: dynamicPayload.description,
                        regionId: selectedDistrict?.attributes?.regionId ? Number(selectedDistrict?.attributes?.regionId[0]) : 0,
                        siteTypeTypeCode: dynamicPayload.typetypeCode,
                        modifiedBy: userId,
                        siteTypeTypeDescription:
                            dynamicPayload.siteTypeTypeDescription,
                        grades: dynamicPayload.typetypeCode === "SCHL" ? dynamicPayload.gradeId : [],
                    },
                });

                const data: any = response.data;

                if (data) {
                    return Promise.resolve({
                        ...data,
                    });
                } else {
                    return Promise.reject("Failed to get all sites data");
                }
            } catch (error) {
                logger.info(error);
                throw error;
            }
        } else {
            return Promise.reject("District not selected");
        }
    }
}

export default new SiteTypeTransportLayer();
