import { ITasksPayload } from "../../model/entity";
import workspaceAPIClient from "./clients/workspace-api.client";

export async function fetchTasksByDurationAPI ({
  size,
  duration,
  currentDate,
}: ITasksPayload) {
  const response = await workspaceAPIClient({
    method: "GET",
    url: `/api/get-task-by-duration?duration=${duration}&maxRecords=${size}&currentDate=${currentDate}`,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject("Failed to get workspace details");
  }
}

export function fetchTask (page: number, size: number, setTasks: any) {
  return workspaceAPIClient({
    method: "GET",
    url: `/api/list-tasks?pageSize=${size}&pageNumber=${page}&sortColumn=id`,
  })
    .then((responseData: any) => {
      setTasks(responseData.data.tasks);
    })
    .catch(() => {
      setTasks([]);
    });
}

export async function fetchInitialTasks (
  page: number,
  size: number,
  sortColumn: string,
) {
  try {
    const response = await workspaceAPIClient({
      method: "GET",
      url: `/api/list-tasks?pageSize=${size}&pageNumber=${page}&sortColumn=${sortColumn
        .split("-")[0]
        .charAt(0)
        .toUpperCase() +
        sortColumn.split("-")[0].slice(1)}&sortType=${sortColumn
        .split("-")[1]
        .toUpperCase()}`,
    });

    const { data, status } = response;

    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}
