import { getNotificationsAction, isNotificationLoading } from "control/actions";
import { put, takeLatest } from "redux-saga/effects";

function* fetchModulesListSaga () {
    try {
        yield put(isNotificationLoading({ isLoading: false }));
    } catch (error) {
        yield put(isNotificationLoading({ isLoading: false }));
        throw error;
    }
}

export default function* NotificationSaga () {
    try {
        yield takeLatest(getNotificationsAction, fetchModulesListSaga);
    } catch (error) {
        yield;
    }
}
