import { makeStyles } from "@material-ui/core/styles";
import { Notification, PageLayout } from "@primeroedge/ui-components";
import { State } from "@progress/kendo-data-query";
import { SitesDataEntity } from "model/entity/sites.entity";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserEntity } from "../../../model/entity";
import { GradeGroupEntity, GradeGroupStatus } from "../../../model/entity/gradeGroup.entity";
import Activities from "../activities-page";
import {
  fetchAllSiteTypeTypesInit,
  fetchAllUSDASiteTypesInit, fetchGradeGroupsInit, fetchPOSConfigInit, fetchPOSRegionSiteTypes, fetchPOSSitesConfigurationTypesByRegion, fetchSiteTypeGradesAddFormInit,
  fetchSiteTypesByRegionInit,
  getAllStates,
  getAreaNames, getExpSettingsBySiteID, getSiteBySiteID, getSiteListInit,
} from "./../../../control/actions";
import AddSite from "./components/siteConfig/addSite";

export interface ISiteGenInfo {
  siteId: number;
  siteName: string;
  siteDescription: string;
  siteCode: string;
  regionId: number;
  siteTypeId: number;
  usdaSiteTypeId: number;
  isActive: boolean;
  includeForReimbursement: boolean;
  isFeedingSite: boolean;
  programs: any;
  nslpexportCode: string | null | number;
  childSites: any;
  siteCharacteristics: number | null;
  parentSiteId: number | null;
}

export interface ISiteCorrAddr {
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  stateId: number;
  contactPerson: string;
  contactPersonPosition: string;
  contactPhone: string;
}

const useStyles = makeStyles(() => ({
  root: {
    margin: "auto",
    padding: "0px 70px 50px 50px",
  },
  header: {
    color: "#7266DB",
  },
  centerAlign: {
    textAlign: "center",
    padding: "10px",
  },
  pBottom: {
    paddingBottom: "20px",
  },
  mAuto: {
    margin: "auto",
  },
  date: {
    width: "100%",
  },
  selectAll: {
    padding: "1opx",
  },
  continueButton: {
    width: "100%",
    marginTop: "20px",
  },
  borderNth: {
    borderBottom: "1px solid #CCCCCC",
    "&:last-child": {
      borderBottom: "0px",
    },
  },
}));

interface ISiteCProps {
  addOrEditSite: string;
}

export const SiteConfigurationPage: React.FC<ISiteCProps> = ({ addOrEditSite }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const addEditGradeGroupStatus: string = useSelector(GradeGroupEntity.getGradeGroupAddEditStatus);
  const selectedActiveDistrict = useSelector(UserEntity.getActiveDistrict);

  // const [tabIndex, setTabIndex] = useState<string | number>(0);
  // const [openGradeGroupDrawer, setOpenGradeGroupDrawer] = React.useState<boolean>(false);
  // const [openSiteTypesDrawer, setOpenSiteTypesDrawer] = useState<boolean>(false);
  const [siteAddEditTitle] = useState<string>("");
  const [addEditSiteFlag, setAddEditSiteFlag] = useState<boolean>(false);
  const currentSiteData = useSelector(SitesDataEntity.getCurrentSite);
  const [generalInfo, setGeneralInfo] = useState<ISiteGenInfo>({
    siteId: 0,
    siteName: "",
    siteDescription: "",
    siteCode: "",
    regionId: 0,
    siteTypeId: 0,
    usdaSiteTypeId: 0,
    isActive: true,
    includeForReimbursement: false,
    isFeedingSite: false,
    programs: [],
    nslpexportCode: "",
    childSites: [],
    siteCharacteristics: null,
    parentSiteId: 0,
  });
  const [correspondenceAddress, setCorrespondenceAddress] = useState<ISiteCorrAddr>({
    addressLine1: "",
    addressLine2: "",
    zipCode: "",
    city: "",
    stateId: 0,
    contactPerson: "",
    contactPersonPosition: "",
    contactPhone: "",
  });
  // const [currentSiteType, setCurrentSiteType] = useState({
  //   siteTypeId: 0,
  //   description: "",
  //   regionId: 0,
  //   siteTypeTypeCode: "",
  //   siteTypeTypeDescription: "",
  //   grades: [],
  // });
  // const [selectedGradeGroup, setSelectedGradeGroup] = React.useState<IGradeGroups>({
  //   districtRegionId: 0,
  //   grades: [],
  //   id: 0,
  //   name: "",
  // });
  const [gridState, setGridState] = React.useState<State>({
    skip: 0,
    take: 10,
    sort: [
      {
        dir: "asc",
        field: "siteCode",
      },
    ],
  });
  // const [addOrEditSiteType, setAddOrEditSiteType] = useState<string>("");
  // const [addOrEditSite, setAddOrEditSite] = useState<string>("");
  const [addOrEditSiteSiteId, setAddOrEditSiteSiteId] = useState<number>(0);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const [alertStatus, setAlertStatus] = React.useState<
    | "success"
    | "error"
    | "default"
    | "primary"
    | "secondary"
    | "warning"
    | "info"
  >("default");
  // const [addNewGradeGroup, setAddNewGradeGroup] = React.useState<boolean>(false);
  // const [selectedSite, setSelectedSite] = useState<any>({});
  const [viewHistoryFlag, setViewHistoryFlag] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getSiteListInit({ sortOrder: 0, pageSize: 10, pageNumber: 1, sortColumn: "siteCode" }));
    dispatch(fetchSiteTypesByRegionInit({ sortOrder: 0, pageSize: 10, pageNumber: 1, sortColumn: "description" }));
    dispatch(fetchGradeGroupsInit());
    dispatch(fetchAllSiteTypeTypesInit());
    dispatch(fetchSiteTypeGradesAddFormInit());
    dispatch(getAreaNames());
    dispatch(getAllStates());
    dispatch(fetchAllUSDASiteTypesInit());
    dispatch(fetchPOSConfigInit());
    if (selectedActiveDistrict.groupGuid !== "" && selectedActiveDistrict.attributes && selectedActiveDistrict?.attributes?.regionId &&
      selectedActiveDistrict?.attributes?.regionId[0]) {
      dispatch(fetchPOSRegionSiteTypes(Number(selectedActiveDistrict?.attributes?.regionId[0])));
      dispatch(fetchPOSSitesConfigurationTypesByRegion(selectedActiveDistrict.groupGuid));
    }
  }, []);

  useEffect(() => {
    if (selectedActiveDistrict) {
      setAddEditSiteFlag(false);
      setViewHistoryFlag(false);
      dispatch(getSiteListInit({ sortOrder: 0, pageSize: 10, pageNumber: 1, sortColumn: "siteCode" }));
      dispatch(fetchSiteTypesByRegionInit({ sortOrder: 0, pageSize: 10, pageNumber: 1, sortColumn: "description" }));
      dispatch(fetchGradeGroupsInit());
      if (selectedActiveDistrict.groupGuid !== "" && selectedActiveDistrict.attributes && selectedActiveDistrict?.attributes?.regionId &&
        selectedActiveDistrict?.attributes?.regionId[0]) {
        dispatch(fetchPOSRegionSiteTypes(Number(selectedActiveDistrict?.attributes?.regionId[0])));
        dispatch(fetchPOSSitesConfigurationTypesByRegion(selectedActiveDistrict.groupGuid));
      }
    }
  }, [selectedActiveDistrict]);

  useEffect(() => {
    if (addEditGradeGroupStatus === GradeGroupStatus.addSuccessful || addEditGradeGroupStatus === GradeGroupStatus.editSuccessful) {
      setAlertMessage("Grade group saved successfully");
      setShowAlert(true);
      setAlertStatus("success");
      dispatch(fetchGradeGroupsInit());
    } else if (addEditGradeGroupStatus === GradeGroupStatus.addFail) {
      setAlertMessage("Failed to add grade group");
      setShowAlert(true);
      setAlertStatus("error");
    } else if (addEditGradeGroupStatus === GradeGroupStatus.editFail) {
      setAlertMessage("Failed to update grade group");
      setShowAlert(true);
      setAlertStatus("error");
    }
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
      setAlertStatus("default");
    }, 5000);
  }, [addEditGradeGroupStatus]);

  const [dataFetched, setDataFetched] = useState<boolean>(false);
  useEffect(() => {
    if (addOrEditSite === "edit" && currentSiteData  && currentSiteData.siteId !== -1 && dataFetched === false) {
      setDataFetched(true);
      dispatch(getSiteBySiteID(currentSiteData.siteId));
      dispatch(getExpSettingsBySiteID(currentSiteData.siteId));
      setAddOrEditSiteSiteId(currentSiteData.siteId);
      setGeneralInfo({
        siteId: currentSiteData.siteId,
        siteName: currentSiteData.siteName,
        siteDescription: currentSiteData.siteDescription,
        siteCode: currentSiteData.siteCode,
        regionId: currentSiteData.regionId,
        siteTypeId: currentSiteData.siteTypeId,
        usdaSiteTypeId: currentSiteData.usdaSiteTypeId,
        isActive: currentSiteData.isActive,
        includeForReimbursement: currentSiteData.includeForReimbursement,
        isFeedingSite: currentSiteData.isFeedingSite,
        programs: [],
        nslpexportCode: currentSiteData.nslpexportCode,
        childSites: [],
        siteCharacteristics: currentSiteData.siteCharacteristics,
        parentSiteId: currentSiteData.parentSiteId,
      });

      setCorrespondenceAddress({
        addressLine1: currentSiteData.addressLine1 || "",
        addressLine2: currentSiteData.addressLine2 || "",
        zipCode: currentSiteData.zipCode || "",
        city: currentSiteData.city || "",
        stateId: currentSiteData.stateId || 0,
        contactPerson: currentSiteData.contactPerson || "",
        contactPersonPosition: currentSiteData.contactPersonPosition || "",
        contactPhone: currentSiteData.contactPhone || "",
      });
    }
  }, [addOrEditSite, currentSiteData])
  const [onRoute, setOnRoute] = useState<boolean>(false);
  const handleCloseAddEdit = (addEditShow: { gridState: State, addSiteClose: boolean }) => {
    setAddEditSiteFlag(addEditShow?.addSiteClose);
    setGridState(addEditShow?.gridState);
    setOnRoute(true);
  };

  const showSuccessAlertAddSite: any = (showSuccessMsg: any, errorMsg: any = "") => {
    if (showSuccessMsg === "SAVE") {
      setAlertMessage("Site Saved successfully!");

      setShowAlert(true);
      setAlertStatus("success");

      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 5000);
    } else if (showSuccessMsg === "ERROR") {
      setAlertMessage(errorMsg);

      setShowAlert(true);
      setAlertStatus("error");

      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 5000);
    }
  };


  const handleAlertClose = () => {
    setShowAlert(false);
  };

  return (
    <>
      {showAlert ? (
        <Notification
          color={alertStatus}
          message={alertMessage}
          onClose={handleAlertClose}
          open={showAlert}
          variant="filled"
        />
      ) : ""}

      {!addEditSiteFlag && !viewHistoryFlag && !onRoute && (
        <div id="siteConfigMainContainer">
          <PageLayout
            breadCrumbs={[]}
            title=""
            className={classes.root}
          >
            <AddSite
              onClose={handleCloseAddEdit}
              siteAddEditTitle={siteAddEditTitle}
              generalInfo={generalInfo}
              addOrEdit={addOrEditSite}
              addOrEditSiteSiteId={addOrEditSiteSiteId}
              correspondenceAddress={correspondenceAddress}
              onSuccess={showSuccessAlertAddSite}
              gridState={gridState}
              programs={generalInfo.programs}
            />
          </PageLayout>
        </div>
      )}
      {onRoute && <Activities />}
    </>
  );
};

export default SiteConfigurationPage;
