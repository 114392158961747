import {
    Box,
    IconButton,
    makeStyles,
    Tooltip,
} from "@material-ui/core";
import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon } from "@primeroedge/ui-components";
import clsx from "clsx";
import { selectedMail } from "control/actions";
import React, { FC } from "react";
import { useDispatch } from "react-redux";

interface IToolbarProps {
    className?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: "center",
        display: "flex",
        flexShrink: 0,
        height: 68,
        padding: theme.spacing(2),
    },
}));

const Toolbar: FC<IToolbarProps> = ({ className, ...rest }: IToolbarProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleBack = (): void => {
        dispatch(selectedMail({}));
    };

    return (
        <div
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Tooltip title="Back">
                <IconButton onClick={handleBack} aria-label="Back">
                    <ArrowLeftIcon />
                </IconButton>
            </Tooltip>
            <Box flexGrow={1} />
            <Tooltip title="Previous Mail" style={{ display: "none" }}>
                <IconButton>
                    <ChevronLeftIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Next Mail" style={{ display: "none" }}>
                <IconButton>
                    <ChevronRightIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default Toolbar;
