import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    Input,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Paper,
} from "@material-ui/core";
import clsx from "clsx";
import { fetchMailUserList, sendReply } from "control/actions/mail.actions";
import { IRecipientUser, MailEntity } from "model/entity/mail.entity";
import React, {
    FC,
    useEffect,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import QuillEditor from "../QuillEditor";

interface IMailReplyProps {
    className?: string;
    isReplyAll?: boolean;
    replyCB?: (event: boolean) => void;
    singleReply: boolean;
    multipleReply: boolean;

}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        display: "flex",
    },
    avatar: {
        marginRight: theme.spacing(2),
    },
    input: {
        width: "100%",
    },
    textareaContainer: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    editor: {
        flexGrow: 1,
        "& .ql-editor": {
            minHeight: 100,
        },
    },
    textarea: {
        ...theme.typography.body1,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        border: "none",
        outline: "none",
        resize: "none",
        width: "100%",
    },
    action: {
        marginRight: theme.spacing(1),
    },
    fileInput: {
        display: "none",
    },
    emailsList: {
        background: "white",
        width: "90%",
        height: "50%",
        overflowY: "auto",
        border: "1px solid #b2b2b2",
        borderRadius: 4,
        padding: 10,
        zIndex: 999,
    },
    userList: {
        position: "absolute",
        background: "white",
        zIndex: 2,
        border: "1px solid",
        maxHeight: "300px",
        overflowX: "auto",
    },
    userListItem: {
        padding: "5px 10px",
        cursor: "pointer",

        "&:hover": {
            backgroundColor: "#f4f6f8",
        },
    },
    senderList: {
        display: "flex",
        flexWrap: "wrap",
    },
    emailListItem: {
        borderBottom: "1px solid #cecece",
        "&:hover": {
            background: "#cecece",
        },
    },
}));

const MailReply: FC<IMailReplyProps> = (props: any) => {
    const { className, isReplyAll, singleReply, multipleReply, replyCB, ...rest } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = { avatar: "" };
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [value, setValue] = useState("");
    const [to, setTo] = useState("");
    const mail = useSelector(MailEntity.getReadMail).message;
    const [showList, setShowList] = useState<boolean>(false);
    const list = useSelector(MailEntity.getUserList).PayLoad;
    const [userSelect, setUserSelect] = useState<IRecipientUser[]>([]);
    const mailView = useSelector(MailEntity.mailToViewDetails);
    const [hideEditor, setHideEditor] = useState<boolean | null | undefined>(singleReply);
    const [hideEditorMultiple, setHideEditorMultipe] = useState<boolean | null | undefined>(multipleReply);

    useEffect(() => {
        setHideEditor(singleReply);
        setHideEditorMultipe(multipleReply);
    }, [props]);

    const handleChange = (event: any) => {
        setValue(event);
    };


    const handleToChange = (e: any) => {
        e.preventDefault();
        e.persist();
        setTo(e.target.value);
        const val = e.target.value;
        if (val.length >= 3) {
            dispatch(fetchMailUserList(val));
        }
    };

    const selectEmailToSend = (selectedUser: any) => {
        setShowList(false);
        setTo(() => "");
        const checkIfUserExists = userSelect.filter((IRuser: IRecipientUser) => IRuser.recipientEmail === selectedUser.eMail);
        if (checkIfUserExists.length === 0) {
            const val = {
                recipientId: selectedUser.userGuid,
                recipientFirstName: selectedUser.firstName,
                recipientLastName: selectedUser.lastName,
                recipientEmail: selectedUser.eMail,
                recipientPrimeroUserId: selectedUser.primeroUserId,
            };
            setUserSelect((prevState) => [...prevState, val]);
        }
    };


    const removeSelectedUser = (IRuser: IRecipientUser) => {
        const filteredUser = userSelect.filter((tempUser: IRecipientUser) => tempUser.recipientEmail !== IRuser.recipientEmail);
        setUserSelect(filteredUser);
    };

    useEffect(() => {

        if (list && list.length > 0) {
            setShowList(true);
        }
    }, [list]);

    // const handleUserSelect = (selectedUser: any) => {

    //     setTo(selectedUser.eMail);

    //     setShowList(false);


    //     const val = {
    //         recipientId: selectedUser.userGuid,
    //         recipientFirstName: selectedUser.firstName,
    //         recipientLastName: selectedUser.lastName,
    //         recipientEmail: selectedUser.eMail,
    //         recipientPrimeroUserId: selectedUser.primeroUserId,
    //     };

    //     const userSelected = userSelect;

    //     userSelected.push(val);

    //     setUserSelect(userSelected);

    // };

    const handleSend = () => {

        if (value && mail.recipient && mail.recipient.length > 0) {

            const recipient = Array.from(mail.recipient);
            let recipients = null;

            if (mailView.selectedOption ? mailView.selectedOption !== "forward" : true) {
                if (mailView.selectedOption === "reply" || !isReplyAll) {
                    recipients = [{
                        recipientId: mail.senderId,
                        recipientFirstName: mailView.senderFirstName,
                        recipientLastName: mailView.senderLastName,
                        recipientEmail: mailView.senderEmail,
                        recipientPrimeroUserId: mailView.senderPrimeroUserId,
                    }];
                } else {

                    recipients = recipient.filter(
                        (x: any) =>
                            x.recipientEmail !== localStorage.getItem("email"),
                    );

                    recipients.push({
                        recipientId: mail.senderId,
                        recipientFirstName: mailView.senderFirstName,
                        recipientLastName: mailView.senderLastName,
                        recipientEmail: mailView.senderEmail,
                        recipientPrimeroUserId: mailView.senderPrimeroUserId,
                    });
                }
            } else if (mailView.selectedOption && mailView.selectedOption === "forward") {
                recipients = userSelect;
            }


            let subject = null;

            if (mailView.selectedOption === "forward") {
                if (!mail.subject.includes("Fwd:")) {
                    subject = `Fwd: ${mail.subject}`;
                } else {
                    subject = `${mail.subject}`;
                }
            } else {

                if (!mail.subject.includes("Re:")) {
                    subject = `Re: ${mail.subject}`;
                } else {
                    subject = `${mail.subject}`;
                }

            }

            const params = {
                conversationId: mail.conversationId,
                recipient: recipients?.filter((recipient: any) => recipient?.recipientEmail !== null),
                content: value,
                subject,
            };
            dispatch(sendReply(params));
        }

        setValue("");
        setTo("");
        setUserSelect([]);
        setHideEditor(false);
        setHideEditorMultipe(false);
        replyCB(false);
    };

    return (
        <>{(hideEditor || hideEditorMultiple) && (
            <div
                className={clsx(classes.root, className)}
                {...rest}
            >
                <Avatar
                    alt="Person"
                    className={classes.avatar}
                    src={user.avatar}
                />
                <Paper
                    className={classes.textareaContainer}
                    variant="outlined"
                >
                    {mailView.selectedOption && mailView.selectedOption === "forward" && (
                        <Box p={2}>
                            <div>
                                <div id="sender-list" className={classes.senderList}>
                                    {userSelect.length > 0 && userSelect.map((user: IRecipientUser) => (
                                        <Chip
                                            key={user.recipientEmail}
                                            id={`email-selected-${user.recipientEmail}`}
                                            avatar={
                                                (
                                                    <Avatar>
                                                        {user.recipientFirstName.charAt(0).toUpperCase()}{user.recipientLastName.charAt(0).toUpperCase()}
                                                    </Avatar>
                                                )
                                            }
                                            label={`${user.recipientFirstName} ${user.recipientLastName}`}
                                            onDelete={removeSelectedUser.bind(null, user)}
                                        />
                                    ))}
                                    <Input
                                        className={classes.input}
                                        disableUnderline={true}
                                        placeholder="To"
                                        onChange={handleToChange}
                                        value={to}
                                    />
                                </div>

                                {(list && list.length > 0 && showList) && (
                                    <List id="send-to-list" classes={{ root: classes.emailsList }} subheader={<li />}>
                                        {list.map((emailEntity: any) => {
                                            return (
                                                <ListItem
                                                    role="button"
                                                    button={true}
                                                    classes={{ root: classes.emailListItem }}
                                                    key={`email-${emailEntity.eMail}`}
                                                    onClick={selectEmailToSend.bind(null, emailEntity)}
                                                >
                                                    <ListItemText primary={emailEntity.eMail} />
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                )}
                            </div>
                        </Box>
                    )}
                    {mailView.selectedOption && mailView.selectedOption === "forward" && <Divider />}
                    <QuillEditor
                        className={classes.editor}
                        onChange={handleChange}
                        placeholder="Leave a message"
                        value={value}
                    />
                    <Box
                        display="flex"
                        alignItems="center"
                        mt={2}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={handleSend}
                            className={classes.action}
                        >
                            {mailView.selectedOption && mailView.selectedOption === "forward" ? "Forward" : "Reply"}
                        </Button>
                    </Box>
                </Paper>
                <input
                    className={classes.fileInput}
                    ref={fileInputRef}
                    type="file"
                />
            </div>
        )
        }
        </>
    );
};

export default MailReply;
