import { ModuleName } from "@primeroedge/ui-components";
import { IAppState } from "model/reducers";
import { ResponseStatus } from "./common.entity";
import { IMailEntity } from "./mail.entity";
import { ITask } from "./tasks.entity";

export interface IWorkspaceDetails {
    broadcastMessages?: IMailEntity[];
    favorites?: any;
    isBroadcastUser?: boolean;
    messages?: IMailEntity[];
    modules?: ModuleName[];
    reports?: any;
    tasks?: ITask[];
    totalUnreadBroadcastMessages?: number;
    totalUnreadMessages?: number;
    status: ResponseStatus;
    activeMessageTabValue?: number;
}

interface IMessage {
    id: string;
    recipientId: string;
    recipientUserName: string;
    conversationId: string;
    senderUserName: string;
    senderId: string;
    subject: string;
    content: string;
    isRead: boolean;
    isDeleted: boolean;
    deletedOn: string;
    createdOn: string;
}

export interface IMyMessagesProps {
    messages: IMessage[];
    broadcastMessages: any;
    unreadMessages: any;
    broadcastUnreadMessages: any;
    deleteMessage: any;
    isBroadcastUser: boolean;
}

export class WorkSpaceDetailsEntity {

    public static broadcastMessages ({ workspace }: IAppState) {
        return workspace.broadcastMessages;
    }

    public static favorites ({ workspace }: IAppState) {
        return workspace.favorites;
    }

    public static isBroadcastUser ({ workspace }: IAppState) {
        return workspace.isBroadcastUser;
    }

    public static messages ({ workspace }: IAppState) {
        return workspace.messages;
    }

    public static modules ({ workspace }: IAppState) {
        return workspace.modules;
    }

    public static reports ({ workspace }: IAppState) {
        return workspace.reports;
    }

    public static tasks ({ workspace }: IAppState) {
        return workspace.tasks;
    }

    public static totalUnreadBroadcastMessages ({ workspace }: IAppState) {
        return workspace.totalUnreadBroadcastMessages;
    }

    public static totalUnreadMessages ({ workspace }: IAppState) {
        return workspace.totalUnreadMessages;
    }

    public static status ({ workspace }: IAppState) {
        return workspace.status;
    }

    public static activeMessageTabValue ({ workspace }: IAppState) {
        return workspace.activeMessageTabValue;
    }

    public static isLoading ({ workspace }: IAppState) {
        return workspace.status === ResponseStatus.Loading;
    }

}
