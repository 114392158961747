import { createAction } from "@reduxjs/toolkit";
import { ITask, ITaskResponse, ITasksFetchQuery, ITasksPayload, ResponseStatus } from "model/entity";

export const FetchTasksAction = createAction(
    "FETCH_TASKS",
    (query: ITasksFetchQuery) => ({
        payload: query,
    }),
);

export const FetchTasksByDuration = createAction("FETCH_TASKS_BY_DURATION", (data: ITasksPayload) => ({
    payload: data,
}));

export const FetchTasksSuccessAction = createAction(
    "FETCH_TASKS_SUCCESS",
    (data: ITaskResponse) => ({
        payload: data,
    }),
);

export const SetUpdatedTasks = createAction(
    "SET_UPDATED_TASKS",
    (tasks: ITask[]) => ({
        payload: tasks,
    }),
);

export const SetTasks = createAction(
    "SET_TASKS",
    (tasks: ITask[]) => ({
        payload: tasks,
    }),
);

export const SetTasksDuration = createAction(
    "SET_TASKS_DURATION",
    (duration: string) => ({
        payload: duration,
    }),
);

export const SetTasksStatus = createAction(
    "SET_TASKS_STATUS",
    (status: ResponseStatus) => ({
        payload: status,
    }),
);

export const deleteTaskAction = createAction("DELETE_TASK", (payload) => ({
    payload,
}));

export const updateDeletedTask = createAction(
    "DELETE_TASK_UPDATE",
    (index: string) => ({
        payload: index,
    }),
);

export const addTaskAction = createAction("ADD_TASK", (data: ITask) => ({
    payload: data,
}));

export const updateTaskAction = createAction("UPDATE_TASK", (data: ITask) => ({
    payload: data,
}));

export const updatedAction = createAction("UPDATED_ACTION", (data: boolean) => ({
    payload: data,
}));
