import {
    Box,
    Card,
    CardContent,
    Divider,
    Typography,
} from "@material-ui/core";
import { Tooltip } from "@primeroedge/ui-components";
import React, { ReactNode } from "react";

const BaseCard: React.FC<{
    title: string;
    CardIcon?: ReactNode;
    bgColor?: string;
    header?: ReactNode;
    extraTabs?: ReactNode;
    tooltip?: string;
}> = ({ CardIcon, ...props }) => {
    return (
        <Card>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ backgroundColor: props.bgColor }}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{ color: "white", height: "50px" }}
                >
                    <Tooltip title={props.tooltip || ""} arrow={true} placement="top-end">
                        <Typography variant="h5" color="textPrimary">
                            <Box display="flex" alignItems="center" component="span">
                                <Box display="flex" alignItems="center" component="span" mx={1.5}>
                                    {CardIcon}
                                </Box>
                                <Box component="span">
                                    {props.title}
                                </Box>
                            </Box>
                        </Typography>
                    </Tooltip>
                </Box>
                {props.header}
                {props.extraTabs}
            </Box>
            <Divider />
            <CardContent style={{ padding: "0px" }}>{props.children}</CardContent>
        </Card>
    );
};

export default BaseCard;
