import {
    AppBar,
    Box,
    CardActions,
    CircularProgress,
    List,
    Tab,
    Tabs,
} from "@material-ui/core";
import { Button, MailIcon, Notification, PageAlert, PlusIcon } from "@primeroedge/ui-components";
import React, { ChangeEvent, FC, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import Compose from "view/pages/mail/MailView/Compose";
import BaseCard from "../../../../../components/BaseCard/BaseCard";
import TabPanel from "../../../../../components/TabPanel/TabPanel";
import {
    fetchWorkspaceDetails, selectedBroadcastMessage, setActiveMessageTabValue, setComposeBoXWindowOpenCLose,
} from "../../../../../control/actions";
import { WorkSpaceDetailsEntity } from "../../../../../model/entity";
import ComposeBroadcast from "../../../mail/MailView/BroadcastMessages/ComposeBroadcast";
import { useStyles } from "../../style";
import TabBadge from "../TabBadge";
import BroadcastItem from "./BroadcastItem";
import MailItem from "./MailItem";

const MyMessages: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const messages = useSelector(WorkSpaceDetailsEntity.messages);
    const broadcastMessages = useSelector(WorkSpaceDetailsEntity.broadcastMessages);
    const totalUnreadMessages = useSelector(WorkSpaceDetailsEntity.totalUnreadMessages);
    const totalUnreadBroadcastMessages = useSelector(WorkSpaceDetailsEntity.totalUnreadBroadcastMessages);
    const isBroadcastUser = useSelector(WorkSpaceDetailsEntity.isBroadcastUser);
    const activeMessageTabValue = useSelector(WorkSpaceDetailsEntity.activeMessageTabValue);
    const isLoading = useSelector(WorkSpaceDetailsEntity.isLoading);

    const [showBroadcastCompose, setShowBroadcastCompose] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
       dispatch(setActiveMessageTabValue(0));
    }, [dispatch]);

    //@ts-ignore
    const handleTabChange = (ev: ChangeEvent<{}>, newValue: number) => {
        dispatch(setActiveMessageTabValue(newValue));
    };

    const handleComposeClose = () => {
        setShowBroadcastCompose(false);
    };

    const handleComposePost = () => {
        handleComposeClose();
        dispatch(fetchWorkspaceDetails());
    };

    const notificationCb = (msg: any) => {
        setShowNotification(msg);
    };
    return (
        <>
            <Notification
                style={{
                    position: "relative",
                    top: "-67px",
                }}
                color="success"
                duration={5000}
                message="Deleted successfully!"
                onClose={() => setShowNotification(false)}
                open={showNotification}
                variant="filled"
            />
            <BaseCard
                title="Messages"
                CardIcon={<MailIcon />}
                tooltip="View and send important messages throughout your organization."
            >
                <AppBar position="static" color="transparent">
                    <Tabs
                        value={activeMessageTabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        aria-label="Messages"
                        variant="fullWidth"
                    >
                        <Tab
                            id="inboxTab"
                            label={(
                                <TabBadge
                                    badgeContent={totalUnreadMessages}
                                    max={99}
                                    showZero={true}
                                    color="primary"
                                >
                                    INBOX
                                </TabBadge>
                            )}
                            aria-controls="inbox-tab"
                        />
                        {isBroadcastUser && (
                            <Tab
                                id="broadcastTab"
                                label={(
                                    <TabBadge
                                        badgeContent={totalUnreadBroadcastMessages}
                                        max={99}
                                        showZero={true}
                                        color="primary"
                                    >
                                        BROADCAST MESSAGE
                                    </TabBadge>
                                )}
                                aria-controls="broadcast-tab"
                            />
                        )}
                    </Tabs>
                </AppBar>

                <Compose />
                {showBroadcastCompose && (
                    <ComposeBroadcast
                        onPost={handleComposePost}
                        onClose={handleComposeClose}
                        message={undefined}
                        editable={false}
                    />
                )}

                <SwipeableViews
                    index={activeMessageTabValue}
                    // onChangeIndex={handleChangeIndex}
                    className={`${classes.msgContentHeight}`}
                >
                    <TabPanel value={activeMessageTabValue} index={0}>
                        {isLoading && (
                            <Box className={classes.tabContainer}>
                                <CircularProgress color="secondary" />
                            </Box>
                        )}
                        {!isLoading && (messages && messages.length > 0 ? (
                            <List>
                                {messages.map((mail: any, i: number) => (
                                    <Fragment key={i}>
                                        {!mail.isRead && <MailItem notificationCb={notificationCb} mail={mail} />}
                                    </Fragment>
                                ))}
                            </List>
                        ) : (
                            <>
                                <Box className={classes.tabContainer} style={{ minHeight: "auto", paddingBottom: "5px" }}>
                                    <PageAlert
                                        icon={<MailIcon size={120} />}
                                        message="New messages will show up here!"
                                        variant="transparent"
                                    />
                                </Box>
                                <p className={classes.pageAlertTxt} >
                                    Click on View All to browse all messages!</p>
                            </>
                        ))}
                    </TabPanel>

                    <TabPanel value={activeMessageTabValue} index={1}>
                        {isLoading && (
                            <Box className={classes.tabContainer}>
                                <CircularProgress color="secondary" />
                            </Box>
                        )}
                        {!isLoading && (broadcastMessages && broadcastMessages.length > 0 ? (
                            <List>
                                {broadcastMessages.map((broadcastMessage: any, i: number) => (
                                    <Fragment key={i}>
                                        {!broadcastMessage.isRead && <BroadcastItem notificationCb={notificationCb} broadcast={broadcastMessage} />}
                                    </Fragment>
                                ))}
                            </List>
                        ) : (
                            <Box className={classes.tabContainer}>
                                <PageAlert
                                    icon={<MailIcon size={120} />}
                                    message="New broadcast messages will show up here!"
                                    variant="transparent"
                                />
                            </Box>
                        ))}
                    </TabPanel>
                </SwipeableViews>

                <CardActions className={classes.taskCardAction}>
                    <Button
                        color="primary"
                        dense={true}
                        id="primaryButton"
                        name="Button"
                        onClick={() => {
                            if (activeMessageTabValue === 0) dispatch(setComposeBoXWindowOpenCLose(true));
                            if (activeMessageTabValue === 1) setShowBroadcastCompose(true);
                        }}
                        startIcon={<PlusIcon />}
                        type="button"
                        variant="default"
                    >
                        New Message
                    </Button>

                    <Button
                        color="default"
                        dense={true}
                        id="defaultButton"
                        name="viewAll"
                        onClick={() => {
                            if (activeMessageTabValue === 0) {
                                history.push("/workspace/inbox");
                            }
                            if (activeMessageTabValue === 1) {
                                dispatch(selectedBroadcastMessage({}));
                                history.push("/workspace/broadcast");
                            }
                        }}
                        type="button"
                        variant="default"
                    >
                        View All
                    </Button>
                </CardActions>
            </BaseCard>
            <style>{`
            .shepherd-target {
                box-shadow: 0 0px 9px white;
            } 
            `}</style>
        </>
    );
};

export default MyMessages;
