import {
    FormControl,
    InputAdornment,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { SlidingDrawer } from "@primeroedge/ui-components";
import { updatedAction, updateTaskAction } from "control/actions/tasks.actions";
import { ITask } from "model/entity/tasks.entity";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addNewTask, updateTask } from "./task-utils";

interface IAddNewTask {
  open: boolean;
  onClose: (success: boolean) => void;
  editable: boolean;
  editableTask?: ITask | null;
}

const classes = makeStyles(() => ({
  containerRoot: {
    alignItems: "center",
    marginBottom: "20px",
  },
  containerRootTopped: {
    alignItems: "baseline",
  },
  fullWidthInputs: {
    width: "100%",
  },
}));

const AddNewTask = ({ open, onClose, editable, editableTask }: IAddNewTask) => {
  const dispatch = useDispatch();
  const styles = classes();
  const initialTask: ITask = {
    title: "",
    dueDate: moment(new Date()).format("YYYY-MM-DD"),
    status: "New",
    description: "",
    id: "",
  };

  const [newTask, updateNewTask] = useState<ITask>(initialTask);
  const [hasErrors, setHasErrors] = useState<string[]>([]);
  const disableForm: boolean = editableTask?.status === 'Done' ? true : false;
  useEffect(() => {
    if (editable && editableTask) {
      updateNewTask(editableTask);
    }
  }, [editable, editableTask]);

  const newTaskValidation = () => {
    const error: string[] = [];
    if (newTask.title.length < 3) {
      error.push("title");
    }
    if (
      !editable &&
      (newTask.dueDate === "" ||
        moment(newTask.dueDate).diff(moment.now(), "minutes") <= -1441)
    ) {
      error.push("dueDate");
    }

    if (newTask.description === "") {
      error.push("description");
    }

    if (error.length > 0) {
      setHasErrors(() => [...error]);
    } else {
      setHasErrors(() => []);
    }

    return error.length > 0;
  };

  const onNewTaskSave = () => {
    const validateNewTask = newTaskValidation();
    if (!validateNewTask) {
      if (!editable) {
        addNewTask({
          title: newTask.title,
          dueDate: newTask.dueDate,
          status: newTask.status,
          description: newTask.description,
        })
          .then(() => {
            updateNewTask(initialTask);
            onClose(true);
            dispatch(updatedAction(true));
          })
          .catch(() => { });
      } else {
        updateTask({ ...newTask, taskId: newTask?.taskId })
          .then(() => {
            dispatch(updateTaskAction({ ...newTask }));
            dispatch(updatedAction(true));
            updateNewTask(initialTask);
            onClose(true);
          })
          .catch(() => { });
      }
    }
  };

  const closeSlider = () => {
    updateNewTask(initialTask);
    onClose(false);
  };

  return (
    <SlidingDrawer
      open={open}
      title={editable ? "Edit Task" : "New Task"}
      width="md"
      primaryAction={{
        onClick: onNewTaskSave,
        disabled: disableForm
      }}
      onCloseIconClick={closeSlider}
    >
      <Grid
        container={true}
        spacing={2}
        classes={{ root: styles.containerRoot }}
      >
        <Grid xs={12} item={true} style={{ marginTop: 10 }}>
          <TextField
            variant="outlined"
            classes={{ root: styles.fullWidthInputs }}
            onChange={(event) => {
              event.preventDefault();
              event.persist();
              updateNewTask((prevState) => ({
                ...prevState,
                title: event.target.value,
              }));
            }}
            value={newTask.title}
            name={"task-name"}
            id={"taskName"}
            label={"Task Name"}
            error={hasErrors.includes("title")}
            helperText={
              hasErrors.includes("title") ? "Task name is required field." : ""
            }
            inputProps={{ maxLength: 100 }}
            disabled={disableForm}
        />
        </Grid>
      </Grid>
      <Grid
        container={true}
        spacing={2}
        classes={{ root: styles.containerRoot }}
      >
        <Grid xs={12} item={true}>
          <TextField
            variant="outlined"
            classes={{ root: styles.fullWidthInputs }}
            onChange={(event) => {
              event.preventDefault();
              event.persist();
              updateNewTask((prevState) => ({
                ...prevState,
                dueDate: event.target.value,
              }));
            }}
            value={
              Boolean(editable && editableTask)
                ? moment(newTask.dueDate).format("YYYY-MM-DD")
                : newTask.dueDate
            }
            label={"Due Date"}
            name="dueDate"
            id="newTaskDueDate"
            type="date"
            error={hasErrors.includes("dueDate")}
            helperText={
              hasErrors.includes("dueDate")
                ? newTask.dueDate !== "" &&
                  moment(newTask.dueDate).diff(moment.now(), "minutes") <= -1441
                  ? "Select a valid due date that is not in the past."
                  : "Due date is required field."
                : ""
            }
            inputProps={{
              "aria-label": "Due Date",
            }}
            disabled={disableForm}
          />
        </Grid>
      </Grid>
      <Grid
        container={true}
        spacing={2}
        classes={{ root: styles.containerRoot }}
      >
        <Grid xs={12} item={true}>
          <FormControl
            variant="outlined"
            classes={{ root: styles.fullWidthInputs }}
            disabled={disableForm}
          >
            <InputLabel htmlFor="status-select" id="status-label">
              Status
            </InputLabel>
            <Select
              id="newTaskStatus"
              value={newTask.status}
              label="Status"
              onChange={(event: any) => {
                event.preventDefault();
                event.persist();
                updateNewTask((prevState) => ({
                  ...prevState,
                  status: event.target.value,
                }));
              }}
              inputProps={{
                name: "Status",
                id: "status-select",
              }}
            >
              <MenuItem value={"New"}>New</MenuItem>
              <MenuItem value={"InProgress"}>In Progress</MenuItem>
              <MenuItem value={"Done"}>Done</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container={true}
        spacing={2}
        classes={{ root: styles.containerRootTopped }}
      >
        <Grid xs={12} item={true}>
          <TextField
            id="newTaskDescription"
            type="textarea"
            name="newTaskDescription"
            label="Description"
            variant="outlined"
            value={newTask.description}
            classes={{ root: styles.fullWidthInputs }}
            onChange={(event) => {
              event.preventDefault();
              event.persist();
              updateNewTask((prevState) => ({
                ...prevState,
                description: event.target.value,
              }));
            }}
            multiline={true}
            rows={6}
            rowsMax={12}
            error={hasErrors.includes("description")}
            helperText={
              hasErrors.includes("description")
                ? "Description is a required field."
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                <span style={{marginTop: '110px'}}>{newTask?.description?.length}/500</span>
            </InputAdornment>
              )
            }}
            inputProps={{
              maxLength: 500,
              "aria-label": "Description",
            }}
            disabled={disableForm}
          />
        </Grid>
      </Grid>
    </SlidingDrawer>
  );
};

export default AddNewTask;
