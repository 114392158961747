import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from "@material-ui/core";
import { Button, IGroup, SummaryList } from "@primeroedge/ui-components";
import { getUserDetailsKeyclockEntity} from "model/entity";
import React, { useEffect, useState } from "react";
import {  useSelector } from "react-redux";

interface IRoles {
    roles: string;
    access: IGroup[];
    sites: IGroup[];
}

const styles = makeStyles({
    root: {
        color: "#1976d2",
        textDecoration: "underline",
        display: "inline",
        cursor: "pointer",
    },
    paper: {
        width: "500px",
        padding: "20px",
    },
    dialogTitle: {
        textAlign: "left",
        borderBottom: "1px solid #d4d4d4",
    },
    roles: {
        margin: "10px",
    },
    dialogAction: {
        justifyContent: "flex-end",
        borderTop: "1px solid #d4d4d4",
    },
});

const RolesInfo = ({ roles, access, sites }: IRoles) => {
    const classes = styles();
    const [showDialog, canShowDialog] = useState(false);
    const [allSitesAccess, setAllSitesAccess] = useState(false);

    useEffect(() => {
        access.forEach((group: IGroup) => {
            if (group.attributes.organizationLevel && group.attributes.organizationLevel[0] === "District"
                && sites?.length === 0) {
                setAllSitesAccess(true);
            }
        });
    }, [access]);

    const  userInfo = useSelector(getUserDetailsKeyclockEntity).AdminServiceUserDetails;

    return (
        <SummaryList
            header="Roles & Permissions"
            canEdit={false}
            items={[
                {
                    title: "Primary District",
                    value: userInfo?.PayLoad?.primaryRegionName,
                    type: "text",
                },
                {
                    title: "Roles",
                    value: roles,
                    type: "text",
                },
                {
                    title: "Access",
                    value: (
                        <>
                            <Typography style={{ color: "#546e7a" }} variant="body2">
                                Your account has access to {" "}
                                {allSitesAccess ? (
                                    <Typography
                                        component={"span"}
                                        variant="body2"
                                    >
                                        all sites.
                                    </Typography>
                                ) : (
                                    <Typography
                                        component={"a"}
                                        role="button"
                                        onClick={() => canShowDialog(() => true)}
                                        classes={{ root: classes.root }}
                                        variant="body2"
                                    >
                                        {sites.length > 0 ? sites.length : 0} sites.
                                    </Typography>
                                )}
                            </Typography>
                            <Dialog
                                open={showDialog}
                                onClose={() => canShowDialog(() => false)}
                                aria-labelledby="alert-dialog-title"
                                classes={{ paper: classes.paper }}
                            >
                                <DialogTitle
                                    classes={{ root: classes.dialogTitle }}
                                    id="alert-roles-title"
                                >
                                    Your Sites
                                </DialogTitle>
                                <DialogContent>
                                    {sites.map((accessItem: IGroup, index: number) => (
                                        <Typography
                                            classes={{ root: classes.roles }}
                                            key={`${accessItem.name}-${index}`}
                                            color="textSecondary"
                                            variant="body2"
                                        >
                                            {`${accessItem.name} ${accessItem.attributes.type ? `(${accessItem.attributes.type[0]})` : ""}`}
                                        </Typography>
                                    ))}
                                </DialogContent>
                                <DialogActions classes={{ root: classes.dialogAction }}>
                                    <Button
                                        id="roles-cancel"
                                        onClick={() => canShowDialog(() => false)}
                                        color="default"
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    ),
                    type: "text",
                },
            ]}
        />
    );
};

export default RolesInfo;
