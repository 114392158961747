import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";

interface IRequiredLabelProps {
    inputLabel: string;
}

const useStyles = makeStyles(() => ({
    error: {
        color: "#EB0000",
    },
}));

const RequiredLabel: FC<IRequiredLabelProps> = ({ inputLabel }) => {
    const classes = useStyles();

    return (
        <span>{inputLabel}<span className={classes.error}> *</span></span>
    );
};

export default RequiredLabel;
