import React from 'react';
import { MaximizeIcon, MinimizeIcon, Minimize2Icon, CloseIcon, Notification } from '@primeroedge/ui-components';
import { Window } from "@progress/kendo-react-dialogs";
import MailSendList from './MailSendList';
import useMailWindow from './hooks/useMailWindow';
import { isNil } from 'lodash';


const MailWindow = function () {
    const {
        canShowMailWindow,
        position,
        stage,
        onClose,
        handleStageChange,
        alertContent,
        setAlertContent
    } = useMailWindow();

    return (
        <>
            <Notification
                color={alertContent?.color}
                duration={5000}
                message={alertContent?.message}
                onClose={() => setAlertContent(null)}
                open={!isNil(alertContent)}
                variant="filled"
            />
            {canShowMailWindow && (
                <Window title={"Send Message"}
                    left={position.right}
                    top={position.top}
                    width={position.width}
                    height={position.height}
                    onClose={onClose}
                    closeButton={() => (<div className='icon-hover'><CloseIcon size={16} onClick={onClose} /></div>)}
                    maximizeButton={() => (<div className='icon-hover'><MaximizeIcon size={16} onClick={() => handleStageChange({ state: 'FULLSCREEN' })} /></div>)}
                    minimizeButton={() => (<div className='icon-hover'><Minimize2Icon size={16} onClick={() => handleStageChange({ state: 'MINIMIZED' })} /></div>)}
                    restoreButton={() => (<div className='icon-hover'><MinimizeIcon size={16} onClick={() => handleStageChange({ state: 'DEFAULT' })} /></div>)}
                    minWidth={65}
                    stage={stage}
                >
                    <MailSendList />
                </Window>
            )}
            <style>{`
                .icon-hover:hover {
                    background-color: #e4e4e4;
                }
                .icon-hover:hover {
                    background-color: #e4e4e4;
                }
                .k-window-actions {
                    gap: 10px;
                }
        `}</style>
        </>
    );
}

export default MailWindow;
