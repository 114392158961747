import { IAppState } from "../reducers";

export interface IAccessibilityInfo {
    theme: string;
    language: string;
}

export interface IUserProfile {
    attributes: any;
    accessLevelDescription: string;
    regions: string | null;
    roles: string | null;
    area: string | null;
    sites: string | null;
    enabled: boolean;
    regionID: number | null;
    accessLevelId: number;
    authenticationMode: string | null;
    cellPhone: string;
    createdUserId: number;
    creationDate: string;
    defaultRealmId: number;
    defaultRegionId: number;
    email: string;
    failedLoginAttempts?: number;
    firstName: string;
    image: string;
    languagepreference: string | null;
    lastFailedLoginDate?: string;
    lastName: string;
    lastPasswordChange?: string;
    mi: string;
    primeroUserId: string;
    regionId: number | null;
    regionName: string | null;
    theme: string | null;
    totalRecords: number;
    updateBy: string | null;
    updateDate: string;
    userId: number;
    userName: string;
    userStatusDescription: string;
    userStatusId: number;
    windowsUserDomain: string;
    windowsUserName: string;
    workPhone: string;
    keyCloakID: string | null;
    navigateOption?: string;
    dashboardV2?: boolean;
    userStatus?: string
    id?: string;
    audioPing?: boolean;
    bannerDuration?: number;
    bannerLocation?: string;
    showBanner?: boolean;
}


export interface IPasswordPolicies {
    code: string;
    name: string | null;
    policyId: number;
    policyValue: string;
    strengthId: number;
}

export interface IAdminServiceUserDetails {
    PayLoad: any;
    StatusCode: number;
    userId: number;
    cellPhone: string;
    email: string;
    firstName: string;
    image: string;
    languagepreference: string;
    lastName: string;
    mi: string;
    theme: string;
    workPhone: string;
    primaryRegionName:string;
    navigateOption: string;
    dashboardV2?: boolean;
    audioPing?: boolean;
    bannerDuration?: number;
    bannerLocation?: string;
    showBanner?: boolean;
}
export interface IUserMgmatUserDetails {
    PayLoad: any;
    StatusCode: number;
    access: any;
    attributes: any;
    createdTimestamp: any;
    disableableCredentialTypes: any;
    email: string;
    emailVerified: boolean;
    enabled: boolean;
    firstName: string;
    groups: any;
    lastName: string;
    primeroUserId: string;
    roles: any;
    userGuid: string;
    userName: string;
}

export interface IProfileErrors {
    firstNameError: boolean;
    lastNameError: boolean;
    workPhoneError: boolean;
}

export interface IRegionLanguage {
    id: number;
    name: string;
    languageId: number;
    isDefault: boolean;
    isVisible: boolean;
    isActive: boolean;
}

export interface IRolesList {
    roleGuid: string;
    roleName: string;
    roleType: string;
}

export interface IUserProfileReducer {
    profile: IUserProfile | null;
    profileUpdateStatus: boolean | null;
    rolesList: IRolesList[];
    userDetailsKeyclock: IUserDetailsKeyclock;
    passwordPolicies: IPasswordPolicies[];
    isUserLoading: boolean;
}

export interface IUserDetailsKeyclock {
    UserMgmatUserDetails: IUserMgmatUserDetails;
    AdminServiceUserDetails: IAdminServiceUserDetails;
}

export const getUserProfileData = (state: IAppState) => {
    return state.profile.profile;
};

export const checkProfileUpdateStatus = (state: IAppState) => {
    return state.profile.profileUpdateStatus;
};

export function getRolesList (state: IAppState) {
    return state.profile.rolesList;
}

export function getUserDetailsKeyclockEntity (state: IAppState) {
    return state.profile.userDetailsKeyclock;
}

export function getPasswordPolicies (state: IAppState) {
    return state.profile.passwordPolicies;
}

export function getIsUserLoading (state: IAppState) {
    return state.profile.isUserLoading;
}

export enum ContactInfoFields {
    FirstName = "firstName",
    LastName = "lastName",
    MiddleName = "mi",
    Email = "email",
    CellPhone = "cellPhone",
    WorkPhone = "workPhone",
}
