import { createAction } from "@reduxjs/toolkit";
import { IAddEditGradeGroupPayload } from "model/entity/gradeGroup.entity";

export const fetchGradeGroupsInit = createAction("FETCH_GRADE_GROUPS_INIT");
export const fetchGradeGroupsSuccess = createAction(
    "FETCH_GRADE_GROUPS_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);
export const fetchGradeGroupsFail = createAction("FETCH_GRADE_GROUPS_FAIL");

export const fetchAvailableGradeGroupsInit = createAction(
    "FETCH_AVAILABLE_GRADES_INIT",
);
export const fetchAvailableGradesSuccess = createAction(
    "FETCH_AVAILABLE_GRADES_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);
export const fetchAvailableGradesFail = createAction(
    "FETCH_AVAILABLE_GRADES_FAIL",
);

export const addGradeGroupInit = createAction(
    "ADD_GRADE_GROUP_INIT",
    (data: {
        name?: string;
        id?: number;
        grades?: number[];
    }) => ({
        payload: data,
    }),
);

export const addGradeGroupSuccess = createAction(
    "ADD_GRADE_GROUP_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);
export const addGradeGroupFail = createAction("ADD_GRADE_GROUP__FAIL");

export const editGradeGroupInit = createAction(
    "EDIT_GRADE_GROUP_INIT",
    (data: IAddEditGradeGroupPayload) => ({
        payload: data,
    }),
);

export const editGradeGroupSuccess = createAction(
    "EDIT_GRADE_GROUP_SUCCESS",
    (data) => ({
        payload: {
            data,
        },
    }),
);
export const editGradeGroupFail = createAction("EDIT_GRADEGROUP_FAIL");
