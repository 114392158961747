import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import Axios from 'axios'
import smartViewStyles from './smartViewStyles';
import { Box, Card, Paper, Typography } from '@material-ui/core';
import { WorkspaceModuleIcon, ModuleName, useAuth, Button, HelpDocumentation, SmartAccount, useProfile, TopBarPageSearch, Copyright, SlidingDrawer, MailIcon, TaskIcon, FileTextIcon, SCLoader } from '@primeroedge/ui-components';
import { useSelector } from 'react-redux';
import { WorkSpaceDetailsEntity, getUpdatedTasks } from 'model/entity';
import { CustomReportsEntity } from 'model/entity/CustomReports.entity';
import Version from '../login-page/version';
import { APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY, API_DATA_EXCHANGE } from 'control/transport/clients/api-constants';
import SmartViewPageDisplay from './components/SmartViewPageDisplay';
import { isNil } from 'lodash';
import { useDistrictTimeZone } from '../common/useDistrictTimezone';
import moment from "moment-timezone";
import { timeZones } from '../common/date-utils';
import { useHistory } from 'react-router-dom';

const messageStyles = { display: 'flex', flexDirection: 'row', gap: 4, alignItems: 'center', cursor: 'pointer' } as any;
const ChipText = ({children}: {children: React.ReactNode}) => (<Typography variant='body2' style={{ background: '#6655D8', padding: '0 5px', color: '#fff', height: 17, width: 17, lineHeight: 1.4, fontSize: 13, borderRadius: '50%'}}>{children}</Typography>)

export default function SmartView({
    returnToDashboard
}: {
    returnToDashboard: () => void
}) {
    const classes = smartViewStyles();
    const timezone = useDistrictTimeZone()
    const { user } = useProfile()
    const { logout } = useAuth();
    const history = useHistory()
    const totalUnreadMessages = useSelector(WorkSpaceDetailsEntity.totalUnreadMessages);
    const customReportsData = useSelector(CustomReportsEntity.getCustomReports);
    const tasks = useSelector(getUpdatedTasks);
    const [userImageUrl, setUserImageUrl] = useState<string>('');
    const [viewAll, setViewAll] = useState<boolean>(false);
    const [isFooterStatic, setIsFooterStatic] = useState<boolean>(false);
    const downloadImage = (blob: string) => {
        const fileURL = `${API_DATA_EXCHANGE}/api/FileStorage/Download?featureName=user&fileName=${blob}`;

        Axios.get(fileURL, {
            responseType: "blob",
            headers: {
                Environment: APIM_ENVIRONMENT,
                "Ocp-Apim-Subscription-Key": APIM_SUBSCRIPTION_KEY,
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": `application/octet-stream`,
            },
        })
            .then((resp: any) => {
                const dwnldlink = window.URL.createObjectURL(
                    new Blob([resp.data], {
                        type: resp.headers["content-type"],
                    }),
                );
                setUserImageUrl(dwnldlink);
            })
            .catch((ex) => {
                console.info(ex);
            });
    };

    const handleLogout = () => {
        localStorage.removeItem('isSmartLogin')
        logout();
    }

    useEffect(function () {
        if (user?.Image) {
            downloadImage(user.Image)
        }
    }, [user])

    const recommended = useMemo(() => {
        if (timezone.selectedDistrictTimezone) {
            const currentTime = moment().tz(timeZones[timezone.selectedDistrictTimezone as string]).format('HH')
            const listToUse = parseInt(currentTime) >= 12 ?  favMasterList : recommendedMasterList;
            const list = listToUse
            .filter((fav: {moduleName: string, pageName: string, resourceName: string}) => {
                const module = user?.RolesPermissions
                    .find((item: any) => item.moduleName === fav.moduleName);
                if (!isNil(module)) {
                    return module.resources
                        .find((pageItem: {resourceName: string, scopes: string[]}) => pageItem.resourceName === fav.resourceName)?.scopes?.includes?.('View')
                }

                return false;

            }).sort((recommendA: any, recommendB: any) => recommendA.order - recommendB.order);
            return list;
        }
        return undefined
    }, [user, timezone])

    const favourites = useMemo(() => {
        return undefined;
    }, [user])

    const onItemNav = function(type: 'Inbox' | 'Tasks' | 'reports') {
        if (type !== 'reports') {
            history.push(`/workspace/${type}`)
        } else {
            window.location.href = '/reports/Custom-Reports'
        }
    }

    const onResize = function() {
        if (document.body.clientHeight > 600) {
            setIsFooterStatic(true);
        } else {
            setIsFooterStatic(false);
        }
    }

    useLayoutEffect(function() {
        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [document])

    return (
        <>
            <Box className={classes.smartViewContainer}>
                <Box className={classes.header}>
                    <HelpDocumentation isStandAlone/>
                    <SmartAccount user={user}
                        userImageUrl={userImageUrl}
                        handleLogout={handleLogout}
                        navigateToProfile={() => {
                            window.location.href = '/workspace/profile'
                        }} />
                </Box>
                <Card className={classes.cardContainer}>
                    <Box className={classes.flexColCentered}>
                        <img
                            src="/logo/SchoolCafe.svg"
                            style={{
                                maxWidth: 350,
                                height: 30
                            }}
                            alt="SchoolCafé"
                        />
                        <span style={{ fontWeight: 500, fontSize: 14 }}>Welcome, {user?.FirstName}!</span>
                        <Box className={classes.mainContent}>
                            <Box style={{ width: '95%' }}>
                                <TopBarPageSearch isStandAlone={true} />
                            </Box>
                            <Box className={classes.summaryCountsContainer}>
                                <Box onClick={() => onItemNav('Inbox')} style={messageStyles}>
                                    <MailIcon size={16} />
                                    <Typography variant="h4" color="textSecondary" style={{ fontSize: 12 }}>MESSAGES</Typography>
                                    <ChipText>{totalUnreadMessages ?? 0}</ChipText>
                                </Box>
                                <Box onClick={() => onItemNav('Tasks')} style={messageStyles}>
                                    <TaskIcon size={13} />
                                    <Typography variant="h4" color="textSecondary" style={{ fontSize: 12 }}>TASKS</Typography>
                                    <ChipText>{tasks?.length ?? 0}</ChipText>
                                </Box>
                                <Box onClick={() => onItemNav('reports')} style={messageStyles}>
                                    <FileTextIcon size={16} />
                                    <Typography variant="h4" color="textSecondary" style={{ fontSize: 12 }}>STARRED REPORTS</Typography>
                                    <ChipText>{customReportsData.customReportFavorites.length ?? 0}</ChipText>
                                </Box>
                            </Box>
                            <Paper style={{ width: '95%', height: 125, padding: 8, marginBottom: 8 }} elevation={3}>
                                <Typography variant="h4" color="textPrimary" style={{ fontSize: 18 }}>Recommended</Typography>
                                <Box className={classes.recommendedFavContainer}>
                                    { !isNil(recommended) ? recommended?.length > 0 ? recommended
                                        .slice(0, 4)
                                        .map((item: any) => (<SmartViewPageDisplay key={`${item.pageName}`} {...item} displayColor='#5DE6B2'/>)) : 
                                        <Typography>No recommendations available</Typography> : (<SCLoader />)}
                                    </Box>
                            </Paper>

                            <Paper style={{ width: '95%', height: 80, padding: 8, marginBottom: 8 }} elevation={3}>
                                <Typography variant="h4" color="primary" style={{ fontSize: 18 }}>Your Favorites</Typography>
                                <Box className={`${classes.recommendedFavContainer}`}>
                                    
                                {isNil(favourites) ? (<Typography color="textSecondary" style={{ width: '100%', textAlign: 'center', fontSize: 14}}>There are no favorites available at this time. (Coming soon!)</Typography>) : null}
                                    </Box>
                            </Paper>

                            <Box style={{ width: '95%' }}>
                                <Button style={{ width: '100%' }} id="exit-smart-dashboard" onClick={returnToDashboard} label={'VIEW FULL DASHBOARD'} variant={'default'}
                                    color={'primary'}
                                    startIcon={<WorkspaceModuleIcon selected={'white'} />} />
                            </Box>
                        </Box>
                    </Box>
                </Card>
                <Box style={isFooterStatic ? {
                    position: 'absolute',
                    bottom: 0
                } : {}}>
                    <Version />
                    <Copyright />
                </Box>
            </Box>
            <SlidingDrawer isViewHeight displayXIcon open={viewAll}
                title={'All Favourites'}
                width="md"
                onBackdropClick={() => { setViewAll(false)}}
                onCloseIconClick={() => { setViewAll(false)}}>
                <Box style={{ display: 'flex', gap: 12, justifyContent: 'space-between', padding: '0 20px', alignContent: 'center', flexWrap: 'wrap', overflowY: 'auto'}}>
                {
                    recommended?.slice(0, 10)?.map((item: any) => (<SmartViewPageDisplay key={`${item.pageName}`} {...item} displayColor='#665AD8'/>))}
                </Box>
            </SlidingDrawer>
        </>
    );
}

const expressPointNonProdUrl = (env: string) => {
    if (env.toLowerCase() === 'solutions') {
        return `https://demoexpresspointui.schoolcafe.com`;
    } else if (env.toLowerCase().includes('localhost')) {
        return `https://devexpresspointui.perseusedge.com`;
    } else if (env.toLowerCase().includes('learn')) {
        return `https://learnexpresspointui.schoolcafe.com`;
    } else if (env.toLowerCase().includes('sales')) {
        return `https://salesexpresspointui.schoolcafe.com`;
    } else {
        return `https://${env}expresspointui.perseusedge.com`;
    }
};

export const recommendedMasterList: Array<{
    moduleName: ModuleName
    pageName: string
    resourceName: string
    onClick: () => void
    order: number
}> = [
        {
            moduleName: ModuleName.Accountability,
            pageName: 'POS',
            resourceName: 'POS',
            onClick: () => {
                if (window.location.host.includes('login.schoolcafe')) {
                    window.open(
                        `https://expresspoint.login.schoolcafe.com?accessToken=${localStorage.getItem(
                            'accessToken'
                        )}&expiresIn=${localStorage.getItem(
                            'expiresIn'
                        )}&refreshExpiresIn=${localStorage.getItem(
                            'refreshExpiresIn'
                        )}&posVersion=${localStorage.getItem(
                            'posVersion'
                        )}&posBetaVersion=${localStorage.getItem(
                            'posBetaVersion'
                        )}&tokenType=${localStorage.getItem(
                            'tokenType'
                        )}&regionId=${localStorage.getItem('userRegion')}`,
                        '_blank'
                    );
                } else {
                    window.open(
                        `${expressPointNonProdUrl(
                            window.location.host.split('.')[0]
                        )}?accessToken=${localStorage.getItem(
                            'accessToken'
                        )}&expiresIn=${localStorage.getItem(
                            'expiresIn'
                        )}&refreshExpiresIn=${localStorage.getItem(
                            'refreshExpiresIn'
                        )}&posVersion=${localStorage.getItem(
                            'posVersion'
                        )}&posBetaVersion=${localStorage.getItem(
                            'posBetaVersion'
                        )}&tokenType=${localStorage.getItem('tokenType')}`,
                        '_blank'
                    );
                }
            },
            order: 1,
        },
        {
            moduleName: ModuleName.Accountability,
            pageName: 'Sessions',
            resourceName: 'Sessions',
            onClick: () => {
                window.location.href = '/accountability/Sessions'
            },
            order: 2,
        },
        {
            moduleName: ModuleName.Production,
            pageName: 'Production Calendar',
            resourceName: "Calendar",
            onClick: () => {
                window.location.href = '/production/calendar'
            },
            order: 3,
        },
        {
            moduleName: ModuleName.Inventory,
            pageName: 'View Orders',
            resourceName: 'View Orders',
            onClick: () => {
                window.location.href = '/inventory/Manage-Orders'
            },
            order: 4,
        },
        {
            moduleName: ModuleName.AccountManagement,
            pageName: 'Accounts',
            resourceName: 'Accounts',
            onClick: () => {
                window.location.href = '/account-management/accounts'
            },
            order: 5,
        },
        {
            moduleName: ModuleName.Accountability,
            pageName: 'Transactions',
            resourceName: 'Transactions',
            onClick: () => {
                window.location.href = '/accountability/Transactions/'
            },
            order: 6,
        },
        {
            moduleName: ModuleName.Insights,
            pageName: 'Command Center - Front Office',
            resourceName: "Command Center FO",
            onClick: () => {
                window.location.href = '/insights/command-center-fo/'
            },
            order: 7,
        },
        {
            moduleName: ModuleName.Insights,
            pageName: 'Command Center - Back Office',
            resourceName: "Command Center BO",
            onClick: () => {
                window.location.href = '/insights/command-center-bo'
            },
            order: 8,
        },
        {
            moduleName: ModuleName.Accountability,
            pageName: 'Accountability Dashboard',
            resourceName: "Accountability Dashboard",
            onClick: () => {
                window.location.href = '/accountability/Accountability-Dashboard/'
            },
            order: 9,
        },
        {
            moduleName: ModuleName.AccountManagement,
            pageName: 'Account Management Dashboard',
            resourceName: "Dashboard",
            onClick: () => {
                window.location.href = '/account-management/Dashboard'
            },
            order: 10,
        },
        {
            moduleName: ModuleName.Workspace,
            pageName: 'User Profile',
            resourceName: "Profile",
            onClick: () => {
                window.location.href = '/workspace/profile'
            },
            order: 11,
        },
        {
            moduleName: ModuleName.Workspace,
            pageName: 'Tasks',
            resourceName: "Tasks",
            onClick: () => {
                window.location.href = '/workspace/tasks'
            },
            order: 12,
        },
        {
            moduleName: ModuleName.Workspace,
            pageName: 'Workspace Dashboard',
            resourceName: "Workspace Dashboard",
            onClick: () => {
                window.location.href = '/'
            },
            order: 13,
        },
    ]

export const favMasterList: Array<{
    moduleName: ModuleName
    pageName: string
    resourceName: string
    onClick: () => void
    order: number
}> = [
        {
            moduleName: ModuleName.Accountability,
            pageName: 'End of Day',
            resourceName: 'End of Day',
            onClick: () => {
                window.location.href = '/accountability/End-of-Day/'
            },
            order: 1,
        },
        {
            moduleName: ModuleName.Production,
            pageName: 'Production Calendar',
            resourceName: "Calendar",
            onClick: () => {
                window.location.href = '/production/calendar'
            },
            order: 2,
        },
        {
            moduleName: ModuleName.Insights,
            pageName: 'Command Center - Front Office',
            resourceName: "Command Center FO",
            onClick: () => {
                window.location.href = '/insights/command-center-fo/'
            },
            order: 3,
        },
        {
            moduleName: ModuleName.Insights,
            pageName: 'Command Center - Back Office',
            resourceName: "Command Center BO",
            onClick: () => {
                window.location.href = '/insights/command-center-bo'
            },
            order: 4,
        },
        {
            moduleName: ModuleName.Accountability,
            pageName: 'Reports (Accoutability)',
            resourceName: "Accountability Reports",
            onClick: () => {
                window.location.href = '/accountability/accountability-reports/'
            },
            order: 5,
        },
        {
            moduleName: ModuleName.AccountManagement,
            pageName: 'Notifications (Account Management)',
            resourceName: "Notifications",
            onClick: () => {
                window.location.href = '/account-management/notifications'
            },
            order: 6,
        },
        {
            moduleName: ModuleName.Inventory,
            pageName: 'Physical Inventory',
            resourceName: "Physical Inventory",
            onClick: () => {
                window.location.href = '/inventory/Physical-Inventory'
            },
            order: 7,
        },
        {
            moduleName: ModuleName.MenuPlanning,
            pageName: 'Publish Menus',
            resourceName: "Publish Menus",
            onClick: () => {
                window.location.href = '/menu-planning/publish-menus'
            },
            order: 8,
        },
        {
            moduleName: ModuleName.Accountability,
            pageName: 'Accountability Dashboard',
            resourceName: "Accountability Dashboard",
            onClick: () => {
                window.location.href = '/accountability/Accountability-Dashboard/'
            },
            order: 9,
        },
        {
            moduleName: ModuleName.AccountManagement,
            pageName: 'Account Management Dashboard',
            resourceName: "Dashboard",
            onClick: () => {
                window.location.href = '/account-management/Dashboard'
            },
            order: 10,
        },
        {
            moduleName: ModuleName.Workspace,
            pageName: 'User Profile',
            resourceName: "Profile",
            onClick: () => {
                window.location.href = '/workspace/Profile'
            },
            order: 11,
        },
        {
            moduleName: ModuleName.Workspace,
            pageName: 'Tasks',
            resourceName: "Tasks",
            onClick: () => {
                window.location.href = '/workspace/tasks'
            },
            order: 12,
        },
        {
            moduleName: ModuleName.Workspace,
            pageName: 'Workspace Dashboard',
            resourceName: "Workspace Dashboard",
            onClick: () => {
                window.location.href = '/'
            },
            order: 13,
        },
    ]
