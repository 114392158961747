import { makeStyles } from '@material-ui/core';
import { ITheme } from '@primeroedge/ui-components';

export default makeStyles((theme: ITheme) => {
    return {
        smartViewContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        header: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            gap: 24,
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: 20,
            height: 36
        },
        flexColCentered: {
            display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4, width: '100%'
        },
        cardContainer: {
            width: 'min(600px, 85vw)',
            padding: '20px 40px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        summaryCountsContainer: {
            display: 'flex',
            gap: 10,
            justifyContent: 'space-between',
            width: '95%',
            marginBottom: 8,
            [theme.breakpoints.up("xs")]: {
                flexDirection: 'row'
            },
            [theme.breakpoints.down("xs")]: {
                flexDirection: 'column',
                alignItems: 'center'
            },
        },
        mainContent: { width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 12, marginTop: 12 },
        recommendedFavContainer: {
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 8,
            paddingBottom: 16,
            gap: 16,
            alignItems: 'center'
        },
        itemContainer : {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            alignItems: 'center',
            cursor: 'pointer',
            width: 160,
            minHeight: 80,
            height: 70,
            padding: '8px 0 12px 0',
            '&:hover': {
                backgroundColor: '#F5F5F9'
            }
        },
        items: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30px',
            width: '30px',
            paddingTop: 2,
            borderRadius: 4,
            padding: '0 4px'
        }
    }
})