import { createAction } from "@reduxjs/toolkit";
import { ResponseStatus } from "../../model/entity";

export const setComposeBoXWindowOpenCLose = createAction(
    "HANDLE_COMPOSE_BOX_WINDOW_OPEN_CLOSE",
    (data: any) => ({
        payload: data,
    }),
);

export const fetchMailUserList = createAction(
    "FETCH_MAIL_USER_LIST",
    (data: any) => ({
        payload: data,
    }),
);

export const setUserMail = createAction("GET_MAIL_USER", (data: any) => ({
    payload: data,
}));

export const sendMail = createAction("SEND_MAIL", (data: any) => ({
    payload: data,
}));

export const updateMailList = createAction("UPDATE_MAIL_LIST", (data: any) => ({
    payload: data,
}));

export const sendReply = createAction("SEND_REPLY", (data: any) => ({
    payload: data,
}));

export const fetchMail = createAction("FETCH_MAIL", (data: any) => ({
    payload: data,
}));

export const fetchInboxMessages = createAction("FETCH_INBOX_MESSAGES", (data: any) => ({
    payload: data,
}));

export const fetchSentMessages = createAction("FETCH_SENT_MESSAGES", (data: any) => ({
    payload: data,
}));

export const getMail = createAction("GET_MAIL", (data: any) => ({
    payload: data,
}));

export const deletedMailsSuccess = createAction(
    "DELETE_SELECTED_MAILS",
    (data: any) => ({ payload: data }),
);

export const updateMailsReadSuccess = createAction(
    "UPDATE_READ_SELECTED_MAILS",
    (data: any) => ({ payload: data }),
);

export const clearMail = createAction("CLEAR_MAIL", (data: any) => ({
    payload: data,
}));
export const searchMail = createAction("SEARCH_MAIL", (data: any) => ({
    payload: data,
}));

export const deleteMail = createAction("DELETE_MAIL", (data: any) => ({
    payload: data,
}));

export const readStatusMail = createAction("CHANGE_READ_STATUS_MAIL", (data: any) => ({
    payload: data,
}));

export const selectedMail = createAction("SELECT_MAIL", (data: any) => ({
    payload: data,
}));

export const getSelectedMail = createAction("SELECT_MAIL");

export const readMail = createAction("READ_MAIL", (data: any) => ({
    payload: data,
}));

export const getReadMail = createAction("GET_READ_MAIL", (data: any) => ({
    payload: data,
}));

export const selectedBroadcastMessage = createAction(
    "SELECTED_BROADCAST_MESSAGE",
    (data: any) => ({
        payload: data,
    }),
);

export const updateReadStatus = createAction(
    "UPDATE_READ_STATUS",
    (data: string[]) => ({
        payload: { messageId: data },
    }),
);

export const updateReadStatusSuccess = createAction(
    "UPDATE_READ_STATUS_SUCCESS",
    (data: any) => ({
        payload: data,
    }),
);
export const setMailListServiceCallStatus = createAction(
    "SET_MAIL_LIST_STATUS",
    (status: ResponseStatus) => ({
        payload: status,
    }),
);

export const uploadAttachment = createAction(
    "UPLOAD_ATTACHMENT",
    (status: any) => ({
        payload: status,
    }),
);

export const updateAttachment = createAction(
    "UPDATE_ATTACHMENT",
    (status: any) => ({
        payload: status,
    }),
);

export const updateAttachmentArray = createAction(
    "UPDATE_ATTACHMENT_ARRAY",
    (status: any) => ({
        payload: status,
    }),
);

export const downloadAttachment = createAction(
    "DOWNLOAD_ATTACHMENT",
    (status: any) => ({
        payload: status,
    }),
);

//custom report mail actions
export const sendMailInit = createAction(
    "SEND_MAILI_INIT",
    (params: any, callbacks: {
        successCallback: () => void;
        errorCallback: () => void;
    }) => ({
        payload: {
            params,
            callbacks
        }
    })
)

