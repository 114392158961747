import { createAction } from "@reduxjs/toolkit";

// Get Sites for grid
export const getSiteListInit = createAction(
    "GET_SITE_LIST_INIT",
    (data: any) => ({
        payload: data,
    }),
);

export const getSiteListInitUpdate = createAction(
    "GET_SITE_LIST_INIT_UPDATE",
    (data: any) => ({
        payload: data,
    }),
);

export const getSiteListSuccess = createAction(
    "GET_SITE_LIST_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const getSiteListUpdateSuccess = createAction(
    "GET_SITE_LIST_UPDATE_SUCCESS",
    (siteList) => ({
        payload: {
            siteList,
        },
    }),
);

export const getSiteListFail = createAction("GET_SITE_LIST_FAIL");

// Add a new site
export const addSiteInit = createAction("ADD_SITE_INIT", (siteData) => ({
    payload: {
        siteData,
    },
}));

export const addSiteSuccess = createAction("ADD_SITE_SUCCESS", (response) => ({
    payload: { response },
}));

export const addSiteFail = createAction("ADD_SITE_FAIL", (error) => ({
    payload: {
        error,
    },
}));

// Edit an existing Site
export const editSiteInit = createAction("EDIT_SITE_INIT", (siteData) => ({
    payload: {
        siteData,
    },
}));

export const editSiteSuccess = createAction("EDIT_SITE_SUCCESS", (siteList) => ({
    payload: {
        siteList,
    },
}));

export const editSiteFail = createAction("EDIT_SITE_FAIL");

export const getAreaNames = createAction("GET_AREA_NAMES");

export const getAreaNamesSuccess = createAction(
    "GET_AREA_NAMES_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const getSiteTypesByRegion = createAction(
    "GET_SITE_TYPES_BY_REGION",
    (payload) => ({
        payload,
    }),
);

export const getSiteTypesByRegionSuccess = createAction(
    "GET_SITE_TYPES_BY_REGION_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const getAllStates = createAction("GET_ALL_STATES");

export const getAllStatesSuccess = createAction(
    "GET_ALL_STATES_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const getCharacteristics = createAction("GET_CHARACTERISTICS");

export const getCharacteristicsSuccess = createAction(
    "GET_CHARACTERISTICS_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const getSiteBySiteID = createAction("GET_SITE_BY_SITE_ID", (payload) => ({
    payload,
}));

export const getSiteBySiteIDSuccess = createAction(
    "GET_SITE_BY_SITE_ID_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const getSiteBySiteIDFailure = createAction(
    "GET_SITE_BY_SITE_ID_FAILURE",
    (payload) => ({
        payload,
    }),
);

export const getExpSettingsBySiteID = createAction("GET_EXP_SITE_SETTINGS_BY_SITE_ID", (payload) => ({
    payload,
}));
export const getExpSettingsBySiteIDSuccess = createAction(
    "GET_EXP_SITE_SETTINGS_BY_SITE_ID_SUCCESS",
    (payload) => ({
        payload,
    }),
);
export const getExpSettingsBySiteIDFailure = createAction(
    "GET_EXP_SITE_SETTINGS_BY_SITE_ID_FAILURE",
    (payload) => ({
        payload,
    }),
);
export const clearCurrentSiteSelection = createAction("CLEAR_CURRENT_SITE_SELECTION");

export const updateSiteGeneralInfoRequest = createAction(
    "UPDATE_SITE_GENERAL_INFO_REQUEST",
    (payload) => ({
        payload,
    }),
);

export const updateSiteGeneralInfoSuccess = createAction(
    "UPDATE_SITE_GENERAL_INFO_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const updateSiteGeneralInfoFailure = createAction(
    "UPDATE_SITE_GENERAL_INFO_FAILURE",
    (payload) => ({
        payload,
    }),
);
export const updateSiteCorrAddrRequest = createAction(
    "UPDATE_SITE_CORR_ADDR_REQUEST",
    (payload) => ({
        payload,
    }),
);

export const updateSiteCorrAddrSuccess = createAction(
    "UPDATE_SITE_CORR_ADDR_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const updateSiteCorrAddrFailure = createAction(
    "UPDATE_SITE_CORR_ADDR_FAILURE",
    (payload) => ({
        payload,
    }),
);

export const getMealTypesByRegion = createAction(
    "GET_MEAL_TYPES_BY_REGION",
    (payload) => ({
        payload,
    }),
);

export const getMealTypesByRegionSuccess = createAction(
    "GET_MEAL_TYPES_BY_REGION_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const getMealTypesFail = createAction("GET_MEAL_TYPES_FAIL");

export const getSitesConfigurationTypesByRegion = createAction(
    "GET_SITESCONFIGURATION_TYPES_BY_REGION",
    (payload) => ({
        payload,
    }),
);

export const getSitesConfigurationTypesByRegionSuccess = createAction(
    "GET_SITESCONFIGURATION_TYPES_BY_REGION_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const getSitesConfigurationTypesFail = createAction("GET_SITESCONFIGURATION_TYPES_FAIL");

export const updateSiteMealTypeRequest = createAction(
    "UPDATE_SITE_MEALTYPE_REQUEST",
    (payload) => ({
        payload,
    }),
);
export const updateSiteMealTypeSuccess = createAction(
    "UPDATE_SITE_MEALTYPE_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const updateSiteMealTypeFailure = createAction(
    "UPDATE_SITE_MEALTYPE_FAILURE",
    (payload) => ({
        payload,
    }),
);
export const clearSiteMealTypeActions = createAction("CLEAR_SITE_MEALTYPE_ACTIONS");

export const updateFeedingSiteRequest = createAction(
    "UPDATE_FEEDING_SITE_REQUEST",
    (payload) => ({
        payload,
    }),
);
export const updateFeedingSiteSuccess = createAction(
    "UPDATE_FEEDING_SITE_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const updateFeedingSiteFailure = createAction(
    "UPDATE_FEEDING_SITE_FAILURE",
    (payload) => ({
        payload,
    }),
);
export const getAcademicYears = createAction("GET_ACADEMIC_YEARS");

export const getAcademicYearsSuccess = createAction("GET_ACADEMIC_YEARS_SUCCESS", (payload) => ({
    payload,
}),
);

export const getProgramsByAcademicYear = createAction(
    "GET_PROGRAMS_BY_ACADEMIC_YEAR",
    (payload) => ({
        payload,
    }),
);

export const getProgramsByAcademicYearSuccess = createAction(
    "PROGRAMS_BY_ACADEMIC_YEAR_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const getProgramsByAcademicYearFail = createAction("PROGRAMS_BY_ACADEMIC_YEAR_FAIL");
export const clearProgramsByAcademicYear = createAction("CLEAR_PROGRAMS_BY_ACADEMIC_YEAR");

export const getCurrentAcademicYear = createAction("GET_CURRENT_ACADEMIC_YEAR");

export const getCurrentAcademicYearSuccess = createAction(
    "GET_CURRENT_ACADEMIC_YEAR_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const getCurrentAcademicYearFail = createAction("GET_CURRENT_ACADEMIC_YEAR_FAIL");

export const updateProgramRequest = createAction("UPDATE_PROGRAM_REQUEST",
    (payload) => ({
        payload,
    }),
);

export const updateProgramSuccess = createAction("UPDATE_PROGRAM_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const updateProgramFailure = createAction("UPDATE_PROGRAM_FAILURE",
    (payload) => ({
        payload,
    }),
);


export const getProgramSitesByAcademicYear = createAction(
    "GET_PROGRAM_SITES_BY_ACADEMIC_YEAR",
    (yearId, programId) => ({
        payload: {
            yearId,
            programId,
        },
    }),
);

export const getProgramSitesByAcademicYearSuccess = createAction(
    "PROGRAM_SITES_BY_ACADEMIC_YEAR_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const getProgramSitesByAcademicYearFail = createAction("PROGRAM_SITES_BY_ACADEMIC_YEAR_FAIL");
export const updateProgramSiteRequest = createAction("UPDATE_PROGRAM_SITE_REQUEST",
    (siteData, yearId, programId) => ({
        payload: {
            siteData: { siteData },
            yearId: { yearId },
            programId: { programId },
        },
    }),
);
export const updateProgramSiteSuccess = createAction("UPDATE_PROGRAM_SITE_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const updateProgramSiteFailure = createAction("UPDATE_PROGRAM_SITE_FAILURE",
    (payload) => ({
        payload,
    }),
);

export const clearCopyProgramSaveStatus = createAction("CLEAR_COPY_PROGRAM_SAVE_STATUS");
export const copyProgramRequest = createAction("COPY_PROGRAM_REQUEST",
    (payload) => ({
        payload,
    }),
);

export const copyProgramSuccess = createAction("COPY_PROGRAM_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const copyProgramFailedForProgramsNotConfigured = createAction("COPY_PROGRAM_FAIL_PROGRAMS_NOT_CONFIGURED",
    (payload) => ({
        payload,
    }),
);

export const copyProgramFailure = createAction("COPY_PROGRAM_FAILURE",
    (payload) => ({
        payload,
    }),
);

export const getSiteCharacterstics = createAction("GET_SITE_CHARACTERSTICS",
    (payload) => ({
        payload,
    }),
);

export const getSiteCharactersticsSuccess = createAction("GET_SITE_CHARACTERSTICS_SUCCESS",
    (payload) => ({
        payload,
    }),
);
export const getSiteCharactersticsFailure = createAction("GET_SITE_CHARACTERSTICS_FAILURE",
    (payload) => ({
        payload,
    }),
);

export const storeSiteCharacterstics = createAction("STORE_SITE_CHARACTERSTICS",
    (payload) => ({
        payload,
    }),
);

export const updateSiteCharacterstics = createAction("UPDATE_SITE_CHARACTERSTICS",
    (payload) => ({
        payload,
    }),
);

export const updateSiteCharacteristicsSuccess = createAction(
    "UPDATE_SITE_CHARACTERSTICS_SUCCESS",
    (payload) => ({
        payload,
    }),
);

export const updateSiteCharacteristicsFailure = createAction(
    "UPDATE_SITE_CHARACTERSTICS_FAILURE",
    (payload) => ({
        payload,
    }),
);

export const getParentSitesInit = createAction("GET_PARENT_SITES_BY_SITE_ID", (payload) => ({
    payload,
}));

export const getParentSitesSuccess = createAction("GET_PARENT_SITES_BY_SITE_ID_SUCCESS", (payload) => ({
    payload,
}));
