import { createAction } from "@reduxjs/toolkit";

export const isNotificationLoading = createAction(
    "IS_NOTIFICATION_LOADING",
    (payload: any) => payload,
);

export const getNotificationsAction = createAction(
    "GET_NOTIFICATION",
);
