
const isObject = (val: any) => typeof val === "object" && val;
export const compareObjects = (obj1: any = {}, obj2: any = {}) => {
    const output: any = {};
    const merged = { ...obj1, ...obj2 };

    for (const key in merged) {
        const value1 = obj1[key];
        const value2 = obj2[key];

        (isObject(value1) || isObject(value2)) ?
            output[key] = compareObjects(value1, value2) :
            output[key] = value1 === value2;
    }

    return output;
};
