import { APIInstance } from "@primeroedge/ui-components";
import { API_ADMINISTRATION, APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY } from "./api-constants";

const administrationAPIClient = new APIInstance({
    baseURL: API_ADMINISTRATION,
    environment: APIM_ENVIRONMENT,
    subscriptionKey: APIM_SUBSCRIPTION_KEY,
}).axios;

export default administrationAPIClient;
