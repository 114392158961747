import { IAppState } from "model/reducers";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as SiteActions from "../actions/sites.actions";
import {
    addSiteFail,
    addSiteInit,
    addSiteSuccess,
    editSiteFail,
    editSiteInit,
    editSiteSuccess,
    getAcademicYearsSuccess,
    getAllStates,
    getAllStatesSuccess,
    getAreaNames,
    getAreaNamesSuccess,
    getCharacteristics,
    getCharacteristicsSuccess,
    getCurrentAcademicYearFail,
    getCurrentAcademicYearSuccess,
    getExpSettingsBySiteID,
    getExpSettingsBySiteIDFailure,
    getExpSettingsBySiteIDSuccess,
    getMealTypesByRegion,
    getMealTypesByRegionSuccess,
    getMealTypesFail,
    getProgramsByAcademicYearFail,
    getProgramsByAcademicYearSuccess,
    getSiteBySiteID,
    getSiteBySiteIDFailure,
    getSiteBySiteIDSuccess,
    getSiteListFail,
    getSiteListInit,
    getSiteListInitUpdate,
    getSiteListSuccess,
    getSiteListUpdateSuccess,
    getSitesConfigurationTypesByRegion,
    getSitesConfigurationTypesByRegionSuccess,
    getSitesConfigurationTypesFail,
    getSiteTypesByRegion,
    getSiteTypesByRegionSuccess,
} from "../actions/sites.actions";
import sitesTransport from "../transport/sites.transport";

function* fetchSiteListSaga (payload: any) {
    try {
        const sites = yield sitesTransport.getSiteList(payload);
        yield put(getSiteListSuccess(sites.PayLoad));
    } catch (error) {
        yield put(getSiteListFail());
    }
}

function* fetchSiteListUpdateSaga (payload: any) {
    try {
        const sites = yield sitesTransport.getSiteList(payload);
        yield put(getSiteListUpdateSuccess(sites.PayLoad));
    } catch (error) {
        yield put(getSiteListFail());
    }
}

function* addSiteSaga (data: any) {
    try {
        const response = yield sitesTransport.addSite(data);
        yield put(addSiteSuccess(response));
    } catch (error) {
        yield put(addSiteFail(error));
    }
}

function* editSiteSaga (data: any) {
    const activeDistrict = localStorage.getItem("activeDistrict");
    const user = yield select((state: IAppState) => state.user.userProfile);
    let selectedDistrict: any;
    if (activeDistrict) {
        selectedDistrict = JSON.parse(activeDistrict);
        try {
            const sites = yield sitesTransport.editSite(data);
            yield put({
                type: editSiteSuccess,
                payload: sites.data.data,
            });
            yield put(
                getSiteListInit({
                    userId: user ? Number(user?.UserId) : 0,
                    regionId: selectedDistrict?.attributes?.regionId ? Number(selectedDistrict?.attributes?.regionId[0]) : 0,
                    sortOrder: 1,
                    pageSize: 10,
                    pageNumber: 1,
                }),
            );
        } catch (error) {
            yield put(editSiteFail());
        }
    }
}

function* getAreaNamesSaga () {
    try {
        const sites = yield sitesTransport.getAreaNames();
        yield put(getAreaNamesSuccess(sites));
    } catch (error) {
        yield put(getSiteListFail());
    }
}

function* getSiteTypesByRegionSaga (action: any) {
    try {
        const siteTypes = yield sitesTransport.getSiteTypesByRegion(action.payload);
        yield put(getSiteTypesByRegionSuccess(siteTypes));
    } catch (error) {
        yield put(getSiteListFail());
    }
}

function* getAllStatesSaga () {
    try {
        const states = yield sitesTransport.getAllStates();
        yield put(getAllStatesSuccess(states));
    } catch (error) {
        yield put(getSiteListFail());
    }
}

function* getCharacteristicsSaga () {
    try {
        const characteristics = yield sitesTransport.getCharacteristics();
        yield put(getCharacteristicsSuccess(characteristics));
    } catch (error) {
        yield put(getSiteListFail());
    }
}

function* getSiteBySiteIDSaga (action: any) {
    try {
        const site = yield sitesTransport.getSiteBySiteID(action.payload);
        yield put(getSiteBySiteIDSuccess(site));
    } catch (error) {
        yield put(getSiteBySiteIDFailure(error));
    }
}

function* getExpSettingsBySiteIDSaga (action: any) {
    try {
        const settings = yield sitesTransport.getExpSettingsBySiteID(action.payload);
        yield put(getExpSettingsBySiteIDSuccess(settings));
    } catch (error) {
        yield put(getExpSettingsBySiteIDFailure(error));
    }
}

/*
function* updateSiteGeneralInfoSaga(action: any) {
    try {
        const response = yield sitesTransport.updateSiteGeneralInfo(action.payload);
        yield put(SiteActions.updateSiteGeneralInfoSuccess(response));
    } catch (error) {
        yield put(SiteActions.updateSiteGeneralInfoFailure(error));
    }
}
*/

function* updateSiteGeneralInfoSaga (action: any) {
    try {
        const response = yield sitesTransport.updateSiteGeneralInfo(action.payload);
        // yield put(SiteActions.updateSiteGeneralInfoSuccess(response));

        if (response.Description === "Success!") {
            action.payload.response("success");
            yield put(SiteActions.updateSiteGeneralInfoSuccess(response));
        } else {
            action.payload.response("error");
        }

    } catch (error) {
        yield put(SiteActions.updateSiteGeneralInfoFailure(error));
    }
}


function* updateSiteCorrAddrSaga (action: any) {
    try {
        const response = yield sitesTransport.updateSiteCorrAddr(action.payload);
        yield put(SiteActions.updateSiteCorrAddrSuccess(response));
    } catch (error) {
        yield put(SiteActions.updateSiteCorrAddrFailure(error));
    }
}
function* getMealTypesByRegionSaga (action: any) {
    try {
        const mealTypes = yield sitesTransport.getMealTypesByRegion(
            action.payload,
        );
        yield put(getMealTypesByRegionSuccess(mealTypes));
    } catch (error) {
        yield put(getMealTypesFail());
    }
}

function* getSitesConfigurationTypesByRegionSaga (action: any) {
    try {
        const sitesConfigurationTypes = yield sitesTransport.getSitesConfigurationTypesByRegion(
            action.payload,
        );
        yield put(getSitesConfigurationTypesByRegionSuccess(sitesConfigurationTypes));
    } catch (error) {
        yield put(getSitesConfigurationTypesFail());
    }
}

function* updateSiteMealTypeRequestSaga (action: any) {
    const activeDistrict = localStorage.getItem("activeDistrict");
    let selectedDistrict: any;
    if (activeDistrict) {
        selectedDistrict = JSON.parse(activeDistrict);
    }
    try {
        const response = yield sitesTransport.updateSiteMealTypeRequest(action.payload);
        const payload = {
            regionId: selectedDistrict?.attributes?.regionId ? Number(selectedDistrict?.attributes?.regionId[0]) : 0,
            siteTypeId: action.payload.siteTypeId,
            siteId: action.payload.siteId,
        };
        yield put(getMealTypesByRegion(payload));
        yield put(SiteActions.updateSiteMealTypeSuccess(response));
    } catch (error) {
        yield put(SiteActions.updateSiteMealTypeFailure(error));
    }
}
function* updateFeedingSiteRequestSaga (action: any) {
    const activeDistrict = localStorage.getItem("activeDistrict");
    let selectedDistrict: any;
    if (activeDistrict) {
        selectedDistrict = JSON.parse(activeDistrict);
    }
    try {
        const response = yield sitesTransport.updateFeedingSiteRequest(action.payload);
        yield put(getSitesConfigurationTypesByRegion({ regionId: selectedDistrict?.attributes?.regionId ? Number(selectedDistrict?.attributes?.regionId[0]) : 0 }));
        yield put(SiteActions.updateFeedingSiteSuccess(response));
    } catch (error) {
        yield put(SiteActions.updateFeedingSiteFailure(error));
    }
}

function* getAcademicYearsSaga () {
    try {
        const states = yield sitesTransport.getAcademicYears();
        yield put(getAcademicYearsSuccess(states));
    } catch (error) {
        yield put(getSiteListFail());
    }
}

function* getCurrentAcademicYearSaga () {
    try {
        const states = yield sitesTransport.getCurrentAcademicYear();
        yield put(getCurrentAcademicYearSuccess(states));
    } catch (error) {
        yield put(getCurrentAcademicYearFail());
    }
}

function* getProgramsByAcademicYearSaga (action: any) {
    try {
        const programsList = yield sitesTransport.getProgramsByAcademicYear(
            action.payload,
        );
        yield put(getProgramsByAcademicYearSuccess(programsList));
    } catch (error) {
        yield put(getProgramsByAcademicYearFail());
    }
}
function* updateProgramRequestSaga (action: any) {
    try {
        const response = yield sitesTransport.updateProgramRequest(action.payload);
        if (response.ResponseEnvelope === null && response.Description === "Success!") {
            yield put(SiteActions.getProgramsByAcademicYear(action.payload.academicYearId));
            yield put(SiteActions.updateProgramSuccess(response));
            action.payload.response("success");
        } else {
            action.payload.response("error");
        }
    } catch (error) {
        yield put(SiteActions.updateProgramFailure(error));
        action.payload.response("error");
    }
}
function* getProgramSitesByAcademicYearSaga (action: any) {
    try {
        const programSitesList = yield sitesTransport.getProgramSitesByAcademicYear(
            action.payload.yearId, action.payload.programId,
        );
        const sites = {
            programId: action.payload.programId,
            data: [...programSitesList],
        };
        yield put(SiteActions.getProgramSitesByAcademicYearSuccess(sites));
    } catch (error) {
        yield put(SiteActions.getProgramSitesByAcademicYearFail());
    }
}
function* updateProgramSiteRequestSaga (action: any) {
    try {
        const response = yield sitesTransport.updateProgramSiteRequest(action.payload.siteData, action.payload.yearId, action.payload.programId);
        yield put(SiteActions.getProgramSitesByAcademicYear(action.payload.yearId, action.payload.programId));
        yield put(SiteActions.updateProgramSiteSuccess(response));
    } catch (error) {
        yield put(SiteActions.updateProgramSiteFailure(error));
    }
}

function* copyProgramsRequestSaga (action: any) {
    try {
        const response = yield sitesTransport.copyProgramsRequest(action.payload);
        if (response.ResponseEnvelope?.MessageInfoCollection?.length > 0) {
            yield put(SiteActions.copyProgramFailedForProgramsNotConfigured(response.ResponseEnvelope?.MessageInfoCollection[0].Description));
        } else {
            yield put(SiteActions.copyProgramSuccess(response));
        }
    } catch (error) {
        yield put(SiteActions.copyProgramFailure(error));
    }
}

function* getSiteCharactersticsSaga (action: any) {
    try {
        const response = yield sitesTransport.getSiteCharacterstics(action.payload);
        yield put(SiteActions.getSiteCharactersticsSuccess(response));
    } catch (error) {
        yield put(SiteActions.getSiteCharactersticsFailure(error));
    }
}

function* updateSiteCharactersticsSaga (action: any) {
    try {
        const response = yield sitesTransport.updateSiteCharacterstics(action.payload);
        yield put(SiteActions.updateSiteCharacteristicsSuccess(response));
    } catch (error) {
        yield put(SiteActions.updateSiteCharacteristicsFailure(error));
    }
}

function* getParentSitesInitSaga (action: any) {
    try {
        const response = yield sitesTransport.getParentSites(action.payload);
        yield put(SiteActions.getParentSitesSuccess(response.PayLoad));
    } catch (error) {
        yield put(SiteActions.getParentSitesSuccess([]));
    }
}

// Root saga is where all the sagas are merged
export default function* SiteSaga () {
    try {
        yield takeEvery(getSiteListInit, fetchSiteListSaga);
        yield takeEvery(getSiteListInitUpdate, fetchSiteListUpdateSaga);
        yield takeLatest(addSiteInit, addSiteSaga);
        yield takeLatest(editSiteInit, editSiteSaga);
        yield takeLatest(getAreaNames, getAreaNamesSaga);
        yield takeLatest(getSiteTypesByRegion, getSiteTypesByRegionSaga);
        yield takeLatest(getAllStates, getAllStatesSaga);
        yield takeLatest(getCharacteristics, getCharacteristicsSaga);
        yield takeLatest(getSiteBySiteID, getSiteBySiteIDSaga);
        yield takeLatest(getExpSettingsBySiteID, getExpSettingsBySiteIDSaga);
        yield takeLatest(SiteActions.updateSiteGeneralInfoRequest, updateSiteGeneralInfoSaga);
        yield takeLatest(SiteActions.updateSiteCorrAddrRequest, updateSiteCorrAddrSaga);
        yield takeLatest(getMealTypesByRegion, getMealTypesByRegionSaga);
        yield takeLatest(getSitesConfigurationTypesByRegion, getSitesConfigurationTypesByRegionSaga);
        yield takeLatest(SiteActions.updateSiteMealTypeRequest, updateSiteMealTypeRequestSaga);
        yield takeLatest(SiteActions.updateFeedingSiteRequest, updateFeedingSiteRequestSaga);
        yield takeLatest(SiteActions.getAcademicYears, getAcademicYearsSaga);
        yield takeLatest(SiteActions.getProgramsByAcademicYear, getProgramsByAcademicYearSaga);
        yield takeLatest(SiteActions.updateProgramRequest, updateProgramRequestSaga);
        yield takeEvery(SiteActions.getProgramSitesByAcademicYear, getProgramSitesByAcademicYearSaga);
        yield takeLatest(SiteActions.updateProgramSiteRequest, updateProgramSiteRequestSaga);
        yield takeLatest(SiteActions.copyProgramRequest, copyProgramsRequestSaga);
        yield takeLatest(SiteActions.getSiteCharacterstics, getSiteCharactersticsSaga);
        yield takeLatest(SiteActions.updateSiteCharacterstics, updateSiteCharactersticsSaga);
        yield takeLatest(SiteActions.getCurrentAcademicYear, getCurrentAcademicYearSaga);
        yield takeLatest(SiteActions.getParentSitesInit, getParentSitesInitSaga);
    } catch (error) {
        yield;
    }
}
