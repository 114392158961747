import { MessageType } from "../../model/entity";
import dataExchangeAPIClient from "./clients/data-exchange-api.client";
import userManagementAPIClient from "./clients/user-management-api.client";
import workspaceAPIClient from "./clients/workspace-api.client";

class MailTransportLayer {
  public async fetchMailUser (value: any): Promise<any> {
    const response = await userManagementAPIClient({
      method: "GET",
      url: `/api/Users/SearchUsersByRegionAsync?searchUsersRequest=${value}`,
    });
    const { data }: any = response;
    if (data) {
      return Promise.resolve({
        ...data,
      });
    } else {
      return Promise.reject("Failed to get the data");
    }
  }

  public async sendMail (params: any): Promise<any> {
    const response = await workspaceAPIClient({
      method: "POST",
      url: `/api/compose-message`,
      data: params,
    });
    const { status }: any = response;
    if (status === 204) {
      return Promise.resolve(true);
    } else {
      return Promise.reject("Failed to get the data");
    }
  }

  public async sendReply (params: any): Promise<any> {
    const response = await workspaceAPIClient({
      method: "POST",
      url: `/api/reply-message`,
      data: params,
    });
    const { data }: any = response;
    if (data) {
      return Promise.resolve({
        ...data,
      });
    } else {
      return Promise.reject("Failed to get the data");
    }
  }

  public async getMail (param: any): Promise<any> {
    const response = await workspaceAPIClient({
      method: "GET",
      url: `/api/${param.type === "Sent" ? "sent" : "list"}-messages`,
      params: param,
    });
    const { data }: any = response;
    if (data) {
      return Promise.resolve({
        ...data,
      });
    } else {
      return Promise.reject("Failed to get the data");
    }
  }

  public async getListMessages (param: any): Promise<any> {
    const response = await workspaceAPIClient({
      method: "GET",
      url: `/api/list-messages`,
      params: param,
    });
    const { data }: any = response;
    if (data) {
      return Promise.resolve({
        ...data,
      });
    } else {
      return Promise.reject("Failed to get the data");
    }
  }

  public async getSentMessages (param: any): Promise<any> {
    const response = await workspaceAPIClient({
      method: "GET",
      url: `/api/sent-messages`,
      params: param,
    });
    const { data }: any = response;
    if (data) {
      return Promise.resolve({
        ...data,
      });
    } else {
      return Promise.reject("Failed to get the data");
    }
  }

  public async readMail (param: any): Promise<any> {
    const response = await workspaceAPIClient({
      method: "GET",
      url: `/api/read-message`,
      params: param,
    });
    const { data }: any = response;
    if (data) {
      return Promise.resolve({
        ...data,
      });
    } else {
      return Promise.reject("Failed to get the data");
    }
  }

  public async searchMail (param: any): Promise<any> {
    const response = await workspaceAPIClient({
      method: "GET",
      url: `/api/${param.messageType === MessageType.Sent
          ? "search-sentmessage"
          : "search-message"
        }`,
      params: param,
    });
    const { data }: any = response;
    if (data) {
      return Promise.resolve({
        ...data,
      });
    } else {
      return Promise.reject("Failed to get the data");
    }
  }

  public async deleteMail (param: any): Promise<any> {
    const response = await workspaceAPIClient({
      method: "DELETE",
      url: `/api/${param.type === "Inbox" ? "delete-message" : "delete-sent-message "
        }`,
      data: param,
    });
    const { status }: any = response;
    if (status === 204) {
      return Promise.resolve(true);
    } else {
      return Promise.reject("Failed to get the data");
    }
  }

  public async readStatusMail (param: any): Promise<any> {
    const response = await workspaceAPIClient({
      method: "PATCH",
      url: `/api/update-read-status`,
      data: param,
    });
    const { status }: any = response;
    if (status === 204) {
      return Promise.resolve(true);
    } else {
      return Promise.reject("Failed to get the data");
    }
  }

  public async uploadAttachment (param: any): Promise<any> {
    const bodyformData = new FormData();

    for (const p of param) {
      bodyformData.append("form", p);
    }

    const response = await dataExchangeAPIClient({
      headers: {
        FeatureName: "Workspace",
        folderName: "document",
        Id: "de96b93d-3b23-4388-b20b-038233c6fceb",
        "Content-Type": "multipart/form-data",
      },

      method: "POST",
      url: `/api/FileStorage/Upload`,
      data: bodyformData,
    });
    const { data }: any = response;

    return Promise.resolve({
      ...data,
    });
  }

  public async downloadAttachment (param: any) {
    try {
      const response = await dataExchangeAPIClient({
        method: "GET",
        url: `/api/FileStorage/Download?featureName=${param.containerName}&fileName=${param.blobName}`,
      });
      const { headers, data }: any = response;

      const dwnldlink = URL.createObjectURL(
        new Blob([data], {
          type: headers["content-type"],
        }),
      );
      console.error(dwnldlink);
    } catch (e) {
      return false;
    }
  }

  public async updateReadStatus (ids: string[]) {
    try {
      const response = await workspaceAPIClient({
        method: "PATCH",
        url: `/api/update-read-status`,
        data: { id: ids, readStatus: true },
      });
      const { status }: any = response;
      if (status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
}

export default new MailTransportLayer();
