import { Maybe } from "../lib/maybe";
export interface IUsersActions {
    // fetching users
    usersFetchInit (): any;
    usersFetchSuccess (users: any): any;
    usersFetchFail (): any;
}

export enum UsersStatus {
    Initial = "Initial",
    Loading = "InProgress",
    SuccessWithUsers = "SuccessWithUsers",
    Failed = "Failed",
    FailedWithInvalidPassword = "InvalidPassword",
    FailedWithNetworkError = "NetworkError",
    FailedWithFormatError = "ResponseFormatError",
    FailedWithUnknownError = "UnknownError",
    Success = "Success",
}

export enum UserStatus {
    Initial = "Initial",
    Loading = "InProgress",
    SuccessWithUsers = "SuccessWithUsers",
    Failed = "Failed",
    FailedWithInvalidPassword = "InvalidPassword",
    FailedWithNetworkError = "NetworkError",
    FailedWithFormatError = "ResponseFormatError",
    FailedWithUnknownError = "UnknownError",
    Success = "Success",
}

export interface IUpdateUserStatus {
    success: boolean;
    message: string;
    data: string;
    dateTime: string;
    errors: IErrors[];
}
export interface IErrors {
    field: string;
    description: string;
    code: number;
}
export interface IUser {
    list?: any;
    imageUrl: string | null;
    image: boolean;
    userId: number;
    userName: string;
    firstName: string;
    lastName: string;
    mi?: string;
    email?: any;
    workPhone?: any;
    cellPhone?: any;
    userStatusId: number;
    lastLoginDate: Date;
    themeName: string;
    OrganizationalLevel?: any;
    roles?: string;
    failedLoginAttempts: number;
    creationDate: Date;
    accessLevelId: number;
    windowsUserDomain: string;
    windowsUserName: string;
    regionId: number;
    updateDate: Date;
    updateBy: number;
    authenticationMode: string;
    languagePreference: string;
    password: string;
    lastPasswordChange: Date;
    lastFailedLoginDate: Date;
    createdUserId: number;
    userFetchStatus: UserStatus;
    userEditFail: any;
    UserGuid?: string;
    IsPIIEnabled?: boolean;
}

export interface IUserInitResponse {
    status: "Failed" | "RequestTimeout" | "Success";
    data: Maybe<IUser[]>;
}
