import { Paper } from "@material-ui/core";
import { Notification, PageLayout } from "@primeroedge/ui-components";
import { isEmpty } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSentMessages, selectedMail } from "../../../../control/actions";
import { MailEntity, MessageType } from "../../../../model/entity";
import MailDetails from "../MailView/MailDetails";
import MailList from "../MailView/MailList";

const SentPage: FC = () => {
    const dispatch = useDispatch();
    const mailToViewDetails = useSelector(MailEntity.mailToViewDetails);
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        dispatch(fetchSentMessages({
            pageSize: 10,
            pageNumber: 1,
            sortColumn: "CreatedOn",
            sortType: "DESC",
            type: MessageType.Sent,
        }));

        return () => {
            dispatch(selectedMail({}));
        };
    }, []);

    const notificationCb = (msg: any) => {
        setShowNotification(msg);
    };

    return (
        <>
            <Notification
                style={{
                    position: "relative",
                    top: "-67px",
                }}
                color="success"
                duration={5000}
                message="Deleted successfully!"
                onClose={() => setShowNotification(false)}
                open={showNotification}
                variant="filled"
            />
            <PageLayout
                title="Sent"
                breadCrumbs={[{ title: "Workspace", path: "/" }]}
            >
                <Paper style={{ marginBottom: "100px" }}>
                    {mailToViewDetails && !isEmpty(mailToViewDetails) ? (
                        <MailDetails notificationCb={notificationCb} messageType={MessageType.Sent} />
                    ) : (
                        <MailList notificationCb={notificationCb} messageType={MessageType.Sent} />
                    )}
                </Paper>
            </PageLayout>
        </>
    );
};

export default SentPage;
