import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    Accordion,
    AccordionGroup,
    Button,
    InfoIcon,
    ITheme, Tooltip,
} from "@primeroedge/ui-components";
import { State } from "@progress/kendo-data-query";
import { isEmpty } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import React, { FC, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compareObjects } from "../../../common/commonFunctions";
import { ISiteCorrAddr, ISiteGenInfo } from "../../index";
import {
    addSiteInit,
    getSiteCharacterstics,
    getSiteListInit,
    getSiteTypesByRegion,
    updateSiteCharacterstics,
    updateSiteCorrAddrRequest,
    updateSiteGeneralInfoRequest,
} from "./../../../../../control/actions/sites.actions";
// import { UserEntity } from "./../../../../../model/entity";
import {
    IAreas,
    IGeneralInfo,
    ISite, ISiteExpSettings, ISiteTypes,
    IState,
    SitesDataEntity,
} from "./../../../../../model/entity/sites.entity";
import { IUsdaSiteTypes, SiteTypeEntity } from "./../../../../../model/entity/siteType.entity";
import RequiredLabel from "./../../../required-label";
import SiteCharacteristics from "./siteCharacteristics";

interface IEditAddSiteProps {
  onClose: (props: { gridState: State, addSiteClose: boolean }) => void;
  siteAddEditTitle: string;
  generalInfo: ISiteGenInfo;
  isNewSite?: boolean;
  addOrEdit: string;
  addOrEditSiteSiteId: number;
  correspondenceAddress: ISiteCorrAddr;
  onSuccess: any;
  gridState: State;
  programs: [];
}

// interface IAuditData {
//   entityId: string;
//   entityTypeId: string;
//   field: string;
//   newValue: string;
//   oldValue: string;
//   userId: number;
//   userName: string;
// }

const siteCategoryMsg = "This site cannot be made a satellite or combined campus as it is currently linked to another site.";

const radioStyles = makeStyles((theme: ITheme) => ({
  root: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}));

const radioLabel = makeStyles((theme: ITheme) => ({
  root: {
    fontFamily: "Roboto",
    fontSize: "14px",
  },
  focused: {
    color: theme.palette.grey[600],
  },
}));

// const switchLabel = makeStyles((theme: ITheme) => ({
//   label: {
//     // wordBreak:"break-word",
//     width: "300px",
//   },
// }));

const radioGroupRow = makeStyles({
  root: {
    flexDirection: "row",
  },
});

const fieldSet = makeStyles({
  root: {
    height: "50px",
  },
});
const getProgramList = (pg: any) => {
  if (pg?.length > 0) {
    let str = "";
    pg.forEach((element: any, index: any) => {
      if (index !== 0) {
        str = str + `, ${element.code}(${element.name})`;
      } else {
        str = str + `${element.code}(${element.name})`;
      }
    });
    return str;
  } else {
    return "None";
  }
};
const AddSite: FC<IEditAddSiteProps> = ({
  onClose,
  generalInfo,
  addOrEdit,
  addOrEditSiteSiteId,
  correspondenceAddress,
  onSuccess,
  // siteAddEditTitle,
  gridState,
  programs,
}) => {
  const dispatch = useDispatch();
  const radioButtonStyle = radioStyles();
  // const user = useSelector(UserEntity.getUserProfile);
  const siteCharacteristics = useSelector(SitesDataEntity.getSiteCharacterstics);
  const siteAreaNameData: IAreas[] = useSelector(SitesDataEntity.getAreaNames);
  const siteTypes: any = useSelector(SitesDataEntity.getSiteTypesByRegion);
  const allUSDASiteTypes: IUsdaSiteTypes[] = useSelector(SiteTypeEntity.getAllUSDASiteTypes);
  const allStates: IState[] = useSelector(SitesDataEntity.getAllStates);
  const currentSiteData = useSelector(SitesDataEntity.getCurrentSite);
  const currentSiteExpSettings = useSelector(SitesDataEntity.getCurrentSiteExpSettings);
  // const currentSiteExpSettingsOrig = useSelector(SitesDataEntity.getCurrentSiteExpSettings);
  const parentSitesData = useSelector(SitesDataEntity.getParentSites);
  const [errorSiteName, setErrorSiteName] = useState(false);
  const [errorSiteDescription, setErrorSiteDescription] = useState(false);
  const [errorSiteCode, setErrorSiteCode] = useState(false);
  const [errorSiteAreaName, setErrorSiteAreaName] = useState(false);
  const [errorSiteType, setErrorSiteType] = useState(false);
  const [errorSiteUsdaSiteType, setErrorSiteUsdaSiteType] = useState(false);
  const [addOrUpdateFlag, setAddOrUpdateFlag] = useState<boolean>(false);
  const [getlatestFlag, setGetlatestFlag] = useState<boolean>(false);
  const [sites, setSites] = useState<IAreas[]>([]);
  const [allSiteTypes, setAllSiteTypes] = useState<ISiteTypes[]>([]);
  const [siteTypesCombined, setSiteTypesCombined] = useState<any>("");
  const [states, setStates] = useState<IState[]>([]);
  const [mealServiceButtons, setMealServiceButtons] = useState<ISiteExpSettings[]>([]);
  const [expSiteSettings, setExpSiteSettings] = useState<ISiteExpSettings[]>([]);
  const [allUsdaSiteTypes, setAllUsdaSiteTypes] = useState<IUsdaSiteTypes[]>(
    [],
  );
  const [auditDetails, setAuditDetails] = useState({} as any);
  const [charsAudit, setCharsAudit] = useState<any[]>([]);
  const activeDistrict = localStorage.getItem("activeDistrict");
  const selectedActiveDistrict = activeDistrict && JSON.parse(activeDistrict);
  const [selectedDistrict, setSelectedDistrict] = React.useState<any>({});
  const radioLabelsStyles = radioLabel();
  const fieldSetStyles = fieldSet({});
  const radioGroupStyles = radioGroupRow({});
  const [oldCharacteristicsData, setOldCharacteristicsData] = useState<any[]>([]);
  const [selectedParentSite, setSelectedParentSite] = useState({
    count: 0,
    isActive: true,
    siteCode: "000",
    siteId: 0,
    siteName: "Select Parent Site",
  });
  const [parentSites, setParentSites] = useState([]);
  const [siteStateGeneralInfo, setSiteStateGeneralInfo] = useState<IGeneralInfo>({
    siteId: 0,
    siteName: "",
    siteDescription: "",
    siteCode: "",
    regionId: 0,
    siteTypeId: 0,
    usdaSiteTypeId: 0,
    isActive: false,
    includeForReimbursement: false,
    isFeedingSite: false,
    nslpexportCode: null,
    childSites: [],
    siteCharacteristics: 0,
    parentSiteId: 0,
  });
  const [effectTriggered, setEffectTriggered] = useState<boolean>(false);

  useEffect(() => {
    console.warn(charsAudit, siteTypesCombined);
  }, [charsAudit, siteTypesCombined]);

  useEffect(() => {
    if (selectedActiveDistrict && !effectTriggered) {
      setSelectedDistrict(selectedActiveDistrict);
    }
    setEffectTriggered(true);
  }, [selectedActiveDistrict]);

  useEffect(() => {
    if (parentSitesData?.length) {
      setParentSites(parentSitesData);
      if (currentSite.parentSiteId) {
        const site = parentSitesData.filter((e: any) => e.siteId === currentSite.parentSiteId);
        if (site?.length) {
          setSelectedParentSite(site[0]);
        }
      } else {
        setSelectedParentSite(parentSitesData[0]);
      }
    }
  }, [parentSitesData]);

  useEffect(() => {
    if (siteCharacteristics.length > 0 && oldCharacteristicsData.length === 0) {
      setOldCharacteristicsData(siteCharacteristics);
    }
  }, [siteCharacteristics]);

  useEffect(() => {
    if (generalInfo.siteId) {
      dispatch(getSiteCharacterstics(generalInfo.siteId));
    }
  }, []);

  useEffect(() => {
    if (generalInfo.siteId) {
      setSiteStateGeneralInfo({
        siteId: generalInfo.siteId,
        siteName: generalInfo.siteName,
        siteDescription: generalInfo.siteDescription,
        siteCode: generalInfo.siteCode,
        regionId: generalInfo.regionId,
        siteTypeId: generalInfo.siteTypeId,
        usdaSiteTypeId: generalInfo.usdaSiteTypeId,
        isActive: generalInfo.isActive,
        includeForReimbursement: generalInfo.includeForReimbursement,
        isFeedingSite: generalInfo.isFeedingSite,
        nslpexportCode: generalInfo.nslpexportCode,
        childSites: generalInfo.childSites,
        siteCharacteristics: generalInfo.siteCharacteristics !== null ? generalInfo.siteCharacteristics : 0,
        parentSiteId: generalInfo.parentSiteId,
      });
    }
  }, [generalInfo]);

  const [currentSite, setCurrentSite] = useState<ISite>({
    siteName: "",
    siteDescription: "",
    siteCode: "",
    regionId: 0,
    siteTypeId: 0,
    usdaSiteTypeId: 0,
    isActive: false,
    addressLine1: "",
    addressLine2: "",
    zipCode: "",
    city: "",
    stateId: 0,
    contactPerson: "",
    contactPersonPosition: "",
    contactPhone: "",
    siteId: 0,
    addedBy: 0,
    addedOn: "",
    addressId: null,
    breakFastReimbursementCategoryId: null,
    cacfpfacility: null,
    cacfppricing: null,
    canOrderBrokenUnits: false,
    canPosusePrincipalAccount: false,
    cependDate: null,
    cepstartDate: null,
    characteristic: null,
    characteristicDetail: null,
    checkOtherSiteSecMeal: false,
    commaSeparatedDeliveryLocations: null,
    connectToServer: null,
    deliveryCost: null,
    districtRegionId: 0,
    enableBin: null,
    enableOrdering: false,
    foodPreparationSiteId: null,
    importKey: null,
    includeForReimbursement: false,
    includeForVerification: false,
    isFeedingSite: false,
    menuGroupEffectiveDate: null,
    menuGroupId: null,
    modifiedBy: 0,
    modifiedOn: "",
    nslpexportCode: null,
    numLines: 0,
    orderComments: null,
    orderingGroupId: null,
    parentSiteId: null,
    phoneNumber: null,
    primeroSchoolId: "",
    sendSiteToSchoolCafe: false,
    showOtherSiteMsg: null,
    stateSiteCode: null,
    storageCost: null,
    subscriptionTerminalId: 0,
    synchronizeDate: "",
    timeClockLogInType: 0,
    updateDate: "",
    usdasiteTypeId: 0,
    useCustomMealType: false,
    groupGuid: null,
    childSites: [],
    siteCharacteristics: 0,
  });

  const [
    siteAreaNameDefaultDataState,
    setSiteAreaNameDefaultDataState,
  ] = useState<IAreas>({
    addedBy: null,
    addedOn: null,
    description: null,
    district: null,
    districtRegionId: null,
    isActive: false,
    isIsd: false,
    modifiedBy: null,
    modifiedOn: null,
    name: "",
    organizationLevelId: null,
    parentRegionId: null,
    regionId: 0,
    subscriptionTerminalId: 0,
    synchronizeDate: "",
    updateDate: "",
  });

  const [stateDefaultDataState, setStateDefaultDataState] = useState<IState>({
    description: "",
    stateCd: "",
    stateId: 0,
  });

  const [
    usdaSiteTypeDefaultDataStateVal,
    setUsdaSiteTypeDefaultDataStateVal,
  ] = useState<IUsdaSiteTypes>({
    description: "",
    notes: "",
    order: 0,
    usdaSiteTypeId: 0,
  });

  const [siteTypeDefaultVal, setSiteTypeDefaultVal] = useState<ISiteTypes>({
    description: "",
    grades: [],
    isSupplier: false,
    regionId: 0,
    siteTypeId: 0,
    siteTypeTypeCode: "",
    siteTypeTypeDescription: "",
  });

  const [
    siteStateCorrespondenceAddress,
    setSiteStateCorrespondenceAddress,
  ] = useState({
    addressLine1: correspondenceAddress.addressLine1,
    addressLine2: correspondenceAddress.addressLine2,
    zipCode: correspondenceAddress.zipCode,
    city: correspondenceAddress.city,
    stateId: correspondenceAddress.stateId,
    contactPerson: correspondenceAddress.contactPerson,
    contactPersonPosition: correspondenceAddress.contactPersonPosition,
    contactPhone: correspondenceAddress.contactPhone,
  });

  const [
    prevSiteStateCorrespondenceAddress,
    setPrevSiteStateCorrespondenceAddress,
  ] = useState({
    addressLine1: correspondenceAddress.addressLine1,
    addressLine2: correspondenceAddress.addressLine2,
    zipCode: correspondenceAddress.zipCode,
    city: correspondenceAddress.city,
    stateId: correspondenceAddress.stateId,
    contactPerson: correspondenceAddress.contactPerson,
    contactPersonPosition: correspondenceAddress.contactPersonPosition,
    contactPhone: correspondenceAddress.contactPhone,
  });

  useEffect(() => {
    console.warn(prevSiteStateCorrespondenceAddress);
  }, [prevSiteStateCorrespondenceAddress]);

  const [
    siteAddress,
    setSiteAddress,
  ] = useState({
    addressLine1: correspondenceAddress.addressLine1,
    addressLine2: correspondenceAddress.addressLine2,
    zipCode: correspondenceAddress.zipCode,
    city: correspondenceAddress.city,
    stateId: correspondenceAddress.stateId,
    contactPerson: correspondenceAddress.contactPerson,
    contactPersonPosition: correspondenceAddress.contactPersonPosition,
    contactPhone: correspondenceAddress.contactPhone,
  });

  useEffect(() => {
    console.warn(siteAddress);
  }, [siteAddress]);

  useEffect(() => {
    setCurrentSite({
      ...currentSite,
      regionId: selectedDistrict?.attributes?.regionId
        ? Number(selectedDistrict?.attributes?.regionId[0])
        : 0,
    });
    setSiteTypeDefaultVal({
      ...siteTypeDefaultVal,
      regionId: selectedDistrict?.attributes?.regionId
        ? Number(selectedDistrict?.attributes?.regionId[0])
        : 0,
    });
  }, [selectedDistrict]);

  useEffect(() => {
    setSites(siteAreaNameData);
  }, [siteAreaNameData]);

  useEffect(() => {
    if (addOrEditSiteSiteId !== 0) {
      setCurrentSite(currentSiteData);
    }
    setSiteAddress({
      addressLine1: currentSiteData.addressLine1 ? currentSiteData.addressLine1 : "",
      addressLine2: currentSiteData.addressLine2 ? currentSiteData.addressLine2 : "",
      zipCode: currentSiteData.zipCode ? currentSiteData.zipCode : "",
      city: currentSiteData.city ? currentSiteData.city : "",
      stateId: currentSiteData.stateId ? currentSiteData.stateId : 0,
      contactPerson: currentSiteData.contactPerson ? currentSiteData.contactPerson : "",
      contactPersonPosition: currentSiteData.contactPersonPosition ? currentSiteData.contactPersonPosition : "",
      contactPhone: currentSiteData.contactPhone ? currentSiteData.contactPhone : "",
    });
  }, [currentSiteData]);

  useEffect(() => {
    if (currentSiteExpSettings.length > 0) {
      const buttons = ["ENNEWSTD", "ENPROGADLT", "ENVIS", "ENSTF"];
      const mealButtonSettings = currentSiteExpSettings.filter((item) => buttons.includes(item.code));
      if (mealButtonSettings.length > 0) {
        const buttonSettings = mealButtonSettings.map((setting: ISiteExpSettings) => {
          const obJ: any = {};
          obJ.id = setting.id;
          obJ.code = setting.code;
          obJ.name = setting.name.split("-")[1].trim();
          obJ.value = setting.value;
          return obJ;
        });
        setMealServiceButtons(buttonSettings);
      } else {
        setMealServiceButtons([]);
      }
      setExpSiteSettings(currentSiteExpSettings.filter((item) => !buttons.includes(item.code)));
    }

  }, [currentSiteExpSettings]);

  useEffect(() => {
    if (siteAreaNameDefaultDataState.regionId !== 0) {
      dispatch(getSiteTypesByRegion(siteAreaNameDefaultDataState.regionId));
    }
  }, [siteAreaNameDefaultDataState]);

  useEffect(() => {
    if (
      selectedDistrict?.name !== "" &&
      siteAreaNameData &&
      siteAreaNameData?.length > 0
    ) {
      const selectedArea: any = siteAreaNameData.find(
        (siteArea: IAreas) =>
          siteArea.regionId ===
          Number(
            selectedDistrict?.attributes?.regionId
              ? selectedDistrict?.attributes?.regionId[0]
              : 0,
          ),
      );

      if (selectedArea) {
        setSiteAreaNameDefaultDataState(selectedArea);

        setSiteStateGeneralInfo({
          ...siteStateGeneralInfo,
          ["regionId"]: Number(
            selectedDistrict?.attributes?.regionId
              ? selectedDistrict?.attributes?.regionId[0]
              : 0,
          ),
        });
      }
    }
  }, [selectedDistrict, siteAreaNameData]);

  useEffect(() => {
    setAllSiteTypes(siteTypes.siteTypes);
    setSiteTypesCombined(addOrEdit === "add" ? "" : " - ");
  }, [siteTypes]);

  useEffect(() => {
    setAllSiteTypes(allSiteTypes);
  }, [allSiteTypes]);

  useEffect(() => {
    setStates(allStates);
  }, [allStates]);

  useEffect(() => {
    setAllUsdaSiteTypes(allUSDASiteTypes);
  }, [allUSDASiteTypes]);

  useEffect(() => {
    if (addOrEditSiteSiteId !== 0) {
      setSiteStateGeneralInfo({
        siteId: currentSite.siteId || 0,
        siteName: currentSite.siteName || "",
        siteDescription: currentSite.siteDescription || "",
        siteCode: currentSite.siteCode || "",
        regionId:
          currentSite.regionId || selectedDistrict?.attributes?.regionId
            ? Number(selectedDistrict?.attributes?.regionId[0])
            : 0,
        siteTypeId: currentSite.siteTypeId || 0,
        usdaSiteTypeId: currentSite.usdaSiteTypeId || 0,
        isActive: currentSite.isActive && true,
        includeForReimbursement: currentSite?.includeForReimbursement || false,
        isFeedingSite: currentSite?.isFeedingSite || false,
        nslpexportCode: currentSite?.nslpexportCode || null,
        childSites: currentSite.childSites,
        siteCharacteristics: currentSite.siteCharacteristics !== null ? currentSite.siteCharacteristics : 0,
        parentSiteId: currentSite.parentSiteId,
      });

      setSiteStateCorrespondenceAddress({
        addressLine1: currentSite.addressLine1 || "",
        addressLine2: currentSite.addressLine2 || "",
        zipCode: currentSite.zipCode || "",
        city: currentSite.city || "",
        stateId: currentSite.stateId || 0,
        contactPerson: currentSite.contactPerson || "",
        contactPersonPosition: currentSite.contactPersonPosition || "",
        contactPhone: currentSite.contactPhone || "",
      });

      setPrevSiteStateCorrespondenceAddress({
        addressLine1: currentSite.addressLine1 || "",
        addressLine2: currentSite.addressLine2 || "",
        zipCode: currentSite.zipCode || "",
        city: currentSite.city || "",
        stateId: currentSite.stateId || 0,
        contactPerson: currentSite.contactPerson || "",
        contactPersonPosition: currentSite.contactPersonPosition || "",
        contactPhone: currentSite.contactPhone || "",
      });

      if (currentSite.regionId !== 0) {
        dispatch(getSiteTypesByRegion(currentSite.regionId));
      }

      if (
        addOrEditSiteSiteId !== 0 &&
        addOrEditSiteSiteId === currentSite.siteId
      ) {
        const defaultAreaNameValueDataNew: any = sites?.find(
          (res: IAreas) => res.regionId === currentSite.regionId,
        );

        setSiteAreaNameDefaultDataState(
          defaultAreaNameValueDataNew
            ? defaultAreaNameValueDataNew
            : {
              addedBy: null,
              addedOn: null,
              description: null,
              district: null,
              districtRegionId: null,
              isActive: false,
              isIsd: false,
              modifiedBy: null,
              modifiedOn: null,
              name: "",
              organizationLevelId: null,
              parentRegionId: null,
              regionId: 0,
              subscriptionTerminalId: 0,
              synchronizeDate: "",
              updateDate: "",
            },
        );
      }

      // for all states
      if (
        addOrEditSiteSiteId !== 0 &&
        addOrEditSiteSiteId === currentSite.siteId
      ) {
        const defaultAllStatesValueDataNew: any = states?.find(
          (res: IState) => res.stateId === currentSite.stateId,
        );

        setStateDefaultDataState(
          defaultAllStatesValueDataNew
            ? defaultAllStatesValueDataNew
            : {
              description: "",
              stateCd: "",
              stateId: 0,
            },
        );
      }

      // For allSIteTypes
      if (
        addOrEditSiteSiteId !== 0 &&
        addOrEditSiteSiteId === currentSite.siteId
      ) {
        const defaultAllSiteTypesDataNew: any = allSiteTypes?.
          find((res: ISiteTypes) => res.siteTypeId === currentSite.siteTypeId);

        setSiteTypeDefaultVal(
          defaultAllSiteTypesDataNew
            ? defaultAllSiteTypesDataNew
            : {
              description: "",
              grades: [],
              isSupplier: false,
              regionId: 0,
              siteTypeId: 0,
              siteTypeTypeCode: "",
              siteTypeTypeDescription: "",
            },
        );
      }

      // For allUsdaSiteTypes
      if (
        addOrEditSiteSiteId !== 0 &&
        addOrEditSiteSiteId === currentSite.siteId
      ) {
        const defaultAllUsdaSiteTypesDataNew: any = allUsdaSiteTypes?.find(
          (res: IUsdaSiteTypes) =>
            res.usdaSiteTypeId === currentSite.usdaSiteTypeId,
        );

        setUsdaSiteTypeDefaultDataStateVal(
          defaultAllUsdaSiteTypesDataNew
            ? defaultAllUsdaSiteTypesDataNew
            : {
              description: "",
              notes: "",
              order: 0,
              usdaSiteTypeId: 0,
            },
        );
      }
    }
  }, [currentSite]);

  useEffect(() => {
    if (
      addOrEditSiteSiteId !== 0 &&
      addOrEditSiteSiteId === currentSite.siteId &&
      allSiteTypes
    ) {
      const defaultAllSiteTypesDataNew: any = allSiteTypes.find(
        (res: ISiteTypes) => res.siteTypeId === currentSite.siteTypeId,
      );

      setSiteTypeDefaultVal(
        defaultAllSiteTypesDataNew
          ? defaultAllSiteTypesDataNew
          : {
            description: "",
            grades: [],
            isSupplier: false,
            regionId: 0,
            siteTypeId: 0,
            siteTypeTypeCode: "",
            siteTypeTypeDescription: "",
          },
      );
    }
  }, [allSiteTypes]);

  const cancelHandler = () => {
    onClose({ gridState, addSiteClose: false });
  };


  const formatPhoneNumber = (val: string): string => {
    val = val ? val.toString().replace(/[^\d+]/g, "") : "";
    if (val.length === 10) {
      return val.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    } else if (val.length > 10) {
      return val.slice(0, 10).replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    } else {
      return val;
    }
  };

  const validateForm = () => {
    let errors = 0;

    setErrorSiteName(false);
    setErrorSiteDescription(false);
    setErrorSiteCode(false);
    setErrorSiteAreaName(false);
    setErrorSiteType(false);
    setErrorSiteUsdaSiteType(false);

    if (siteStateGeneralInfo.siteName.length === 0) {
      ++errors;
      setErrorSiteName(true);
    }

    if (siteStateGeneralInfo.siteDescription.length === 0) {
      ++errors;
      setErrorSiteDescription(true);
    }

    if (!siteStateGeneralInfo?.siteCode) {
      ++errors;
      setErrorSiteCode(true);
    }

    if (siteStateGeneralInfo.regionId === 0) {
      ++errors;
      setErrorSiteAreaName(true);
    }

    if (siteStateGeneralInfo.siteTypeId === 0) {
      ++errors;
      setErrorSiteType(true);
    }

    if (siteStateGeneralInfo.usdaSiteTypeId === 0) {
      ++errors;
      setErrorSiteUsdaSiteType(true);
    }

    return !!errors;
  };

  const compareData = (originalData: any, newData: any) => {
    const result: any = {};
    for (const key in originalData) {
      if (newData[key] !== originalData[key]) result[key] = newData[key];
    }

    return result;
  };

  const getOrginalStringValues = (type: string, value: number) => {
    let oldValue;

    switch (type) {
      case "regionId":
        sites.forEach((e) => {
          if (e.regionId === value) {
            oldValue = e.name;
          }
        });
        break;
      case "siteTypeId":
        siteTypes.siteTypes.forEach((e: any) => {
          if (e.siteTypeId === value) {
            oldValue = `${e.siteTypeTypeCode} - ${e.description}`;
          }
        });
        break;
      case "usdaSiteTypeId":
        allUSDASiteTypes.forEach((e: any) => {
          if (e.usdaSiteTypeId === value) {
            oldValue = e.description;
          }
        });
        break;
      case "isActive":
        oldValue = String(value);
        break;
      case "includeForReimbursement":
        oldValue = String(value);
        break;
      case "isFeedingSite":
        oldValue = String(value);
        break;
      case "siteCharacteristics":
        oldValue = siteCharacteristicsIndex(value);
        break;
      case "parentSiteId":
        const site = parentSitesData.filter((e: any) => e.siteId === value);
        if (site?.length) {
          oldValue = site[0].siteName;
        }
        break;
      default:
        oldValue = value;
        break;
    }

    return oldValue;
  };

  // const convertText = (title: string) => {
  //   switch (title) {
  //     case "regionId":
  //       return "Area Name";

  //     case "siteTypeId":
  //       return "Site Type";

  //     case "usdaSiteTypeId":
  //       return "USDA Site Type";

  //     default:
  //       const result = title.replace(/([A-Z])/g, " $1");

  //       return result.charAt(0).toUpperCase() + result.slice(1);
  //   }
  // };

  const siteCharacteristicsIndex = (val: any) => {

    const ind: any = {
      3: "Satellite",
      4: "Combined",
    };

    if (val === null) {
      return "Not Applicable";
    }

    return (ind[val]);
  };

  const getGeneralSiteValues = (cmpData: any) => {
    const updatedData: any = {};

    for (const key in cmpData) {
      switch (key) {
        case "regionId":
          sites.forEach((e) => {
            if (e.regionId === cmpData.regionId) {
              updatedData[key] = e.name;
            }
          });
          break;
        case "siteTypeId":
          siteTypes.siteTypes.forEach((e: any) => {
            if (e.siteTypeId === cmpData.siteTypeId) {
              updatedData[key] = `${e.siteTypeTypeCode} - ${e.description}`;
            }
          });
          break;
        case "usdaSiteTypeId":
          allUSDASiteTypes.forEach((e: any) => {
            if (e.usdaSiteTypeId === cmpData.usdaSiteTypeId) {
              updatedData[key] = e.description;
            }
          });
          break;
        case "siteName":
          updatedData[key] = cmpData[key];
          break;
        case "siteDescription":
          updatedData[key] = cmpData[key];
          break;
        case "siteCode":
          updatedData[key] = cmpData[key];
          break;
        case "isActive":
          updatedData[key] = String(cmpData[key]);
          break;
        case "siteId":
          updatedData[key] = cmpData[key];
          break;
        case "includeForReimbursement":
          updatedData[key] = cmpData[key];
          break;
        case "isFeedingSite":
          updatedData[key] = cmpData[key];
          break;
        case "nslpexportCode":
          updatedData[key] = cmpData[key];
          break;
        case "siteCharacteristics":
          updatedData[key] = siteCharacteristicsIndex(cmpData[key]);
          break;
        case "parentSiteId":
          updatedData[key] = cmpData[key];
          break;
        default:
        // console.info(`No case for ${key}`);
      }
    }

    return updatedData;
  };

  const getAddressValues = (cmpData: any) => {
    const updatedData: any = {};

    for (const key in cmpData) {
      switch (key) {
        case "addressLine1":
          updatedData[key] = cmpData[key];
          break;
        case "addressLine2":
          updatedData[key] = cmpData[key];
          break;
        case "city":
          updatedData[key] = cmpData[key];
          break;
        case "contactPerson":
          updatedData[key] = cmpData[key];
          break;
        case "contactPersonPosition":
          updatedData[key] = cmpData[key];
          break;
        case "stateId":
          states.forEach((e) => {
            if (e.stateCd === cmpData.stateId) {
              updatedData[key] = e.description;
            }
          });
          break;
        case "zipCode":
          updatedData[key] = cmpData[key];
          break;
        default:
        // console.info(`No case for ${key}`);
      }
    }

    return updatedData;
  };

  const setCharsAuditString = (oldValues: any[], newValues: any[], field: string) => {
    const obj: any = {};
    obj.entityId = JSON.stringify(currentSite?.siteId);
    obj.comment = "Site-Characteristics";
    obj.field = field;
    obj.oldValues = oldValues;
    obj.newValues = newValues;

    return [obj];
  };

  const getCharacteristicsValues = (cmpData: any) => {
    const oldValues: any = [];
    const newValues: any = [];

    for (let i = 0; i < cmpData.length; i++) {
      if (cmpData[i].isActive === false) {
        newValues.push(JSON.stringify(
          {
            title: `Characteristics - ${siteCharacteristics[i].siteCharacteristicDescription}`,
            name: siteStateGeneralInfo.siteName,
            value: String(siteCharacteristics[i].isActive),
          }));

        oldValues.push(JSON.stringify(
          {
            title: `Characteristics - ${siteCharacteristics[i].siteCharacteristicDescription}`,
            name: siteStateGeneralInfo.siteName,
            value: String(oldCharacteristicsData[i].isActive),
          }));
      }
      const charData: any[] = Object.values(cmpData[i].siteCharacteristicData);
      if (charData.length > 0) {
        for (let j = 0; j < charData.length; j++) {
          if (cmpData[i].siteCharacteristicData[j].isSelected === false) {
            newValues.push(JSON.stringify(
              {
                title: `Characteristics - ${siteCharacteristics[i].siteCharacteristicDescription} - ${siteCharacteristics[i].siteCharacteristicData[j].siteCharacteristicDataDescription}`,
                name: siteStateGeneralInfo.siteName,
                value: String(siteCharacteristics[i].siteCharacteristicData[j].isSelected),
              }));

            oldValues.push(JSON.stringify(
              {
                title: `Characteristics - ${siteCharacteristics[i].siteCharacteristicDescription} - ${siteCharacteristics[i].siteCharacteristicData[j].siteCharacteristicDataDescription}`,
                name: siteStateGeneralInfo.siteName,
                value: String(oldCharacteristicsData[i].siteCharacteristicData[j].isSelected),
              }));
          }
          const charDataOptions: any[] = Object.values(cmpData[i].siteCharacteristicData[j].siteCharacteristicDataOptions);
          if (charDataOptions.length > 0) {
            for (let k = 0; k < charDataOptions.length; k++) {
              if (cmpData[i].siteCharacteristicData[j].siteCharacteristicDataOptions[k].isOptionSelected === false) {
                newValues.push(JSON.stringify(
                  {
                    title: `Characteristics - ${siteCharacteristics[i].siteCharacteristicDescription} - ${siteCharacteristics[i].siteCharacteristicData[j].siteCharacteristicDataDescription} - ${siteCharacteristics[i].siteCharacteristicData[j].siteCharacteristicDataOptions[k].optionValue}`,
                    name: siteStateGeneralInfo.siteName,
                    value: String(siteCharacteristics[i].siteCharacteristicData[j].siteCharacteristicDataOptions[k].isOptionSelected),
                  }));

                oldValues.push(JSON.stringify(
                  {
                    title: `Characteristics - ${siteCharacteristics[i].siteCharacteristicDescription} - ${siteCharacteristics[i].siteCharacteristicData[j].siteCharacteristicDataDescription} - ${siteCharacteristics[i].siteCharacteristicData[j].siteCharacteristicDataOptions[k].optionValue}`,
                    name: siteStateGeneralInfo.siteName,
                    value: String(oldCharacteristicsData[i].siteCharacteristicData[j].siteCharacteristicDataOptions[k].isOptionSelected),
                  }));
              }
            }
          }
        }
      }
    }

    const auditHistory = setCharsAuditString(oldValues, newValues, "Site Charateristics");
    setCharsAudit(auditHistory);
  };

  const getConfiguration = () => {
    const siteSettings = mealServiceButtons.concat(expSiteSettings);
    return siteSettings.map((setting: ISiteExpSettings) => {
      const obJ: any = {};
      obJ.configId = setting.id;
      obJ.siteId = generalInfo.siteId;
      obJ.value = setting.code === "FEEDING" ? false : setting.value;
      obJ.configCode = setting.code;
      return obJ;
    });
  };

  // const sitesAudit = (modified: any, original: any, sitesId: any) => {

  //   const oldValues: any = [];
  //   const newValues: any = [];
  //   const notChangedValues: any = [];


  //   if (original.length > 0) {
  //     const notCommon = original.filter((x: any) => {

  //       const common = modified.filter((a: any) => {
  //         if (x.code === a.configCode) {
  //           if (x.value !== a.value) {

  //             oldValues.push(JSON.stringify({ sitesId, name: x.name, value: (x.value).toString() }));
  //             newValues.push(JSON.stringify({ sitesId, name: x.name, value: (a.value).toString() }));

  //           }
  //         }
  //       });

  //     });

  //   }

  //   return { oldValues, newValues };
  // };


  const onSave = (e: FormEvent) => {
    e.preventDefault();
    if (validateForm()) return;

    setAddOrUpdateFlag(true);
    const reqObj = {
      ...siteStateGeneralInfo,
      ...siteStateCorrespondenceAddress,
      groupGuid: addOrEdit === "add" ?  currentSiteData.groupGuid : currentSiteData.groupGuid,
      configuration: getConfiguration(),
    };

    reqObj.siteCharacteristics = reqObj.siteCharacteristics === 0 ? null : reqObj.siteCharacteristics;
    reqObj.parentSiteId = selectedParentSite.siteId;
    reqObj.regionId = selectedDistrict?.attributes?.regionId
      ? Number(selectedDistrict?.attributes?.regionId[0])
      : 0;

    // const siteHistoryEntityId = currentSite?.siteId;
    // const sites_history: any = sitesAudit(reqObj?.configuration, currentSiteExpSettingsOrig, currentSite?.siteId);

    if (addOrEdit === "add") {
      dispatch(addSiteInit(reqObj));
    } else {
      dispatch(updateSiteGeneralInfoRequest({
        generalInfo: reqObj,
        groupGuid: currentSiteData.groupGuid,
        response: (status: any) => {
          if (status === "success") {
          }
        },
      }));

      dispatch(updateSiteCorrAddrRequest(reqObj));

      if (siteCharacteristics.length > 0) {
        const characteristics = cloneDeep(siteCharacteristics);
        characteristics.forEach((e: any) => {
          e.siteCharacteristicData = JSON.stringify(e.siteCharacteristicData);
        });
        dispatch(updateSiteCharacterstics({ siteCharacteristics: characteristics }));
      }
      setGetlatestFlag(true);

      const currentSitesData = generalInfo;
      const CurrentAddressData = siteAddress;

      const cmpData = compareData(currentSiteData, reqObj);
      const cmpAddressData = compareData(CurrentAddressData, reqObj);

      if (!isEmpty(cmpData) || !isEmpty(cmpAddressData)) {
        const updatedValues = getGeneralSiteValues(cmpData);
        const updatedAddressValues = getAddressValues(cmpAddressData);
        const oldValues: any = [];
        const newValues: any = [];

        for (const key in updatedValues) {
          // const obj: any = {};

          let auditNewValue = updatedValues[key];

          if (key === "siteCharacteristics") {
            auditNewValue = siteCharacteristicsIndex(updatedValues[key]);
          } else if (key === "parentSiteId") {
            const site = parentSitesData.filter((event: any) => event.siteId === updatedValues[key]);
            if (site?.length) {
              auditNewValue = site[0].siteName;
            }
          }

          newValues.push(JSON.stringify(
            {
              title: `General Information - ${key}`,
              name: siteStateGeneralInfo.siteName,
              value: String(auditNewValue),
            }));
          const mergObj: any = { ...currentSitesData, ...prevSiteStateCorrespondenceAddress };
          oldValues.push(
            JSON.stringify(
              {
                title: `General Information - ${key}`,
                name: siteStateGeneralInfo.siteName,
                value: getOrginalStringValues(key, mergObj[key]),
              }));
        }

        for (const key in updatedAddressValues) {
          newValues.push(JSON.stringify(
            {
              title: `Correspondence Address - ${key}`,
              name: siteStateGeneralInfo.siteName,
              value: updatedAddressValues[key],
            }));
          const mergObj: any = { ...currentSitesData, ...prevSiteStateCorrespondenceAddress };
          oldValues.push(
            JSON.stringify(
              {
                title: `Correspondence Address - ${key}`,
                name: siteStateGeneralInfo.siteName,
                value: getOrginalStringValues(key, mergObj[key]),
              }));
        }
        setAuditDetails({ oldValues, newValues });
      }
      const cmpCharacteristicsData = compareObjects(oldCharacteristicsData, siteCharacteristics);
      if (!isEmpty(cmpCharacteristicsData)) {
        getCharacteristicsValues(Object.values(cmpCharacteristicsData));
      }
    }
  };

  const updateSiteGeneralInfoResp = useSelector(SitesDataEntity.getUpdateSiteGeneralInfoResp);
  const updateSiteGeneralInfoErrors = useSelector(SitesDataEntity.getUpdateSiteGeneralInfoErrors);
  const updateSiteCorrAddrResp = useSelector(SitesDataEntity.getUpdateSiteCorrAddrResp);
  const updateSiteCharacteristicsResp = useSelector(SitesDataEntity.getUpdateSiteCharacteristicsResp);

  useEffect(() => {
    if (addOrUpdateFlag && addOrEdit === "edit") {
      if (!updateSiteGeneralInfoResp && !updateSiteCorrAddrResp) {

        return;
      } else {

        if (updateSiteGeneralInfoResp?.Description === "Success!" && updateSiteGeneralInfoResp?.ResponseEnvelope?.ValidationCollection?.length > 0) {
          setAuditDetails([]);
          let errorMsgs = "";

          if (updateSiteGeneralInfoErrors?.length > 1) {
            updateSiteGeneralInfoErrors.forEach((message: any) => {
              errorMsgs += `${message.Description}, `;
            });
          } else if (updateSiteGeneralInfoErrors?.length === 1) {
            errorMsgs = updateSiteGeneralInfoErrors[0]?.Description;
          }
          const msg = "ERROR";
          onSuccess(msg, errorMsgs);

        } else if (updateSiteGeneralInfoResp?.Description === "Success!" && updateSiteGeneralInfoResp?.ResponseEnvelope?.ValidationCollection?.length === 0) {

          if (
            updateSiteGeneralInfoResp?.Description === "Success!" &&
            updateSiteCorrAddrResp?.Description === "Success!"
          ) {
            const historyObj: any = {};
            historyObj.entityId = JSON.stringify(currentSite?.siteId);
            historyObj.oldValues = auditDetails.oldValues;
            historyObj.newValues = auditDetails.newValues;

            // dispatch(AddAuditGroupInit({
            //   entityTypeId: "Site-Configuration",
            //   entityId: JSON.stringify(currentSite?.siteId),
            //   auditPayload: [historyObj],
            // }));

            if (getlatestFlag) {
              const sort = gridState?.sort && gridState?.sort?.length > 0 && gridState?.sort[0]?.dir === "asc" ? 0 : 1;
              const pageNum = gridState && gridState?.skip && gridState?.take ? gridState?.skip + gridState?.take : 10;
              const page = pageNum / 10;
              dispatch(
                getSiteListInit({ sortOrder: sort, pageSize: 10, pageNumber: page, sortColumn: "siteCode" }),
              );
              setGetlatestFlag(false);
            }
          }

          setAddOrUpdateFlag(false);
          onClose({ gridState, addSiteClose: false });
          const msg = "SAVE";
          onSuccess(msg);
        }
      }
    }
  }, [updateSiteGeneralInfoResp, updateSiteCorrAddrResp]);

  useEffect(() => {
    if (addOrUpdateFlag && addOrEdit === "edit" && !updateSiteCharacteristicsResp) {

      return;
    } else {
      if (updateSiteCharacteristicsResp?.Description === "Success!") {
        // dispatch(AddAuditGroupInit({
        //   entityTypeId: "Site-Characteristics",
        //   entityId: JSON.stringify(currentSite?.siteId),
        //   auditPayload: charsAudit,
        // }));
      }
    }
  }, [updateSiteCharacteristicsResp]);

  const addSiteResp = useSelector(SitesDataEntity.getAddSiteResp);

  const getAddSiteErrorsVal = useSelector(SitesDataEntity.getAddSiteErrors);

  useEffect(() => {
    if (addOrUpdateFlag && addOrEdit === "add") {
      if (!getAddSiteErrorsVal && !addSiteResp) {
        return;
      } else {
        if (getAddSiteErrorsVal || addSiteResp) {
          if (
            getAddSiteErrorsVal?.length === 0 ||
            addSiteResp?.ResponseEnvelope?.ValidationCollection.length === 0
          ) {
            setAddOrUpdateFlag(false);
            onClose({ gridState, addSiteClose: false });
            const msg = "SAVE";
            onSuccess(msg);

            const sort = gridState?.sort && gridState?.sort?.length > 0 && gridState?.sort[0]?.dir === "asc" ? 0 : 1;
            const pageNum = gridState && gridState?.skip && gridState?.take ? gridState?.skip + gridState?.take : 10;
            const page = pageNum / 10;
            dispatch(
              getSiteListInit({ sortOrder: sort, pageSize: 10, pageNumber: page, sortColumn: "siteCode" }),
            );
          } else if (getAddSiteErrorsVal?.length > 0) {
            let errorMsgs = "";

            if (getAddSiteErrorsVal?.length > 1) {
              getAddSiteErrorsVal.forEach((message: any) => {
                errorMsgs += `${message.Description}, `;
              });
            } else {
              getAddSiteErrorsVal.forEach((message: any) => {
                errorMsgs += `${message.Description} `;
              });
            }

            const msg = "ERROR";
            onSuccess(msg, errorMsgs);
          }
        }
      }
    }
  }, [getAddSiteErrorsVal, addSiteResp]);

  const onFieldChangeSiteName = (
    name: string,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSiteStateGeneralInfo({
      ...siteStateGeneralInfo,
      [name]: event.target.value,
    });

    if (name === "siteName") {
      setErrorSiteName(event.target.value.length === 0);
    }
  };

  const onFieldChangeSiteDesc = (
    name: string,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSiteStateGeneralInfo({
      ...siteStateGeneralInfo,
      [name]: event.target.value,
    });

    if (name === "siteDescription") {
      setErrorSiteDescription(event.target.value.length === 0);
    }
  };

  const onFieldChangeSiteCode = (
    name: string,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (event.target.value.length <= 20) {
      setSiteStateGeneralInfo({
        ...siteStateGeneralInfo,
        [name]: event.target.value,
      });
    }

    if (name === "siteCode") {
      setErrorSiteCode(event.target.value.length === 0);
    }
  };

  const onFieldChangeCorrespondence = (
    name: string,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSiteStateCorrespondenceAddress({
      ...siteStateCorrespondenceAddress,
      [name]: event.target.value,
    });
  };

  const onSelectAreaName = (newValue: IAreas) => {
    if (newValue) {
      setSiteAreaNameDefaultDataState(newValue);
      setSiteStateGeneralInfo({
        ...siteStateGeneralInfo,
        ["regionId"]: newValue.regionId,
        ["siteTypeId"]: 0,
      });

      setErrorSiteAreaName(newValue.regionId === 0);

      dispatch(getSiteTypesByRegion(newValue.regionId));
    } else if (newValue === null) {
      setErrorSiteAreaName(true);

      setSiteStateGeneralInfo({
        ...siteStateGeneralInfo,
        ["regionId"]: 0,
      });

      setSiteAreaNameDefaultDataState({
        ...siteAreaNameDefaultDataState,
        addedBy: null,
        addedOn: null,
        description: null,
        district: null,
        districtRegionId: null,
        isActive: false,
        isIsd: false,
        modifiedBy: null,
        modifiedOn: null,
        name: "",
        organizationLevelId: null,
        parentRegionId: null,
        subscriptionTerminalId: 0,
        synchronizeDate: "",
        updateDate: "",
      });
    }
  };

  const onSelectSiteType = (newValue: ISiteTypes) => {
    if (newValue) {
      setSiteTypesCombined(" - ");
      setSiteTypeDefaultVal(newValue);
      setSiteStateGeneralInfo({
        ...siteStateGeneralInfo,
        ["siteTypeId"]: newValue.siteTypeId,
      });

      setErrorSiteType(newValue.siteTypeId === 0);
    } else if (newValue === null) {
      setErrorSiteType(true);
      setSiteTypesCombined("");

      setSiteStateGeneralInfo({
        ...siteStateGeneralInfo,
        ["siteTypeId"]: 0,
      });

      setSiteTypeDefaultVal({
        ...siteTypeDefaultVal,
        description: "",
        grades: [],
        isSupplier: false,
        siteTypeId: 0,
        siteTypeTypeCode: "",
        siteTypeTypeDescription: "",
      });
    }
  };

  const onSelectUsdaSiteType = (newValue: IUsdaSiteTypes) => {
    if (newValue) {
      setUsdaSiteTypeDefaultDataStateVal(newValue);

      setSiteStateGeneralInfo({
        ...siteStateGeneralInfo,
        ["usdaSiteTypeId"]: newValue.usdaSiteTypeId,
      });

      setErrorSiteUsdaSiteType(newValue.usdaSiteTypeId === 0);
    } else if (newValue === null) {
      setErrorSiteUsdaSiteType(true);
      setSiteStateGeneralInfo({
        ...siteStateGeneralInfo,
        ["usdaSiteTypeId"]: 0,
      });

      setUsdaSiteTypeDefaultDataStateVal({
        description: "",
        notes: "",
        order: 0,
        usdaSiteTypeId: 0,
      });
    }
  };

  const onSelectRadio = (event: any) => {
    if (event.target.name === "isActive") {
      setSiteStateGeneralInfo({
        ...siteStateGeneralInfo,
        [event.target.name]: event.target.value === "true" ? true : false,
      });
    }

    if (event.target.name === "siteCharacteristics") {
      setCurrentSite({
        ...currentSite,
        siteCharacteristics: parseInt(event.target.value),
      });
      setSiteStateGeneralInfo({
        ...siteStateGeneralInfo,
        siteCharacteristics: parseInt(event.target.value),
      });
    }
  };

  //   const onIncludeForReimbursementChange = (event: any) => {
  //     setSiteStateGeneralInfo({
  //       ...siteStateGeneralInfo,
  //       includeForReimbursement: event?.target?.checked,
  //     });
  //   };

  const onIsFeedingSiteChange = (event: any) => {
    setSiteStateGeneralInfo({
      ...siteStateGeneralInfo,
      isFeedingSite: event?.target?.checked,
    });
  };

  const updateFieldChanged = (event: any) => {
    const name = event?.target?.name;
    const index = Number(name.split("-")[1]);
    const newArray = expSiteSettings.map((a) => ({ ...a }));

    if (newArray[index]?.code === "FEEDING") {
      newArray[index].value = false; // As required by API to send always false
    } else {
      newArray[index].value = event?.target?.checked;
    }
    setExpSiteSettings(newArray);
  };


  const onSelectCorrespondenceState = (newValue: IState) => {
    if (newValue) {
      setStateDefaultDataState(newValue);

      setSiteStateCorrespondenceAddress({
        ...siteStateCorrespondenceAddress,
        ["stateId"]: newValue.stateId,
      });

      setErrorSiteUsdaSiteType(newValue !== null);
    }
  };

  return (
    <>
      <AccordionGroup>
        <Accordion title="General Information" expanded={true}>
          <Grid
            container={true}
            spacing={3}
            style={{ padding: "6px" }}
          >
            <Grid item={true} md={4} xs={6}>
              <TextField
                error={errorSiteName}
                helperText={errorSiteName ? "Site Name is Required" : ""}
                id="siteName"
                name="siteName"
                label={<RequiredLabel inputLabel="Site Name" />}
                value={siteStateGeneralInfo.siteName}
                variant="outlined"
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
                onChange={(event) =>
                  onFieldChangeSiteName("siteName", event)
                }
              />
            </Grid>
            <Grid item={true} md={4} xs={6}>
              <TextField
                error={errorSiteDescription}
                helperText={
                  errorSiteDescription ? "Site Description is Required" : ""
                }
                id="siteDescription"
                label={<RequiredLabel inputLabel="Site Description" />}
                value={siteStateGeneralInfo.siteDescription || ""}
                variant="outlined"
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
                onChange={(event) =>
                  onFieldChangeSiteDesc("siteDescription", event)
                }
              />
            </Grid>
            <Grid item={true} md={4} xs={6}>

              <TextField
                error={errorSiteCode}
                helperText={errorSiteCode ? "Site Code is Required" : ""}
                id="siteCode"
                label={<RequiredLabel inputLabel="Site Code" />}
                value={siteStateGeneralInfo.siteCode}
                variant="outlined"
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
                onChange={(event) =>
                  onFieldChangeSiteCode("siteCode", event)
                }
              />
            </Grid>
            <Grid item={true} md={4} xs={6}>
              <Autocomplete
                id="areaName"
                options={sites}
                getOptionLabel={(option: IAreas) => option.name}
                getOptionSelected={(option, value) => option.regionId === value.regionId}
                value={siteAreaNameDefaultDataState}
                disabled={true}
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                  display: "inline-flex",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<RequiredLabel inputLabel="Area Name" />}
                    variant="outlined"
                    error={errorSiteAreaName}
                    helperText={
                      errorSiteAreaName ? "Area Name is Required" : ""
                    }
                    size="medium"
                  />
                )}
                onChange={(newValue: any) => {
                  onSelectAreaName(newValue);
                }}
              />
            </Grid>
            <Grid item={true} md={4} xs={6}>
              <Autocomplete
                id="siteType"
                options={allSiteTypes}
                getOptionLabel={(option: ISiteTypes) => `${option.description}`}
                getOptionSelected={(option, value) => option?.siteTypeId === value.siteTypeId}
                value={siteTypeDefaultVal}
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                  display: "inline-flex",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<RequiredLabel inputLabel="Site Type" />}
                    variant="outlined"
                    error={errorSiteType}
                    helperText={
                      errorSiteType ? "Site Type is Required" : ""
                    }
                    size="medium"
                  />
                )}
                onChange={(newValue: any) => {
                  onSelectSiteType(newValue);
                }}
              />
            </Grid>
            <Grid item={true} md={4} xs={6}>
              <Autocomplete
                id="usdaSiteTypeId"
                options={allUsdaSiteTypes}
                getOptionLabel={(option: IUsdaSiteTypes) =>
                  option.description
                }
                getOptionSelected={(option, value) => option?.usdaSiteTypeId === value?.usdaSiteTypeId}
                value={usdaSiteTypeDefaultDataStateVal}
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                  display: "inline-flex",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<RequiredLabel inputLabel="USDA Site Type" />}
                    variant="outlined"
                    error={errorSiteUsdaSiteType}
                    helperText={
                      errorSiteUsdaSiteType
                        ? "USDA Site Type is Required"
                        : ""
                    }
                    size="medium"
                  />
                )}
                onChange={(newValue: any) => {
                  onSelectUsdaSiteType(newValue);
                }}
              />
            </Grid>
            <Grid item={true} md={4} xs={6}>
              <FormControl component="fieldset" classes={fieldSetStyles}>
                <FormLabel component="legend" classes={radioLabelsStyles}>
                  Site Status
                </FormLabel>
                <RadioGroup
                  aria-label="type"
                  name="isActive"
                  value={siteStateGeneralInfo.isActive}
                  onChange={onSelectRadio}
                  classes={radioGroupStyles}
                >
                  <FormControlLabel
                    value={true}
                    control={(
                      <Radio
                        classes={{
                          root: radioButtonStyle.root,
                          checked: radioButtonStyle.checked,
                        }}
                      />
                    )}
                    label="Active"
                    id="siteStatusActive"
                  />
                  <FormControlLabel
                    value={false}
                    control={(
                      <Radio
                        classes={{
                          root: radioButtonStyle.root,
                          checked: radioButtonStyle.checked,
                        }}
                      />
                    )}
                    label="Inactive"
                    id="siteStatusInActive"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {/* <Grid item={true} md={4} xs={6}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={siteStateGeneralInfo?.includeForReimbursement}
                    onChange={onIncludeForReimbursementChange}
                    color="primary"
                    name="checkedB"
                    id="siteReimbursementToggle"
                    inputProps={{ "aria-label": "Is Site included for reimbursement" }}
                  />
                )}
                label="Include For Reimbursement"
                labelPlacement="start"
              />
            </Grid> */}
            <Grid item={true} md={4} xs={6}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={siteStateGeneralInfo?.isFeedingSite}
                    onChange={onIsFeedingSiteChange}
                    color="primary"
                    name="checkedB"
                    id="IsFeedingSiteToggle"
                    inputProps={{ "aria-label": "Is Feeding Site" }}
                  />
                )}
                label="Feeding Site"
                labelPlacement="start"
              />
            </Grid>

            <Grid item={true} md={4} xs={6}>
              <span>
                <Typography variant="h6">
                  Reimbursement Programs
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                >
                  {`${getProgramList(programs)}`}
                </Typography>
              </span>

            </Grid>

            <Grid item={true} md={4} xs={6}>
              <FormControl component="fieldset" classes={fieldSetStyles}>
                <FormLabel component="legend" classes={radioLabelsStyles}>
                  Site Category
                </FormLabel>
                <RadioGroup
                  aria-label="type"
                  name="siteCharacteristics"
                  value={siteStateGeneralInfo.siteCharacteristics}
                  onChange={onSelectRadio}
                  classes={radioGroupStyles}
                >
                  <FormControlLabel
                    value={0}
                    control={(
                      <Radio
                        classes={{
                          root: radioButtonStyle.root,
                          checked: radioButtonStyle.checked,
                        }}
                      />
                    )}
                    label="Not Applicable"
                    id="notapplicable"
                  />
                  {currentSite.childSites?.length ? (
                    <>
                      <Tooltip title={siteCategoryMsg}>
                        <FormControlLabel
                          value={3}
                          disabled={true}
                          control={(
                            <Radio
                              classes={{
                                root: radioButtonStyle.root,
                                checked: radioButtonStyle.checked,
                              }}
                            />
                          )}
                          label="Satellite"
                          id="satellite"
                        />
                      </Tooltip>

                      <Tooltip title={siteCategoryMsg}>
                        <FormControlLabel
                          value={4}
                          disabled={true}
                          control={(
                            <Radio
                              classes={{
                                root: radioButtonStyle.root,
                                checked: radioButtonStyle.checked,
                              }}
                            />)}
                          label="Combined"
                          id="Combined"
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <FormControlLabel
                        value={3}
                        control={(
                          <Radio
                            classes={{
                              root: radioButtonStyle.root,
                              checked: radioButtonStyle.checked,
                            }}
                          />
                        )}
                        label="Satellite"
                        id="satellite"
                      />
                      <FormControlLabel
                        value={4}
                        control={(
                          <Radio
                            classes={{
                              root: radioButtonStyle.root,
                              checked: radioButtonStyle.checked,
                            }}
                          />)}
                        label="Combined"
                        id="Combined"
                      />
                    </>
                  )}

                  <FormControlLabel
                    value={"icon"}
                    control={(
                      <Tooltip title={"Combined sites serve meals together. All reports and claims counts are separate by site and are not combined."}>
                        <InfoIcon />
                      </Tooltip>
                    )}
                    label=""
                    id="error_icon"
                  />
                </RadioGroup>
              </FormControl>
              {(siteStateGeneralInfo.siteCharacteristics) ? (
                <Autocomplete
                  id="parentSites"
                  options={parentSites}
                  getOptionLabel={(option: any) =>
                    `${option.siteId} - ${option.siteName}`
                  }
                  getOptionSelected={(option, value) => option?.siteId === value?.siteId}
                  value={selectedParentSite}
                  style={{
                    marginRight: "10px",
                    marginTop: "10px",
                    width: "100%",
                    display: "inline-flex",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<RequiredLabel inputLabel="Parent Site" />}
                      variant="outlined"
                      error={!selectedParentSite?.isActive}
                      helperText={
                        !selectedParentSite?.isActive ? "The selected parent is inactive. Please select another site." : ""
                      }
                    />
                  )}
                  onChange={(newValue: any) => {
                    setSelectedParentSite(newValue);
                    setCurrentSite({
                      ...currentSite,
                      parentSiteId: newValue.siteId,
                    });
                  }}
                />
              ) : (<></>)}
            </Grid>

            <Grid item={true} md={4} xs={4}>
              <span>
                <Typography variant="h6">
                  Reimbursement Claim Export Code
                </Typography>
              </span>
              <TextField
                id="nslpexportCode"
                name="nslpexportCode"
                value={siteStateGeneralInfo?.nslpexportCode}
                variant="outlined"
                style={{
                  width: "100%",
                }}
                onChange={(event) => {
                  if (event.target.value.length <= 20) {
                    setSiteStateGeneralInfo({
                      ...siteStateGeneralInfo,
                      nslpexportCode: event.target.value,
                    });
                  }
                }
                }
              />
              <Grid item={true}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      id={"Reimbursement_export_code"}
                      checked={siteStateGeneralInfo?.nslpexportCode === siteStateGeneralInfo.siteCode ? true : false}
                      color="primary"
                      onChange={(event: any) => {
                        if (event.target.checked) {
                          setSiteStateGeneralInfo({
                            ...siteStateGeneralInfo,
                            nslpexportCode: siteStateGeneralInfo.siteCode,
                          });
                        } else {
                          setSiteStateGeneralInfo({
                            ...siteStateGeneralInfo,
                            nslpexportCode: "",
                          });
                        }
                      }}
                    />
                  )}
                  label={"Same as Site Code"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Accordion>

        <Accordion title="Correspondence Address (OPTIONAL)" >
          <Grid
            container={true}
            spacing={3}
            direction="row"
            style={{ padding: "6px" }}
          >
            <Grid item={true} md={4} xs={6}>
              <TextField
                id="addressLine1"
                name="addressLine1"
                label="Address1"
                value={siteStateCorrespondenceAddress.addressLine1}
                variant="outlined"
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
                onChange={(event) =>
                  onFieldChangeCorrespondence("addressLine1", event)
                }
              />
            </Grid>
            <Grid item={true} md={4} xs={6}>
              <TextField
                id="addressLine2"
                name="addressLine2"
                label="Address2"
                value={siteStateCorrespondenceAddress.addressLine2}
                variant="outlined"
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
                onChange={(event) =>
                  onFieldChangeCorrespondence("addressLine2", event)
                }
              />
            </Grid>
            <Grid item={true} md={4} xs={6}>
              <TextField
                id="city"
                name="city"
                label="City"
                value={siteStateCorrespondenceAddress.city}
                variant="outlined"
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
                onChange={(event) =>
                  onFieldChangeCorrespondence("city", event)
                }
              />
            </Grid>
            <Grid item={true} md={4} xs={6}>
              <Autocomplete
                id="stateId"
                options={states}
                getOptionLabel={(option: IState) => option.stateCd}
                getOptionSelected={(option) => option?.stateId === option.stateId}
                value={stateDefaultDataState}
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                  display: "inline-flex",
                }}
                renderInput={(params) => (
                  <TextField {...params} label="State" variant="outlined" size="medium" />
                )}
                onChange={(newValue: any) => {
                  onSelectCorrespondenceState(newValue);
                }}
              />
            </Grid>
            <Grid item={true} md={4} xs={6}>
              <TextField
                id="zipCode"
                name="zipCode"
                label="Zip"
                value={siteStateCorrespondenceAddress.zipCode}
                variant="outlined"
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
                onChange={(event) =>
                  onFieldChangeCorrespondence("zipCode", event)
                }
              />
            </Grid>
          </Grid>

          <Grid
            container={true}
            spacing={3}
            direction="row"
            style={{ padding: "6px" }}
          >
            <Grid item={true} md={4} xs={6}>
              <TextField
                id="contactPerson"
                name="contactPerson"
                label="Contact Person"
                value={siteStateCorrespondenceAddress.contactPerson}
                variant="outlined"
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
                onChange={(event) =>
                  onFieldChangeCorrespondence("contactPerson", event)
                }
              />
            </Grid>
            <Grid item={true} md={4} xs={6}>
              <TextField
                id="contactPersonPosition"
                name="contactPersonPosition"
                label="Contact Person Position"
                value={siteStateCorrespondenceAddress.contactPersonPosition}
                variant="outlined"
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
                onChange={(event) =>
                  onFieldChangeCorrespondence(
                    "contactPersonPosition",
                    event,
                  )
                }
              />
            </Grid>
            <Grid item={true} md={4} xs={6}>
              <TextField
                id="contactPhone"
                name="contactPhone"
                label="Contact Phone"
                value={formatPhoneNumber(
                  siteStateCorrespondenceAddress.contactPhone,
                )}
                variant="outlined"
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
                onChange={(event) =>
                  onFieldChangeCorrespondence("contactPhone", event)
                }
              />
            </Grid>
          </Grid>
        </Accordion>

        {currentSiteExpSettings.length > 0 && (
          <Accordion title="Characteristics">
            <SiteCharacteristics
              generalInfo={generalInfo}
            />
          </Accordion>
        )}

        {currentSiteExpSettings.length > 0 ? (
          <Accordion title="POS Configuration (OPTIONAL)">
            <Grid
              container={true}
              direction="row"
            >
              <Grid item={true} md={8} xs={8} sm={8}>
                <Grid container={true} direction="row" >
                  {expSiteSettings.map((setting: ISiteExpSettings, index: number) => (
                    (setting.code !== "FEEDING" && setting.name != "Feeding Site") &&
                    <Grid item={true} md={6} xs={6} style={{ padding: "5px 0px" }} >
                      <Grid container={true} direction="row" justify="flex-start" alignItems="center">
                        <Grid item={true} md={8} xs={8}>
                          <span>{setting.name}</span>
                        </Grid>
                        <Grid item={true} md={4} xs={4}>
                          <FormControlLabel
                            control={(
                              <Switch
                                checked={setting.value}
                                onChange={updateFieldChanged}
                                color="primary"
                                name={`switch-` + index}
                                id="siteReimbursementToggle"
                                inputProps={{ "aria-label": setting.name }}
                              />
                            )}
                            label="" // {setting.value ? 'Yes' : 'No'}
                            // className={setting.length > 30 ? switchLabelStyles.label : ''} 
                            // style={{width:"300px", padding:"5px"}}
                            labelPlacement="end"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {/* <Grid item sm={1} md={1} > */}
              <Divider
                orientation="vertical"
                flexItem={true}
                style={{ margin: "0px 30px" }}
              />
              {/* </Grid> */}
              <Grid item={true} md={3} xs={3} sm={3}>
                <Grid container={true} direction="column" justify="center" alignItems="flex-start">
                  <Grid item={true}>
                    <Typography variant="h6">Meal Service Buttons</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Grid container={true} direction="row" spacing={4}>
                      <Grid item={true}>
                        <Grid container={true} direction="column">
                          {mealServiceButtons.map((item: ISiteExpSettings, index: number) => (
                            <Grid item={true}>
                              <Grid container={true} style={{ padding: "5px 0px" }} direction="row" alignItems="center" spacing={2}>
                                <Grid item={true} md={8} sm={8} xs={8}>
                                  <span>{item.name}</span>
                                </Grid>
                                <Grid item={true} md={4} sm={4} xs={4}>
                                  <FormControlLabel
                                    control={(
                                      <Switch
                                        checked={item.value}
                                        onChange={(event) => {
                                          const value = event?.target?.checked;
                                          const name = event?.target?.name;
                                          const index = Number(name.split("-")[1]);
                                          const newArray = mealServiceButtons.map((a) => ({ ...a }));
                                          newArray[index].value = value;
                                          setMealServiceButtons(newArray);
                                        }}
                                        color="primary"
                                        name={`mealService-` + index}
                                        id="siteReimbursementToggle"
                                        inputProps={{ "aria-label": item.name }}
                                      />
                                    )}
                                    label="" // {item.value ? 'Yes' : 'No'}
                                    labelPlacement="end"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Accordion>) : <> </>}
      </AccordionGroup>
      <Grid
        xs={12}
        item={true}
        style={{
          marginTop: 24,
          flexDirection: "row",
          display: "flex",
          placeContent: "center flex-end",
          alignItems: "center",
        }}
      >
        <Button
          color="default"
          id="cancelButton"
          label="Cancel"
          name="Button"
          onClick={cancelHandler}
          type="button"
          variant="default"
          style={{ marginRight: 15 }}
        />

        <Button
          color="primary"
          id="successButton"
          label="Save"
          name="Button"
          onClick={onSave}
          type="button"
          variant="default"
        />
      </Grid>
    </>
  );
};

export default AddSite;
