import administrationAPIClient from "control/transport/clients/administration-api.client";
import { useEffect, useState } from "react";

export const useDistrictTimeZone = () => {
    const activeDistrict: any = localStorage.getItem("activeDistrict");

    const [selectedDistrictTimezone, setSelectedDistrictTimezone] = useState<string>("");

    useEffect(() => {
        const selectedDistrict: any = JSON.parse(activeDistrict);

        const response = administrationAPIClient({
            method: "GET",
            url: `/api/SystemSettings/${selectedDistrict ? selectedDistrict?.attributes?.regionId[0] : 0}/SettingValue/DTTIMEZONE`,
        });
        response.then((data) => {
            if (data.status === 200) {
                setSelectedDistrictTimezone(data.data.PayLoad);

            }
        });
    }, [activeDistrict]);

    return {
        selectedDistrictTimezone,
    };

};
