import { APIInstance } from "@primeroedge/ui-components";
import { API_DATA_EXCHANGE, APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY } from "./api-constants";

const dataExchangeAPIClient = new APIInstance({
    baseURL: API_DATA_EXCHANGE,
    environment: APIM_ENVIRONMENT,
    subscriptionKey: APIM_SUBSCRIPTION_KEY,
}).axios;

export default dataExchangeAPIClient;
