import { State } from "@progress/kendo-data-query";
import { IAppState } from "model/reducers";
import { POSConfigUpdateStatus } from "model/reducers/posconfiguration.reducer";
export interface ISites {
    isLoading: boolean;
    siteList: any;
    // areaNames: IAreaName[];
    areaNames: IAreas[];
    // siteTypes: ISiteType[];
    siteTypes: ISiteTypeNew[];
    allStates: IState[];
    characteristics: ICharacteristic[];
    currentSite: ISite;
    currentSiteExpSettings: ISiteExpSettings[];
    addSiteError: any;
    siteGeneralInfoError: any;
    siteCorrAddrError: any;
    addSiteResponse: any;
    updateSiteGeneralInfoResp: any;
    updateSiteCorrAddrResp: any;
    mealTypes: IMealType[];
    sitesConfigurationTypes: ISiteConfigurationType[];
    updateSiteMealTypeResp: any;
    updateSiteMealTypeError: any;
    updateFeedingSiteResp: any;
    updateFeedingSiteError: any;
    academicYears: any;
    currentAcademicYear: any;
    programsList: IProgram[];
    updateProgramResp: any;
    updateProgramError: any;
    programSitesList: any;
    updateProgramSiteResp: any;
    updateProgramSiteError: any;
    copyProgramSaveStatus: POSConfigUpdateStatus;
    siteCharacterstics: any;
    storeSiteCharacterstics: any;
    updateSiteCharacteristicsResp: any;
    siteCharacteristicsError: any;
    parentSites: any;
}

export interface IAreas {
    addedBy: null | string;
    addedOn: null | string;
    description: null | string;
    district: null | string;
    districtRegionId: null | number;
    isActive: boolean;
    isIsd: boolean;
    modifiedBy: null | string;
    modifiedOn: null | string;
    name: string;
    organizationLevelId: null | number;
    parentRegionId: null | number;
    regionId: number;
    subscriptionTerminalId: number;
    synchronizeDate: string;
    updateDate: string;
}

export interface ISiteListItem {
    siteId: number;
    siteName: string;
    contactPerson: string;
    contactPersonPosition: string;
    contactPhone: string;
    addressId: number;
    siteDescription: string;
    parentSiteId: number;
    deliveryCost: number;
    storageCost: number;
    regionId: number;
    siteTypeId: number;
    numLine: number;
    siteCode: string;
    siteTypeDescription: string;
    isActive: boolean;
    isFeedingSite: boolean;
    includeForReimbursement: boolean;
    stateSiteCode: number;
}

export interface ISite {
    siteName: string;
    siteDescription: string;
    siteCode: string;
    regionId: number;
    siteTypeId: number;
    usdaSiteTypeId: number;
    isActive: boolean;
    addressLine1: string;
    addressLine2: string;
    zipCode: string;
    city: string;
    stateId: number;
    contactPerson: string;
    contactPersonPosition: string;
    contactPhone: string;
    siteId: number;
    addedBy: number;
    addedOn: string;
    addressId: null | number;
    breakFastReimbursementCategoryId: null | number;
    cacfpfacility: null | number;
    cacfppricing: null | number;
    canOrderBrokenUnits: boolean;
    canPosusePrincipalAccount: boolean;
    cependDate: null | string;
    cepstartDate: null | string;
    characteristic: null | string;
    characteristicDetail: null | string;
    checkOtherSiteSecMeal: boolean;
    commaSeparatedDeliveryLocations: null | string;
    connectToServer: null | string;
    deliveryCost: null | number;
    districtRegionId: number;
    enableBin: null | number;
    enableOrdering: boolean;
    foodPreparationSiteId: null | number;
    importKey: null | string;
    includeForReimbursement: boolean;
    includeForVerification: boolean;
    isFeedingSite: boolean; // null | string;
    menuGroupEffectiveDate: null | string;
    menuGroupId: null | number;
    modifiedBy: number;
    modifiedOn: string;
    nslpexportCode: null | string | number;
    numLines: number;
    orderComments: null | string;
    orderingGroupId: null | number;
    parentSiteId: null | number;
    phoneNumber: null | string;
    primeroSchoolId: string;
    sendSiteToSchoolCafe: boolean;
    showOtherSiteMsg: null | string;
    stateSiteCode: null | number;
    storageCost: null | number;
    subscriptionTerminalId: number;
    synchronizeDate: string;
    timeClockLogInType: number;
    updateDate: string;
    usdasiteTypeId: number;
    useCustomMealType: boolean;
    groupGuid: string | null;
    childSites: any;
    siteCharacteristics: number | null;
}

export interface IAreaName {
    isActive: boolean;
    isIsd: boolean;
    name: string;
    regionId: number;
}

export interface ISiteTypeNew {
    siteTypes: ISiteTypes[];
    totalPages: number;
    totalSiteTypesCount: number;
}

export interface ISiteExpSettings {
    id: number;
    name: string;
    code: string;
    value: boolean;
}

export interface ISiteTypes {
    description: string;
    grades: [];
    isSupplier: boolean;
    regionId: number;
    siteTypeId: number;
    siteTypeTypeCode: string;
    siteTypeTypeDescription: string;
}


export interface ISiteType {
    description: string;
    siteTypeId: number;
    siteTypeTypeCode: string;
}
export interface IMealType {
    description: string;
    mealTypeId: number;
    startTime: string;
    endTime: string;
    reimbursable: boolean;
    isEditable: boolean;
    isDefault: boolean;
    isValid: boolean;

}
export interface ISiteConfigurationType {
    siteName: string;
    siteId: number;
    siteTypeId: number;
    useCustomMealType: boolean;
    isFeedingSite: boolean;

}
export interface IState {
    stateId: number;
    stateCd: string;
    description?: string;
}

export interface ICharacteristic {
    id: number;
    name: string;
    description?: string;
    siteCharacteristicsId?: number;
    details?: ICharacteristic[];
    checked?: boolean;
}

export interface IProgram {
    programId: number;
    name: string;
    code: string;
    classificationId?: number;
    isConfigured: boolean;
    isFeedingSite: boolean;
    isReimbursement: boolean;
    classifications: IProgramClassification[];
    academicYearId: number;
}
export interface IProgramClassification {
    id: number;
    name: string;
    programId: number;
}
export interface IProgramSite {
    siteId: number;
    siteName: string;
    classificationId?: number;
    isFeedingSite: boolean;
    isReimbursement: boolean;
}

export interface ISitesCloseProps {
    selectedSite: any;
    actionType: string;
    gridState: State;
}

export enum ISiteActionType {
    Edit = "Edit",
    ViewHistory = "View History",
}

export interface IGeneralInfo {
    siteId: number;
    siteName: string;
    siteDescription: string;
    siteCode: null | string | number;
    regionId: number;
    siteTypeId: number;
    usdaSiteTypeId: number;
    isActive: boolean;
    includeForReimbursement: boolean;
    isFeedingSite: boolean;
    nslpexportCode: string | null | number;
    childSites: any;
    siteCharacteristics: number | null;
    parentSiteId: number | null;
}

export function Sites (state: IAppState) {
    return state.sites;
}

export class SitesDataEntity {

    public static getSiteList ({ sites }: IAppState) {
        return sites.siteList;
    }

    public static getAreaNames ({ sites }: IAppState) {
        return sites.areaNames;
    }

    public static getSiteTypesByRegion ({ sites }: IAppState) {
        return sites.siteTypes;
    }

    public static getAllStates ({ sites }: IAppState) {
        return sites.allStates;
    }

    public static getCharacteristics ({ sites }: IAppState) {
        return sites.characteristics;
    }

    public static getCurrentSite ({ sites }: IAppState) {
        return sites.currentSite;
    }

    public static getCurrentSiteExpSettings ({ sites }: IAppState) {
        return sites.currentSiteExpSettings;
    }

    public static getIsLoading ({ sites }: IAppState) {
        return sites.isLoading;
    }

    public static getSiteCorrAddrErrors ({ sites }: IAppState) {
        return sites.siteCorrAddrError;
    }

    public static getAddSiteResp ({ sites }: IAppState) {
        return sites.addSiteResponse;
    }

    public static getAddSiteErrors ({ sites }: IAppState) {
        return sites.addSiteError;
    }

    public static getUpdateSiteGeneralInfoResp ({ sites }: IAppState) {
        return sites.updateSiteGeneralInfoResp;
    }

    public static getUpdateSiteGeneralInfoErrors ({ sites }: IAppState) {
        return sites.siteGeneralInfoError;
    }

    public static getUpdateSiteCorrAddrResp ({ sites }: IAppState) {
        return sites.updateSiteCorrAddrResp;
    }
    public static getMealTypesByRegion ({ sites }: IAppState) {
        return sites.mealTypes;
    }
    public static getMealTypesByRegionSuccess ({ sites }: IAppState) {
        return sites;
    }
    public static getSitesConfigurationTypesByRegion ({ sites }: IAppState) {
        return sites.sitesConfigurationTypes;
    }
    public static getUpdateSiteMealTypeResp ({ sites }: IAppState) {
        return sites.updateSiteMealTypeResp;
    }
    public static getUpdateSiteMealTypeError ({ sites }: IAppState) {
        return sites.updateSiteMealTypeError;
    }
    public static getUpdateFeedingSiteResp ({ sites }: IAppState) {
        return sites.updateFeedingSiteResp;
    }
    public static getUpdateFeedingSiteError ({ sites }: IAppState) {
        return sites.updateFeedingSiteError;
    }
    public static getAcademicYears ({ sites }: IAppState) {
        return sites.academicYears;
    }
    public static getCurrentAcademicYear ({ sites }: IAppState) {
        return sites.currentAcademicYear;
    }
    public static getProgramsByAcademicYear ({ sites }: IAppState) {
        return sites.programsList;
    }
    public static getUpdateProgramResp ({ sites }: IAppState) {
        return sites.updateProgramResp;
    }
    public static getUpdateProgramError ({ sites }: IAppState) {
        return sites.updateProgramError;
    }
    public static getProgramSitesByAcademicYear ({ sites }: IAppState) {
        return sites.programSitesList;
    }
    public static getUpdateProgramSiteResp ({ sites }: IAppState) {
        return sites.updateProgramSiteResp;
    }
    public static getUpdateProgramSiteError ({ sites }: IAppState) {
        return sites.updateProgramSiteError;
    }
    public static getCopyProgramSaveStatus ({ sites }: IAppState) {
        return sites.copyProgramSaveStatus;
    }
    public static getSiteCharacterstics ({ sites }: IAppState) {
        return sites.siteCharacterstics;
    }
    public static getStoreSiteCharacterstics ({ sites }: IAppState) {
        return sites.storeSiteCharacterstics;
    }
    public static getUpdateSiteCharacteristicsResp ({ sites }: IAppState) {
        return sites.updateSiteCharacteristicsResp;
    }
    public static getSiteCharacteristicsErrors ({ sites }: IAppState) {
        return sites.siteCharacteristicsError;
    }
    public static getParentSites ({ sites }: IAppState) {
        return sites.parentSites;
    }
}
