import { createReducer } from "@reduxjs/toolkit";
import * as actions from "../../control/actions/customReports.actions";
import produce from "immer";
import { ICustomReportsData } from "model/entity/CustomReports.entity";

const initialState: ICustomReportsData = {
    customReportsData: {
        customReportDistributeds: [],
        customReportFavorites: [],
    },
    isLoading: false,
    customReportsByIdData: {
        customReportByIdData: null,
        isLoading: false
    },
    unstarCustomReport: {
        isLoading: false,
        deleteCustomReportFavoriteStatus: ""
    },
    mailWindow: {
        showWindow: false,
        metaData: null,
    },
};

export const customReportsReducer = createReducer(initialState, {
    [actions.getFavoriteCustomReportsInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
            draft = initialState;
        }),
    [actions.getFavoriteCustomReportsSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.isLoading = false;
            draft.customReportsData = action.payload;
        }),
    [actions.getFavoriteCustomReportsFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
            draft = initialState;
        }),  
    [actions.getCustomReportByIdInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.customReportsByIdData = {
                isLoading: true,
                customReportByIdData: null
            };
        }),
    [actions.getCustomReportByIdSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.customReportsByIdData = {
                isLoading: false,
                customReportByIdData: action.payload
            };
        }),
    [actions.getCustomReportByIdFailed.toString()]: (state) =>
        produce(state, (draft) => {
            draft.customReportsByIdData = initialState.customReportsByIdData
        }), 

    [actions.deleteCustomReportFavoriteInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.unstarCustomReport.isLoading = true;
            draft.unstarCustomReport.deleteCustomReportFavoriteStatus = "";
        }),

    [actions.deleteCustomReportFavoriteSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.unstarCustomReport.deleteCustomReportFavoriteStatus = action.payload.Description;
            draft.unstarCustomReport.isLoading = false;
        }),

    [actions.deleteCustomReportFavoriteFailed.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.unstarCustomReport.deleteCustomReportFavoriteStatus = action.payload?.Description;
            draft.unstarCustomReport.isLoading = false;
        }),    
    [actions.uiShowMainWindowInit.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.mailWindow.showWindow = !draft.mailWindow.showWindow;
            draft.mailWindow.metaData = {
                dataItem: action.payload
            }
        }),
    });
    