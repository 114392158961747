export const APIM_ENVIRONMENT = process.env.REACT_APP_APIM_ENVIRONMENT;
export const APIM_SUBSCRIPTION_KEY = process.env.REACT_APP_APIM_SUBSCRIPTION_KEY;

export const APIM_BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const API_USER_MANAGEMENT = process.env.REACT_APP_API_USER_MANAGEMENT;
export const API_WORKSPACE = process.env.REACT_APP_API_WORKSPACE;
export const API_ADMINISTRATION = process.env.REACT_APP_API_ADMINISTRATION;
export const API_DATA_EXCHANGE = process.env.REACT_APP_API_DATA_EXCHANGE;
export const API_SHARED_UTILITIES = process.env.REACT_APP_API_SHARED_UTILITIES;
export const API_MENU_PLANNING = process.env.REACT_APP_API_MENU_PLANNING;
export const API_ELIGIBILITY = process.env.REACT_APP_API_ELIGIBILITY_URL;
export const API_POS = process.env.REACT_APP_API_POS_URL;
export const AAPINSIGHTS_INSTRUMENTATION_KEY = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONA_KEY;
export const REACT_APP_ENABLE_APP_INSIGHTS = process.env.REACT_APP_ENABLE_APP_INSIGHTS;
export const API_ACCOUNT_MANAGEMENT =
    process.env.REACT_APP_API_ACCOUNT_MANAGEMENT_URL;
export const API_INVENTORY = process.env.REACT_APP_API_INVENTORY;
export const API_ITEM_MANAGEMENT = process.env.REACT_APP_API_ITEM_MANAGEMENT;

