import React from 'react';
import { KGrid, Label, SlidingDrawer } from "@primeroedge/ui-components"
import { Box, CircularProgress } from "@material-ui/core";
import { isNull } from 'lodash';
import useFetchReportSqlData from './hooks/useFetchReportSqlData';
import { useStyles } from '../../style';
import DownloadReport from './DownloadReport';

interface IViewCustomReportProps {
    openDrawer: boolean;
    closeDrawer: any;
    gridState?: any;
    columns?: any;
    sqlData?: any;
    handleDataStateChange?: any;
    DownloadReport?: any;
    sqlDataError?: any;
    sqlColumns?: any;
    selectedFavorite?: any;
}

const ViewCustomReport = (props: IViewCustomReportProps) => {
    const {
        gridState,
        sqlData,
        sqlColumns,
        isDataLoading,
        handleDataStateChange,
        sqlDataError
    } = useFetchReportSqlData(props.selectedFavorite);
    const classes = useStyles();
    return (
        <SlidingDrawer
            open={props.openDrawer}
            title="View Custom Report"
            width="lg"
            onCloseIconClick={props.closeDrawer}>
            <div>
                {isDataLoading && (
                    <Box className={classes.tabContainer}>
                        <CircularProgress color="secondary" />
                    </Box>  
                )}
                              
                {(!isDataLoading) && (sqlColumns?.length > 0) && (<KGrid
                    {...gridState}
                        columns={sqlColumns}
                    data={sqlData}
                    primaryField={sqlColumns?.[0]?.field}
                    total={sqlData?.length}
                    processedData={false}
                    sortable={false}
                    pageable={true}
                    onDataStateChange={handleDataStateChange}
                    pageSizes={[100, 200, 500]}
                    resizable={true}
                    toolbarOptions={() => DownloadReport(props.selectedFavorite.customReportName, props.selectedFavorite.moduleName, props.selectedFavorite.sql)}
                />)}
                {(!isDataLoading) && (!isNull(sqlData) && sqlData?.length === 0) && (<div>
                    <Label id={'no-data-available'} color='warning' style={{ display: 'flex', alignItems: 'center' }}>{!isNull(sqlDataError) ? sqlDataError : 'No Data Available'}</Label>
                
                </div>)}
            </div>
        </SlidingDrawer>
    )
}

export default ViewCustomReport;