import {
  IBroadcast,
  IFetchBroadcastPayload,
  SortType,
} from "../../model/entity";
import workspaceAPIClient from "./clients/workspace-api.client";

class BroadcastTransport {
  public async fetchBroadcastMessages ({
    pageSize = 5,
    pageNumber = 1,
    sortColumn = "CreatedOn",
    sortType = SortType.DESC,
  }: IFetchBroadcastPayload): Promise<Partial<IBroadcast>> {
    const response = await workspaceAPIClient ({
      method: "GET",
      url: `/api/list-broadcasts?pageSize=${pageSize}&pageNumber=${pageNumber}&sortColumn=${sortColumn}&sortType=${sortType}`,
    });
    if (response.data) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject("Failed to get broadcast messages");
    }
  }

  public async deleteBroadcast (id: string[]): Promise<null> {
    const response = await workspaceAPIClient ({
      method: "DELETE",
      url: `/api/delete-broadcast`,
      data: { id },
    });
    if (response) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject("Failed to delete broadcast message");
    }
  }
}

export default new BroadcastTransport();
