import React, { Fragment } from 'react';
import { SlidingDrawer, Tooltip } from "@primeroedge/ui-components";
import { Divider, List } from "@material-ui/core";
import ListItem from '../../ListItem';


type ViewAllRecentsListProps = {
    openDrawer?:any;
    closeDrawer?: () => void;
    recentsData?:any;
};

export const ViewAllRecentsList:  React.FC<ViewAllRecentsListProps> = ({
    recentsData,
    openDrawer,
    closeDrawer
})  =>{

    const handleRowClick = ( url: any) => {
            const baseUrl = window.location.origin;
            const sanitizedUrl = url?.startsWith('/') ? url : `/${url}`;
            window.open(`${baseUrl}${sanitizedUrl}`, '_blank');
    };

    return(
        <>
        <SlidingDrawer
            open={openDrawer}
            title="Recents"
            width="sm"
            onCloseIconClick={closeDrawer}>
            {(recentsData && recentsData?.length > 0) && (
                <List>
                    {recentsData.slice(0, 10).map((recent: any, i: number) => (
                        <Fragment key={i}>
                            <ListItem
                                onClick={()=>{handleRowClick(recent.url )}}
                                title={
                                   <Tooltip title = {recent.url}>
                                     <span style={{ color: 'rgb(102,90,216)' }}>
                                        {recent.pageName}
                                    </span>
                                   </Tooltip>
                                }
                            />
                            <Divider />
                        </Fragment>
                    ))}
                </List>
            )}
        </SlidingDrawer>
    </>
    )
}