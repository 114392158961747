import { ResponseStatus } from "types/mail";
import { IAppState } from "../reducers";

export interface IFavoriteCustomReports {
    customReportFavoriteId: number;
    customReportId: number;
    userId: number;
    regionId: number;
    url: string;
    moduleName: string;
    customReportName: string;
    sqlQuery?: string;
    containsPii?: boolean;
    customReportDescription?: string;
}
export interface IDistributedCustomReports {
    customReportId: number;
    url: string;
    customReportName: string,
    distributedOn: string,
    distributionHistoryId: number
    customReportDescription?: string;
}

export interface ICustomReports {
    customReportFavorites: IFavoriteCustomReports[];
    customReportDistributeds: IDistributedCustomReports[];
}
export interface ICustomReportsById {
    customReportByIdData: any;
    isLoading: boolean;
}
export interface IUnStarCustomReport {
    isLoading: boolean;
    deleteCustomReportFavoriteStatus: string;
}
export interface IMailWindow {
    showWindow: boolean;
    metaData: any;
}

export interface ICustomReportsMail {
    userList: any;
    status: ResponseStatus;
    attachments: any;
    email: {
        isSending: boolean;
        isSent: boolean;
        isError: boolean;
    }
}
export interface ICustomReportsData {
    customReportsData: ICustomReports;
    customReportsByIdData: ICustomReportsById;
    unstarCustomReport: IUnStarCustomReport;
    mailWindow: IMailWindow;
    isLoading: boolean;
}

export class CustomReportsEntity {

    public static isReportsDataLoading({ customReports }: IAppState) {
        return customReports.isLoading;
    }
    public static getCustomReports({ customReports }: IAppState) {
        return customReports.customReportsData;
    }
    public static getCustomReportById({ customReports }: IAppState) {
        return customReports.customReportsByIdData.customReportByIdData;
    }
    public static isCustomReportByIdLoading({ customReports }: IAppState) {
        return customReports.customReportsByIdData.isLoading;
    }
    public static getMailWindowStatus({ customReports }: IAppState): boolean {
        return customReports.mailWindow.showWindow;
    }
    public static getMetaData({ customReports } : IAppState) {
        return customReports.mailWindow.metaData;
    }
    public static isEmailSending({ customReportsMail }: IAppState) {
        return customReportsMail.email?.isSending;
    }
    public static isEmailSent({ customReportsMail }: IAppState) {
        return customReportsMail?.email?.isSent;
    }
    public static isEmailError({ customReportsMail }: IAppState) {
        return customReportsMail.email?.isError;
    }
}
