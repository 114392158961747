import { APIInstance } from "@primeroedge/ui-components";
import {
    API_INVENTORY,
    APIM_ENVIRONMENT,
    APIM_SUBSCRIPTION_KEY,
} from "./api-constants";

const inventoryAPIClient = new APIInstance({
    baseURL: API_INVENTORY,
    environment: APIM_ENVIRONMENT,
    subscriptionKey: APIM_SUBSCRIPTION_KEY,
}).axios;

export default inventoryAPIClient;
