import { PageNotFound } from "@primeroedge/ui-components";
import DashboardPage from "./pages/dashboard-page";
import WorkspacePages from "./pages/workspace";
import { IRouteWithSubRoutesProps } from "./RouteWithSubRoutes";

const routes: IRouteWithSubRoutesProps[] = [
    {
        path: "/",
        exact: true,
        component: DashboardPage,
    },
    {
        path: "/workspace",
        component: WorkspacePages,
    },
    {
        path: "*",
        component: PageNotFound,
    },
];

export default routes;
