import { IGridDataState } from "@primeroedge/ui-components";
import { IAppState } from "../reducers";
import { ResponseStatus, SortType } from "./common.entity";
import { IRecipientUser } from "./mail.entity";

export interface IBroadcast {
    messages?: IBroadcastMessage[];
    totalMessageCount?: number;
    totalPages?: number;
    gridState?: IGridDataState;
    status: ResponseStatus;
}

export interface IBroadcastMessage {
    attachments: any[];
    broadcastEndDateTime: string;
    broadcastStartDateTime: string;
    content: string;
    conversationId: string;
    createdOn: string;
    id: string;
    isDeleted: boolean;
    isDraft: boolean;
    messageType: string;
    recipient: IRecipientUser[];
    senderFirstName: string;
    senderId: string;
    senderLastName: string;
    subject: string;
}

export interface IFetchBroadcastPayload {
    pageSize: number;
    pageNumber: number;
    sortColumn?: string;
    sortType?: SortType;
}

export class BroadcastEntity {

    public static messages ({ broadcast }: IAppState) {
        return broadcast.messages;
    }

    public static totalMessageCount ({ broadcast }: IAppState) {
        return broadcast.totalMessageCount;
    }

    public static totalPages ({ broadcast }: IAppState) {
        return broadcast.totalPages;
    }

    public static status ({ broadcast }: IAppState) {
        return broadcast.status;
    }

    public static isLoading ({ broadcast }: IAppState) {
        return broadcast.status === ResponseStatus.Loading;
    }
}
