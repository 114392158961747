import { IGridCellProps, IGridColumnProps } from "@primeroedge/ui-components";
import { INewTask, ITask } from "model/entity";
// import moment from "moment";
import React from "react";
import TasksTransportLayer from "../../../control/transport/tasks.transport";
import TaskStatusLabel from "./tasks-status-label";

export const tasksTableColumns: IGridColumnProps[] = [
    {
        field: "Selected",
        width: "5%",
    },
    {
        field: "title",
        title: "Task Name",
        width: '35%',
    },
    {
        field: "description",
        title: "Description",
        width: '35%',

    },
    {
        field: "status",
        title: "Status",
        width: '7%',
        cell: (rowData: IGridCellProps) => <TaskStatusLabel task={rowData.dataItem} />,
    },
    {
        field: "dueDate",
        filter: "date",
        hidden: false,
        title: "Due Date",
        width: '10%',
    },
];

export const addNewTask = (task: INewTask): Promise<ITask> => TasksTransportLayer.addNewTask(task);

export const updateTask = (task: any): Promise<any> => TasksTransportLayer.updateTask(task);

