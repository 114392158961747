import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import {
  AddAll,
  RemoveAll,
  RemoveUser,
  SelectedUser,
  resetSelectedUsers,
  setDismissedMessages,
  setGlobalMessage,
  updateUsers,
} from "./../../control/actions/message.actions";

const initialState: any = {
  selectedUsers: [],
};
const initialGlobalMessageState: any = {
  isGlobalMessage: false,
  messagesDismissed: [],
}
export const messagesReducer = createReducer(initialState, {
  [SelectedUser.toString()]: (state, action) =>
    produce(state, (draft: any) => {
      draft.selectedUsers.push(action.payload);
    }),
    [resetSelectedUsers.toString()]: (state) =>
    produce(state, (draft: any) => {
      draft.selectedUsers = [];
    }),
    

  [RemoveUser.toString()]: (state, action) =>
    produce(state, (draft: any) => {
      draft.selectedUsers = draft.selectedUsers.filter(
        (ele: any) => ele.userGuid !== action.payload.userGuid,
      );
    }),
  [AddAll.toString()]: (state, action) =>
    produce(state, (draft: any) => {
      draft.selectedUsers = action.payload;
    }),
  [RemoveAll.toString()]: (state) =>
    produce(state, (draft: any) => {
      draft.selectedUsers = [];
    }),
  [updateUsers.toString()]: (state, action) =>
    produce(state, (draft: any) => {
      draft.selectedUsers = action.payload;
    }),
});

export const GlobalMessageReducer = createReducer(initialGlobalMessageState, {
  [setGlobalMessage.toString()]: (state, action) =>
  produce(state, (draft: any) => {
    draft.isGlobalMessage = action.payload;
  }), 
  [setDismissedMessages.toString()]: (state, action) =>
  produce(state, (draft: any) => {
    draft.messagesDismissed = action.payload;
  }), 
})
