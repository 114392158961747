import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import BroadcastSaga from "./broadcast.saga";
import MailSaga from "./mail.saga";
import NotificationSaga from "./notification.saga";
import WelcomeSaga from "./onboarding.saga";
import SiteSaga from "./sites.saga";
import SiteTypeSage from "./sitetype.saga";
import TasksSaga from "./tasks.saga";
import UserSaga from "./user.saga";
import WorkspaceDetailsSaga from "./workspace-details.saga";
import watchCustomReportsSaga from "./customReport.saga";
import watchCustomReportsMailSaga from "./customReportMail.saga";
import watchMyPagesSagas from "./myPagess.saga";

export const sagaMiddleware = createSagaMiddleware();

export function* rootSaga () {
    yield all([
        NotificationSaga(),
        WorkspaceDetailsSaga(),
        MailSaga(),
        TasksSaga(),
        BroadcastSaga(),
        WelcomeSaga(),
        UserSaga(),
        SiteSaga(),
        SiteTypeSage(),
        watchCustomReportsSaga(),
        watchCustomReportsMailSaga(),
        watchMyPagesSagas()
    ]);
}
