import { APIInstance } from "@primeroedge/ui-components";
import { API_ELIGIBILITY, APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY } from "./api-constants";

const eligibilityAPIClient = new APIInstance({
    baseURL: API_ELIGIBILITY,
    environment: APIM_ENVIRONMENT,
    subscriptionKey: APIM_SUBSCRIPTION_KEY,
}).axios;

export default eligibilityAPIClient;
