import {
    AppBar,
    Box,
    CardActions,
    CircularProgress,
    Divider,
    List,
    Tab,
    Tabs,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Button, Label, PageAlert, PlusIcon, TaskIcon } from "@primeroedge/ui-components";
import clsx from "clsx";
import BaseCard from "components/BaseCard/BaseCard";
import TabPanel from "components/TabPanel/TabPanel";
import { FetchTasksByDuration, updatedAction } from "control/actions";
import { getUpdatedTasks, isTasksLoading, isTaskUpdated, ITask } from "model/entity";
import moment from "moment";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import AddNewTask from "view/pages/tasks-page/add-task";
import { useStyles } from "../../style";
import ListItem from "../ListItem";

const MyTask: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const tasks = useSelector(getUpdatedTasks);
    const isLoading = useSelector(isTasksLoading);
    const [value, setValue] = useState(0);
    const [showAddNewTask, canShowAddNewTask] = useState<boolean>(false);
    const taskUpdated = useSelector(isTaskUpdated);

    const [btnGroup, setBtnGroup] = useState("day");
    const [editableTask, setEditableTask] = useState<ITask | null | undefined>(
        null,
    );
    const currentDate = moment(moment()).format("MM/DD/YYYY");

    useEffect(() => {
        if (taskUpdated) {
            dispatch(updatedAction(false));
        }
        dispatch(
            FetchTasksByDuration({
                size: 5,
                duration: btnGroup,
                currentDate,
            }),
        );
    }, [taskUpdated, btnGroup, dispatch]);

    //@ts-ignore
    const handleChange = (ev: ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const handleTodayAction = () => {
        setBtnGroup("day");
    };

    const handleWeekAction = () => {
        setBtnGroup("week");
    };

    const handleMonthAction = () => {
        setBtnGroup("month");
    };

    const handleRowClick = (task: ITask) => {
        setEditableTask(task);
        canShowAddNewTask(true);
    };

    const getLabel = (status: string, dueDate: string) => {
        const dd = moment.parseZone(dueDate).format("MM/DD/YYYY");
        const statusToCheck = status.toLowerCase();
        let labelType: any;
        switch (statusToCheck) {
            case "new": {
                labelType = "primary";
                break;
            }
            case "done": {
                labelType = "success";
                break;
            }
            default: {
                labelType = "default";
            }
        }

        return (
            <div className={classes.taskAction}>
                <Label
                    id={`${status}-${dueDate.split(" ").join("-")}`}
                    color={labelType}
                    className={`font10 ${classes.textTf}`}
                >
                    {status === "InProgress" ? "In Progress" : status}
                </Label>
                <span>&nbsp; &nbsp;</span>
                <Typography
                    variant="body2"
                    style={{
                        color:
                            status !== "Done" &&
                                moment(dueDate).diff(moment.now(), "minutes") <= -1441
                                ? "error"
                                : "black",
                    }}
                    className={`font10 ${(status !== "Done" &&
                    moment(dueDate).diff(moment.now(), "minutes") <= -1441) ? classes.taskDue : classes.taskNoDue }`}
                >
                    {status !== "Done" && <>Due on &nbsp;</>}
                    <span>{dd}</span>
                </Typography>
            </div>
        );
    };

    const handleAddNewTaskClose = (success: boolean) => {
        canShowAddNewTask(false);
        setEditableTask(null);
        if (success) {
            FetchTasksByDuration({
                size: 5,
                duration: btnGroup,
                currentDate,
            });
        }
    };

    return (
        <>
            <BaseCard
                title="My Tasks"
                CardIcon={
                    <TaskIcon size={20} />
                }
                tooltip={"View and schedule tasks to set up helpful reminders."}
                extraTabs={(
                    <div>
                        <Button
                            id="day"
                            dense={true}
                            className={clsx(classes.taskToggleBtn, {
                                active: btnGroup === "day",
                            })}
                            onClick={handleTodayAction}
                        >
                            Today
                        </Button>
                        <Button
                            id="week"
                            dense={true}
                            className={clsx(classes.taskToggleBtn, {
                                active: btnGroup === "week",
                            })}
                            onClick={handleWeekAction}
                        >
                            Next 7 Days
                        </Button>
                        <Button
                            id="month"
                            dense={true}
                            className={clsx(classes.taskToggleBtn, {
                                active: btnGroup === "month",
                            })}
                            onClick={handleMonthAction}
                        >
                            Next 30 Days
                        </Button>
                    </div>
                )}
            >
                <AppBar position="static" color="transparent">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        aria-label="My Tasks"
                        variant="fullWidth"
                    >
                        <Tab label="MY TASKS" id="myTasksTab" />
                        <Tab label="RECOMMENDED" id="recommendedTasksTab" disabled={true} />
                    </Tabs>
                </AppBar>

                {isLoading && (
                    <Box className={classes.tabContainer}>
                        <CircularProgress color="secondary" />
                    </Box>
                )}
                {!isLoading && (
                    <SwipeableViews
                        index={value}
                        onChangeIndex={handleChangeIndex}
                        className={classes.msgContentHeight}
                    >
                        <Fragment>
                            <TabPanel value={value} index={0}>
                                {tasks && tasks.length > 0 ? (
                                    <List>
                                        {tasks.map((task: any, i: number) => (
                                            <Fragment key={i}>
                                                <ListItem
                                                    title={(
                                                        <span
                                                            style={{
                                                                textDecoration:
                                                                    task.status === "Done"
                                                                        ? "line-through"
                                                                        : "none",
                                                            }}
                                                        >
                                                            {task.title}
                                                        </span>
                                                    )}
                                                    info={getLabel(task.status, task.dueDate)}
                                                    onClick={() => handleRowClick(task)}
                                                />
                                                <Divider />
                                            </Fragment>
                                        ))}
                                    </List>
                                ) : (
                                    <>
                                        <Box className={classes.tabContainer} style={{ minHeight: "auto", paddingBottom: "5px" }}>
                                            <PageAlert
                                                icon={<Box p={2}><TaskIcon size={86} /></Box>}
                                                message="Your tasks will show up here!"
                                                variant="transparent"
                                            />
                                        </Box>
                                        <p className={classes.pageAlertTxt} >
                                            Click on View All to browse all tasks!</p>
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Box py={2}>Recommended</Box>
                            </TabPanel>
                        </Fragment>
                    </SwipeableViews>
                )}
                <CardActions className={classes.taskCardAction}>
                    <Button
                        color="primary"
                        dense={true}
                        id="primaryButton"
                        name="Button"
                        onClick={() => {
                            setEditableTask(null);
                            canShowAddNewTask(true);
                        }}
                        startIcon={<PlusIcon />}
                        type="button"
                        variant="default"
                    >
                        New Task
                    </Button>
                    <Button
                        color="default"
                        dense={true}
                        id="defaultButton"
                        name="Button"
                        onClick={() => history.push("/workspace/tasks")}
                        type="button"
                        variant="default"
                    >
                        View All
                    </Button>
                </CardActions>
            </BaseCard>
            <AddNewTask
                open={showAddNewTask}
                onClose={handleAddNewTaskClose}
                editable={Boolean(editableTask)}
                editableTask={editableTask}
            />
        </>
    );
};

export default MyTask;
