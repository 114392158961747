import { useEffect, useState } from 'react';
import { useProfile } from '@primeroedge/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentsInit } from 'control/actions/myPages.action';
import { getRecents } from 'model/entity/myPages.entity';

export const useRecents = () => {
    const dispatch = useDispatch();
    const { user } = useProfile();
    const activeDistrict = localStorage.getItem("activeDistrict");
    let selectedDistrict: any;
    selectedDistrict = activeDistrict && JSON.parse(activeDistrict);
    const [recentsData, setRecentsData] = useState([]);
    const getRecentsList = useSelector(getRecents);

    useEffect(() => {
        dispatch(getRecentsInit({
            userId: user ? Number(user?.UserId) : 0,
            regionId: selectedDistrict?.attributes?.regionId ? Number(selectedDistrict?.attributes?.regionId[0]) : 0,
        }))
    }, []);

    useEffect(() => {
        if (getRecentsList?.length > 0) {
            setRecentsData(getRecentsList);
        }
    }, [getRecentsList]);

    return {
        recentsData
    }
}