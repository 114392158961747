import { Accordion, Alert, InfoIcon, KGrid } from '@primeroedge/ui-components';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

export default forwardRef(function MyPreferenceSubscriptions(_: any, ref: any) {
    const [subscriptions, setSubscriptions] = useState<any>({});

    useImperativeHandle(ref, () => ({
        getValues: () => subscriptions
    }))

    useEffect(function () {
        setSubscriptions({})
    }, [])

    return (
        <Accordion className='accordion-preferences'  title="Subscriptions">
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <Alert variant='default' open
                    color="tertiary"
                    message="Select the events you would like to subscribe and how you get notified"
                    onClose={null}
                    icon={<InfoIcon />}
                />
                <KGrid primaryField='id' selectedField='id'
                    onSelectionChange={(event: any) => {
                        console.log('on seldct', event)
                    }}
                    data={[]}
                    columns={[{
                        field: 'id',
                        title: '',
                        locked: true
                    },
                    {
                        field: "name",
                        title: "Component",
                        width: 200,
                        locked: true
                    },
                    {
                        filterable: false,
                        sortable: false,
                        title: "Event",
                        width: 200
                    },
                    {
                        filterable: false,
                        sortable: false,
                        title: "Criteria",
                        width: 200
                    },
                    {
                        filterable: false,
                        sortable: false,
                        title: "Duration",
                        width: 200
                    },
                    {
                        filterable: false,
                        sortable: false,
                        title: "Timing",
                        width: 200
                    },
                    {
                        filterable: false,
                        sortable: false,
                        locked: true,
                        width: 200,
                        title: "Action(s)",
                    },
                ]} />
            </div>
        </Accordion>
    );
})