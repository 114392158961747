import { IGroup, IUser, IUser as IUserProfile, ResponseStatus } from "@primeroedge/ui-components";
import { createReducer } from "@reduxjs/toolkit";
import {
    getRemovePictureFail,
    getRemovePictureInit,
    getRemovePictureSuccess,
    getUserDetailsKeyclockInit, getUserDetailsKeyclockInitSuccess, passwordPoliciesSuccess, updateActiveDistrictSuccess, updateUserProfileSuccess,
    userAddFail, userAddInit, userAddSuccess, userEditFail, userEditSuccess, userFetchFail, userFetchInit, userFetchSuccess, usersFetchFail, usersFetchInit,
    usersFetchSuccess, userUpdateStatusFail, userUpdateStatusInit, userUpdateStatusSuccess, validateEmailSuccess, viewFilteredRoleDataFail, viewFilteredRoleDataInit,
    viewFilteredRoleDataSuccess, viewFilteredSiteDataFail, viewFilteredSiteDataInit, viewFilteredSiteDataSuccess, viewFilterRoleDataSuccess, viewFilterSiteDataFail,
    viewFilterSiteDataInit, viewFilterSiteDataSuccess, viewOrganizationSuccess, viewRegionsAndSitesSuccess, viewRolesSuccess, viewUsersSuccess,
} from "control/actions";
import produce from "immer";
import { IOrganizationList, IPasswordPolicies, IRegionsSites, IRoleFilterData, IRolesList, ISiteFilterData, IUserDetailsKeyclock } from "model/entity";
import { UsersStatus, UserStatus } from "types/user-types";
import {
    userAuthFailInvalidFormat,
    userAuthFailInvalidUser,
    userAuthFailUnknownError,
    userAuthLogoutDone,
    userAuthSuccess,
} from "../../control/actions/auth.actions";

export enum AuthStatus {
    NotAuthenticated = "NotAuthenticated",
    Loading = "InProgress",
    SuccessWithToken = "SuccessWithToken",
    FailedWithInvalidUser = "InvalidUser",
    FailedWithInvalidPassword = "InvalidPassword",
    FailedWithNetworkError = "NetworkError",
    FailedWithFormatError = "ResponseFormatError",
    FailedWithUnknownError = "UnknownError",
}
export interface IUsersState {
    list: IUser[];
    themeName: string;
    usersFetchStatus: string;
    userEditStatus: string;
    isLoading: boolean;
    selectedUserData: IUser;
    regionsAndSites: IRegionsSites;
    rolesList: IRolesList[];
    organizationList: IOrganizationList[];
    siteFilterData: ISiteFilterData[];
    roleFilterData: IRoleFilterData[];
    validEmail: string;
    userDetailsKeyclock: IUserDetailsKeyclock;
    siteFilterLoading: boolean;
    roleFilterLoading: boolean;
    siteInitLoading: boolean;
    passwordPolicies: IPasswordPolicies[];
    isUserLoading: boolean;
    removePictureStatus: string;
    userRemovePictureStatus: string;
  }

export interface IUserState {
    list: any;
    themeName: "light" | "dark";
    userAuthStatus: [AuthStatus, string | null];
    language: "en-US" | "en-GB" | "fr-FR";
    userProfile: IUserProfile;
    UserProfileStatus: ResponseStatus;
    activeDistrict: IGroup;
    sideNavOpenStatus: boolean;
}

const initialState: IUserState = {
    list: [],
    themeName: "dark",
    userAuthStatus: [AuthStatus.NotAuthenticated, null],
    language: "en-US",
    userProfile: {
        UserId: "",
        UserGuid: "",
        Name: "",
        UserName: "",
        EmailVerified: false,
        FirstName: "",
        LastName: "",
        Email: "",
        Groups: [],
        Roles: [],
        RolesPermissions: [],
        Sites: [],
        Regions: [],
        Image: "",
        IsPIIEnabled: false,
        NavigateOption: "",
    },
    UserProfileStatus: ResponseStatus.Initial,
    activeDistrict: {
        groupGuid: "",
        name: "",
        attributes: {
            regionId: ["0"],
        },
    },
    sideNavOpenStatus: true,
};

const userinitialState = {
    list: {},
    themeName: "dark",
    userFetchStatus: UserStatus.Initial,
    userEditSuccess: null,
    userEditFail: null,
    selectedUserData: {},
};

export const userReducer = createReducer(initialState, {

    [userAuthSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.userAuthStatus = [
                AuthStatus.SuccessWithToken,
                action.payload.accessToken,
            ];
            draft.themeName = "light";
        }),

    [userAuthFailInvalidUser.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.userAuthStatus = [AuthStatus.FailedWithInvalidUser, null];
        }),

    [userAuthFailInvalidFormat.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.userAuthStatus = [AuthStatus.FailedWithFormatError, null];
        }),

    [userAuthFailUnknownError.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.userAuthStatus = [AuthStatus.FailedWithUnknownError, null];
        }),

    [userAuthLogoutDone.toString()]: () => initialState,

    [updateUserProfileSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.userProfile = action.payload;
        }),

    [updateActiveDistrictSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.activeDistrict = action.payload;
        }),
});

export const userDetailsReducer = createReducer(userinitialState, {
    [userFetchInit.toString()]: (state) =>

        produce(state, (draft) => {
            draft.list = userinitialState.list;
            draft.userFetchStatus = UserStatus.Loading;
        }),

    [userFetchFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.list = userinitialState.list;
            draft.userFetchStatus = UserStatus.Failed;
        }),

});

export const usersReducer = createReducer(initialState, {

    [usersFetchInit.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.list = initialState.list;
            draft.usersFetchStatus = UsersStatus.Loading;
        }),

    [usersFetchSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.list = action.payload;
            draft.usersFetchStatus = UsersStatus.SuccessWithUsers;
        }),

    [usersFetchFail.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.list = initialState.list;
            draft.usersFetchStatus = UsersStatus.Failed;
        }),

    [userAddInit.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.list = initialState.list;
            draft.usersFetchStatus = UsersStatus.Loading;
        }),

    [userAddSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            if (
                action.payload.response &&
                action.payload.response.ResponseEnvelope.ValidationCollection.length !==
                0
            ) {
                draft.addUserError =
                    action.payload.response.ResponseEnvelope.ValidationCollection;
            } else {
                draft.addUserResponse = action.payload;
                draft.addUserError = undefined;
            }
        }),

    [userAddFail.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.addUserError = action.payload && action.payload.error;
        }),

    [userUpdateStatusInit.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.list = initialState.list;
            draft.usersFetchStatus = UsersStatus.Loading;
        }),

    [userUpdateStatusSuccess.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.list = initialState.list;
            draft.usersFetchStatus = UsersStatus.SuccessWithUsers;
        }),

    [userUpdateStatusFail.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.list = initialState.list;
            draft.usersFetchStatus = UsersStatus.Failed;
        }),

    [userEditFail.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.userEditStatus = action.payload;
        }),

    [userEditSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.userEditStatus = action.payload;
        }),

    [viewUsersSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.list = action.payload;
        }),

    [userFetchSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.selectedUserData = action.payload.PayLoad;
        }),

    [viewRegionsAndSitesSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.regionsAndSites = action.payload;
        }),

    [viewRolesSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.rolesList = action.payload;
        }),

    [viewOrganizationSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.organizationList = action.payload;
        }),

    [viewFilterSiteDataInit.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.isLoading = false;
            draft.siteInitLoading = true;
        }),

    [viewFilterSiteDataSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.siteFilterData = action.payload;
            draft.isLoading = false;
            draft.siteInitLoading = false;
        }),

    [viewFilterSiteDataFail.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.isLoading = true;
            draft.siteInitLoading = false;
        }),

    [viewFilterRoleDataSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.roleFilterData = action.payload;
        }),

    [validateEmailSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.validEmail = action.payload;
        }),
    [getUserDetailsKeyclockInit.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.isUserLoading = true;
        }),
    [getUserDetailsKeyclockInitSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.userDetailsKeyclock = action.payload;
            draft.isUserLoading = false;
        }),
    [viewFilteredSiteDataInit.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.siteFilterLoading = true;
        }),
    [viewFilteredSiteDataSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.siteFilterData = action.payload;
            draft.siteFilterLoading = false;
        }),
    [viewFilteredSiteDataFail.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.siteFilterLoading = false;
        }),
    [viewFilteredRoleDataInit.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.roleFilterLoading = true;
        }),
    [viewFilteredRoleDataSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            draft.roleFilterData = action.payload;
            draft.roleFilterLoading = false;
        }),
    [viewFilteredRoleDataFail.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.roleFilterLoading = false;
        }),
    [passwordPoliciesSuccess.toString()]: (state, action) =>
        produce(state, (draft: IUsersState) => {
            draft.passwordPolicies = action.payload;
        }),
    [getRemovePictureInit.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.userRemovePictureStatus = UsersStatus.Initial;
        }),
    [getRemovePictureSuccess.toString()]: (state, action) =>
        produce(state, (draft: any) => {
            if (action.payload.ResponseEnvelope?.ValidationCollection) {
                draft.userRemovePictureStatus = UsersStatus.Failed;
            } else {
                draft.userRemovePictureStatus = UsersStatus.Success;
                draft.removePictureStatus = action.payload.PayLoad.message;
            }
        }),
    [getRemovePictureFail.toString()]: (state) =>
        produce(state, (draft: any) => {
            draft.userRemovePictureStatus = UsersStatus.Failed;
        }),
});
