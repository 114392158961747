import { Dialog, Link, makeStyles, Typography, SvgIcon } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { AppLoader, IconButton } from '@primeroedge/ui-components';
import Axios from 'axios';
import { APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY, API_ADMINISTRATION } from 'control/transport/clients/api-constants';
import { isString } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
    Download
} from "react-feather";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(() => ({
    textColor: {
        color: '#665AD8',
        fontWeight: 500,
        fontSize: '16px',
        margin: '10px 0 0 0',
    },
    version: {
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    tabClass: {
        margin: '10px'
    },
    pdfDocument: {
        width: '100%'
    },
    paperWidthSm: {
        maxWidth: '100% !important'
    }
}));
const Version = () => {
    const url = `${API_ADMINISTRATION}/api/SystemSettings/GetWebVersion`;
    const [version, setVersion] = useState<string>("");
    const [documentLink, setDocumentLink] = useState<string>("");
    const [showDocument, setShowDocument] = useState<boolean>(false);
    const [pdfPages, setPdfPages] = useState<number>(0);
    const [nextPage, setNextPage] = useState<number>(1);
    const classes = useStyles();
    const getVersionInfo = async () => {
        await Axios.get(url, {
            headers: {
                Environment: APIM_ENVIRONMENT,
                "Ocp-Apim-Subscription-Key": APIM_SUBSCRIPTION_KEY,
            },
        }).then((resp) => {
            const { PayLoad } = resp.data;
            const version = isString(PayLoad) ? PayLoad : PayLoad?.version ?? '';
            setVersion(version);
            setDocumentLink(PayLoad?.releaseNotes ?? '');
        }).catch(() => {
            console.error("error getting the version number");
        })
    };

    const onClickVersion = function () {
        setShowDocument(!showDocument);
    }

    const onDocumentLoadSuccess = function ({ numPages }: { numPages: number }) {
        setPdfPages(numPages)
    }

    useEffect(() => {
        getVersionInfo();
    }, []);

    const onDowload = async function () {
        Axios({
            method: 'GET',
            url: documentLink,
            responseType: 'blob',
        }).then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Release Version - ${version}.pdf`);
            link.click();
        }).catch(err => {
            console.log('error', err);
        })
    }

    return (
        <footer>
            <Typography
                align="center"
                className={classes.textColor}
                onClick={onClickVersion}
            >
                <span className={classes.version}>Version {version}</span><span className={classes.tabClass}>|</span><Link href='https://schoolcafek12.com/' target='_blank' underline='none'> About SchoolCafé</Link>
            </Typography>
            {showDocument && (
                <Dialog open={showDocument}
                    onClose={onClickVersion}
                    aria-labelledby="version-documentation"
                    classes={{
                        paperWidthSm: classes.paperWidthSm
                    }}>
                    <IconButton id="pdf-download" type="button" onClick={onDowload} style={{ position: 'absolute', top: 0, right: 0, zIndex: 20 }}>
                        <SvgIcon fontSize="small">
                            <Download />
                        </SvgIcon>
                    </IconButton>
                    <div style={{ height: '90%', overflowY: 'auto', position: 'relative' }}>
                        <Document file={{ url: documentLink }}
                            onLoadSuccess={onDocumentLoadSuccess}
                            className={classes.pdfDocument}
                            loading={<AppLoader />}
                            >
                            <Page pageNumber={nextPage}></Page>
                        </Document>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Pagination count={pdfPages} shape="rounded" onChange={function (event: any, newPage: number) {
                            event.preventDefault();
                            setNextPage(newPage);
                        }} />
                    </div>
                </Dialog>
            )}
        </footer>
    )

}

export default Version;