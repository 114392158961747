import { createAction } from "@reduxjs/toolkit";

export const SelectedUser = createAction("SELECTED_USER", (data: any) => ({
  payload: data,
}));
export const resetSelectedUsers = createAction("RESET_SELECTED_USERS");

export const AddAll = createAction("ADD_ALL", (data: any[]) => ({
  payload: data,
}));

export const updateUsers = createAction("UPDATE_USERS", (data: any[]) => ({
  payload: data,
}));

export const RemoveAll = createAction("REMOVE_ALL");

export const RemoveUser = createAction("REMOVE_USER", (data: any) => ({
  payload: data,
}));
export const setGlobalMessage = createAction("SET_GLOBAL_MESSAGE", (data: boolean) => ({
  payload: data,
}));
export const setDismissedMessages = createAction("SET_DISMISSED_GLOBAL_MESSAGE", (data: string[]) => ({
  payload: data,
}));

