import React from 'react';

import { Menu, MenuItem } from '@material-ui/core';
import {
    Button,
    DownloadIcon,
    ExcelIcon,
    IconButton,
    // PdfIcon
} from '@primeroedge/ui-components';
import useDownloadReport from './hooks/useDownloadReport';

const DownloadReport = function (customReportName: string, moduleName: string, sqlQuery: string) {
    const {
        dropdownRef,
        onDropdownClick,
        onDropdownClose,
        callDownload
    } = useDownloadReport(customReportName, moduleName, sqlQuery);
    return (
        <>
            <IconButton id='download-report'
                onClick={onDropdownClick}>
                <DownloadIcon />
            </IconButton>
            <Menu
                id="download-report-options-menu"
                anchorEl={dropdownRef}
                keepMounted
                open={Boolean(dropdownRef)}
                onClose={onDropdownClose}
            >
                <MenuItem onClick={() => { }}>
                    <Button variant='text'
                        id='download-report-excl'
                        style={{ width: '105px' }}
                        label={'EXCEL'}
                        startIcon={<ExcelIcon />}
                        onClick={callDownload(1)} />
                </MenuItem>
            </Menu>
        </>
    );
}

export default DownloadReport;