import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    Input,
} from '@material-ui/core';
import { IRecipientUser } from 'types/mail';
import useMailWindowStyles from './useMailWindowStyles';
import DisplayEmailSelection from './DisplayEmailSelection';
import useMailWindowEmailList from './hooks/useMailWindowEmailList';
import QuillEditor from 'view/pages/mail/MailView/QuillEditor';

const MailSendList = function () {
    const classes = useMailWindowStyles();

    const {
        to,
        subject,
        inputRef,
        list,
        isLoading,
        showList,
        noUsersMessage,
        userSelect,
        messageBody,
        buttonDisable,
        emailInputWidth,
        isSendingEmail,
        senderListRef,
        senderListContainerRef,
        handleToChange,
        handleSubjectChange,
        selectEmailToSend,
        removeSelectedUser,
        handleChange,
        handleSend,
    } = useMailWindowEmailList();

    return (
        <>
            <Box p={2}>
                <div id="sender-list" ref={senderListContainerRef} className={classes.senderList}>
                    <span className={classes.toText} >To: </span>
                    <div id="selected-emails-list" ref={senderListRef}>
                        {userSelect.length > 0 && userSelect.map((user: IRecipientUser) => (
                            <Chip
                                key={user.recipientEmail}
                                className={classes.chipEmail}
                                id={`email-selected-${user.recipientEmail}`}
                                avatar={(
                                    <Avatar>
                                        {user.recipientFirstName.charAt(0).toUpperCase()}{user.recipientLastName.charAt(0).toUpperCase()}
                                    </Avatar>
                                )}
                                label={`${user.recipientFirstName} ${user.recipientLastName}`}
                                onDelete={removeSelectedUser.bind(null, user)}
                            />
                        ))}
                    </div>
                    <Input
                        type="text"
                        className={classes.inputAuto}
                        disableUnderline={true}
                        onChange={handleToChange}
                        value={to}
                        inputRef={inputRef}
                        id="search"
                        name="search"
                        title="search"
                        inputProps={{
                            "aria-label": "Search",
                            style: { width: `${emailInputWidth}px` }
                        }}
                    />
                </div>
                <DisplayEmailSelection isLoading={isLoading}
                    showList={showList}
                    list={list}
                    selectEmailToSend={selectEmailToSend}
                    noUsersMessage={noUsersMessage} />
                <Input
                    className={classes.input}
                    disableUnderline={false}
                    placeholder="Subject"
                    onChange={handleSubjectChange}
                    value={subject}
                    id="subject"
                    name="subject"
                    title="subject"
                    inputProps={{
                        "aria-label": "Subject",
                        style: { height: '35px' }
                    }}
                />
            </Box>
            <Divider />
            <QuillEditor
                className={classes.editor}
                onChange={handleChange}
                placeholder="Leave a message"
                value={messageBody}
                
                modules={{
                    toolbar: [
                        [{ 'header': [1, 2, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }]
                    ],
                }}
                formats={[
                    'header',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                ]}
            />
            <Divider />
            <Box
                display="flex"
                alignItems="center"
                py={1}
                px={2}
            >
                <Button
                    type="button"
                    color="secondary"
                    variant="contained"
                    disabled={buttonDisable || isSendingEmail}
                    onClick={handleSend}
                    className={classes.action}
                >
                    {isSendingEmail && (<CircularProgress size={28} style={{ marginTop: "4px" }} />)}
                    <span>Send</span>
                </Button>
            </Box>
        </>
    );
}

export default MailSendList;