import { createAction } from "@reduxjs/toolkit";
export const getFavoritesInit = createAction("GET_FAVORITES_INIT", (data) => ({
    payload: data,
  }));
  
  export const getFavoritesSuccess = createAction("GET_FAVORITES_SUCCESS", (data) => ({
    payload: data,
  }));
  
  export const getFavoritesFail = createAction("GET_FAVORITES_FAIL");
export const deleteFavoriteInit = createAction("DELETE_FAVORITES_INIT",(data) => ({
  payload: data,
}));
export const deleteFavoriteSuccess = createAction("DELETE_FAVORITES_SUCCESS", (data) => ({
  payload: data,
}));
export const deleteFavoriteFail = createAction("DELETE_FAVORITES_FAIL");
export const updateFavoriteInit = createAction("UPDATE_FAVORITES_INIT",(data) => ({
  payload: data,
}));
export const updateFavoriteSuccess = createAction("UPDATE_FAVORITES_SUCCESS", (data) => ({
  payload: data,
}));
export const updateFavoriteFail = createAction("UPDATE_FAVORITES_FAIL");

export const updateFavoritesOrderInit = createAction("UPDATE_FAVORITES_ORDER_INIT",(data) => ({
  payload: data,
}));
export const updateFavoritesOrderSuccess = createAction("UPDATE_FAVORITES_ORDER_SUCCESS", (data) => ({
  payload: data,
}));
export const updateFavoritesOrderFail = createAction("UPDATE_FAVORITES_ORDER_FAIL");

export const getRecentsInit = createAction("GET_RECENTS_INIT", (data) => ({
  payload: data,
}));

export const getRecentsSuccess = createAction("GET_RECENTS_SUCCESS", (data) => ({
  payload: data,
}));

export const getRecentsFail = createAction("GET_RECENTS_FAIL");