import { createAction } from "@reduxjs/toolkit";

export const AuthLogin = createAction("AUTH_LOGIN");
export const AuthLogout = createAction("AUTH_LOGOUT");

export const userAuthLogoutInit = createAction("USER_AUTH_LOGOUT_INIT");
export const userAuthLogoutDone = createAction("USER_AUTH_LOGOUT_DONE");

export const userAuthSuccess = createAction(
    "USER_AUTH_SUCCESS",
    (accessToken: string) => ({
        payload: {
            accessToken,
        },
    }),
);

export const userAuthFailInvalidUser = createAction(
    "USER_AUTH_FAIL_INVALID_USER",
);

export const userAuthFailUnknownError = createAction(
    "USER_AUTH_FAIL_UNKNOWN_ERROR",
);

export const userAuthFailInvalidFormat = createAction(
    "USER_AUTH_FAIL_INVALID_FORMAT",
);
