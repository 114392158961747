import { Maybe } from "lib/maybe";
import logger from "util/logger";
import administrationAPIClient from "./clients/administration-api.client";
import eligibilityAPIClient from "./clients/eligibility-api.client";
import posAPIClient from "./clients/pos-api.client";

interface ISiteByRegion {
    siteId: number;
    siteName: string;
    contactPerson: string;
    contactPersonPosition: number | null;
    contactPhone: number | null;
    addressId: number | null;
    siteDescription: string;
    parentSiteId: number | null;
    deliveryCost: number | null;
    storageCost: null;
    regionId: number | null;
    siteTypeId: number | null;
    numLine: number | null;
    siteCode: string;
    siteTypeDescription: string;
    isActive: string;
    isFeedingSite: string;
    includeForReimbursement: string;
    stateSiteCode: null;
}

interface ISiteByRegionResponse {
    data: Maybe<ISiteByRegion>;
}

class SiteTransportLayer {
    public async getSiteList (reqData: any): Promise<ISiteByRegionResponse> {
        const activeDistrict = localStorage.getItem("activeDistrict");
        let selectedDistrict: any;
        if (activeDistrict) {
            selectedDistrict = JSON.parse(activeDistrict);
            const regionId = selectedDistrict?.attributes?.regionId ? selectedDistrict?.attributes?.regionId[0] : 0;
            try {
                const response = await administrationAPIClient({
                    method: "POST",
                    url: `api/Site/userId/0/regionId/${Number(regionId)}`,
                    data: reqData.payload,
                });
                // DeStructuring the data [ES6 feature]
                const { data }: any = response;

                if (data) {
                    return data;
                } else {
                    return Promise.reject("Failed to get Sites data");
                }
            } catch (error) {
                logger.info(error);
                throw error;
            }
        } else {
            return Promise.reject("No district selected");
        }
    }

    public async addSite (payload: any): Promise<any> {
        const dynamicPayload = payload.payload.siteData;
        const activeDistrict = localStorage.getItem("activeDistrict");
        let selectedDistrict: any;

        if (activeDistrict) {
            selectedDistrict = JSON.parse(activeDistrict);

            try {
                const response = await administrationAPIClient({
                    method: "POST",
                    url: `api/Site?parentGroupGuid=${selectedDistrict?.groupGuid}`,
                    data: dynamicPayload,
                });

                const { data }: any = response;

                return data;
            } catch (error) {
                throw error;
            }
        }
    }

    public async editSite (payload: any): Promise<any> {
        const dynamicPayload = payload.payload.siteData;
        const activeDistrict = localStorage.getItem("activeDistrict");
        let selectedDistrict: any;

        if (activeDistrict) {
            selectedDistrict = JSON.parse(activeDistrict);

            try {
                const response = await administrationAPIClient({
                    method: "PATCH",
                    url: `api/Site/Site`,
                    data: {
                        siteId: dynamicPayload.siteId,
                        siteName: dynamicPayload.siteName,
                        contactPerson: dynamicPayload.contactPerson,
                        contactPersonPosition: dynamicPayload.contactPersonPosition,
                        contactPhone: dynamicPayload.contactPhone,
                        addressId: null,
                        siteDescription: dynamicPayload.siteDescription,
                        parentSiteId: null,
                        deliveryCost: null,
                        storageCost: null,
                        isActive:
                            dynamicPayload.siteStatus === "true" ? true : false,
                        siteCode: "001",
                        numLines: 1,
                        orderingGroupId: null,
                        menuGroupId: null,
                        menuGroupEffectiveDate: null,
                        canOrderBrokenUnits: false,
                        regionId: selectedDistrict?.attributes?.regionId ? Number(selectedDistrict?.attributes?.regionId[0]) : 0,
                        siteTypeId: 206,
                        snackReimbursementCategoryId: null,
                        breakFastReimbursementCategoryId: null,
                        isFeedingSite: true,
                        includeForReimbursement: false,
                        connectToServer: true,
                        siteCharacteristics: 0,
                        primeroSchoolId: "a2648bc2-b4da-4cdb-acb3-b2be6abeb2d2",
                        subscriptionTerminalId: 0,
                        updateDate: "2020-02-11T17:44:37.153",
                        districtRegionId: 2,
                        addressLine1: dynamicPayload.addressLine1,
                        addressLine2: dynamicPayload.addressLine2,
                        city: dynamicPayload.city,
                        zipCode: dynamicPayload.zipCode,
                        stateId: dynamicPayload.stateId,
                        phoneNumber: null,
                        showOtherSiteMsg: null,
                        checkOtherSiteSecMeal: null,
                        foodPreparationSiteId: null,
                        includeForVerification: true,
                        useCustomMealType: false,
                        timeClockLogInType: null,
                        synchronizeDate: "2020-02-11T09:44:37.153",
                        canPosusePrincipalAccount: null,
                        usdasiteTypeId: null,
                        cepstartDate: null,
                        cependDate: null,
                        stateSiteCode: null,
                        importKey: null,
                        sendSiteToSchoolCafe: false,
                        orderComments: null,
                        characteristic: null,
                        characteristicDetail: null,
                        addedBy: null,
                        addedOn: "2020-02-11T09:44:37.153",
                        modifiedBy: null,
                        modifiedOn: null,
                        cacfpfacility: null,
                        cacfppricing: null,
                        enableBin: false,
                        nslpexportCode: null,
                        enableOrdering: true,
                        commaSeparatedDeliveryLocations: null,
                    },
                });

                const { data }: any = response;

                if (data) {
                    return Promise.resolve({
                        ...data,
                    });
                } else {
                    return Promise.reject("Failed to get Sites data");
                }
            } catch (error) {
                logger.info(error);

                throw error;
            }
        }
    }

    public async getAreaNames (): Promise<any> {
        try {
            const url = "api/Region/ByLoginRealm";
            const response = await administrationAPIClient({
                method: "GET",
                url,
            });

            return Promise.resolve(response.data.PayLoad);
        } catch (error) {
            logger.info(error);

            throw (error);
        }
    }

    public async getSiteTypesByRegion (payload: any): Promise<any> {
        try {
            const response = await administrationAPIClient({
                method: "POST",
                url: "api/SiteType/RegionSiteTypes",
                data: {
                    regionId: payload,
                    onlySiteTypes: true,
                },
            });
            // DeStructuring the data [ES6 feature]
            const { data }: any = response;

            if (data) {
                return Promise.resolve(data.PayLoad);
            } else {
                return Promise.reject("Failed to get Sites data");
            }
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async getAllStates (): Promise<any> {
        try {
            const url = "api/District/States";
            const response = await administrationAPIClient({
                method: "GET",
                url,
            });

            return Promise.resolve(response.data.PayLoad);
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async getCharacteristics (): Promise<any> {
        try {
            const url = "api/Site/Characteristics";
            const response = await administrationAPIClient({
                method: "GET",
                url,
            });

            return Promise.resolve(response.data.PayLoad);
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async getSiteBySiteID (payload: string): Promise<any> {
        try {
            const url = `api/Site/${payload}`;
            const response = await administrationAPIClient({
                method: "GET",
                url,
            });

            return Promise.resolve(response.data.PayLoad);
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async getExpSettingsBySiteID (payload: string): Promise<any> {
        try {
            const url = `/api/POSConfig/Site/${payload}`;
            const response = await administrationAPIClient({
                method: "GET",
                url,
            });

            return Promise.resolve(response.data.PayLoad);
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async updateSiteGeneralInfo (payload: any): Promise<any> {
        try {
            const response = await administrationAPIClient({
                method: "PATCH",
                url: `api/Site/${payload.generalInfo.siteId}/SiteInfo?groupGuid=${payload.groupGuid}`,
                data: payload.generalInfo,
            });

            const { data }: any = response;

            return data;
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async updateSiteCorrAddr (payload: any): Promise<any> {
        try {
            const response = await administrationAPIClient({
                method: "PATCH",
                url: `api/Site/${payload.siteId}/SiteAddress`,
                data: payload,
            });

            const { data }: any = response;

            return data;
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async getMealTypesByRegion (payload: any): Promise<any> {
        try {
            const apiUrl = `api/Site/${payload.regionId}/MealType/${payload.siteTypeId}?siteId=${payload.siteId}`;
            const response = await administrationAPIClient({
                method: "GET",
                url: apiUrl,
                data: {
                    regionId: payload,
                    onlySiteTypes: true,
                },
            });
            // DeStructuring the data [ES6 feature]
            const { data }: any = response;

            if (data) {
                return Promise.resolve(data.PayLoad);

            } else {
                return Promise.reject("Failed to get Meal data");
            }
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async getSitesConfigurationTypesByRegion (payload: any): Promise<any> {
        try {
            const apiUrl = `api/Site/Configuration/${payload.regionId}`;
            const response = await administrationAPIClient({
                method: "GET",
                url: apiUrl,
                data: {
                    regionId: payload,
                },
            });
            // DeStructuring the data [ES6 feature]
            const { data }: any = response;

            if (data) {
                return Promise.resolve(data.PayLoad);

            } else {
                return Promise.reject("Failed to get Site Conf data");
            }
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async updateSiteMealTypeRequest (payload: any): Promise<any> {
        try {
            const apiUrl = `api/Site/${payload.regionId}/MealType/${payload.siteTypeId}?siteId=${payload.siteId}`;
            const response = await administrationAPIClient({
                method: "POST",
                url: apiUrl,
                data: payload,
            });

            const { data }: any = response;

            return data;
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async updateFeedingSiteRequest (payload: any): Promise<any> {
        try {
            const apiUrl = `api/Site/Configuration/${payload.regionId}`;
            const response = await administrationAPIClient({
                method: "PATCH",
                url: apiUrl,
                data: payload,
            });

            const { data }: any = response;

            return data;
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }
    public async getAcademicYears (): Promise<any> {
        try {
            const url = "api/Site/AcademicYears";
            const response = await administrationAPIClient({
                method: "GET",
                url,
            });

            return Promise.resolve(response.data.PayLoad);
        } catch (error) {
            logger.info(error);
            throw error;
        }
    }

    public async getCurrentAcademicYear (): Promise<any> {
        try {
            const url = "api/DDLists/CurrentAcademicYear";
            const response = await eligibilityAPIClient({
                method: "GET",
                url,
            });

            return Promise.resolve(response.data.PayLoad);
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async getProgramsByAcademicYear (payload: any): Promise<any> {
        try {
            const apiUrl = `api/Reimbursements/${payload}/Programs`;
            const response = await posAPIClient({
                method: "GET",
                url: apiUrl,
                data: {
                    academicYearId: payload,
                },
            });
            const { data }: any = response;

            if (data) {
                return Promise.resolve(data.PayLoad);

            } else {
                return Promise.reject("Failed to get Program Conf data");
            }
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async updateProgramRequest (payload: any): Promise<any> {
        try {
            const apiUrl = `api/Reimbursements/${payload.academicYearId}/Programs`;
            const response = await posAPIClient({
                method: "PATCH",
                url: apiUrl,
                data: {
                    program: payload.program,
                    sites: payload.sites,
                },
            });

            const { data }: any = response;

            return data;
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async getProgramSitesByAcademicYear (yearId: any, programId: any): Promise<any> {
        const yearID = typeof yearId === "object" ? yearId.yearId : yearId;
        const programID = typeof programId === "object" ? programId.programId : programId;
        try {
            const apiUrl = `api/Reimbursements/${yearID}/Programs/${programID}/Sites`;
            const response = await posAPIClient({
                method: "GET",
                url: apiUrl,
                data: {
                    academicYearId: yearId,
                    programId,
                },
            });
            const { data }: any = response;
            if (data) {
                return Promise.resolve(data.PayLoad);

            } else {
                return Promise.reject("Failed to get Program Site Conf data");
            }
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }


    public async updateProgramSiteRequest (payload: any, yearId: any, programId: any): Promise<any> {
        try {
            const apiUrl = `api/Reimbursements/${yearId.yearId}/Programs/${programId.programId}/Sites`;
            const response = await posAPIClient({
                method: "PATCH",
                url: apiUrl,
                data: payload.siteData,
            });

            const { data }: any = response;

            return data;
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async copyProgramsRequest (payload: any): Promise<any> {
        try {
            const response = await posAPIClient({
                method: "POST",
                url: `/api/Reimbursements/${payload.fromAcademicYearId}/CopyPrograms/${payload.toAcademicYearId}`,
                data: payload,
            });
            const { data }: any = response;
            if (data) {
                return Promise.resolve(data);

            } else {
                return Promise.reject("Failed to Copy Programs");
            }
        } catch (error) {
            logger.info(error);

            throw error;
        }
    }

    public async getSiteCharacterstics (siteId: any): Promise<any> {
        try {
            const url = `/api/Site/${siteId}/Characteristics`;
            const response = await administrationAPIClient({
                method: "GET",
                url,
            });

            return Promise.resolve(response.data.PayLoad);
        } catch (error) {
            logger.info(error);
            throw error;
        }
    }

    public async updateSiteCharacterstics (payload: any): Promise<any> {
        try {
            const url = "api/Site/SaveSiteCharacteristics";
            const response = await administrationAPIClient({
                method: "POST",
                url,
                data: payload,
            });

            const { data }: any = response;

            return data;
        } catch (error) {
            logger.info(error);
            throw error;
        }
    }

    public async getParentSites (siteId: any): Promise<any> {
        try {
            const response = await administrationAPIClient({
                url: `api/Site/Parents?siteId=${siteId}`,
                method: "POST",
            });

            const { data }: any = response;

            return data;
        } catch (error) {
            logger.info(error);
            throw error;
        }
    }
}

export default new SiteTransportLayer();
