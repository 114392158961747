import { makeStyles } from "@material-ui/core";
import { ITheme } from "@primeroedge/ui-components";

export const useStyles = makeStyles((theme: ITheme) => ({
    paperRoot: {
        padding: 12,
        backgroundColor: "#2F4858",
        height: "50px",
    },
    container: {
        [theme.breakpoints.up(761)]: {
            padding: "3rem 8rem",
        },
        [theme.breakpoints.down(760)]: {
            padding: "3rem 4rem",
        },
    },
    gridRowSpacing: {
        margin: "1rem 0",
    },
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        padding: theme.spacing(3),
    },
    image: {
        maxWidth: "100%",
        width: 560,
        maxHeight: 300,
        height: "auto",
    },
    workspaceContainer: {
        marginTop: "50px",
        paddingLeft: "0px",
        paddingRight: "0px",
        marginLeft: "130px",
        marginRight: "130px",
    },
    tasks: {
        width: "100%",
        height: "max-content",
    },
    task: {
        marginBottom: 8,
    },
    modules: {},
    heading: {
        color: "#697C87",
        fontFamily: "Roboto",
        fontWeight: 500,
        fontSize: "0.9rem",
        marginTop: "2rem",
    },
    cardTitle: {
        fontFamily: "Roboto",
        color: "#697C87",
        fontSize: 11,
        fontWeight: 500,
        textAlign: "center",
        marginTop: "0.4rem",
    },
    cardWidth: {
        marginTop: "1rem",
        width: "7.5rem",
        height: "7.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        boxShadow: theme.shadows[4],
    },
    messages: {
        width: "100%",
        marginRight: 8,
        height: "max-content",
    },
    message: {
        marginBottom: 8,
    },
    icon: {
        display: "flex",
        justifyContent: "center",
    },
    pageNavigation: {
        cursor: "pointer",
        outline: "0",
    },
    myModuleContainer: {
        display: "flex",
        flexDirection: "row",
        overflowX: "scroll",
        padding: "12px 40px 12px 0",
        // margin: "0 10px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    myModuleElement: {
        // margin: "0 12px",
        marginRight: "28px",
        "&:first-child": {
            marginLeft: "9px",
        },
    },
    modulesFrame: {
        display: "flex",
        alignItems: "center",
    },
    arrowIcons: {
        height: "20px",
        width: "20px",
        color: "white",
        borderRadius: "11px",
        backgroundColor: "#0E509C",
        "&:hover": {
            backgroundColor: "#2F4858",
        },
        marginRight: "20px",
    },
    tabContainer: {
        width: "100%",
        minHeight: "288px",
        display: "flex",
        placeContent: "center",
        placeItems: "center",
    },
    topbar: {
        minHeight: "50px",
        top: "auto",
        position: "absolute",
    },
    taskToggleBtn: {
        fontWeight: 400,
        marginRight: 5,
        border: "none",
        borderRadius: "36px",
        textTransform: "none",
        "&:last-child": {
            marginRight: "8px",
        },
        "&.active": {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            backgroundColor: theme.palette.primary.main,
        },
    },
    msgText: {
        display: "block",
        width: "350px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    msgContent: {
        display: "block",
        width: "350px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        color: "#bebebed4",
    },
    msgList: {
        display: "block",
        "&:first-child": {},
    },
    bannerPaper: {
        // backgroundColor: "#0e509c",
        // color: "#FFFFFF",
        fontSize: "12px",
    },
    msgBody: {
        display: "flex",
        justifyContent: "space-between",
        padding: "3px 40px",
    },
    msgAction: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    taskAction: {
        display: "flex",
        justifyContent: "space-between",
        width: "180px",
    },
    broadcastMessages: {
        display: "flex",
        justifyContent: "space-between",
        padding: "13px 40px",
        fontWeight: "bold",
        "&:first-child": {
            paddingTop: "24px",
        },
    },
    msgLength: {
        display: "block",
        width: "350px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    broadcastMessagesAction: {
        display: "flex",
        justifyContent: "space-between",
        padding: "20px",
    },
    taskBody: {
        padding: "6px 0",
    },
    taskCardAction: {
        display: "flex",
        justifyContent: "space-between",
        padding: "12px",
    },
    taskDue: {
        color: "#EB0000",
    },
    taskNoDue: {
        color: theme.palette.common.black,
    },
    msgContentHeight: {
        height: "288px",
    },
    bannerLink: {
        textDecoration: "underline",
        cursor: "pointer",
    },
    actionBanner: {
        display: "flex",
        position: "relative",
        paddingBottom: "10px",
    },
    link: {
        color: "#FFFFFF",
        textDecoration: "underline",
        textTransform: "none",
    },
    actionBtn: {
        right: "0",
        position: "absolute",
        textDecoration: "underline",
        cursor: "pointer",
    },
    messageAction: {
        width: "96px",
    },
    msgDate: {
        fontSize: "13px",
        paddingRight: "10px",
    },
    textTf: {
        textTransform: "none",
    },
    messagePanel: {
        paddingTop: "112px",
        overflowX: "hidden",
        justifyContent: "space-around",
        display: "flex",
    },
    loader: {
        textAlign: "center",
        overflow: "hidden",
    },
    pageAlertTxt: {
        paddingTop: "0px !important",
        textAlign: "center",
        color: "#546e7a",
    },
    favoriteModuleName:{
        fontSize: "13px", 
        marginRight: '200px', 
        background:"#5DE6B2",
        padding: "5px 20px 5px 20px",
        fontWeight:"bold",
        display: "inline-block", 
        width: "125px", 
        textAlign: "center",
    }
}));
