import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import {
    Button,
    Card,
    Checkbox,
    CheckCircleFilledIcon,
    CheckCircleOutlineIcon,
    ChevronRightIcon,
    FlagIcon,
    useProfile,
    Tooltip,
} from "@primeroedge/ui-components";
import { passwordPoliciesInit, viewFilterSiteDataInit, viewOrganizationInit, viewRegionsAndSites, viewRolesInit } from "control/actions";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Onboarding from "../welcome-page";

const useStyles = makeStyles(() => ({
    root: {
        margin: "120px auto",
        width: "500px",
    },
    header: {
        color: "#7266DB",
    },
    fw: {
        fontWeight: "normal",
    },
    centerAlign: {
        textAlign: "center",
        padding: "10px",
    },
    pBottom: {
        paddingBottom: "20px",
    },
    mAuto: {
        margin: "auto",
    },
    date: {
        width: "100%",
    },
    selectAll: {
        padding: "10px",
    },
    continueButton: {
        width: "100%",
        marginTop: "20px",
    },
    borderNth: {
        borderBottom: "1px solid #CCCCCC",
        "&:last-child": {
            borderBottom: "0px",
        },
    },
}));

const DistrictSelction = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { user }: any = useProfile();
    const [userGroups, setUserGroups] = useState([]);
    const [selectDistrict, setSelectDistrict] = useState<any>();
    const [districtSelected, setDistrictSelected] = useState<boolean>(false);
    const [welcomeScreen, setWelcomeScreen] = useState<boolean>(false);
    const [districtScreen, setDistrictScreen] = useState<boolean>(true);

    useEffect(() => {
        dispatch(passwordPoliciesInit());
        dispatch(viewRolesInit());
        dispatch(viewOrganizationInit());
    }, []);

    useEffect(() => {
        if (selectDistrict !== undefined) {
            dispatch(viewRegionsAndSites(selectDistrict.groupGuid));
            dispatch(viewFilterSiteDataInit(selectDistrict.groupGuid));
        }
    }, [selectDistrict]);

    useEffect(() => {
        if (user !== null && user.Roles && user.Roles.includes("Onboarding")) {
            let tempDistObj: any = [];
            user.Groups.map((district: any) => {
                let tempDist = { ...district };
                tempDist.isSelected = false;
                tempDistObj.push(tempDist);
            });
            setUserGroups(tempDistObj);
        }
    }, [user]);

    const triggerNavigation = () => {
        if (selectDistrict.attributes.onboardingCompleted[0] !== "False") {
            window.location.href = `/system/Onboarding`;
        } else {
            setWelcomeScreen(true);
            setDistrictScreen(false);
        }
    };

    const updateDistricts = (district: any, checked: boolean) => {
        let tempDistObj: any = [];
        userGroups.map((dist: any) => {
            if (dist.groupGuid === district.groupGuid) {
                let tempDist = { ...dist };
                tempDist.isSelected = checked;
                tempDistObj.push(tempDist);
            } else if (dist.groupGuid !== district.groupGuid) {
                let tempDistClear = { ...dist };
                tempDistClear.isSelected = false;
                tempDistObj.push(tempDistClear);
            }
        });
        if(checked === false) {
            setDistrictSelected(false);
        } else if(checked === true) {
            setDistrictSelected(true);
        }
        setUserGroups(tempDistObj);
    }

    return (
        <>
            {districtScreen &&
                <div className={classes.root}>
                    <Typography variant="h3" className={`${classes.centerAlign} ${classes.header}`}>
                        Welcome to SchoolCafé!
                    </Typography>
                    <Typography variant="h4" className={`${classes.centerAlign} ${classes.pBottom} ${classes.fw}`}>
                        Select a district below to get started
                    </Typography>
                    <Paper variant="outlined">
                        <Card
                            id="system"
                            title=""
                            content={(
                                <>
                                    <div style={{}}>
                                        {userGroups.map((district: any) => {
                                            return (
                                                <Grid item={true} xs={12} style={{ padding: "25px 10px" }} className={classes.borderNth}>
                                                    <Grid container={true} spacing={1}>
                                                        {district.attributes.onboardingCompleted[0] !== "False" ? (
                                                            <Grid item={true} xs={2} style={{ margin: "auto" }}>
                                                                <Tooltip title="Onboarding is complete">
                                                                    <FlagIcon color="green" />
                                                                </Tooltip>
                                                            </Grid>
                                                        ) : (
                                                            <Grid item={true} xs={2} style={{ margin: "auto" }}>
                                                                <Tooltip title="Onboarding is incomplete">
                                                                    <FlagIcon color="red" />
                                                                </Tooltip>
                                                            </Grid>
                                                        )
                                                        }
                                                        <Grid item={true} xs={8}>
                                                            <div
                                                                id={district.name}
                                                                onClick={(e: any) => {
                                                                    if (e.currentTarget.id === district.name) {
                                                                        setSelectDistrict(district);
                                                                    }
                                                                    e.preventDefault();
                                                                }}
                                                            >
                                                                <p style={{ fontWeight: "bold", paddingBottom: "5px" }}>{district.name}</p>
                                                                {district.attributes.onboardingCompleted[0] === "False" ? (
                                                                    <p>This school district is ready for onboarding, please select to start onboarding</p>
                                                                ) : (
                                                                    <p>This district has been already onboarded. Please select to go to dashboard</p>
                                                                )}
                                                            </div>
                                                        </Grid>
                                                        <Grid item={true} xs={2} style={{ margin: "auto" }}>
                                                            <Checkbox
                                                                color="default"
                                                                id={district.groupGuid}
                                                                key={district.groupGuid}
                                                                onChange={(e: any) => {
                                                                    if (e.currentTarget.id === district.groupGuid) {
                                                                        setSelectDistrict(district);
                                                                        updateDistricts(district, e.target.checked);
                                                                    }
                                                                    e.preventDefault();
                                                                }}
                                                                onFocusVisible={function noRefCheck() { }}
                                                                size="medium"
                                                                checked={district.isSelected}
                                                                labelPlacement="start"
                                                                checkedIcon={<CheckCircleFilledIcon color="green" />}
                                                                icon={<CheckCircleOutlineIcon color="grey" />}
                                                                edge="end"
                                                                className={classes.mAuto}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })
                                        }
                                    </div>
                                </>
                            )
                            }
                        />
                    </Paper >
                    <Button
                        buttonGroupColor="secondary"
                        buttonGroupVariant="text"
                        color="primary"
                        endIcon={<ChevronRightIcon />}
                        id="primaryButton"
                        label="Continue"
                        name="Button"
                        onClick={(e) => {
                            e.preventDefault();
                            triggerNavigation();
                        }}
                        type="button"
                        useEllipsis={true}
                        variant="default"
                        className={classes.continueButton}
                        disabled={!districtSelected}
                    />
                </div >
            }
            {
                welcomeScreen &&
                <Onboarding />
            }
        </>
    );
};

export default DistrictSelction;
