import { Box, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { styles } from './security';
import { Accordion, AccordionGroup, AccountStatus, AppInfoContext, Button, ButtonGroup, InfoIcon, Notification, NotificationWithDialog, Tooltip, UserCard } from '@primeroedge/ui-components';
import { IRegionLanguage, IUserProfile, WorkSpaceDetailsEntity, checkProfileUpdateStatus } from 'model/entity';
import { getRegionLanguages } from './profile.utils';
import { isNil } from 'lodash';
import { resetProfileUpdateStatus, saveProfileInfoAction,  } from 'control/actions';
import { useDispatch, useSelector } from 'react-redux';
import MyPreferenceNotifications from './MyPreferenceNotifications';
import MyPreferenceSubscriptions from './MyPreferenceSubscriptions';

interface IMyPreferences {
    editableProfile: IUserProfile;
    setTabsBool: any;
    canEdit: any;
    setActiveTab: any;
}

const MyPreferences = ({
    editableProfile,
    setTabsBool,
    canEdit,
    setActiveTab,
}: IMyPreferences) => {
    const classes = styles();
    const notificationsRef = useRef<{getValues: () => {
        showBanner?: boolean;
        bannerAudio?: boolean;
        bannerLocation?: string;
        bannerDuration?: string | number;
    }} | null>(null);
    const subscriptionsRef = useRef<{
        getValues: () => any
    } | null>(null);
    const { setBannerSettings } = useContext(AppInfoContext);

    const isBroadcastUser = useSelector(WorkSpaceDetailsEntity.isBroadcastUser);
    const [cancelCOnfirmation, setCancelConfirmation] = useState<boolean>(false);
    const [selectedLanguage, setSelectedLanguage] = useState<string>("");
    const [languages, setLanguages] = useState<IRegionLanguage[]>([]);
    const [selectedTheme, setSelectedTheme] = useState<String | null>("");
    const updateStatus = useSelector(checkProfileUpdateStatus);
    const [showAlert, canShowAlert] = useState<"success" | "error" | undefined>(undefined);
    const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
    const [selectedNavigationOption, setSelectedNavigationOption] = useState<string | undefined>("");
    const [dashboardV2, setDashboardV2] = useState<boolean>(false);
    const [showAdditionalPreferences] = useState<boolean>(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (languages.length === 0) {
            getRegionLanguages()
                .then((data: IRegionLanguage[]) => {
                    setLanguages(data);
                })
                .catch((error) => {
                    console.error("Error fetching region languages:", error);
                });
        }
    }, []);
    useEffect(() => {
        if (editableProfile) {
            setSelectedTheme(editableProfile.theme);
            setSelectedNavigationOption(editableProfile?.navigateOption);
            if (!isNil(editableProfile.languagepreference)) {
                const defaultLang = languages.find((data) => data.name === editableProfile?.languagepreference)?.name;
                if (defaultLang) {
                    setSelectedLanguage(defaultLang);
                }
            }
            setDashboardV2(!!editableProfile.dashboardV2)
        }
    }, [languages, editableProfile]);

    useEffect(() => {
        setActiveTab(2);
    }, [dispatch, setActiveTab, canEdit]);

    useEffect(() => {
        if (!isNil(updateStatus)) {
            setDisableSaveButton(false);
            if (updateStatus) {
                canShowAlert(() => "success");
                canEdit(true);
                setActiveTab(2);
            } else {
                canShowAlert(() => "error");
                canEdit(true);
            }
        }
    }, [canEdit, dispatch, updateStatus]);

    //@ts-ignore
    const onLanguageChange = (event: any) => {
        setSelectedLanguage(event.target.value);
    }

    const onNavigationOptionChange = (value: string) => {
        setSelectedNavigationOption(value);
    }


    const handleCancel = () => {
        setCancelConfirmation(true);
    };

    const onCancel = () => {
        setTabsBool(true);
        setCancelConfirmation(false);
        canEdit(false);
        setActiveTab(0);
    };

    const onSave = () => {
        setDisableSaveButton(true);
        try {
            dispatch(saveProfileInfoAction({
                ...editableProfile,
                languagepreference: selectedLanguage,
                theme: selectedTheme,
                navigateOption: selectedNavigationOption,
                dashboardV2: dashboardV2,
                ...(notificationsRef.current?.getValues?.())

            }));
            setBannerSettings({
                audioPing: notificationsRef.current?.getValues?.().bannerAudio ?? false,
                //@ts-ignore
                bannerDuration:
                    notificationsRef.current?.getValues?.()?.bannerDuration ?? 10000,
                bannerLocation: notificationsRef.current?.getValues?.()?.bannerLocation ?? 'Bottom Right',
                showBanner: notificationsRef.current?.getValues?.()?.showBanner ?? false,
            })
            if (dashboardV2) {
                localStorage.setItem('dashboardV2Settings', `${dashboardV2}`)
            }
        } catch (error) {
            canShowAlert(() => "error");
        }
    }

    const onThemeChange = (theme: string) => {
        setSelectedTheme(theme);
    }

    return (
        <>
            <Notification
                color={showAlert === "success" ? "success" : "error"}
                duration={5000}
                message={showAlert === "success" ?
                    "Profile updated successfully! To view any changes to your preferences, please log out and back in." :
                    "Error updating preferences."}
                open={showAlert !== undefined}
                onClose={() => {
                    canShowAlert(() => undefined);
                    dispatch(resetProfileUpdateStatus());
                }}
                variant="filled"
            />

            <Grid container={true} spacing={2}>
                <Grid xs={12} md={4} lg={3} item={true}>
                    <UserCard
                        imageSrc={""}
                        isUserCard={true}
                        name={`${editableProfile.firstName} ${editableProfile.lastName}`}
                        status={
                            editableProfile.userStatusDescription !== "Disabled"
                                ? AccountStatus.Active
                                : AccountStatus.InActive
                        }
                        statusText={
                            editableProfile.userStatusDescription !== "Disabled"
                                ? "Active"
                                : "Disabled"
                        }
                    />
                </Grid>
                <Grid xs={12} md={8} lg={9} item={true}>
                    <AccordionGroup>
                        <Accordion className='accordion-preferences' title="Sites & Users" expanded={true} >
                            <Box style={{ width: '100%', marginTop: 16 }}>
                                <Grid container item spacing={5} style={{ marginBottom: 40 }}>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', padding: '0 20px' }}>
                                        <Typography>Expand Side Navigation</Typography>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: '8px 20px' }}>
                                        <div style={{ width: '100%', }}>
                                            <ButtonGroup
                                                buttons={[
                                                    {
                                                        id: 'on-hover',
                                                        label: 'ON HOVER',
                                                        name: 'on-hover',
                                                        onClick: () => onNavigationOptionChange("ON HOVER")
                                                    },
                                                    {
                                                        id: 'on-click',
                                                        label: 'ON CLICK',
                                                        name: 'on-click',
                                                        onClick: () => onNavigationOptionChange("ON CLICK")
                                                    },
                                                ]}
                                                color="primary"
                                                orientation="horizontal"
                                                selectedIndex={selectedNavigationOption === 'ON HOVER' ? 0 : 1}
                                                title="preferences-buttons"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={5} style={{ marginBottom: 40 }}>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', padding: '0 20px' }}>
                                        <Typography>Language (<b>coming soon!</b>)</Typography>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: '8px 20px' }}>

                                        <Select
                                            id="languages"
                                            value={selectedLanguage}
                                            disabled
                                            variant='outlined'
                                            onChange={onLanguageChange}
                                            inputProps={{
                                                name: "language",
                                                id: "language-select",
                                            }}
                                            style={{ width: '200px', height: 42 }}
                                        >
                                            {languages.map(data => (
                                                <MenuItem value={data.name}>{data.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={5} style={{ marginBottom: 40 }}>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', padding: '0 20px' }}>
                                        <Typography>Theme (<b>coming soon!</b>)</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: '8px 20px' }}>
                                        <div style={{ width: '130%' }}>
                                            <ButtonGroup
                                                buttons={[
                                                    {
                                                        disabled: selectedTheme === 'Default',
                                                        id: 'ligth-theme',
                                                        label: 'LIGHT',
                                                        name: 'light',
                                                        onClick: () => onThemeChange('Perseus'),
                                                    },
                                                    {
                                                        disabled: selectedTheme === 'Perseus',
                                                        id: 'dark-theme',
                                                        label: `DARK`,
                                                        name: 'dark',
                                                        onClick: () => onThemeChange('Default'),
                                                    },

                                                ]}
                                                color="primary"
                                                orientation="horizontal"
                                                selectedIndex={selectedTheme === 'Perseus' ? 0 : 1}
                                                title="theme-buttons"

                                            />
                                        </div>
                                    </Grid>

                                    <Grid container item spacing={5}>
                                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', padding: '0 20px' }}>
                                            <Typography>Use Workspace Dashboard 2.0 <b>(BETA)</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: '8px 20px' }}>
                                            <div style={{ width: '130%', display: 'flex', gap: 8 }}>
                                                <ButtonGroup
                                                    buttons={[
                                                        {
                                                            id: 'on-v2',
                                                            label: 'ON',
                                                            name: 'on-v2',
                                                            onClick: () => setDashboardV2(true),
                                                        },
                                                        {
                                                            id: 'off-v2',
                                                            label: `OFF`,
                                                            name: 'off-v2',
                                                            onClick: () => setDashboardV2(false),
                                                        },

                                                    ]}
                                                    color="primary"
                                                    orientation="horizontal"
                                                    selectedIndex={dashboardV2 ? 0 : 1}
                                                    title="theme-buttons"

                                                />
                                                <Tooltip style={{ display: 'flex', alignItems: 'center' }} title='Upon logging in, you will be redirected to a new Workspace dashboard. This dashboard is currently in *beta* and has some experimental features which will be expanded upon over time'>
                                                    <InfoIcon />
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={5} style={{ marginBottom: 40 }}>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', padding: '0 20px' }}>
                                        <Typography>Is broadcast user</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', padding: '8px 20px' }}>
                                        <Typography><b>{isBroadcastUser ? 'Yes' : 'No'}</b></Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Accordion>
                        {showAdditionalPreferences && (<> <MyPreferenceSubscriptions ref={subscriptionsRef} />
                            <MyPreferenceNotifications ref={notificationsRef} initialNotifications={{
                                audioPing: !!editableProfile.audioPing,
                                bannerDuration: editableProfile.bannerDuration ?? 0,
                                bannerLocation: editableProfile.bannerLocation ?? '',
                                showBanner: !!editableProfile.showBanner
                            }} />
                        </>)}
                    </AccordionGroup>
                    <div className={classes.box}>
                        <Grid
                            xs={12}
                            item={true}
                            classes={{ root: classes.controlsRoot }}
                            style={{ marginTop: 20 }}
                        >
                            <Button
                                id="cancel-profile-edit"
                                color="default"
                                onClick={handleCancel}
                            >
                                CANCEL
                            </Button>
                            <Button id="ave-profile-edit" disabled={disableSaveButton} color="primary" onClick={onSave}>SAVE CHANGES</Button>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            <style>{`
            .accordion-preferences .MuiAccordionSummary-root.Mui-expanded {
                border-bottom: 2px solid rgba(0, 0, 0, 0.12);
            }`}</style>
            {cancelCOnfirmation && (

                <NotificationWithDialog
                    message="Are you sure you want to cancel?"
                    open={true}
                    primaryAction={{
                        callback: onCancel,
                        title: "Yes",
                    }}
                    secondaryAction={{
                        callback: () => {
                            setTabsBool(false);
                            setCancelConfirmation(false);
                            canEdit(false);
                        },
                        title: "No",
                    }}
                    title=""
                />

            )}
        </>
    )

}

export default MyPreferences;