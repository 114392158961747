import { createReducer } from "@reduxjs/toolkit";
import { AuthLogout, isNotificationLoading } from "control/actions";
import produce from "immer";
import { INotification } from "../entity";

const initialState: INotification = {
    isLoading: false,
};

export const notificationReducer = createReducer(initialState, {
    [isNotificationLoading.toString()]: (state: INotification, action: any) =>
        produce(state, (draft) => {
            draft.isLoading = action.payload.isLoading;
        }),
    [AuthLogout.toString()]: () => initialState,
});
