import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import * as actions from "../../control/actions/onboarding.actions";
import { IWelcomeState } from "../entity/onboarding.entity";

const initialState: IWelcomeState = {
    isLoading: false,
    welcomeList: [],
    activitiesList: [],
};

export const onboardingReducer = createReducer(initialState, {
    // Welcomme
    [actions.getWelcomeInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
        }),
    [actions.getWelcomeSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.isLoading = false;
            draft.welcomeList = action.payload;
        }),
    [actions.getWelcomeFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
        }),

    [actions.postWelcomeInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
        }),
    [actions.postWelcomeSuccess.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
            // draft.welcomeList = action.payload;
        }),
    [actions.postWelcomeFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
        }),

    // Activites
    [actions.getActivitiesInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
        }),
    [actions.getActivitiesSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.isLoading = false;
            draft.activitiesList = action.payload;
        }),
    [actions.getActivitiesFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
        }),

    [actions.postActivitiesInit.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = true;
        }),
    [actions.postActivitiesSuccess.toString()]: (state, action) =>
        produce(state, (draft) => {
            draft.isLoading = false;
            draft.activitiesList = action.payload;
        }),
    [actions.postActivitiesFail.toString()]: (state) =>
        produce(state, (draft) => {
            draft.isLoading = false;
        }),
});
