import logger from "../../util/logger";
import administrationAPIClient from "./clients/administration-api.client";

class OnboardingTransportLayer {
    // Welcomme
    public static async getWelcome (moduleId: any): Promise<any> {
        const res = await administrationAPIClient({
            method: "GET",
            url: `/api/Onboarding/GetDistrictOnboardingFeatures/${moduleId.payload}`,
        });
        return res.data.PayLoad;
    }

    public static async postWelcome (payload: any): Promise<any> {
        const resourcePayload = payload.payload;
        try {
            const response = await administrationAPIClient({
                method: "POST",
                url: "/api/Onboarding/UpdateDistrictOnboardingFeature",
                data: resourcePayload,
            });
            if (response.data) {
                OnboardingTransportLayer.getWelcome({payload: 2});
                return response.data;
            }
        } catch (error) {
            logger.info(error);
            throw error;
        }
    }

    // Activities
    public static async getActivities (moduleId: any): Promise<any> {
        const res = await administrationAPIClient({
            method: "GET",
            url: `/api/Onboarding/GetLatestOnboardingFeaturesStatus/${moduleId.payload}`,
        });
        return res.data.PayLoad;
    }

    public static async postActivities (payload: any): Promise<any> {
        const resourcePayload = payload;
        try {
            const response = await administrationAPIClient({
                method: "POST",
                url: "/api/Onboarding/UpdateDistrictOnboardingFeatureList",
                data: resourcePayload,
            });

            return response.data;
        } catch (error) {
            logger.info(error);
            throw error;
        }
    }
}

export default OnboardingTransportLayer;
