import { Card, CardContent, CardHeader, Grid, makeStyles, Paper, TextField, Typography, InputAdornment } from "@material-ui/core";
import { AccountStatus, Button, NotificationWithDialog, useAuth, UserCard, EyeIcon, EyeOffIcon, IconButton } from "@primeroedge/ui-components";
import { passwordPoliciesInit, userConfirmPasswordInit } from "control/actions";
import { getPasswordPolicies, IPasswordPolicies, IUserProfile } from "model/entity";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RequiredLabel from "view/pages/required-label";

interface ISecurityProps {
    profileImageUrl: any;
    editableProfile: IUserProfile;
    setCancelUserConfirmation: any;
    cancelUserConfirmation: boolean;
    setTabsBool: any;
    canEdit: any;
    setActiveTab: any;
    canShowAlert: any;
}

export const styles = makeStyles(() => ({
    root: {
        width: "100%",
    },
    cardContent: {
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
    editContainer: {
        display: "flex",
        flexDirection: "row",
    },
    inputRoot: {
        width: "200px",
        "&:first-child": {
            marginRight: "20px",
        },
    },
    box: {
        display: "flex",
        justifyContent: "space-between",
    },
    controlsRoot: {
        display: "flex",
        justifyContent: "space-between",
    },
    hideFileInput: {
        display: "none",
    },
    loaderContainer: {
        width: "100%",
        height: "60vh",
        padding: "10px",
    },
}));

const SecurityInfo = ({
    profileImageUrl,
    editableProfile,
    setCancelUserConfirmation,
    cancelUserConfirmation,
    setTabsBool,
    canEdit,
    setActiveTab,
    canShowAlert,
}: ISecurityProps) => {
    const dispatch = useDispatch();
    const classes = styles();
    const passwordPoliciesData = useSelector(getPasswordPolicies);
    const [password, setPassword] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const [validLength, isValidLength] = useState<boolean>(false);
    const [lowerCaseValid, isLowerCaseValid] = useState<boolean>(false);
    const [isNumber, setIsNumber] = useState<boolean>(false);
    const [isSpecial, setIsSpecial] = useState<boolean>(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);
    const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState<string>("");
    const [minCharacters, setMinCharacters] = useState<number>(0);
    const [upperLowerChar, setUpperLowerChar] = useState<string>("");
    const [specialChar, setSpecialChar] = useState<number>(0);
    const [numericChar, setNumericChar] = useState<number>(0);
    const [newPasswordError, setNewPasswordError] = useState<boolean>(false);
    const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState<string>("");
    const { logout } = useAuth();

    useEffect(() => {
        dispatch(passwordPoliciesInit());
        setActiveTab(1);
        canEdit(false);
    }, [dispatch, setActiveTab, canEdit]);

    useEffect(() => {
        if (passwordPoliciesData.length > 0) {
            (passwordPoliciesData as IPasswordPolicies[]).forEach((a: IPasswordPolicies, i: number) => {
                switch (a.code) {
                    case "MPL":
                        setMinCharacters(parseInt(((passwordPoliciesData as IPasswordPolicies[]).map((a: IPasswordPolicies) => a.policyValue))[i]));
                        break;
                    case "RULC":
                        setUpperLowerChar((passwordPoliciesData as IPasswordPolicies[]).map((a: IPasswordPolicies) => a.policyValue)[i]);
                        break;
                    case "MSPC":
                        setSpecialChar(parseInt((passwordPoliciesData as IPasswordPolicies[]).map((a: IPasswordPolicies) => a.policyValue)[i]));
                        break;
                    case "MNC":
                        setNumericChar(parseInt((passwordPoliciesData as IPasswordPolicies[]).map((a: IPasswordPolicies) => a.policyValue)[i]));
                        break;
                    default:
                        break;
                }
            });
        }
    }, [passwordPoliciesData]);

    useEffect(() => {
        if (password.newPassword.length >= minCharacters) {
            isValidLength(true);
        } else {
            isValidLength(false);
        }
    }, [minCharacters, password.newPassword.length]);

    useEffect(() => {
        const lowerCase = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");
        if (upperLowerChar === "True") {
            if (lowerCase.test(password.newPassword)) {
                isLowerCaseValid(true);
            } else {
                isLowerCaseValid(false);
            }
        } else {
            isLowerCaseValid(true);
        }
    }, [password, upperLowerChar]);

    useEffect(() => {
        const validNumber = new RegExp("^(?=.*[0-9])");
        if (numericChar > 0 && validNumber.test(password.newPassword)) {
            const newNumberRegex = new RegExp("[0-9*]", "g");
            const numberCount =
                password.newPassword.match(newNumberRegex)?.length ?? 0;
            setIsNumber(numberCount >= numericChar);
        } else if (numericChar === 0) {
            setIsNumber(true);
        } else {
            setIsNumber(false);
        }
    }, [password.newPassword, numericChar]);

    useEffect(() => {
        const specialCharacter = new RegExp("^(?=.*[!@#$%^&*])");
        if (specialChar && specialCharacter.test(password.newPassword)) {
            const newCharRegex = new RegExp("[!@#$%^&*]", "g");
            const charCount = password.newPassword.match(newCharRegex)?.length ?? 0;
            setIsSpecial(charCount >= specialChar);
        } else {
            setIsSpecial(false);
        }
    }, [password.newPassword, specialChar]);

    useEffect(() => {
        if ((password.newPassword.includes(">") || password.newPassword.includes("<") || password.newPassword.includes("%")) ||
            (password.confirmPassword.includes(">") || password.confirmPassword.includes("<") || password.confirmPassword.includes("%"))) {
            setIsSpecial(false);
        }
    }, [password]);

    useEffect(() => {
        const specialCharacter = new RegExp("^(?=.*[!@#$%^&*])");
        if (
            specialChar > 0 &&
            specialCharacter.test(password.newPassword)
        ) {
            const newCharRegex = new RegExp("[!@#$%^&*]", "g");
            const charCount =
                password.newPassword.match(newCharRegex)?.length ?? 0;
            setIsSpecial(charCount >= specialChar);
        } else if (specialChar === 0) {
            setIsSpecial(true);
        } else {
            setIsSpecial(false);
        }
    }, [password.newPassword, specialChar]);

    const handlePassword = (name: any, value: any) => {
        setPassword({
            ...password,
            [name]: value,
        });
    };

    const handleNewPasswordBlur = () => {
        if (!password.newPassword) {
            setNewPasswordError(true);
            setNewPasswordErrorMsg("Please enter new password");
            setConfirmPasswordError(false);
            setConfirmPasswordErrorMsg("");
        } else {
            setNewPasswordError(false);
            setNewPasswordErrorMsg("");
        }
    };

    const handleConfirmPasswordBlur = () => {
        if (!password.confirmPassword) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorMsg("Please enter confirm password");
        } else if (!password.newPassword && password.confirmPassword) {
            setNewPasswordError(true);
            setNewPasswordErrorMsg("Please enter new password");
            setConfirmPasswordError(false);
            setConfirmPasswordErrorMsg("");
        } else if (password.newPassword !== password.confirmPassword) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorMsg("Password and Confirm Password doesn't match , Please check and enter correct password");
        } else {
            setConfirmPasswordError(false);
            setConfirmPasswordErrorMsg("");
        }
    };

    const handleConfirmPasswordChange = () => {
        if (!password.newPassword) {
            setNewPasswordError(true);
            setNewPasswordErrorMsg("Please enter new password");
        } else if (!password.confirmPassword) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorMsg("Please enter confirm password");
        } else if (!password.newPassword && !password.confirmPassword) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorMsg("Please enter new password and confirm password");
        } else if (password.newPassword !== password.confirmPassword) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorMsg("Password and Confirm Password doesn't match , Please check and enter correct password");
        } else {
            if ((password.newPassword.includes(">") || password.newPassword.includes("<") || password.newPassword.includes("%")) ||
                (password.confirmPassword.includes(">") || password.confirmPassword.includes("<") || password.confirmPassword.includes("%"))) {
                setConfirmPasswordError(true);
                setConfirmPasswordErrorMsg("Password did not meet rules/policies");
            } else {
                if (password.newPassword.length < minCharacters) {
                    setConfirmPasswordError(true);
                    setConfirmPasswordErrorMsg("Password is too short");
                } else if (password.newPassword === password.confirmPassword
                    &&
                    (!validLength || !isSpecial || !isNumber || !lowerCaseValid)) {
                    setConfirmPasswordError(true);
                    setConfirmPasswordErrorMsg("Password did not meet rules/policies");
                } else {
                    if (password.newPassword === password.confirmPassword && isSpecial && isNumber && lowerCaseValid) {
                        const payload = {
                            email: editableProfile.email,
                            password: password.newPassword,
                        };
                        dispatch(userConfirmPasswordInit({
                            payload,
                            action: handleUserProfilePasswordStatus,
                        }));
                    }
                }
            }
        }
    };

    const handleUserProfilePasswordStatus = (response: any) => {
        if (response.PayLoad) {
            canShowAlert(() => "success");
            // setTabsBool(true);
            canEdit(false);
            setActiveTab(0);
            setTimeout(() => {
                logout();
            }, 5000);
        } else {
            canShowAlert(() => "error");
        }
    };

    const handleCancel = () => {
        setCancelUserConfirmation(true);
    };

    const onCancel = () => {
        setTabsBool(true);
        setCancelUserConfirmation(false);
        canEdit(false);
        setActiveTab(0);
    };

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const handleShowPassword = function () {
        setShowPassword(!showPassword);
    };
    const handleShowConfirmPassword = function () {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div>
            <Grid container={true} spacing={2}>
                <Grid xs={12} md={4} lg={3} item={true}>
                    <UserCard
                        imageSrc={profileImageUrl}
                        isUserCard={true}
                        name={`${editableProfile.firstName} ${editableProfile.lastName}`}
                        status={
                            editableProfile.userStatusDescription !== "Disabled"
                                ? AccountStatus.Active
                                : AccountStatus.InActive
                        }
                        statusText={
                            editableProfile.userStatusDescription !== "Disabled"
                                ? "Active"
                                : "Disabled"
                        }
                    />
                </Grid>
                <Grid xs={12} md={8} lg={9} item={true}>
                    <Paper>
                        <Card className={classes.root}>
                            <CardHeader title="Security" />
                            <CardContent classes={{ root: classes.cardContent }}>
                                <Grid container={true} spacing={6}>
                                    <Grid item={true} xs={12} md={12} lg={8}>
                                        <TextField
                                            fullWidth={true}
                                            error={newPasswordError}
                                            helperText={newPasswordErrorMsg}
                                            id="Password"
                                            type={showPassword ? "text" : "password"}
                                            name="newPassword"
                                            value={password.newPassword}
                                            onChange={(event) =>
                                                handlePassword(event.target.name, event.target.value)
                                            }
                                            onBlur={handleNewPasswordBlur}
                                            label={<RequiredLabel inputLabel="Password" />}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Show Password"
                                                            size="small"
                                                            color="inherit"
                                                            onClick={handleShowPassword}
                                                        >
                                                            {showPassword ? (
                                                                <EyeIcon />
                                                            ) : (
                                                                <EyeOffIcon />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={8}>
                                        <TextField
                                            fullWidth={true}
                                            error={confirmPasswordError}
                                            helperText={confirmPasswordErrorMsg}
                                            id="Confirm Password"
                                            type={showConfirmPassword ? "text" : "password"}
                                            name="confirmPassword"
                                            value={password.confirmPassword}
                                            onBlur={handleConfirmPasswordBlur}
                                            onChange={(event) =>
                                                handlePassword(event.target.name, event.target.value)
                                            }
                                            label={<RequiredLabel inputLabel="Confirm Password" />}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Show Confirm Password"
                                                            size="small"
                                                            color="inherit"
                                                            onClick={handleShowConfirmPassword}
                                                        >
                                                            {showConfirmPassword ? (
                                                                <EyeIcon />
                                                            ) : (
                                                                <EyeOffIcon />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={8}>
                                        <Card style={{ backgroundColor: "#80808026" }}>
                                            <CardContent style={{ borderTop: "0px" }}>
                                                <Typography style={{ fontWeight: "bold" }}>
                                                    Password must have:
                                                </Typography>
                                                {minCharacters ? (
                                                    <Typography
                                                        color={validLength ? "primary" : "textPrimary"}
                                                    >
                                                        - At least {minCharacters} characters
                                                    </Typography>
                                                ) : (
                                                    ""
                                                )}
                                                {upperLowerChar === "True" ? (
                                                    <Typography
                                                        color={lowerCaseValid ? "primary" : "textPrimary"}
                                                    >
                                                        - Both uppercase and lowercase characters
                                                    </Typography>
                                                ) : (
                                                    ""
                                                )}
                                                {specialChar ? (
                                                    <Typography
                                                        color={isSpecial ? "primary" : "textPrimary"}
                                                    >
                                                        {specialChar > 1 && (
                                                            <>
                                                                - At least {specialChar} special characters ({">"},{" "}
                                                                {"<"}, and {"%"} are not allowed)
                                                            </>
                                                        )}
                                                        {specialChar === 1 && (
                                                            <>
                                                                - At least {specialChar} special character ({">"},{" "}
                                                                {"<"}, and {"%"} are not allowed)
                                                            </>
                                                        )}
                                                    </Typography>
                                                ) : (
                                                    ""
                                                )}
                                                {numericChar ? (
                                                    <Typography
                                                        color={isNumber ? "primary" : "textPrimary"}
                                                    >
                                                        - At least {numericChar}{" "}
                                                        {numericChar > 1 ? "numbers" : "number"}                                                    </Typography>
                                                ) : (
                                                    ""
                                                )}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Paper>
                    <div className={classes.box}>
                        <Grid
                            xs={12}
                            item={true}
                            classes={{ root: classes.controlsRoot }}
                            style={{ marginTop: 20 }}
                        >
                            <Button
                                id="cancel-profile-edit"
                                color="default"
                                onClick={handleCancel}
                            >
                                CANCEL
                            </Button>
                            <Button id="ave-profile-edit" color="primary" onClick={handleConfirmPasswordChange}>SAVE CHANGES</Button>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            {cancelUserConfirmation && (
                <NotificationWithDialog
                    message="Are you sure you want to cancel?"
                    open={true}
                    primaryAction={{
                        callback: onCancel,
                        title: "Yes",
                    }}
                    secondaryAction={{
                        callback: () => {
                            setTabsBool(false);
                            setCancelUserConfirmation(false);
                            canEdit(false);
                        },
                        title: "No",
                    }}
                    title=""
                />
            )}
        </div>
    );
};

export default SecurityInfo;
