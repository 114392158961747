import { AppLoader } from "@primeroedge/ui-components";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { rootSaga, sagaMiddleware } from "./control/middleware";
import { store } from "./model";
import { persistor } from "./model/store";
import App from "./view/App";

sagaMiddleware.run(rootSaga);
ReactDOM.render(
    (
        <Provider store={store}>
            <PersistGate loading={<AppLoader />} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    ),
    document.getElementById("root"),
);
