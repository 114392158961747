import React from 'react';
import { SlidingDrawer } from "@primeroedge/ui-components";
import { Divider, List, TextField } from "@material-ui/core";
import ListItem from '../../ListItem';
import { FavoriteActions } from './FavoriteActions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useViewAllFavorites } from '../hooks/useViewAllFavorites';
import { Tooltip } from "@primeroedge/ui-components";

type ViewAllFavoritesListProps = {
    editingIndex?: any;
    setEditingIndex?: any;
    editedName?: any;
    setEditedName?: any;
    openDrawer?: any;
    closeDrawer?: () => void;
};

export const ViewAllFavoritesList: React.FC<ViewAllFavoritesListProps> = ({
    editedName,
    setEditedName,
    editingIndex,
    setEditingIndex,
    openDrawer,
    closeDrawer
}) => {

    const { favoritesData,
        handleOnDragEnd } = useViewAllFavorites();

    const handleNameChange = (e: any) => {
        setEditedName(e.target.value);
    }

    const handleRowClick = (id: any, url: any) => {
        if (editingIndex !== id) {
            const baseUrl = window.location.origin;
            const sanitizedUrl = url?.startsWith('/') ? url : `/${url}`;
            window.open(`${baseUrl}${sanitizedUrl}`, '_blank');
        }
    };

    return (
        <>
            <SlidingDrawer
                open={openDrawer}
                title="Favorites"
                width="sm"
                onCloseIconClick={closeDrawer}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="favorites">
                        {(provided: any) => (
                            <List
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {favoritesData.map((favorite: any, index: any) => (
                                    <Draggable
                                        key={favorite.id}
                                        draggableId={favorite.id.toString()}
                                        index={index}
                                    >
                                        {(provided: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <ListItem
                                                    onClick={() => {
                                                        handleRowClick(favorite.id, favorite.url);
                                                    }}
                                                    style={{ padding: "0px" }}
                                                    title={editingIndex === favorite.id ? (
                                                        <TextField
                                                            name="favoriteName"
                                                            id="edit-favorite-Name"
                                                            value={editedName}
                                                            variant="outlined"
                                                            onChange={(event: any) =>
                                                                handleNameChange(event)}
                                                            inputProps={{ maxLength: 25 }}
                                                        />
                                                    ) : (
                                                        <Tooltip title={favorite.url}>
                                                            <span style={{ color: 'rgb(102,90,216)' }}>
                                                                {favorite.favoriteName}
                                                            </span>
                                                        </Tooltip>
                                                    )
                                                    }
                                                    action={<FavoriteActions favoriteId={favorite?.id}
                                                        favoriteName={favorite.favoriteName}
                                                        setEditedName={setEditedName}
                                                        editedName={editedName}
                                                        editingIndex={editingIndex}
                                                        setEditingIndex={setEditingIndex}
                                                        closeDrawer={closeDrawer}
                                                        url={favorite.url}
                                                        moduleName={favorite.moduleName} />}
                                                />
                                                <Divider />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
            </SlidingDrawer>
        </>
    )
}