
import { IAppState } from "model/reducers";
import { put, select, takeLatest } from "redux-saga/effects";
import logger from "util/logger";
import * as actions from "../actions/sitetype.actions";
import siteTypeTransport from "../transport/sitetype.transport";

function* sitetypeByRegionSaga (action: any) {
    try {
        const regionData = yield siteTypeTransport.siteTypeInit(action.payload);
        yield put(actions.fetchSiteTypesByRegionSuccess(regionData.data));
    } catch (e) {
        yield put(actions.fetchSiteTypesByRegionFail());
        logger.info(e);
    }
}

function* getAllSiteTypeTypesSaga () {
    try {
        const allSiteTypeTypes = yield siteTypeTransport.getAllSiteTypeTypes();
        yield put({
            type: actions.fetchAllSiteTypeTypesSuccess,
            payload: allSiteTypeTypes.data,
        });
    } catch (e) {
        yield put(actions.fetchAllSiteTypeTypeseFail());
        logger.info(e);
    }
}

function* getAllUSDASiteTypesSaga () {
    try {
        const allUSDASiteTypes = yield siteTypeTransport.getAllUSDASiteTypes();
        yield put({
            type: actions.fetchAllUSDASiteTypesSuccess,
            payload: allUSDASiteTypes.data.PayLoad,
        });
    } catch (e) {
        yield put(actions.fetchAllUSDASiteTypesFail());
        logger.info(e);
    }
}

function* addSiteTypeSaga (data: any) {
    try {
        const user = yield select((state: IAppState) => state.user.userProfile);
        const resp = yield siteTypeTransport.addSiteType(data, Number(user?.UserId));
        yield put({
            type: actions.sitetypeAddSuccess,
            payload: resp,
        });
    } catch (e) {
        logger.info(e);
    }
}

function* editSiteTypeSaga (data: any) {
    try {
        const user = yield select((state: IAppState) => state.user.userProfile);
        const resp = yield siteTypeTransport.editSiteType(data, Number(user?.UserId));
        yield put({
            type: actions.sitetypeAddSuccess,
            payload: resp,
        });
    } catch (e) {
        yield put(actions.sitetypeEditFail());
        logger.info(e);
    }
}


function* addSiteTypeFormGradesSaga () {
    try {
        const addSiteTypeFormGrades = yield siteTypeTransport.addSiteTypeFormGrades();
        yield put({
            type: actions.fetchSiteTypeGradesAddFormSuccess,
            payload: addSiteTypeFormGrades.data,
        });
    } catch (e) {
        yield put(actions.fetchSiteTypeGradesAddFormFail());
        logger.info(e);
    }
}

export default function* watchSiteTypesSaga () {
    try {
        yield takeLatest(
            actions.fetchSiteTypesByRegionInit,
            sitetypeByRegionSaga,
        );
        yield takeLatest(
            actions.fetchAllSiteTypeTypesInit,
            getAllSiteTypeTypesSaga,
        );
        yield takeLatest(
            actions.fetchAllUSDASiteTypesInit,
            getAllUSDASiteTypesSaga,
        );
        yield takeLatest(actions.sitetypeAddInit, addSiteTypeSaga);
        yield takeLatest(actions.sitetypeEditInit, editSiteTypeSaga);
        yield takeLatest(
            actions.fetchSiteTypeGradesAddFormInit,
            addSiteTypeFormGradesSaga,
        );
    } catch (error) {
        yield;
    }
}

