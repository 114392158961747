import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import {
    AuthLogout,
    SetBroadcastGridState,
    SetBroadcastMessages,
    SetBroadcastStatus,
    SetBroadcastTotalMessageCount,
    SetBroadcastTotalPages,
} from "../../control/actions";
import { IBroadcast, ResponseStatus } from "../entity";

const initialState: IBroadcast = {
    status: ResponseStatus.Initial,
};

export const broadcastReducer = createReducer(initialState, {
    [SetBroadcastMessages.toString()]: (state, action) =>
        produce(state, (draft: IBroadcast) => {
            draft.messages = action.payload;
        }),
    [SetBroadcastGridState.toString()]: (state, action) =>
        produce(state, (draft: IBroadcast) => {
            draft.gridState = action.payload;
        }),
    [SetBroadcastTotalMessageCount.toString()]: (state, action) =>
        produce(state, (draft: IBroadcast) => {
            draft.totalMessageCount = action.payload;
        }),
    [SetBroadcastTotalPages.toString()]: (state, action) =>
        produce(state, (draft: IBroadcast) => {
            draft.totalPages = action.payload;
        }),
    [SetBroadcastStatus.toString()]: (state, action) =>
        produce(state, (draft: IBroadcast) => {
            draft.status = action.payload;
        }),
    [AuthLogout.toString()]: () => initialState,
});
