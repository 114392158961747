import {
    Box,
    Hidden,
    IconButton,
    Input,
    makeStyles,
    Menu,
    MenuItem,
    Paper,
    SvgIcon,
    Tooltip,
    Typography,
} from "@material-ui/core";
import {
    ChevronLeftIcon,
    ChevronRightIcon, MenuIcon, MoreVerticalIcon, NotificationWithDialog, RefreshIcon, SearchIcon,
} from "@primeroedge/ui-components";
import clsx from "clsx";
import { fetchMail, getMail, searchMail } from "control/actions/mail.actions";
import { MailEntity, MessageType } from "model/entity";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface IToolbarProps {
    className?: string;
    mails: number;
    selectedMails: any;
    handleDeleteSelectedMails: () => void;
    handleReadSelectedMails: (params: any) => void;
    handleOtherMenuOption: (value: string) => void;
    messageType: MessageType;
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        height: 68,
        display: "flex",
        alignItems: "center",
    },
    searchContainer: {
        alignItems: "center",
        display: "flex",
        marginLeft: theme.spacing(2),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
    },
    searchInput: {
        marginLeft: theme.spacing(2),
        flexGrow: 1,
    },
}));

const MailListToolbar: FC<IToolbarProps> = ({
    className,
    mails,
    selectedMails,
    handleDeleteSelectedMails,
    handleReadSelectedMails,
    handleOtherMenuOption,
    messageType,
    ...rest
}: IToolbarProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const totalMessages = useSelector(MailEntity.getTotalCount);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchInput, setSearchInput] = useState("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const list = useSelector(MailEntity.getComposeMailOpenStatus);
    const index = selectedMails[0] && list.findIndex((x: any) => x.id === selectedMails[0]);
    const mail = useSelector(MailEntity.mailToViewDetails);
    const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (selectedMails.length > 0) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        handleDeleteSelectedMails();
        handleClose();
    };

    const handleRead = () => {
        handleReadSelectedMails({
            pageSize: 10,
            pageNumber: currentPage > 0 ? currentPage : 1,
            sortColumn: "CreatedOn",
            sortType: "DESC",
            readStatus: true,
            type: messageType,
        });
        handleClose();
    };

    const handleUnRead = () => {
        const params = {
            pageSize: 10,
            pageNumber: currentPage > 0 ? currentPage : 1,
            sortColumn: "CreatedOn",
            sortType: "DESC",
            readStatus: false,
            type: messageType,
        };

        handleReadSelectedMails(params);
        handleClose();
    };

    const handleOpenSidebar = (): void => {
        // dispatch(openSidebar());
    };

    const handleRefresh = (): void => {
        const params = {
            pageSize: 10,
            pageNumber: 1,
            sortColumn: "createdOn",
            type: messageType,
        };
        dispatch(fetchMail(params));
    };

    const handleSearchInput = (e: any) => {

        setSearchInput(e.target.value);
        if (e.target.value) {
            const params = {
                SearchText: e.target.value,
                PageSize: 10,
                PageNumber: 1,
                sortType: "DESC",
                messageType,
                FolderType: messageType,
            };

            dispatch(searchMail(params));
        } else {
            const params = {
                pageSize: 10,
                pageNumber: 1,
                sortColumn: "CreatedOn",
                sortType: "DESC",
                type: messageType,
            };
            dispatch(fetchMail(params));
        }
    };

    const handlePageChange = (pageType: number) => {
        if (pageType > 0) {
            if ((currentPage * 10) <= totalMessages) {
                dispatch(getMail({
                    messages: [],
                }));
                const params = {
                    pageSize: 10,
                    pageNumber: currentPage + 2,
                    sortColumn: "CreatedOn",
                    sortType: "DESC",
                    messageType,
                    type: messageType,
                    SearchText: searchInput,
                    FolderType: messageType,

                };

                if (searchInput) {
                    dispatch(searchMail(params));
                } else {
                    dispatch(fetchMail(params));
                }

                setCurrentPage(() => currentPage + 1);
            }
        } else {
            if (currentPage > 1) {
                dispatch(getMail({
                    messages: [],
                }));
                const params = {
                    pageSize: 10,
                    pageNumber: currentPage - 1,
                    sortColumn: "CreatedOn",
                    sortType: "DESC",
                    messageType,
                    type: messageType,
                    SearchText: searchInput,
                    FolderType: messageType,
                };

                if (searchInput) {
                    dispatch(searchMail(params));
                } else {
                    dispatch(fetchMail(params));
                }

                setCurrentPage(() => currentPage - 1);
            } else {
                dispatch(getMail({
                    messages: [],
                }));
                const params = {
                    pageSize: 10,
                    pageNumber: 1,
                    sortColumn: "CreatedOn",
                    sortType: "DESC",
                    messageType,
                    type: messageType,
                    SearchText: searchInput,
                    FolderType: messageType,
                };

                if (searchInput) {
                    dispatch(searchMail(params));
                } else {
                    dispatch(fetchMail(params));
                }

                setCurrentPage(() => 0);
            }
        }
    };

    const handleOtherMenu = (selectedOption: string) => {
        handleOtherMenuOption(selectedOption);
    };

    useEffect(() => {
        const params = {
            pageSize: 10,
            pageNumber: currentPage > 0 ? currentPage : 1,
            sortColumn: "CreatedOn",
            sortType: "DESC",
            messageType,
            type: messageType,
        };

        if (searchInput) {
            dispatch(searchMail(params));
        } else {
            dispatch(fetchMail(params));
        }

    }, [mail]);

    return (
        <div
            className={clsx(classes.root, className)}
            {...rest}
        >
            <NotificationWithDialog
                key="id"
                message="Are you sure you want to delete the selected?"
                open={showDeleteConfirm}
                primaryAction={{
                    callback: () => {
                        setshowDeleteConfirm(false);
                        handleDelete();
                    },
                    title: "Confirm",
                }}
                secondaryAction={{
                    callback: () => {
                        setshowDeleteConfirm(false);
                    },
                    title: "Cancel",
                }}
                title="Confirmation"
            />
            <Hidden mdUp={true}>
                <IconButton onClick={handleOpenSidebar}>
                    <SvgIcon fontSize="small">
                        <MenuIcon />
                    </SvgIcon>
                </IconButton>
            </Hidden>
            <Hidden smDown={false}>
                <Box flexGrow={1} />
                <Paper
                    className={classes.searchContainer}
                    variant="outlined"
                >
                    <SvgIcon
                        color="action"
                        fontSize="small"
                    >
                        <SearchIcon />
                    </SvgIcon>
                    <Input
                        type="text"
                        name="Search mail"
                        className={classes.searchInput}
                        disableUnderline={true}
                        placeholder="Search mail"
                        value={searchInput}
                        onChange={handleSearchInput}
                        inputProps={{ "aria-label": "Search Mail" }}
                    />
                </Paper>
                <Tooltip title="Refresh" style={{ display: "none" }}>
                    <IconButton onClick={handleRefresh} aria-label="Refresh">
                        <RefreshIcon size={20} />
                    </IconButton>
                </Tooltip>
                {selectedMails.length > 0 && (
                    <Tooltip title="More Options" onClick={handleClick}>
                        <IconButton aria-label="More Options">
                            <MoreVerticalIcon size={20} />
                        </IconButton>
                    </Tooltip>
                )}
                <Menu
                    id="mail-operations-menu"
                    anchorEl={anchorEl}
                    keepMounted={true}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={
                        () => { setshowDeleteConfirm(true);}
                    }>
                        Delete
                    </MenuItem>
                    {selectedMails.length === 1 && messageType !== MessageType.Sent && (
                        <MenuItem
                            onClick={list.length > 0 && list[index] && list[index].isRead ? handleUnRead : handleRead}
                        >
                            {`${list.length > 0 && list[index] && list[index].isRead ? "Mark as Unread" : "Mark as Read"}`}
                        </MenuItem>
                    )}
                    {selectedMails.length > 1 && messageType !== MessageType.Sent && (
                        <MenuItem onClick={handleRead}>Mark as Read</MenuItem>
                    )}
                    {selectedMails.length > 1 && messageType !== MessageType.Sent && (
                        <MenuItem onClick={handleUnRead}>Mark as Unread</MenuItem>
                    )}
                    {selectedMails.length === 1 && (
                        <MenuItem onClick={() => handleOtherMenu("reply")}>Reply</MenuItem>
                    )}
                    {selectedMails.length === 1 && (
                        <MenuItem onClick={() => handleOtherMenu("replyAll")}>Reply All</MenuItem>
                    )}
                    {selectedMails.length === 1 && (
                        <MenuItem onClick={() => handleOtherMenu("forward")}>Forward</MenuItem>
                    )}
                </Menu>
                {mails > 0 && (
                    <Box
                        display="flex"
                        alignItems="center"
                    >
                        <Tooltip title="Previous Page">
                            <div>
                                <IconButton
                                    disabled={currentPage === 0}
                                    onClick={handlePageChange.bind(null, -1)}
                                    aria-label="Previous Page"
                                >
                                    <ChevronLeftIcon size={20} />
                                </IconButton>
                            </div>
                        </Tooltip>

                        <>
                            <Typography
                                noWrap={true}
                                variant="body2"
                                color="textSecondary"
                            >
                                {(currentPage * 10) + 1} -
                                {" "}
                                {(currentPage * 10) + 10 > totalMessages ? totalMessages : (currentPage * 10) + 10}
                                {" "}
                                of
                                {" "}
                                {totalMessages}
                            </Typography>
                            <Tooltip title="Next Page">
                                <div>
                                    <IconButton
                                        disabled={(currentPage * 10) + 10 > totalMessages}
                                        onClick={handlePageChange.bind(null, 1)}
                                        aria-label="Next Page"
                                    >
                                        <ChevronRightIcon size={20} />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </>

                    </Box>
                )}
            </Hidden>
        </div>
    );
};

export default MailListToolbar;
