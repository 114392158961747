import {
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: `calc(100% - ${theme.spacing(6)}px)`,
        maxHeight: `calc(100% - ${theme.spacing(6)}px)`,
        width: 600,
        position: "fixed",
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
        outline: "none",
        zIndex: 1300,
        display: "flex",
        flexDirection: "column",
        minHeight: 500,
        overflow: "auto",
    },
    emailsList: {
        position: "absolute",
        background: "white",
        width: "90%",
        height: "50%",
        overflowY: "scroll",
        border: "1px solid #b2b2b2",
        borderRadius: 4,
        padding: 10,
        zIndex: 999,
    },
    loaderPosition: {
        position: "absolute",
        background: "white",
        padding: 10,
        zIndex: 999,
    },
    fullScreen: {
        height: "100%",
        width: "100%",
    },
    input: {
        width: "100%",
    },
    inputAuto: {
        width: "auto",
    },
    toText: {
        margin: "0px 10px 0px 0px",
        padding: "6px 0 7px",
        color: "theme.palette.text.primary",
    },
    editor: {
        flexGrow: 1,
        "& .ql-editor": {
            minHeight: 300,
        },
    },
    userList: {
        position: "absolute",
        background: "white",
        zIndex: 2,
        border: "1px solid",
        maxHeight: "300px",
        overflowX: "auto",
    },
    userListItem: {
        padding: "5px 10px",
        cursor: "pointer",

        "&:hover": {
            backgroundColor: "#f4f6f8",
        },
    },
    senderList: {
        display: "flex",
        flexWrap: "wrap",
        borderBottom: "1px solid #e4e4e4"
    },
    action: {
        marginRight: theme.spacing(1),
    },
    chipEmail: {
        marginLeft: "3px",
        marginRight: "3px",
    },
    emailListItem: {
        borderBottom: "1px solid #cecece",
        "&:hover": {
            cursor: "pointer",
            background: "#cecece",
        },
    },
    attachmentBox: {
        maxHeight: "100px",
        overflowY: "auto",
    },
    attachment: {
        margin: "10px",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: theme.palette.background.default,
        padding: "10px",
    },
    attachmentImage: {
        color: theme.palette.primary.main,
    },
    cross: {
        cursor: "pointer",
    },
}));


export default useStyles;