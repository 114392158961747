import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { sagaMiddleware } from "../control/middleware";
import createRootReducer from "./reducers";

export const history = createBrowserHistory();

export const rootReducer = createRootReducer(history);

export const persistedReducer = persistReducer({
    key: "ws_state",
    storage,
    whitelist: ["workspace"],
}, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: [sagaMiddleware, routerMiddleware(history)],
});

export const persistor = persistStore(store);
