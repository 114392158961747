const siteCharacteristicsJSON = (siteId: any) => {
  const JSON = [
    {
      siteCharacteristicId: null,
      siteId,
      siteCharacteristicDescription: "K-12",
      isActive: false,
      displayOrder: 1,
      siteCharacteristicData: [
        {
          siteCharacteristicDataDescription: "Public",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
        {
          siteCharacteristicDataDescription: "Parochial",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
        {
          siteCharacteristicDataDescription: "Charter",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
        {
          siteCharacteristicDataDescription: "RCCI",
          isSelected: false,
          siteCharacteristicDataOptions: [
            {
              optionValue: "Yes",
              isOptionSelected: true,
            },
            {
              optionValue: "No",
              isOptionSelected: false,
            },
          ],
        },
      ],
    },
    {
      siteCharacteristicId: null,
      siteId,
      siteCharacteristicDescription: "RCCI with no School",
      isActive: false,
      displayOrder: 2,
      siteCharacteristicData: [],
    },
    {
      siteCharacteristicId: null,
      siteId,
      siteCharacteristicDescription: "Camp",
      isActive: false,
      displayOrder: 3,
      siteCharacteristicData: [
        {
          siteCharacteristicDataDescription: "Day",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
        {
          siteCharacteristicDataDescription: "Residential",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
        {
          siteCharacteristicDataDescription: "Residential - year round",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
      ],
    },
    {
      siteCharacteristicId: null,
      siteId,
      siteCharacteristicDescription: "CACFP Facility",
      isActive: false,
      displayOrder: 4,
      siteCharacteristicData: [
        {
          siteCharacteristicDataDescription: "Adult Day Care Center",
          isSelected: false,
          siteCharacteristicDataOptions: [
            {
              optionValue: "Yes",
              isOptionSelected: true,
            },
            {
              optionValue: "No",
              isOptionSelected: false,
            },
          ],
        },
        {
          siteCharacteristicDataDescription: "At-Risk After School Care Center",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
        {
          siteCharacteristicDataDescription: "Child Care Center",
          isSelected: false,
          siteCharacteristicDataOptions: [
            {
              optionValue: "Yes",
              isOptionSelected: true,
            },
            {
              optionValue: "No",
              isOptionSelected: false,
            },
          ],
        },
        {
          siteCharacteristicDataDescription: "Day Care Home",
          isSelected: false,
          siteCharacteristicDataOptions: [
            {
              optionValue: "Tier I",
              isOptionSelected: true,
            },
            {
              optionValue: "Tier II",
              isOptionSelected: false,
            },
            {
              optionValue: "Tier II Mixed",
              isOptionSelected: false,
            },
          ],
        },
        {
          siteCharacteristicDataDescription: "Head Start",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
        {
          siteCharacteristicDataDescription: "Homeless/Emergency Shelter",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
        {
          siteCharacteristicDataDescription: "Outside School Hours Care Center(OSHCC)",
          isSelected: false,
          siteCharacteristicDataOptions: [
            {
              optionValue: "Yes",
              isOptionSelected: true,
            },
            {
              optionValue: "No",
              isOptionSelected: false,
            },
          ],
        },
      ],
    },
    {
      siteCharacteristicId: null,
      siteId,
      siteCharacteristicDescription: "Summer Site Eligibility",
      isActive: false,
      displayOrder: 5,
      siteCharacteristicData: [
        {
          siteCharacteristicDataDescription: "Open",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
        {
          siteCharacteristicDataDescription: "Restricted Open",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
        {
          siteCharacteristicDataDescription: "Closed Enrolled",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
        {
          siteCharacteristicDataDescription: "Camp",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
        {
          siteCharacteristicDataDescription: "Migrant Site",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
        {
          siteCharacteristicDataDescription: "National Youth Sports Program",
          isSelected: false,
          siteCharacteristicDataOptions: [],
        },
      ],
    },
  ];

  return JSON;
};

export default siteCharacteristicsJSON;
