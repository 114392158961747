import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from "@material-ui/core";
import { AlertCircleIcon, Button } from "@primeroedge/ui-components";
import clsx from "clsx";
import { useDispatch, useSelector } from 'react-redux';
import { setDismissedMessages } from 'control/actions';
import { getGlobalMessagesDismissed } from 'model/entity/message.entity';

const GlobalMessages = ({ messages, setUpdatedGlobalMessages, classes }: any) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(messages.length - 1);
  const [messagesDismissed, setMessagesDismissed] = useState<string[]>([]);
  const getGlobalMessagesDismissedFromReducer = useSelector(getGlobalMessagesDismissed);
  const [globalMessages, setGlobalMessages] = useState<any[]>(messages); // Initialize with all messages
  const dispatch = useDispatch();
  const [more, setMore] = useState(false);

  useEffect(() => {
    if (messagesDismissed.length > 0) {
      dispatch(setDismissedMessages(messagesDismissed));
    }
  }, [messagesDismissed, dispatch]);
  useEffect(() => {
    setUpdatedGlobalMessages(globalMessages);
  }, [globalMessages]);

  useEffect(() => {
    // Filter out dismissed messages from globalMessages
    const remainingMessages = globalMessages.filter((message: any) => !getGlobalMessagesDismissedFromReducer?.includes(message?.messageId));
    setGlobalMessages(remainingMessages);
  }, [getGlobalMessagesDismissedFromReducer]);

  const handleDismiss = () => {
    if (currentMessageIndex > 0) {
      const dismissedMessageId = globalMessages[currentMessageIndex].messageId;
      setMessagesDismissed([...messagesDismissed, dismissedMessageId]);
      setCurrentMessageIndex(currentMessageIndex - 1);
    } else {
      const dismissedMessageId = globalMessages[currentMessageIndex].messageId;
      setMessagesDismissed([...messagesDismissed, dismissedMessageId]);
    }
  }

  return (
    <>
      {globalMessages && globalMessages.length > 0 && (
        <Box id="global-broadcast-messages-container" className={classes.globalMessageRoot}>
          <Container maxWidth="lg">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <AlertCircleIcon />&ensp;
                  <Typography variant="h6" component="p">
                    BROADCAST MESSAGE
                  </Typography>
                </Box>
                <Box marginTop={2} className={clsx("ql-editor", classes.messageContent)}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `${globalMessages[currentMessageIndex].content.substring(
                        0,
                        more ? globalMessages[currentMessageIndex].content.length : 75,
                      )}`,
                    }}
                  />
                  <span>
                    {!more && globalMessages[currentMessageIndex].content.length > 75 && (
                      <a
                        onClick={() => setMore(true)}
                        className={classes.bannerLink}
                      >
                        View More
                      </a>
                    )}
                    {more && (
                      <a
                        className={classes.bannerLink}
                        onClick={() => setMore(false)}
                      >
                        Show Less
                      </a>
                    )}
                  </span>
                </Box>
              </Box>
              <Box display="flex" flexWrap="nowrap">
                <Button
                  id="defaultButton"
                  name="Button"
                  onClick={handleDismiss}
                  type="button"
                  variant="text"
                  color="inherit"
                >
                  Dismiss
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
}

export default GlobalMessages;
