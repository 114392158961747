import { APIInstance } from "@primeroedge/ui-components";
import { API_WORKSPACE, APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY } from "./api-constants";

const workspaceAPIClient = new APIInstance({
    baseURL: API_WORKSPACE,
    environment: APIM_ENVIRONMENT,
    subscriptionKey: APIM_SUBSCRIPTION_KEY,
}).axios;

export default workspaceAPIClient;
