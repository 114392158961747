import { AppBar, Box, CardActions, CircularProgress, Divider, List, Tab, Tabs } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useMyCustomReports } from './hooks/useMyCustomReports';
import SwipeableViews from 'react-swipeable-views';
import TabPanel from "components/TabPanel/TabPanel";
import { useStyles } from '../../style';
import { IDistributedCustomReports, IFavoriteCustomReports } from 'model/entity/CustomReports.entity';
import { Button, FileTextIcon, Notification, PageAlert, PlusIcon } from '@primeroedge/ui-components';
import BaseCard from 'components/BaseCard/BaseCard';
import TabBadge from '../TabBadge';
import ListItem from '../ListItem';
import ViewCustomReport from './ViewCustomReport';
import { useDistrictTimeZone } from 'view/pages/common/useDistrictTimezone';
import { convertToDistrictDateTimeFormat } from 'view/pages/common/date-utils';
import FavoriteReportMenu from './FavoriteReportMenu';
import { isNil } from 'lodash';
import MailWindow from './MailWindow';
import DistributedReportMenu from './DistributedReportMenu';

const MyCustomReports = () => {
    const { handleChange,
        value,
        isLoading,
        customReportsData,
        reportsCount,
        onFavoriteReportClick,
        openDrawer,
        closeDrawer,
        selectedFavorite,
        downloadFile,
        alertContent,
        setAlertContent
    } = useMyCustomReports();

    const classes = useStyles();

    const { selectedDistrictTimezone } = useDistrictTimeZone();
    const getDistributedTableData = (data: IDistributedCustomReports) => {
        return (
            <span style={{ fontSize: "13px" }}>
                {convertToDistrictDateTimeFormat(data.distributedOn,
                    selectedDistrictTimezone, "MM/DD/YYYY")}
            </span>
        );
    };
    return (
        <>
            <Notification
                color={alertContent?.color ?? 'success'}
                duration={5000}
                message={alertContent?.message}
                onClose={() => setAlertContent(null)}
                open={!isNil(alertContent?.message)}
                variant="filled"
            />
            <ViewCustomReport openDrawer={openDrawer} closeDrawer={closeDrawer} selectedFavorite={selectedFavorite} />
            <MailWindow />
            <BaseCard
                title="My Custom Reports"
                CardIcon={<FileTextIcon />}
            >
                <AppBar position="static" color="transparent">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        aria-label="Reports"
                        variant="fullWidth"
                    >
                        <Tab
                            id="inboxTab"
                            label={(
                                <TabBadge
                                    badgeContent={reportsCount.favoritesCount}
                                    max={99}
                                    showZero={true}
                                    color="primary"
                                >
                                    STARRED REPORTS
                                </TabBadge>
                            )}
                            aria-controls="inbox-tab"
                        />
                        <Tab
                            id="broadcastTab"
                            label={(
                                <TabBadge
                                    badgeContent={reportsCount.distributedCount}
                                    max={99}
                                    showZero={true}
                                    color="primary"
                                >
                                    DISTRIBUTED REPORTS
                                </TabBadge>
                            )}
                            aria-controls="broadcast-tab"
                        />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    index={value}
                    className={`${classes.msgContentHeight}`}
                >
                    <TabPanel value={value} index={0}>
                        {(isLoading || selectedDistrictTimezone === null) && (
                            <Box className={classes.tabContainer}>
                                <CircularProgress color="secondary" />
                            </Box>
                        )}
                        {!isLoading && selectedDistrictTimezone !== null && ((customReportsData && customReportsData?.customReportFavorites?.length > 0) ? (
                            <List>
                                {customReportsData.customReportFavorites.map((favorite: IFavoriteCustomReports, i: number) => (
                                    <Fragment key={i}>
                                        <ListItem
                                            onClick={() => onFavoriteReportClick(favorite)}
                                            subTitle={favorite?.customReportDescription ?? ""}
                                            title={(
                                                <span>
                                                    {favorite.customReportName}
                                                </span>
                                            )}
                                            info={(
                                                <span style={{ fontSize: "13px", marginRight: '10px' }}>
                                                    {favorite.moduleName}
                                                </span>
                                            )}
                                            action={<FavoriteReportMenu favorite={favorite} setAlertContent={setAlertContent}/>}
                                        />
                                        <Divider />
                                    </Fragment>
                                ))}
                            </List>
                        ) : (
                            <>
                                <Box className={classes.tabContainer} style={{ minHeight: "auto", paddingBottom: "5px", alignItems: 'center', alignContent: 'center', display: 'flex', marginTop: '100px' }}>
                                    <PageAlert
                                        message="New starred reports will show up here!"
                                        variant="transparent"
                                    />
                                </Box>
                                <p className={classes.pageAlertTxt} >
                                    Click on View All to browse all custom reports!</p>
                            </>
                        ))}
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        {isLoading && (
                            <Box className={classes.tabContainer}>
                                <CircularProgress color="secondary" />
                            </Box>
                        )}
                        {!isLoading && customReportsData && customReportsData?.customReportDistributeds?.length > 0 ? (
                            <List>
                                {customReportsData.customReportDistributeds.map((distributedReport: IDistributedCustomReports, i: number) => (
                                    <Fragment key={i + 10}>
                                        <ListItem
                                            title={(
                                                <span>
                                                    {distributedReport.customReportName}
                                                </span>
                                            )}
                                            onClick={() => downloadFile(distributedReport)}
                                            info={getDistributedTableData(distributedReport)}
                                            subTitle={distributedReport?.customReportDescription ?? ""}
                                            action={<DistributedReportMenu distributedReport={distributedReport} />}
                                        />
                                        <Divider />
                                    </Fragment>
                                ))}

                            </List>
                        ) : (
                            <Box className={classes.tabContainer}>
                                <PageAlert
                                    message="New distributed reports will show up here!"
                                    variant="transparent"
                                />
                            </Box>
                        )}
                    </TabPanel>
                </SwipeableViews>

                <CardActions className={classes.taskCardAction}>
                    <Button
                        color="primary"
                        dense={true}
                        id="primaryButton"
                        name="Button"
                        onClick={() => { window.location.href = '/reports/Custom-Reports?formMode=ADD' }}
                        startIcon={<PlusIcon />}
                        type="button"
                        variant="default"
                    >
                        New
                    </Button>

                    <Button
                        color="default"
                        dense={true}
                        id="defaultButton"
                        name="viewAll"
                        onClick={() => { window.location.href = '/reports/Custom-Reports' }}
                        type="button"
                        variant="default"
                    >
                        View All
                    </Button>
                </CardActions>
            </BaseCard>
        </>
    )

}

export default MyCustomReports;