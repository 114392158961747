import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import { IMail, IMailEntity } from "model/entity/mail.entity";
import { AuthLogout } from "../../control/actions";
import * as actions from "../../control/actions/mail.actions";

const initialState = {
    setComposeMailOpen: false,
    userList: [],
    mailList: [],
    selectedMail: {},
    readMail: {},
    selectedMessage: {},
    totalMessageCount: 0,
    totalPages: 0,
    attachments: [],
};

export const mailReducer = createReducer(initialState, {
         [actions.setComposeBoXWindowOpenCLose.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             draft.setComposeMailOpen = action.payload;
           }),

         [actions.setUserMail.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             draft.userList = action.payload;
           }),
         [actions.getMail.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             draft.mailList = action.payload.messages;
             if (action.payload.totalMessageCount) {
               draft.totalMessageCount = action.payload.totalMessageCount;
               draft.totalPages = action.payload.totalPages;
             }
           }),
         [actions.clearMail.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             draft.mailList = action.payload;
           }),
         [actions.selectedMail.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             if (action.payload === {}) {
               const index = draft.mailList.findIndex(
                 (mail) => mail.id === action.payload.messageId[0],
               );
               const tempMail = draft.mailList[index];
               draft.mailList[index] = { ...tempMail, isRead: true };
             }
             draft.selectedMail = action.payload;
           }),
         [actions.getSelectedMail.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             draft.selectedMail = action.payload;
           }),
         [actions.getReadMail.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             draft.readMail = action.payload;
           }),
         [actions.selectedBroadcastMessage.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             draft.selectedMessage = action.payload;
           }),

         [actions.updateMailList.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             draft.mailList = action.payload;
           }),
         [actions.updateReadStatusSuccess.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             // const index = draft.mailList.findIndex((mail) => mail.id === action.payload.messageId[0]);
             // const tempMail = draft.mailList[index];
             // draft.mailList[index] = { ...tempMail, isRead: true };
             const tempMailList = draft.mailList;
             tempMailList.forEach((mail: IMailEntity) => {
               if (mail.id === action.payload.messageId[0]) {
                 mail.isRead = !mail.isRead;
               }
             });
             draft.mailList = [...tempMailList];
           }),
         [actions.deletedMailsSuccess.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             const tempMailsList = draft.mailList.filter(
               (mail: IMailEntity) => !action.payload.id.includes(mail.id),
             );
             draft.mailList = [...tempMailsList];
           }),
         [actions.updateAttachment.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             let attachments = draft.attachments;
             attachments = attachments.concat(action.payload);
             draft.attachments = attachments;
           }),
         [actions.updateAttachmentArray.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             draft.attachments = action.payload;
           }),
         [actions.updateMailsReadSuccess.toString()]: (state) =>
           produce(state, (draft: IMail) => {
             const tempMailsList = draft.mailList;
             for (let i = 0; i <= tempMailsList.length; i++) {
               tempMailsList[i].isRead = true;
             }
             draft.mailList = tempMailsList;
           }),
         [actions.setMailListServiceCallStatus.toString()]: (state, action) =>
           produce(state, (draft: IMail) => {
             draft.status = action.payload;
           }),
         [AuthLogout.toString()]: () => initialState,
       });
