import { updateFavoritesOrderInit } from "control/actions/myPages.action";
import { getFavorites } from "model/entity/myPages.entity";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useViewAllFavorites = () => {
    const dispatch = useDispatch();

    const getFavoritesList = useSelector(getFavorites);
    const [favoritesData, setFavoritesData] = useState([]);

    useEffect(() => {
        if (getFavoritesList?.length > 0) {
            setFavoritesData(getFavoritesList);
        }
    }, [getFavoritesList])

    const onUpdateFavoritesOrder = (reorderedItems: any) => {
        const updatedFavoritesOrder = reorderedItems.map(
            (item: any, index: any) => ({
                id: item.id,
                displayorder: index + 1,
            })
        );
        dispatch(updateFavoritesOrderInit(updatedFavoritesOrder))
    }

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return;
        const reorderedItems = Array.from(favoritesData);
        const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, reorderedItem);
        setFavoritesData(reorderedItems);
        onUpdateFavoritesOrder(reorderedItems);
    };

    return {
        favoritesData,
        handleOnDragEnd
    }
}