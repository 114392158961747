import { useAuth, useProfile } from "@primeroedge/ui-components";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Route, RouteProps } from "react-router";
import { AuthLogin, AuthLogout } from "../control/actions";

export interface IRouteWithSubRoutesProps extends RouteProps {
    routes?: RouteProps[];
}

const RouteWithSubRoutes: React.FC<RouteProps> = (route: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isAuthenticated } = useAuth();
    const {user} = useProfile();
    const validationCollectionFieldForOnboarding = localStorage.getItem("validationCollectionFieldForOnboarding");

    const onDashboardV2EnableListener = function() {
        history.push('/?miniView=true')
    }

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(AuthLogin());
            if( user?.Roles.includes('District Onboarding')) {
                window.location.replace("/system/setup-onboarding")
            } 
            else if (validationCollectionFieldForOnboarding ===
                'RedirectToProfilePage') {
                history.push("/workspace/profile")
                }
        } else {
            dispatch(AuthLogout());
        }
    }, [dispatch, isAuthenticated]);

    useEffect(function() {
        window.addEventListener('show-dashboard-v2', onDashboardV2EnableListener);

        return function() {
            window.removeEventListener('show-dashboard-v2', onDashboardV2EnableListener)
        }
    }, [])

    const renderChildRoute = (props: any) => (
        <route.component {...props} routes={route.routes} />
    );
    const renderRoutes = () => {
        if(isAuthenticated) {
            if(user?.Roles.includes('District Onboarding')) {
                return <Route path="/system/setup-onboarding" render={renderChildRoute} />
            } else {
                return <Route path={route.path} render={renderChildRoute} />
            }
        } else {
            history.push('/login');
            return null;
        }
    }
    return renderRoutes();
};

export default RouteWithSubRoutes;
