import { Box, Grid } from "@material-ui/core";
import { MainLayout, ModuleName, useProfile, Notification } from "@primeroedge/ui-components";
import { APIM_BASE_URL, APIM_SUBSCRIPTION_KEY } from "control/transport/clients/api-constants";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkspaceDetails } from "../../../control/actions";
import Banner from "../../Banner/index";
import DistrictSelection from "../districtSelection-page";
import MyMessages from "./components/MyMessages/MyMessages";
import MyTask from "./components/MyTask/MyTask";
import MyCustomReports from "./components/MyStarredReports";
import { getFavoriteCustomReportsInit } from "control/actions/customReports.actions";
import { getFavoriteDeleteStatus, getFavoriteUpdateStatus } from "model/entity/myPages.entity";
import { getFavoritesInit } from "control/actions/myPages.action";
import MyPages from "./components/MyPages/MyPages";
import SmartView from './SmartView';
import { useLocation } from 'react-router-dom';
import qs from 'qs'

const DashboardPage: FC = () => {
    const dispatch = useDispatch();
    const [onboarding, setonboarding] = useState<boolean>(false);
    const [broadcastMessageStatus, setBroadcastMessageStatus] = useState<boolean>(false);

    const { user }: any = useProfile();
    const activeDistrict = localStorage.getItem("activeDistrict");
    const deleteFavoriteStatus = useSelector(getFavoriteDeleteStatus);
    const updateFavoriteStatus = useSelector(getFavoriteUpdateStatus);
    let selectedDistrict: any;
    selectedDistrict = activeDistrict && JSON.parse(activeDistrict);

    const location = useLocation()
    const [isSmartDashboard, setIsSmartDashboard] = useState<boolean>(function () {
        return JSON.parse(localStorage.getItem('dashboardV2') ?? 'false');
    })

    useEffect(() => {
        if (user !== null && user.Roles && user.Roles.includes("Onboarding")) {
            setonboarding(true);
        }
    }, [user]);

    useEffect(() => {
        dispatch(fetchWorkspaceDetails());
        dispatch(getFavoriteCustomReportsInit());
    }, [dispatch]);

    const onReturnToDashboard = function () {
        setIsSmartDashboard(false);
        localStorage.setItem('dashboardV2', 'false');
    }

    const onDashboardV2EnableListener = function () {
        const query = qs.parse(location.search.split('?')[1])
        if (query.miniView) {
            setIsSmartDashboard(true);
        }
    }

    useEffect(function () {
        onDashboardV2EnableListener()
    }, [location])

    const onActiveDistrictChange = () => {
        dispatch(getFavoriteCustomReportsInit());
    }
    const [successAlertMessage, setSuccessAlertMessage] = useState<String>('');
    const [errorAlertMessage, setErrorAlertMessage] = useState<String>('');

    useEffect(() => {
        if (deleteFavoriteStatus) {
            setSuccessAlertMessage("Successfully Deleted.");
            dispatch(getFavoritesInit({
                userId: user ? Number(user?.UserId) : 0,
                regionId: selectedDistrict?.attributes?.regionId ? Number(selectedDistrict?.attributes?.regionId[0]) : 0,
            }))
        }
        if (updateFavoriteStatus) {
            setSuccessAlertMessage("Successfully Done.");
            dispatch(getFavoritesInit({
                userId: user ? Number(user?.UserId) : 0,
                regionId: selectedDistrict?.attributes?.regionId ? Number(selectedDistrict?.attributes?.regionId[0]) : 0,
            }))
        }
    }, [deleteFavoriteStatus, updateFavoriteStatus]);

    return (
        <>
            {successAlertMessage !== '' && (
                <Notification
                    color="success"
                    duration={5000}
                    message={successAlertMessage}
                    onClose={() => setSuccessAlertMessage(() => '')}
                    open
                    variant="filled"
                />
            )}
            {errorAlertMessage !== '' && (
                <Notification
                    color="error"
                    duration={5000}
                    message={errorAlertMessage}
                    onClose={() => setErrorAlertMessage(() => '')}
                    open
                    variant="filled"
                />
            )}
            <MainLayout
                noNavigation={isSmartDashboard} onActiveDistrictChange={onActiveDistrictChange}
                apimUrl={`${APIM_BASE_URL}`}
                moduleName={ModuleName.Workspace}
                subscriptionKey={`${APIM_SUBSCRIPTION_KEY}`}
                broadcastMessageExists={broadcastMessageStatus}>
                {!onboarding ?
                    isSmartDashboard ?
                    (<SmartView returnToDashboard={onReturnToDashboard} />) : (
                    <>
                        {(<Banner
                                onBroadCastDisplay={(prop: boolean) => setBroadcastMessageStatus(prop)} />)}
                        <Box p={broadcastMessageStatus ? 0 : 3}>
                            <Grid container={true} spacing={3} style={{ marginTop: 20 }}>
                                <Grid item={true} xs={12} sm={12} md={6}>
                                    <MyMessages />
                                </Grid>
                                <Grid item={true} xs={12} sm={12} md={6}>
                                    <MyTask />
                                </Grid>
                                <Grid item={true} xs={12} sm={12} md={6}>
                                    <MyCustomReports />
                                </Grid>
                                <Grid item={true} xs={12} sm={12} md={6}>
                                    <MyPages />
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                ) : (
                    <DistrictSelection />
                )}
            </MainLayout>
        </>
    );
};

export default DashboardPage;
