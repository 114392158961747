import { Grid, makeStyles, Switch } from "@material-ui/core";
import {
    AccountStatus,
    Alert,
    AppLoader,
    Button,
    IPermission,
    IResources,
    ModuleName,
    PageLayout,
    SummaryList,
    useProfile,
    UserCard
} from "@primeroedge/ui-components";
import Axios from "axios";
import { APIM_ENVIRONMENT, APIM_SUBSCRIPTION_KEY, API_DATA_EXCHANGE } from "control/transport/clients/api-constants";
import _ from "lodash";
import { ISystemPagePermissions } from "model/entity/permissions.entity";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailsKeyclockInit, passwordPoliciesInit } from "../../../control/actions";
import {
    getIsUserLoading,
    getPasswordPolicies,
    getUserDetailsKeyclockEntity, IAdminServiceUserDetails,
    IPasswordPolicies,
    IUserMgmatUserDetails
} from "../../../model/entity/profile.entity";
import * as userEntity from "../../../model/entity/user.entity";

interface IViewUserProps {
    selectedUser?: any;
    editUser: any;
    organizationData: any;
    onClose: () => void;
}

const useStyles = makeStyles(() => ({
    loaderContainer: {
        height: "60vh",
        padding: "10px",
    },
}));

const ViewUser = (props: IViewUserProps) => {
    const styles = useStyles({});
    const { selectedUser, editUser } = props;
    const { user } = useProfile();

    const onEditClick = () => {
        editUser(userData, regions, areas, sites, accessLevelDescription, roles, _.toString(userGuid), userStatus, firstName, lastName, email,
            cellPhone, workPhone, primeroUserId, middleName, image, lastPasswordChange, userImageUrl, attributes, passwordAge);
    };

    const userData = useSelector(userEntity.getUserData);
    const userDetails = useSelector(getUserDetailsKeyclockEntity);
    const passwordPoliciesData = useSelector(getPasswordPolicies);
    const isUserLoading = useSelector(getIsUserLoading);
    const dispatch = useDispatch();
    const [keyCloakData, setKeyCloakData] = useState<IUserMgmatUserDetails[]>([]);
    const [adminData, setAdminData] = useState({} as IAdminServiceUserDetails);
    const [regions, setRegions] = useState<string>("");
    const [areas, setAreas] = useState<string>("");
    const [sites, setSites] = useState<string>("");
    const [roles, setRoles] = useState<string>("");
    const [userGuid, setuserGuid] = useState<string>("");
    const [bool, setBool] = useState<boolean>(false);
    const [userStatus, setUserStatus] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [cellPhone, setCellPhone] = useState<string>("");
    const [workPhone, setWorkPhone] = useState<string>("");
    const [image, setImage] = useState<string>("");
    const [primeroUserId, setPrimeroUserId] = useState<string>("");
    const [middleName, setMiddleName] = useState<string>("");
    const [lastPasswordChange, setLastPasswordChange] = useState<string>("");
    let site: string;
    const siteObj: any[] = [];
    const [accessLevelDescription] = useState<string>("");
    const [userImageUrl, setUserImageUrl] = useState<string>("");
    const [attributes, setAttributes] = useState<any>(null);
    const [minPasswordExpiry, setMinPasswordExpiry] = useState<number>(0);
    const [date1, setDate1] = useState<any>();
    const [date2, setDate2] = useState<any>();
    const [diffTime, setDiffTime] = useState<any>();
    const [diffDays, setDiffDays] = useState<any>();
    const [passwordAge, setPasswordAge] = useState<number>(0);
    const [Toggle, setToggle] = useState<boolean>(false);
    const today = Date.now();
    const todaysDate = new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).format(today);
    const [editPermission, setEditPermission] = useState<boolean>(false);

    useEffect(() => {
        if (user?.RolesPermissions) {
            user?.RolesPermissions.forEach((permission: IPermission) => {
                if (permission?.moduleName === ModuleName.System && permission?.resources?.length > 0) {
                    permission?.resources?.forEach((resource: IResources) => {
                        if (resource?.resourceName === ISystemPagePermissions.Users && resource?.scopes.includes("Edit")) {
                            setEditPermission(true);
                        }
                    });
                }
            });
        }
    }, [user]);

    useEffect(() => {
        if (!selectedUser || !selectedUser.attributes) {
            setBool(true);
        }
        if ( selectedUser && selectedUser !== "" && bool === true) {
            setBool(false);
            dispatch(getUserDetailsKeyclockInit(selectedUser.email));
            dispatch(passwordPoliciesInit());
        }
    }, [selectedUser, bool]);

    useEffect(() => {
        if (selectedUser && (selectedUser.email ===
            (userDetails.AdminServiceUserDetails.PayLoad.email &&
                userDetails.AdminServiceUserDetails.PayLoad.email.toLowerCase()))
            && (userDetails.UserMgmatUserDetails || userDetails.AdminServiceUserDetails)) {
            setKeyCloakData(userDetails.UserMgmatUserDetails.PayLoad);
            setAdminData(userDetails.AdminServiceUserDetails.PayLoad);
        }
    }, [selectedUser, userDetails]);

    useEffect(() => {
        if (adminData) {
            setUserImageUrl("");
            setWorkPhone(adminData.workPhone);
            setCellPhone(adminData.cellPhone);
            setMiddleName(adminData.mi);
        }
        if (adminData.image) {
            downloadImage(adminData.image);
            setImage(adminData.image);
        }
    }, [adminData]);

    useEffect(() => {

        if (keyCloakData.length > 0) {
            for (let i = 0; i < _.flatten(_.flatten(keyCloakData?.map((a) => a.groups))).length; i++) {
                if (_.flatten(_.flatten(keyCloakData.map((a) => a.groups)).map((a) => a.attributes).map((a) => a.organizationLevel))[i] === "District"
                    && _.flatten(_.flatten(keyCloakData.map((a) => a.groups)).map((a) => a.attributes).map((a) => a.parentRegionId))[i] === "-10"
                    && _.flatten(_.flatten(keyCloakData.map((a) => a.groups)).map((a) => a.attributes).map((a) => a.type))[i] === "group") {
                    setRegions(_.flatten(_.flatten(keyCloakData.map((a) => a.groups)).map((a) => a.name))[i]);
                }
            }
            for (let i = 0; i < _.flatten(_.flatten(keyCloakData.map((a) => a.groups))).length; i++) {
                if (_.flatten(_.flatten(keyCloakData.map((a) => a.groups)).map((a) => a.attributes).map((a) => a.organizationLevel))[i] === "Area"
                    && _.flatten(_.flatten(keyCloakData.map((a) => a.groups)).map((a) => a.attributes).map((a) => a.parentRegionId))[i] !== "-10"
                    && _.flatten(_.flatten(keyCloakData.map((a) => a.groups)).map((a) => a.attributes).map((a) => a.type))[i] === "group") {
                    setAreas(_.flatten(_.flatten(keyCloakData.map((a) => a.groups)).map((a) => a.name))[i]);
                }
            }
            setFirstName(keyCloakData.map((first) => first.firstName).toString());
            setLastName(keyCloakData.map((last) => last.lastName).toString());
            setEmail(keyCloakData.map((a) => a.email).toString());
            setPrimeroUserId(keyCloakData.map((userId) => userId.primeroUserId).toString());
            if (keyCloakData.map((a) => a.attributes)[0]) {
                setUserStatus(_.flatten(keyCloakData.map((a) => a.attributes).map((s) => s.userStatus)).toString());
                setLastPasswordChange(_.flatten(keyCloakData.map((a) => a.attributes).map((s) => s.lastPasswordChangeDate)).toString());
            }
            setAttributes(keyCloakData.map((a) => a.attributes));
            for (let i = 0; i < _.flatten(_.flatten(keyCloakData.map((a) => a.groups))).length; i++) {
                if (_.flatten(_.flatten(keyCloakData.map((a) => a.groups)).map((a) => a.attributes).map((a) => a.type))[i] === "Site"
                    // && _.flatten(_.flatten(keyCloakData.map((a) => a.groups)).map((a) => a.attributes).map((a) => a.siteTypeCode))[i] === "SCHL"
                ) {
                    site = (_.flatten(_.flatten(keyCloakData.map((a) => a.groups)).map((a) => a.name))[i]);
                    siteObj.push(site);
                }
            }
            setSites(_.toString(_.sortBy(siteObj)));
        }
        let rolesData: string;
        const roleObj: any[] = [];
        if (keyCloakData.length > 0) {
            _.sortBy(_.flatten(keyCloakData.map((user: any) => user.roles))
                .map((role: any) => {
                    rolesData = role.name;
                    roleObj.push(rolesData);
                }));
        }
        setRoles(_.toString(_.sortBy(roleObj)));
        if (selectedUser && selectedUser.attributes) {
            if (keyCloakData.length > 0) {
                keyCloakData.forEach((guid: any) => setuserGuid(guid.userGuid));
            }
        }

    }, [keyCloakData]);

    useEffect(() => {
        if (lastPasswordChange) {
            setDate1(new Date(lastPasswordChange));
            setDate2(new Date(todaysDate));
        }
    }, [lastPasswordChange]);
    useEffect(() => {
        if (date1 && date2) {
            setDiffTime(Math.abs(date2 - date1));
        }
    }, [date1, date2]);
    useEffect(() => {
        if (diffTime >= 0) {
            setDiffDays(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
        }
    }, [diffTime]);

    useEffect(() => {
        if (passwordPoliciesData.length > 0) {
            for (let i = 0; i < passwordPoliciesData.length; i++) {
                if (passwordPoliciesData.map((a: IPasswordPolicies) => a.code)[i] === "MPA") {
                    setMinPasswordExpiry(parseInt(passwordPoliciesData.map((a: IPasswordPolicies) => a.policyValue)[i]));
                }
            }
        }
    }, [passwordPoliciesData]);

    useEffect(() => {
        if (diffDays >= 0) {
            setPasswordAge(minPasswordExpiry - diffDays);
        }
    }, [diffDays]);

    const downloadImage = (blob: string) => {
        const fileURL = `${API_DATA_EXCHANGE}/api/FileStorage/Download?featureName=user&fileName=${blob}`;

        Axios.get(fileURL, {
            responseType: "blob",
            headers: {
                Environment: APIM_ENVIRONMENT,
                "Ocp-Apim-Subscription-Key": APIM_SUBSCRIPTION_KEY,
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": `application/octet-stream`,
            },
        })
            .then((resp) => {
                const dwnldlink = window.URL.createObjectURL(
                    new Blob([resp.data], {
                        type: resp.headers["content-type"],
                    }),
                );
                setUserImageUrl(dwnldlink);
            })
            .catch((ex) => {
                console.info(ex);
            });
    };

    const handleCancel = () => {
        setUserImageUrl("");
        props.onClose();
    };
    useEffect(() => {
        if (userDetails.UserMgmatUserDetails.PayLoad.length > 0) {
            if (userDetails.UserMgmatUserDetails.PayLoad[0].attributes?.isBroadcastUser === undefined) {
                setToggle(false);
            } else if (userDetails.UserMgmatUserDetails.PayLoad[0].attributes?.isBroadcastUser[0] === "true") {
                setToggle(true);
            } else {
                setToggle(false);
            }
        }
    });
    return (
        <>
            {isUserLoading && selectedUser === undefined ? (
                <Grid container={true} spacing={2}>
                    <Grid xs={12} item={true} classes={{ root: styles.loaderContainer }}>
                        {<AppLoader />}
                    </Grid>
                </Grid>
            ) : (
                < PageLayout
                    breadCrumbs={[
                        {
                            title: "System",
                        },
                    ]}
                    title="User Information"
                >
                    {(keyCloakData.length === 0 || bool) &&
                        (
                            <Grid container={true} spacing={2}>
                                <Grid xs={12} item={true} classes={{ root: styles.loaderContainer }}>
                                    {<AppLoader />}
                                </Grid>
                            </Grid>
                        )
                    }
                    {!(keyCloakData.length === 0 || bool) && keyCloakData && adminData &&
                        (
                            <Grid container={true} spacing={2}>
                                <Grid xs={12} md={4} lg={3} item={true}>
                                    <UserCard
                                        name={`${firstName} ${lastName}`}
                                        imageSrc={userImageUrl}
                                        status={_.toUpper(userStatus) === _.toUpper("Active") ? AccountStatus.Active
                                            : AccountStatus.InActive}
                                        statusText={_.toUpper(userStatus) === _.toUpper("Active") ? "Active" : (userStatus === "Locked") ? "Locked" : "Disabled"}
                                        actionButton={() => (
                                            <>
                                                {editPermission && (
                                                    <Button
                                                        color="primary"
                                                        id="successButton"
                                                        label="Edit"
                                                        name="Button"
                                                        onClick={onEditClick}
                                                        type="button"
                                                        variant="default"
                                                    />
                                                )}
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid xs={12} md={8} lg={9} item={true} >
                                    <Grid container={true} spacing={1}>
                                        <Grid item={true} xs={12}>
                                            <SummaryList
                                                header="Contact Info"
                                                items={[
                                                    {
                                                        title: "Name",
                                                        value: `${firstName} ${lastName}`,
                                                    },
                                                    {
                                                        title: "Email",
                                                        value: email,
                                                    },
                                                    {
                                                        title: "Cell Phone",
                                                        value: cellPhone,
                                                    },
                                                    {
                                                        title: "Work Phone",
                                                        value: workPhone,
                                                    },
                                                    {
                                                        title: "Is Broadcast User",
                                                        value: (
                                                            <Switch
                                                                color="primary"
                                                                name="broadcast"
                                                                id="isBroadcastUser"
                                                                inputProps={{ "aria-label": "true" }}
                                                                checked={Toggle}
                                                            />
                                                        ),
                                                    },
                                                ]}
                                                titleClassName={null}
                                                valueClassName={null}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12} style={{ marginTop: 20 }}>
                                            <SummaryList
                                                header="Security"
                                                items={[
                                                    {
                                                        title: "Password",
                                                        value: (
                                                            <div style={{ padding: "15px 0" }}>
                                                                <Alert
                                                                    color="info"
                                                                    onClose={null}
                                                                    open={true}
                                                                    message={(
                                                                        <>
                                                                            <span style={{ lineHeight: "1.5", fontSize: "12px", fontWeight: "bold" }}>
                                                                                *User's password will expire in {passwordAge} days.
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        ),
                                                    },
                                                ]}
                                                titleClassName={null}
                                                valueClassName={null}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12} style={{ marginTop: 20 }}>
                                            <SummaryList
                                                header="Organization & Permissions"
                                                items={[
                                                    {
                                                        title: "Organizational Level",
                                                        value: accessLevelDescription,
                                                    },
                                                    {
                                                        title: "Region(s)",
                                                        value: regions,
                                                    },
                                                    {
                                                        title: "Area(s)",
                                                        value: areas,
                                                    },
                                                    {
                                                        title: "Site(s)",
                                                        value: sites,
                                                    },
                                                    {
                                                        title: "Role",
                                                        value: roles,
                                                    },
                                                ]}
                                                titleClassName={null}
                                                valueClassName={null}
                                            />
                                        </Grid>
                                        <Grid
                                            container={true}
                                            xs={12}
                                            style={{ marginTop: 24 }}
                                            direction="row"
                                            justify="flex-end"
                                            alignItems="center"
                                        >
                                            <Button
                                                color="default"
                                                id="cancelButton"
                                                label="Cancel"
                                                name="Button"
                                                onClick={handleCancel}
                                                type="button"
                                                variant="default"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }
                </PageLayout>
            )
            }
        </>
    );
};

export default ViewUser;
