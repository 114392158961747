import React, { FC, useEffect, useState } from "react";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import { AlertCircleIcon, Button, ITheme } from "@primeroedge/ui-components";
import clsx from "clsx";
import {
    getBroadcastBanner,
    getGlobalBroadcatBanner,
    updateBroadCast,
} from "control/transport/message-api";
import GlobalMessages from "./GlobalMessages";
import { getGlobalMessagesDismissed } from "model/entity/message.entity";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        padding: "8px 0",
        borderRadius: 0,
        color: "black",
        zIndex: 1,
        position: "relative",
        backgroundColor: theme.palette.tertiary.dark,
        marginTop: '25px'
    },
    globalMessageRoot: {
        padding: "8px 0",
        borderRadius: 0,
        color: "white",
        zIndex: 1,
        position: "relative",
        backgroundColor: theme.palette.secondary.main,
        marginTop: '25px',
    },
    bannerLink: {
        textDecoration: "underline",
        cursor: "pointer",
    },
    messageContent: {
        padding: "unset",
        overflow: "unset",
        fontSize: "0.84em",
        "& a": {
            color: "inherit",
        },
    },
}));

interface IBannerProps {
    onBroadCastDisplay?: (props: boolean) => void;
    className?: any;
}

const Banner: FC<IBannerProps> = ({ onBroadCastDisplay, className }) => {
    const classes = useStyles();
    const [more, setMore] = useState(false);
    const [messages, setMessages] = useState<any[]>([]);
    const [globalMessages, setGlobalBroadcastMessages] = useState<any[]>([]);
    const getGlobalMessagesDismissedFromReducer = useSelector(getGlobalMessagesDismissed);
    const [updatedGlobalMessage, setUpdatedGlobalMessages] = useState<any[]>([]);

    useEffect(() => {
        getBroadcastBanner(setMessages);
        getGlobalBroadcatBanner(setGlobalBroadcastMessages);
    }, []);

    useEffect(() => {
        if (onBroadCastDisplay) {
            if ((messages && messages.length > 0) || 
            (updatedGlobalMessage && 
                updatedGlobalMessage.length > 0)) {
                onBroadCastDisplay(true);
            } else {
                onBroadCastDisplay(false);
            }
        }
    }, [messages, onBroadCastDisplay, updatedGlobalMessage]);

    const dismissBroadcast = () => {
        const data = { id: [messages[0].messageId] };
        updateBroadCast(data)
            .then(() => {
                getBroadcastBanner(setMessages);
            });
    };
    return (
        <Box style={{ marginTop: 50 }}>
        {globalMessages && globalMessages.length > 0 && (
            <GlobalMessages messages={globalMessages} setUpdatedGlobalMessages={setUpdatedGlobalMessages} classes={classes}/>
        )}
            { getGlobalMessagesDismissedFromReducer.length === globalMessages.length && 
            messages && messages.length > 0 && !messages[0].isRead && (
                <Box id="broadcast-messages-container" className={clsx(classes.root, className)}>
                    <Container maxWidth="lg">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box display="flex" flexDirection="column">
                                <Box display="flex" alignItems="center">
                                    <AlertCircleIcon />&ensp;
                                    <Typography variant="h6" component="p">
                                        BROADCAST MESSAGE
                                    </Typography>
                                </Box>
                                <Box className={clsx("ql-editor", classes.messageContent)}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: `${messages[0].content.substring(
                                                0,
                                                more ? messages[0].content.length : 75,
                                            )}`,
                                        }}
                                    />
                                    <span>
                                        {!more && messages[0].content.length > 75 && (
                                            <a
                                                onClick={() => setMore(true)}
                                                className={classes.bannerLink}
                                            >
                                                View More
                                            </a>
                                        )}
                                        {more && (
                                            <a
                                                className={classes.bannerLink}
                                                onClick={() => setMore(false)}
                                            >
                                                Show Less
                                            </a>
                                        )}
                                    </span>
                                </Box>
                            </Box>
                            <Box display="flex" flexWrap="nowrap">
                                <Button
                                    id="defaultButton"
                                    name="Button"
                                    onClick={dismissBroadcast}
                                    type="button"
                                    variant="text"
                                    color="inherit"
                                >
                                    Dismiss
                                </Button>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            )}
        </Box>
    );
};

export default Banner;
