
import * as actions from "control/actions/user.actions";
import { isEmpty } from "lodash";
import { put, takeLatest } from "redux-saga/effects";
import UsersTransportLayer from "../transport/user.transport";


function* userDetailsSaga (payload: any) {
  try {
    const userData = yield UsersTransportLayer.getUser(payload.payload);
    if (!isEmpty(userData)) {
      yield put(actions.userFetchSuccess(userData));
    }
  } catch (error) {
    yield put(actions.userFetchFail());
  }
}

function* usersAddSaga (data: any) {
  try {
    const userAddData = yield UsersTransportLayer.addUsers(data);
    if (
      Array.isArray(userAddData.ResponseEnvelope?.ValidationCollection) &&
      userAddData.ResponseEnvelope.ValidationCollection.length
    ) {
      yield put(
        actions.userEditFail(userAddData.ResponseEnvelope.ValidationCollection),
      );
    } else {
      yield put(actions.userAddSuccess(userAddData));
      data.payload.action(userAddData);
      // yield put(
      //   actions.usersFetchInit({
      //     pageSize: 10000,
      //     pageNumber: 1,
      //     sortColumn: "userId",
      //     sortOrder: 1,
      //   }),
      // );
    }
  } catch (error) {
    yield put(actions.userAddFail(error));
  }
}

function* usersEditSaga (data: any) {
  try {
    const userEditData = yield UsersTransportLayer.editUsers(data);
    if (
      Array.isArray(userEditData.ResponseEnvelope?.ValidationCollection) &&
      userEditData.ResponseEnvelope?.ValidationCollection.length
    ) {
      yield put(
        actions.userEditFail(userEditData.ResponseEnvelope.ValidationCollection),
      );
    } else {
      yield put({
        type: actions.userEditSuccess,
        payload: userEditData,
      });
      data.payload.action(userEditData);
    }
  } catch (error) {
    yield put(actions.userEditFail(error));
  }
}

function* usersUpdateStatusSaga (action: {
  payload: { userId: number; userStatusId: number };
}) {
  try {
    yield UsersTransportLayer.updateUserStatus(
      action.payload.userId,
      action.payload.userStatusId,
    );
    yield put(
      actions.userUpdateStatusSuccess(
        action.payload.userId,
        action.payload.userStatusId,
      ),
    );
    yield put(actions.userFetchInit(action.payload.userId));
    // yield put(
    //   actions.usersFetchInit({
    //     pageSize: 10000,
    //     pageNumber: 1,
    //     sortColumn: "userId",
    //     sortOrder: 1,
    //   }),
    // );
  } catch (error) {
    yield put(actions.userUpdateStatusFail());
  }
}

function* viewRegionsAndSitesSaga (payload: any) {
  try {
    const usersData = yield UsersTransportLayer.getRegionsAndSites(payload.payload);
    if (!isEmpty(usersData)) {
      yield put(actions.viewRegionsAndSitesSuccess(usersData));
    } else {
      yield put(actions.viewRegionsAndSitesSuccess([]));
    }
  } catch (error) {
    yield put(actions.viewRegionsAndSitesFail());
  }
}

function* viewOrganizationSaga () {
  try {
    const organizationData = yield UsersTransportLayer.getOrganization();
    if (!isEmpty(organizationData)) {
      yield put(actions.viewOrganizationSuccess(organizationData));
    } else {
      yield put(actions.viewOrganizationSuccess([]));
    }
  } catch (error) {
    yield put(actions.viewOrganizationFail());
  }
}

function* viewSiteFilterSaga (payload: any) {
  try {
    const siteData = yield UsersTransportLayer.getSiteFilter(payload.payload);
    if (!isEmpty(siteData)) {
      yield put(actions.viewFilterSiteDataSuccess(siteData));
    } else {
      yield put(actions.viewFilterSiteDataSuccess([]));
    }
  } catch (error) {
    yield put(actions.viewFilterSiteDataFail());
  }
}

function* viewRoleFilterSaga (payload: any) {
  try {
    const roleData = yield UsersTransportLayer.getRoleFilter(payload.payload.map((title: any) => title.roleName));
    if (!isEmpty(roleData)) {
      yield put(actions.viewFilterRoleDataSuccess(roleData));
    } else {
      yield put(actions.viewFilterRoleDataSuccess([]));
    }
  } catch (error) {
    yield put(actions.viewFilterRoleDataFail());
  }
}

function* validateEmailSaga (payload: any) {
  try {
    const emailValid = yield UsersTransportLayer.getValidateEmail(payload.payload);
    if (!isEmpty(emailValid)) {
      yield put(actions.validateEmailSuccess(emailValid));
      payload.payload.action(emailValid);
    } else {
      yield put(actions.validateEmailSuccess([]));
    }
  } catch (error) {
    yield put(actions.validateEmailFail());
  }
}


function* usersResetPasswordSaga (data: any) {
  try {
    const userResetPasswordData = yield UsersTransportLayer.usersResetPassword(data);
    if (
      Array.isArray(userResetPasswordData.ResponseEnvelope?.ValidationCollection) &&
      userResetPasswordData.ResponseEnvelope?.ValidationCollection.length
    ) {
      yield put(
        actions.userResetPasswordFail(userResetPasswordData.ResponseEnvelope.ValidationCollection),
      );
    } else {
      yield put({
        type: actions.userResetPasswordSuccess,
        payload: userResetPasswordData,
      });
      data.payload.action(userResetPasswordData);
    }
  } catch (error) {
    yield put(actions.userResetPasswordFail(error));
  }
}

function* updateUserProfileSaga (data: any) {
  try {
    yield put(actions.updateUserProfileSuccess(data.payload));
  } catch (error) {
    yield put(actions.updateUserProfileFail);
  }
}

function* viewSiteFilteredSaga (payload: any) {
  try {
    const siteData = yield UsersTransportLayer.getSiteFiltered(payload.payload);
    if (!isEmpty(siteData)) {
      yield put(actions.viewFilteredSiteDataSuccess(siteData));
    } else {
      yield put(actions.viewFilteredSiteDataSuccess([]));
    }
  } catch (error) {
    yield put(actions.viewFilteredSiteDataFail());
  }
}

function* viewRoleFilteredSaga (payload: any) {
  try {
    const roleData = yield UsersTransportLayer.getRoleFiltered(payload.payload);
    if (!isEmpty(roleData)) {
      yield put(actions.viewFilteredRoleDataSuccess(roleData));
    } else {
      yield put(actions.viewFilteredRoleDataSuccess([]));
    }
  } catch (error) {
    yield put(actions.viewFilteredRoleDataFail());
  }
}

export default function* watchUserSagas () {
  yield takeLatest(actions.userAddInit, usersAddSaga);
  yield takeLatest(actions.userEditInit, usersEditSaga);
  yield takeLatest(actions.userUpdateStatusInit, usersUpdateStatusSaga);
  yield takeLatest(actions.userFetchInit, userDetailsSaga);
  yield takeLatest(actions.viewRegionsAndSites, viewRegionsAndSitesSaga);
  yield takeLatest(actions.viewOrganizationInit, viewOrganizationSaga);
  yield takeLatest(actions.viewFilterSiteDataInit, viewSiteFilterSaga);
  yield takeLatest(actions.viewFilterRoleDataInit, viewRoleFilterSaga);
  yield takeLatest(actions.validateEmailInit, validateEmailSaga);
  yield takeLatest(actions.userResetPasswordInit, usersResetPasswordSaga);
  yield takeLatest(actions.updateUserProfileInit, updateUserProfileSaga);
  yield takeLatest(actions.viewFilteredSiteDataInit, viewSiteFilteredSaga);
  yield takeLatest(actions.viewFilteredRoleDataInit, viewRoleFilteredSaga);
}
